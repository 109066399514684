import axios from 'axios';
import { createCancelToken, statusError, CancelCallback, Result, errorResult } from '../apiActions';

export type NetworkByHostReqParams = {
  subdomain: string;
};

export async function getNetworkByHost(
  params: NetworkByHostReqParams,
  cancelCallback?: CancelCallback
): Promise<{ network: Network; featureToggle: FeatureToogle } | number | undefined> {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const { data } = await axios.get('/api/network/getNetworkByHost', { params, cancelToken });

    return data;
  } catch (error) {
    return statusError(error);
  }
}

type ValidSubdomainParams = {
  username: string;
  subdomain: string;
};

export async function checkValidSubdomain(
  params: ValidSubdomainParams,
  cancelCallback?: CancelCallback
): Promise<Result<boolean>> {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const { status, data } = await axios.get(`/api/Network/CheckValidSubdomain`, {
      params,
      cancelToken,
    });

    return { ok: true, status, data };
  } catch (error) {
    console.log(error);
    return errorResult(error);
  }
}
