import { Fragment, useEffect, useRef } from 'react';

import { useAuth } from 'hooks';
import { fetchMedia } from 'lib/helper';

import FSModal, { useModal } from 'components/modal/FullscreenModal/FullscreenModal';
import AcceptTerms from 'components/modal/TermsModal/AcceptTerms';

import logo from 'assets/images/work+.png';

import { LogoWrapper } from './TermsModalStyles';

const TermsModal = () => {
  const { network, userId, userInfo, token } = useAuth();

  const { open, modalProps } = useModal({
    background: '#fff',
    onClose: () => triggerRef.current?.click(),
  });

  const triggerRef = useRef<HTMLButtonElement>(null);

  const { user } = userInfo;
  useEffect(() => {
    if (userId) {
      if (!user.termsAccepted || !user.lgpdTermsAccepted) {
        triggerRef.current?.click();
      }
    }
  }, [userId, user?.termsAccepted, user?.lgpdTermsAccepted]);

  if (user?.termsAccepted && user?.lgpdTermsAccepted) return null;

  return (
    <Fragment>
      <FSModal
        closable={false}
        header={
          <LogoWrapper>
            <img src={network.logo ? fetchMedia(network.logo, token) : logo} alt="network logo" />
          </LogoWrapper>
        }
        modalProps={modalProps}
      >
        <AcceptTerms />
      </FSModal>
      <button
        style={{ visibility: 'hidden', opacity: 0 }}
        onClick={() => open(triggerRef)}
        ref={triggerRef}
      />
    </Fragment>
  );
};

export default TermsModal;
