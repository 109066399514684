import React, { useLayoutEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/pro-solid-svg-icons';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from 'antd';

import { useSelector } from 'store';
import { like, dislike } from 'store/actions/postsActions';
import { makeGetPostSelector } from 'store/selectors/postSelectors';

// #region STYLES
const button = (liked: boolean) => (theme: Themes.DefaultTheme) =>
  css`
    &.ant-btn-link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      padding: 0;
      margin: 0;
      color: inherit;
      background: none;
      border: 0;

      ${liked &&
      css`
        color: ${theme.colors.primary};
      `}
    }
  `;

const Label = styled.span`
  text-transform: capitalize;
`;
// #endregion

interface LikeButtonProps {
  className: string;
  postId: number;
}

const LikeButton: React.FC<LikeButtonProps> = props => {
  const { className, postId } = props;
  const { t } = useTranslation();

  // REDUX
  const { current: getPost } = useRef(makeGetPostSelector());
  const dispatch: AppDispatch = useDispatch();
  const { liked } = useSelector((state: RootState) => getPost(state, postId));

  const btnLikeRef = useRef<HTMLButtonElement>(null);

  const handleLike = () => {
    if (!liked) {
      dispatch(like(postId));
    } else {
      dispatch(dislike(postId));
    }
  };

  useLayoutEffect(() => {
    btnLikeRef.current?.blur();
  }, [liked]);

  return (
    <Button
      css={button(liked)}
      type="link"
      size="small"
      className={className}
      onClick={handleLike}
      ref={btnLikeRef}
    >
      <FontAwesomeIcon className="anticon" icon={faThumbsUp} />
      <Label>{t('like')}</Label>
    </Button>
  );
};

export default LikeButton;
