import { useEffect, useState, useCallback } from 'react';

const { gapi } = window;
const GAPI_KEY = process.env.REACT_APP_GAPI_KEY;
const GAPI_CLIENT = process.env.REACT_APP_GAPI_CLIENT;

function useGapi(discoveryDocs: string[], scope: string) {
  const [error, setError] = useState<any>(undefined);
  const [initializing, setInitializing] = useState(true);
  const [isSignedIn, setSignedIn] = useState(false);
  const [hasScope, setHasScope] = useState(false);

  const updateSigninStatus = useCallback(isSignedIn => {
    setSignedIn(isSignedIn);
  }, []);

  const updateGoogleUser = useCallback(
    (googleUser: gapi.auth2.GoogleUser) => {
      setHasScope(googleUser.hasGrantedScopes(scope));
    },
    [scope]
  );

  useEffect(() => {
    const initClient = async () => {
      try {
        await gapi.client.init({
          apiKey: GAPI_KEY,
          clientId: GAPI_CLIENT,
          discoveryDocs,
          scope,
        });

        const authInstance = gapi.auth2.getAuthInstance();
        authInstance.isSignedIn.listen(updateSigninStatus);
        updateSigninStatus(authInstance.isSignedIn.get());

        authInstance.currentUser.listen(updateGoogleUser);
        updateGoogleUser(authInstance.currentUser.get());
      } catch (error) {
        setError(error);
      } finally {
        setInitializing(false);
      }
    };

    if (!isSignedIn) gapi.load('client:auth2', initClient);
  }, [discoveryDocs, isSignedIn, scope, updateGoogleUser, updateSigninStatus]);
  return { isSignedIn, hasScope, initializing, error };
}

export default useGapi;
