/* eslint-disable react/jsx-props-no-spreading */
import { useDropzone, FileRejection } from 'react-dropzone';
import { useTheme } from '@emotion/react';
import useAuth from '../../../../../hooks/useAuth';

import { iconUploader, iconDisabled } from '../UploaderStyle';

//#region TYPES
type FileRejectedHandler = (rejectedFiles: File[], fileRejections: FileRejection[]) => void;
export type { FileRejection };

export interface FileUploaderProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onVideoAdd: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  onFileRejected?: FileRejectedHandler;
}

function sizeValidator(file: File, maxPostFileSize: number) {
  if (file.size > maxPostFileSize * 1e6) {
    return { message: `Video cannot exceed ${maxPostFileSize}MB`, code: 'file-too-large' };
  }

  return null;
}

function VideoUploader({
  onVideoAdd,
  className = '',
  children,
  disabled,
  onFileRejected,
}: FileUploaderProps) {
  const theme = useTheme();
  const { maxPostFileSize } = useAuth().networkSettings;

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'video/*,.flv,.mkv',
    onDrop: onVideoAdd,
    multiple: false,
    noDrag: true,
    noKeyboard: true,
    disabled,
    maxSize: maxPostFileSize * 1e6,
    validator: file => sizeValidator(file, maxPostFileSize),
    onDropRejected: fileRejections =>
      onFileRejected?.(
        fileRejections.map(({ file }) => file),
        fileRejections
      ),
  });

  return (
    <div
      {...getRootProps({
        className: `${iconUploader(theme)} ${disabled && iconDisabled(theme)} ${className}`,
      })}
    >
      <input {...getInputProps()} />
      {children}
    </div>
  );
}

export default VideoUploader;
