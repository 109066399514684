const usersEn = {
  briefDescription: 'A brief description of the user',
  coworkers: 'Coworkers',
  coworkersFollow: 'Coworkers I follow',
  currentPassword: 'Current password',
  editProfile: 'Edit profile',
  fullName: 'Full name',
  linkDescription: 'The user can inform a link of personal website, portfolio, etc.',
  newCoworker: 'New coworker',
  newPassword: 'New password',
  notFoundTitle: 'We could not find who you were looking for.',
  notFoundSubtitle: 'Is this person in the company?',
  peopleFound: 'We have found {{count}} person',
  peopleFound_plural: 'We have found {{count}} people',
  repeatPassword: 'Repeat new password',
  searchPeople: 'Search people',
  userUpdated: 'User information updated',
  errors: {
    couldNotSaveContactInfo: "Couldn't save contact info",
    uq_userinfo_email: 'The e-mail is already being used in this network',
    uq_userinfo_username: 'The username is already being used in this network',
  },
};

export default usersEn;
