import { VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

import login_screen from 'assets/images/screen-person.png';

import { Container, Divider, Wrapper } from './TransitionScreenStyles';

//#region TYPES
export interface TransitionScreenProps {
  errorType?: string | Nullable;
}
//#endregion

const TransitionScreen: VFC<TransitionScreenProps> = ({ errorType }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Wrapper>
        <div className="img-wrapper">
          <img src={login_screen} alt="Pessoa em frente a um monitor" />
        </div>
        {!errorType && <FontAwesomeIcon className="spinner" icon={faSpinner} spin size="3x" />}
        <Divider />
        <p>{errorType ? t(`login.seniorXErrors.${errorType}`) : t('login.waitSeniorX')}</p>
      </Wrapper>
    </Container>
  );
};

export default TransitionScreen;
