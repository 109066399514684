import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// ACTIONS

// GLOBAL COMPONENTS
import { Menu, Dropdown } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faCaretDown, faUserLock, faUserFriends } from '@fortawesome/pro-solid-svg-icons';
import { createSelector } from 'reselect';

// HOOKS
import { useFeatureToggle, usePermissions } from 'hooks';
import { useSelector } from 'store';
import { changeVisibility } from 'store/actions/newPostActions';
import { Visibility } from 'store/types/newPostTypes';

import * as S from './PostScopeSelectorStyles';

//#region TYPES
interface PostScopeSelectorProps {
  disabled?: boolean;
  storeKey: 'NEW_POST';
}
interface ToggleButtonProps {
  selected: boolean;
  isNewComposer: boolean;
}
//#endregion

const newPostSelector = createSelector(
  (state: RootState) => state.newPost.posts,
  (_: RootState, storeKey: 'NEW_POST') => storeKey,
  (posts, storeKey) => {
    const newPostStore = posts[storeKey];
    return { visibility: newPostStore?.visibility };
  }
);

const PostScopeSelector: React.VFC<PostScopeSelectorProps> = ({ disabled = false, storeKey }) => {
  const [createPublicPost] = usePermissions('createPublicPost');
  const [isNewComposer] = useFeatureToggle('new_composer');

  const dispatch = useDispatch();
  const { visibility } = useSelector(state => newPostSelector(state, storeKey));

  useEffect(() => {
    if (!createPublicPost) dispatch(changeVisibility(storeKey, 'followers'));
  }, [dispatch, createPublicPost, storeKey]);

  const handleVisibility = useCallback(
    ({ key }) => {
      if (key === visibility) return;
      dispatch(changeVisibility(storeKey, key));
    },
    [dispatch, storeKey, visibility]
  );

  const menu = (
    <Menu onClick={handleVisibility}>
      {createPublicPost && (
        <Menu.Item key="public">
          <Public selected={visibility === 'public'} isNewComposer={isNewComposer} />
        </Menu.Item>
      )}
      <Menu.Item key="private">
        <Private selected={visibility === 'private'} isNewComposer={isNewComposer} />
      </Menu.Item>
      <Menu.Item key="followers">
        <Followers selected={visibility === 'followers'} isNewComposer={isNewComposer} />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown disabled={disabled} overlay={menu}>
      <S.Button isNewComposer={isNewComposer}>
        <ToggleButton
          visibility={visibility}
          createPublicPost={createPublicPost}
          isNewComposer={isNewComposer}
        />
        <FontAwesomeIcon icon={faCaretDown} className="caret" />
      </S.Button>
    </Dropdown>
  );
};

export default PostScopeSelector;

const Public: React.VFC<ToggleButtonProps> = ({ selected, isNewComposer }) => {
  const { t } = useTranslation();

  return (
    <>
      <S.Icon icon={faGlobe} selected={selected} isNewComposer={isNewComposer} />
      <S.Label selected={selected} isNewComposer={isNewComposer}>
        {t('new-post.visibility.public')}
      </S.Label>
    </>
  );
};
const Private: React.VFC<ToggleButtonProps> = ({ selected, isNewComposer }) => {
  const { t } = useTranslation();

  return (
    <>
      <S.Icon icon={faUserLock} selected={selected} isNewComposer={isNewComposer} />
      <S.Label selected={selected} isNewComposer={isNewComposer}>
        {t('new-post.visibility.private')}
      </S.Label>
    </>
  );
};

const Followers: React.VFC<ToggleButtonProps> = ({ selected, isNewComposer }) => {
  const { t } = useTranslation();

  return (
    <>
      <S.Icon icon={faUserFriends} selected={selected} isNewComposer={isNewComposer} />
      <S.Label selected={selected} isNewComposer={isNewComposer}>
        {' '}
        {t('new-post.visibility.followers')}
      </S.Label>
    </>
  );
};

function ToggleButton({
  visibility,
  createPublicPost,
  isNewComposer,
}: {
  visibility: Omit<Visibility, 'group' | 'admin'> | undefined;
  createPublicPost: boolean;
  isNewComposer: boolean;
}) {
  switch (visibility) {
    case 'public':
      return <Public selected={visibility === 'public'} isNewComposer={isNewComposer} />;
    case 'private':
      return <Private selected={visibility === 'private'} isNewComposer={isNewComposer} />;
    case 'followers':
      return <Followers selected={visibility === 'followers'} isNewComposer={isNewComposer} />;
    default: {
      if (!createPublicPost)
        return <Followers selected={visibility === 'followers'} isNewComposer={isNewComposer} />;
      return <Public selected={visibility === 'public'} isNewComposer={isNewComposer} />;
    }
  }
}
