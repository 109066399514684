/* eslint-disable no-undef */
export const PROFILE_TYPES = {
  user: 1,
  group: 2,
  publisher: 3,
} as const;
export const MEDIA_TYPES = {
  image: 1,
  video: 2,
  file: 4,
  audio: 5,
  survey: 6,
} as const;
export const POST_TYPES = {
  all: [1, 2, 3, 4],
  public: 1,
  private: 2,
  following: 3,
  group: 4,
} as const;
export const ITEM_TYPES = {
  post: 1,
  event: 2,
  survey: 3,
  video: 4,
  album: 5,
  photo: 6,
  folder: 7,
  file: 8,
  comment: 9,
  birthday: 10,
  chat: 11,
  group: 14,
  chatChannel: 16,
} as const;

export const NOTIFICATION_EVENTS = {
  groupPublicPost: 1,
  postMention: 2,
  commentMention: 3,
  addedPrivateGroup: 4,
  videoFinish: 5,
  scheduleExecuted: 6,
} as const;

export const NOTIFICATION_TYPES = {
  notificationPanel: 1,
  email: 2,
  push: 3,
} as const;

export const CHAT_ROOM_TYPES = {
  individual: 1,
  group: 2,
} as const;

export const PEOPLE_FILTER = {
  active: 1,
  disabled: 2,
  follow: 3,
} as const;

export const DEVICE_PLATFORMS = {
  android: 1,
  ios: 2,
  webBrowser: 3,
} as const;

export const POST_VIEW_TYPE = {
  like: 1,
  feed: 2,
  comment: 3,
  visualization: 4,
  email: 5,
  viewCount: 6,
  likeCount: 7,
  commentCount: 8,
} as const;

export const REACTION_TYPE = {
  like: 1,
  love: 2,
  applause: 3,
  support: 4,
} as const;

export enum NotificationMessageType {
  NewMentions,
  NewComments,
  AddedToPage,
  AddedToGroup,
  NewLikes,
  NewBirthdays,
  UserConnected,
  UserDisconnected,
  UpdatedPermissions,
  DeactivatedUser,
  DeactivatedNetwork,
  NewAppVersion,
  NewPosts,
  SendToAllExcept,
  SendPrivateInpersonated,
  PublicPost,
  GroupPublicPost,
  SendPrivate,
  SendToUser,
  EditPostReady,
  VideoFinished,
  ScheduleExecuted,
  DeletedChatRoom,
  DeletedMessage,
  NewChatMessage,
}

declare global {
  /** { user: 1, group: 2, publisher: 3 } */
  export type ProfileTypes = (typeof PROFILE_TYPES)[keyof typeof PROFILE_TYPES];
  /** { image: 1, video: 2, file: 4, audio: 5 } */
  export type MediaTypes = (typeof MEDIA_TYPES)[keyof typeof MEDIA_TYPES];
  /** { all: [1, 2, 3, 4], public: 1, private: 2, following: 3, group: 4 } */
  export type PostTypes = (typeof POST_TYPES)[keyof typeof POST_TYPES];
  /**
   *  * post: 1
   *  * event: 2
   *  * survey: 3
   *  * video: 4
   *  * album: 5
   *  * photo: 6
   *  * folder: 7
   *  * file: 8
   *  * comment: 9
   *  * birthday: 10
   *  * chat: 11
   *  * group: 14
   *  * chatChannel: 16
   * */
  export type ItemTypes = (typeof ITEM_TYPES)[keyof typeof ITEM_TYPES];

  /** { groupPublicPost: 1, postMention: 2, commentMention: 3, addedPrivateGroup: 4, videoFinish: 5, scheduleExecuted: 6 } */
  export type NotificationEventType =
    (typeof NOTIFICATION_EVENTS)[keyof typeof NOTIFICATION_EVENTS];
  /** { notificationPanel: 1, email: 2, push: 3 } */
  export type NotificationType = (typeof NOTIFICATION_TYPES)[keyof typeof NOTIFICATION_TYPES];

  export type ChatRoomTypes = (typeof CHAT_ROOM_TYPES)[keyof typeof CHAT_ROOM_TYPES];

  /** { active: 1, disabled: 2, follow: 3 } */
  export type PeopleFilters = (typeof PEOPLE_FILTER)[keyof typeof PEOPLE_FILTER];

  /** { android: 1, ios: 2, webBrowser: 3 } */
  export type DevicePlatforms = (typeof DEVICE_PLATFORMS)[keyof typeof DEVICE_PLATFORMS];

  /** { like: 1, feed: 2, comment: 3, visualization: 4, email: 5, viewCount: 6, likeCount: 7, commentCount: 8 } */
  export type PostViewType = (typeof POST_VIEW_TYPE)[keyof typeof POST_VIEW_TYPE];

  export type ReactionType = (typeof REACTION_TYPE)[keyof typeof REACTION_TYPE];
}

export const LS_WIDGET_CONFIG = '4bee.widget-config';

export const REFRESH_PROFILE_IMG = 'REFRESH_PROFILE_IMG';

export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegex =
  /^\+?(?:[0-9]{2})? ?\(? ?[1-9]{2} ?\)? (?:[2-8]|9[1-9])[0-9]{3}(?: |-)[0-9]{4}$/;
export const hexRegex = /^#([0-9A-F]{3}){1,2}$/i;
export const phoneMaskRegex = /[+()\- ]/g;
export const urlRegex =
  /^(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/s;
export const httpUrlRegex =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/s;
export const httpProtocolUrlRegex = /[a-zA-Z]?:\/\/\/?[-a-zA-Z0-9@:%._+~#=]/;

// API URLS
const HTTPS = window.location.protocol === 'https:';
const ENV = process.env;
export const API_DOMAIN = HTTPS ? ENV.REACT_APP_API_DOMAIN_SSL : ENV.REACT_APP_API_DOMAIN;
export const AUTH_API = HTTPS ? ENV.REACT_APP_AUTH_API_SSL : ENV.REACT_APP_AUTH_API;
export const LEGACY_4BEE = ENV.REACT_APP_4BEE_LEGACY;
export const LINK_PREVIEW_API = HTTPS ? ENV.REACT_APP_LINK_PREVIEW_SSL : ENV.REACT_APP_LINK_PREVIEW;
export const API_MEDIA = HTTPS ? ENV.REACT_APP_GET_MEDIA_SSL : ENV.REACT_APP_GET_MEDIA;

// GAPI
export const CALENDAR_DISCOVERY = ENV.REACT_APP_CALENDAR_DISCOVERY!;
export const CALENDAR_SCOPE = ENV.REACT_APP_GAPI_CALENDAR_SCOPE!;

// GA
export const GA_ID = ENV.REACT_APP_GA_ID!;
export const GTAG_ID = ENV.REACT_APP_GTAG_ID!;

export const APP_TITLE = ENV.REACT_APP_TITLE!;

// FIREBASE
export const FIREBASE_KEY = ENV.REACT_APP_FIREBASE_KEY!;
export const APP_STORE_CODE = ENV.REACT_APP_APP_STORE_CODE!;
export const VAPID_KEY = ENV.REACT_APP_FCM_VAPID_KEY!;

// reCapatcha key
export const RECAPTCHA_KEY = '6LdHi-oUAAAAAHVfaLUKx2VoNmsFejTYf-xy-t43';
