import { useFeatureToggle } from 'hooks';
import SidebarNew from './Sidebar';
import SidebarOld from './Sidebar_Old';

function Sidebar() {
  const [newFeed] = useFeatureToggle('new_feed');

  if (newFeed) return <SidebarNew />;

  return <SidebarOld />;
}

export default Sidebar;
