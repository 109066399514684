const settingsPt = {
  language: {
    changeLanguage: 'Altere o idioma',
    chooseLanguage: 'Escolha o idioma que você quer usar no 4beeWork',
  },
  notifications: {
    emailNotifications: 'Notificações por email',
    mentionInComment: 'Comentários em que você foi mencionado',
    mentionInPost: 'Qualquer post que você for mencionado',
    notificationHint: 'Você pode escolher quais notificações deseja receber por email.',
    whenAddInGroup: 'Quando você for adicionado a um grupo privado',
    whenGroupPostPublic: 'Quando um grupo criar um post público',
    whenPostPublished: 'Quando um post agendado for publicado',
    whenVideoConverted: 'Quando a conversão de um vídeo for concluída',
  },
  security: {
    changePassword: 'Alterar Senha',
    changePasswordDescription: 'Você pode atualizar sua senha',
    description:
      'A área de segurança de uma conta é uma seção onde o usuário pode alterar sua senha.',
    descriptionInit: 'Agora você pode cadastrar um ',
    descriptionEnd: ' para fazer o seu login de forma rápida na sua rede.',
    descriptionBoldInit: 'e-mail pessoal',
    descriptionBoldEnd: ' corporativo',
    lastUpdate: 'Última atualização',
    recoveryEmail: 'E-mail de login por código único',
    recoveryEmailDescription: 'Adicione um endereço de e-mail',
    recoveryTitlePlaceHolder: 'E-mail de código único',
    tabTitle: 'Segurança',
    tabUniqueCodeTitle: 'Login por código único',
    tabUniqueCodeDescription:
      'O código de segurança será enviado para o seu e-mail para verificar sua identidade ao fazer login.',
    recoveryEmailPane: {
      description:
        'será usado para enviarmos o código de acesso para a sua conta. Você pode usar um ',
      boldInit: 'O seu e-mail para login ',
      saveSuccess: 'E-mail salvo com sucesso.',
    },
  },
};

export default settingsPt;
