// GLOBAL
import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Button } from 'antd';
// import { CheckboxOptionType } from 'antd/es/checkbox';
import PubSub from 'pubsub-js';
// HOOKS
import { useTranslation } from 'react-i18next';

import styled from '@emotion/styled';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';

import { useAuth } from 'hooks';
import { MEDIA_TYPES, POST_TYPES } from 'lib/constants';
// REDUX
import { updateFeedFilters } from 'store/actions/postsActions';
import { selectFilters } from 'store/selectors/postSelectors';

import Sider from 'components/layout/LeftSide/Sider/Sider';
import SearchInput from 'components/common/SearchInput';

//#region TYPES
interface LabelProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}
//#endregion

// #region STYLES
const Container = styled.div`
  position: relative;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  .check-box-group .ant-radio-wrapper {
    display: block;
    color: $gray-label;
  }
`;
const SectionTitle = styled.h6`
  color: ${({ theme }) => theme.colors.gray8};
  text-transform: uppercase;
`;
const AppContainer = styled.div`
  margin-top: auto;

  .app {
    margin-bottom: 8px;
  }

  button {
    padding: 0;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.gray10};

    ${({ theme }) => theme.typography.fontSmall()};
  }
`;
const StyledLabel = styled.div`
  /* margin-top: 16px; */
  margin-bottom: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray9};

  ${({ theme }) => theme.typography.fontNormal()}
`;
const Icon = styled.div`
  display: inline-block;
  width: 28px;
  height: 28px;
  margin-right: 8px;
  color: #fff;
  background: ${({ theme }) => theme.colors.gray3};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  ${({ theme }) => theme.typography.fontNormal(1.75)};
`;

const TextButton = styled.span`
  font-weight: 600;
  ${({ theme }) => theme.typography.fontSmall(1.75)};
  color: ${({ theme }) => theme.colors.gray10};
`;
// #endregion

const Label: React.VFC<LabelProps> = ({ children, ...rest }) => (
  <StyledLabel {...rest}>{children}</StyledLabel>
);
const Sidebar = () => {
  const { t } = useTranslation();
  const { networkFunctions } = useAuth();
  const [query, setQuery] = useState('');

  // REDUX
  const dispatch = useDispatch();
  const filters = useSelector(selectFilters);
  const { mediaType } = filters;

  // const postTypesOptions: Array<CheckboxOptionType> = [
  //   { label: t('feed.all'), value: POST_TYPES.all as any },
  //   {
  //     label: t('feed.public'),
  //     value: POST_TYPES.public,
  //   },
  //   {
  //     label: t('feed.private'),
  //     value: POST_TYPES.private,
  //   },
  //   {
  //     label: t('feed.following'),
  //     value: POST_TYPES.following,
  //   },
  // ];

  // const onChangeVisibility = useCallback(
  //   values => {
  //     const { target } = values;
  //     dispatch(updateFeedFilters({ postTypes: target.value }));
  //   },
  //   [dispatch]
  // );
  const onChangeContent = useCallback(
    value => {
      dispatch(updateFeedFilters({ mediaType: value }));
    },
    [dispatch]
  );

  const clear = useCallback(() => {
    setQuery('');
  }, []);

  useEffect(() => {
    setQuery(filters.query!);
  }, [filters.query]);

  useEffect(() => {
    return () => {
      dispatch(updateFeedFilters({ postTypes: POST_TYPES.all, mediaType: undefined, query: '' }));
    };
  }, [dispatch]);

  useEffect(() => {
    PubSub.subscribe('CLEAR_FILTERS', clear);
    return () => {
      PubSub.unsubscribe('CLEAR_FILTERS');
    };
  }, [clear]);

  return (
    <Sider>
      <Container>
        {/* QUERY */}
        <h1>{t('feed.home')}</h1>
        <SearchInput
          allowClear
          placeholder={t('feed.search')}
          value={query}
          onChange={({ target }) => setQuery(target.value)}
          onSearch={value => dispatch(updateFeedFilters({ query: value }))}
          style={{ width: '100%', margin: '7px 0 16px' }}
        />
        <SectionTitle style={{ fontWeight: 600 }}>{t('filters')}</SectionTitle>
        {/* VISIBILITY */}
        {/* <Label>{t('feed.visibility')}</Label>
        <Radio.Group
          className="check-box-group"
          onChange={onChangeVisibility}
          value={postTypes}
          options={postTypesOptions}
        /> */}

        {/* MEDIA TYPE */}
        <Label>{t('feed.content.label')}</Label>
        <Select value={mediaType || 0} onChange={onChangeContent} style={{ width: '100%' }}>
          <Select.Option value={0}>{t('feed.content.all_types')}</Select.Option>
          <Select.Option value={MEDIA_TYPES.image}>{t('feed.content.images')}</Select.Option>
          <Select.Option value={MEDIA_TYPES.video}>{t('feed.content.videos')}</Select.Option>
          <Select.Option value={MEDIA_TYPES.file}>{t('feed.content.files')}</Select.Option>
          {networkFunctions.polls && (
            <Select.Option value={MEDIA_TYPES.survey}>{t('common.survey')}</Select.Option>
          )}
        </Select>

        <AppContainer>
          <SectionTitle>{t('availableApp')}</SectionTitle>
          <div className="app">
            <Button
              type="link"
              href="https://play.google.com/store/apps/details?id=br.com.android.beeworkplus"
              target="_blank"
              style={{ marginLeft: -12 }}
            >
              <Icon>
                <FontAwesomeIcon icon={faGooglePlay} color="#757575" />
              </Icon>

              <TextButton>Android</TextButton>
            </Button>
          </div>
          <div className="app">
            <Button
              type="link"
              href="https://apps.apple.com/br/app/4bee-work-plus/id1510586849"
              target="_blank"
              style={{ marginLeft: -12 }}
            >
              <Icon>
                <FontAwesomeIcon icon={faApple} color="#757575" />
              </Icon>
              <TextButton>iOS</TextButton>
            </Button>
          </div>
        </AppContainer>
      </Container>
    </Sider>
  );
};

export default Sidebar;
