import { useState } from 'react';

import { Skeleton } from 'antd';

import { useAuth } from 'hooks';
import { getNetworkLogo } from 'lib/helper';

import logo from 'assets/images/work+.svg';

// COMPONENTS
import Navbar from '../Navbar/Navbar';
import SearchBox from '../Navbar/SearchBox/SearchBox';
import ProfileAvatar from './ProfileAvatar/ProfileAvatar';
import ChatButton from './ChatButton/ChatButton';

import {
  Logo,
  LogoLink,
  LogoWrapper,
  SearchSkeleton,
  SideColumn,
  Wrapper,
} from './AppHeaderStyles';

type AppHeaderProps = {
  loading: boolean;
};

const AppHeader = ({ loading }: AppHeaderProps) => {
  const { network } = useAuth();

  const [isLogoError, setLogoError] = useState(false);

  const logoUrl = getNetworkLogo(network);

  return (
    <Wrapper>
      <LogoLink to="/">
        <LogoWrapper>
          {network.networkId ? (
            <Logo
              alt="Network logo"
              src={isLogoError ? logo : logoUrl}
              onError={() => setLogoError(true)}
            />
          ) : (
            <Skeleton.Image style={{ maxHeight: '32px', width: '32px' }} />
          )}
        </LogoWrapper>
      </LogoLink>
      <SideColumn>
        <SearchSkeleton
          active
          loading={loading}
          paragraph={{ rows: 1, width: '100%' }}
          title={false}
        >
          <SearchBox />
          <Navbar />
        </SearchSkeleton>
      </SideColumn>
      <ProfileAvatar />
      <ChatButton />
    </Wrapper>
  );
};

export default AppHeader;
