import styled from '@emotion/styled';
import { up } from 'styled-breakpoints';
import { BackTop as AntBackTop } from 'antd';
import { basePostsContent, basePostsInnerContent } from 'styles/component-styles/feed';
import { css } from '@emotion/react';

export const PostsContent = styled.div`
  ${basePostsContent}
`;

export const InnerPostsContent = styled.div`
  ${basePostsInnerContent}
`;

export const DrawerActionsWrapper = styled.div`
  display: none;
  margin-bottom: 2em;

  i {
    margin-right: 4px;
  }

  ${up('tablet')} {
    display: block;
  }
`;

export const BackTop = styled(AntBackTop)`
  right: 20%;

  ${up('lgTablet')} {
    right: 30%;
  }
`;

export const TabTitle = styled.span`
  display: inline-block;
  margin-right: 0.5rem;
`;

export const TabCount = styled.span`
  ${({ theme }) => css`
    display: inline-block;
    padding: 0.25em 0.5em;
    max-width: 2.5rem;
    height: 100%;
    border-radius: 4px;
    color: ${theme.colors.white};
    background-color: ${theme.colors.danger};
    font-weight: normal;
    ${theme.typography.fontxxSmall()}
  `}
`;
