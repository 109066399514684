import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import ImageUploader from '../../Common/Uploader/ImageUploader/ImageUploader';
import FilePreview from '../../Common/Attachment/FilePreview';
import LinkAttachment from '../../Common/Attachment/LinkAttachment/LinkAttachment';
import Preview from '../../Common/Preview/ImagePreview/Preview';

import { AddImage, Wrapper, mediaPreview, previewImageContainer } from './AttachmentsPreviewStyles';

function usePreview({ resetFiles, fileType, handleMediaRemoved, loading }) {
  const preview = {
    file: file => (
      <FilePreview file={file} key={file.name} onClickDelete={resetFiles} disable={loading} />
    ),
    image: file => (
      <Preview
        css={mediaPreview}
        disable={loading}
        key={file.index}
        fileUrl={file.preview}
        type="image"
        remove={() => handleMediaRemoved(file)}
      />
    ),
    link: file => (
      <LinkAttachment
        disable={loading}
        key={file.url}
        url={file.url}
        title={file.title}
        clickable={false}
        description={file.description}
        image={file.images && file.images[0]}
        onRemove={resetFiles}
        newPost
      />
    ),
    pureLink: attachment => (
      <div>
        <a href={attachment.url} target="_blank" rel="noopener noreferer noreferrer">
          {attachment.url}
        </a>
        <Button
          type="text"
          shape="circle"
          icon={<FontAwesomeIcon icon={faTimes} className="anticon" />}
          onClick={resetFiles}
        />
      </div>
    ),
    video: file => (
      <Preview
        css={mediaPreview}
        key={file.index}
        fileUrl={file.preview}
        type={fileType}
        remove={() => handleMediaRemoved(file)}
      />
    ),
  };

  return preview[fileType];
}

const AttachmentsPreview = props => {
  const { resetFiles, files, fileType, handleFileAdded, handleMediaRemoved, loading } = props;

  const { t } = useTranslation();
  const networkSettings = useSelector(state => state.auth.networkSettings);

  const preview = usePreview({ resetFiles, fileType, handleMediaRemoved, loading });

  const renderAddMoreImages = () => {
    const arrayFilled = files.length === networkSettings.MaxPhotosPermittedInPost;
    return (
      <AddImage title={arrayFilled ? t('files.fileLimit') : ''}>
        <div className="image-text">{t('files.add')}</div>
        <div className="image-count">({files.length})</div>
      </AddImage>
    );
  };

  const renderPreview = () => {
    const wrapperClasses = {
      image: previewImageContainer,
    };

    if (!preview) return null;

    return (
      <div css={wrapperClasses[fileType]}>
        {fileType === 'image' && (
          <ImageUploader
            css={mediaPreview}
            onImageAdd={acceptedFiles => handleFileAdded(acceptedFiles, 'image')}
            disabled={files.length >= networkSettings.MaxPhotosPermittedInPost}
            canSendMultiple
          >
            {renderAddMoreImages()}
          </ImageUploader>
        )}
        {files.map(preview)}
      </div>
    );
  };

  return <Wrapper>{renderPreview()}</Wrapper>;
};

export default AttachmentsPreview;
