import React, { ReactEventHandler, HTMLProps } from 'react';

import styled from '@emotion/styled';
import { up } from 'styled-breakpoints';

// #region STYLES
const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 405px;
  overflow: hidden;
  background: ${({ theme }) => theme.colors.gray1};

  img {
    width: 100%;
    object-fit: cover;

    ${up('tablet')} {
      max-width: 171px;
    }
  }
`;
// #endregion

type ImageAttachmentProps = Pick<HTMLProps<HTMLImageElement>, 'src' | 'alt' | 'className'> & {
  onImgError?: ReactEventHandler<HTMLImageElement>;
};

const ImageAttachment = React.forwardRef<HTMLImageElement, ImageAttachmentProps>((props, ref) => {
  const { src, alt, className, onImgError } = props;

  return (
    <>
      <ImageContainer className={className}>
        <img src={src} alt={alt} ref={ref} onError={onImgError} />
      </ImageContainer>
    </>
  );
});
ImageAttachment.defaultProps = {
  className: '',
};

export default ImageAttachment;
