import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { css } from '@emotion/react';
import { Button } from 'antd';

// #region STYLES
const button = css`
  &.ant-btn-link {
    color: inherit;
  }
`;
// #endregion

interface CommentButtonProps {
  onClick: () => void;
}

const CommentButton = (props: CommentButtonProps) => {
  const { t } = useTranslation();
  const { onClick } = props;
  return (
    <Button css={button} type="link" size="small" onClick={onClick}>
      <FontAwesomeIcon icon={faComment} className="anticon" />
      {t('post.comment')}
    </Button>
  );
};

export default CommentButton;
