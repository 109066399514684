import { pipe, sortBy, reverse, filter } from 'lodash/fp';
import { createSelector } from 'reselect';
import moment from 'moment';

import { CHAT_ROOM_TYPES } from 'lib/constants';

export const chatSelector = (state: RootState) => state.chat;
const messagesSelector = (state: RootState, roomId: number) => chatSelector(state).rooms[roomId];
const chatRoomsSelector = (state: RootState) => chatSelector(state).chatList;

export const makeGetChatMessagesSelector = () =>
  createSelector(messagesSelector, messages => {
    if (!messages) return [];
    return sortBy(m => moment(m.message.created).toDate(), messages);
  });

export const getRooms = createSelector(
  chatRoomsSelector,
  pipe(
    filter<ChatRoomInfo>(
      r => r.room.type === CHAT_ROOM_TYPES.individual || r.room.type === CHAT_ROOM_TYPES.group
    ),
    sortBy(r => moment(r.room.messages[0].created).toDate()),
    reverse
  )
);

export const getIndividualRooms = createSelector(
  getRooms,
  filter<ChatRoomInfo>(r => r.room.type === CHAT_ROOM_TYPES.individual)
);

export const getGroupRooms = createSelector(
  getRooms,
  filter<ChatRoomInfo>(r => r.room.type === CHAT_ROOM_TYPES.group)
);

export const getHasMore = (state: RootState, roomId: number) => state.chat.hasMoreMessages[roomId];
export const getActiveRoom = (state: RootState) => state.chat.chattingWith;
