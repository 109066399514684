import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

import CreateChannelModal from '../Modal/CreateChannelModal';
import * as S from './ChatSectionStyles';

//#region TYPES
type ChatSectionElement = ReturnType<typeof ChatSection> | Array<ReturnType<typeof ChatSection>>;

type ChatSectionProps = {
  title: string;
  createChannel?: boolean;
  className?: string;
  children: React.ReactNode;
};
type ChatListContainerProps = {
  className?: string;
  children: ChatSectionElement;
};
//#endregion

function ChatSection({ title, createChannel = false, className, children }: ChatSectionProps) {
  const { t } = useTranslation('chat');
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <section className={className}>
      <S.SectionTitle>
        {title}
        {createChannel && (
          <S.CreateButton onClick={() => setModalVisible(true)}>
            <span>
              <FontAwesomeIcon className="icon" icon={faPlus} />
            </span>
            {t('create')}
          </S.CreateButton>
        )}
      </S.SectionTitle>
      <div>{children}</div>
      <CreateChannelModal visible={modalVisible} setVisible={setModalVisible} />
    </section>
  );
}

function ChatListContainer({ className, children }: ChatListContainerProps) {
  return <S.SectionContainer className={className}>{children}</S.SectionContainer>;
}
ChatListContainer.ChatSection = ChatSection;

export default ChatSection;
export { ChatListContainer, ChatSection };
