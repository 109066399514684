import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Button, ConfigProvider, Drawer } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/pt-br';

import { ThemeProvider } from '@emotion/react';

import { useGAConfig, useAuth } from 'hooks';
// UTILS
import enUS from 'antd/es/locale/en_US';
import ptBR from 'translation/antd_pt-BR';
import { APP_TITLE, DEVICE_PLATFORMS, RECAPTCHA_KEY } from 'lib/constants';
import { isLoggedIn } from 'lib/Auth';
import { account } from 'lib/api';
import { setFavicon } from 'lib/helper';
import * as storage from 'lib/storageHelper';
import { requestToken } from 'lib/config/configureFirebase';
import { deviceTokenReceived } from 'store/actions/authActions';
import { currentThemeSelector } from 'store/selectors/authSelectors';

// COMPONENTS
import Offline from 'components/application/Offline';
import Online from 'components/application/Online';
import CookieConsent from 'components/CookieConsent/CookieConsent';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

// STYLE
import modifyTheme from 'styles/themes/themeChanger';
import GlobalStyles from 'styles/GlobalStyles';

import CacheBuster from './CacheBuster';

//#region TYPES
type Locale = import('antd/es/locale-provider').Locale;
//#endregion

const languages: Record<string, Locale> = {
  'pt-BR': ptBR as Locale,
  'en-US': enUS,
};

const App = () => {
  useGAConfig();
  const { t, i18n } = useTranslation();
  const { network, userId } = useAuth();
  const dispatch: AppDispatch = useDispatch();

  const [requestPermission, setRequestPermission] = useState(
    'Notification' in window ? Notification.permission === 'default' : false
  );

  const themeName = useSelector(currentThemeSelector);
  const currentTheme = useMemo(() => modifyTheme(themeName), [themeName]);

  const cookieConsent = storage.get<string>('4bee.cookie-consent', 'localStorage');

  const { checkDevice } = useApp({ network, userId });

  async function requestNotificationHandler() {
    const permission = await Notification.requestPermission();
    setRequestPermission(permission === 'default');

    if (userId && permission === 'granted') {
      const deviceToken = await requestToken();
      if (deviceToken) {
        dispatch(deviceTokenReceived(deviceToken));
        checkDevice(deviceToken, userId);
      }
    }
  }

  return (
    <CacheBuster>
      {(isLoading, isLatestVersion, refreshCacheAndReload) => {
        if (isLoading) return null;
        if (!isLoading && !isLatestVersion) refreshCacheAndReload();

        return (
          <ConfigProvider locale={languages[i18n.language]}>
            <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY} language={i18n.language}>
              <ThemeProvider theme={currentTheme}>
                <Helmet
                  titleTemplate={`${network.name} | ${APP_TITLE} - %s`}
                  defaultTitle={APP_TITLE}
                >
                  <html lang={i18n.language} />
                </Helmet>
                <GlobalStyles />
                <ErrorBoundary>
                  {isLoggedIn() ? <Online /> : <Offline />}
                  {themeName && !cookieConsent && <CookieConsent />}
                  {window.Notification && (
                    <Drawer
                      visible={requestPermission}
                      closable={false}
                      mask={false}
                      placement="top"
                      height={70}
                      bodyStyle={{ padding: 20 }}
                    >
                      <Button type="link" size="small" onClick={requestNotificationHandler}>
                        {t('requestPermission')}
                      </Button>
                    </Drawer>
                  )}
                </ErrorBoundary>
              </ThemeProvider>
            </GoogleReCaptchaProvider>
          </ConfigProvider>
        );
      }}
    </CacheBuster>
  );
};

export default App;

function useApp({ network, userId }: { network: Network; userId: number }) {
  const { i18n } = useTranslation();
  const languageRef = useRef(i18n.language);
  const dispatch: AppDispatch = useDispatch();

  const checkDevice = useCallback(async (deviceToken: string, userId: number) => {
    if (userId) {
      await account.checkDevice({
        deviceToken,
        platform: DEVICE_PLATFORMS.webBrowser,
        userId,
      });
    }
  }, []);

  useEffect(() => {
    i18n.on('languageChanged', lng => {
      moment.locale(lng);
      languageRef.current = lng;
    });
  }, [i18n]);

  useEffect(() => {
    const { current: lng } = languageRef;
    moment.locale(lng);
  }, []);

  useEffect(() => {
    if (network.icon) {
      setFavicon(network.networkId);
    }
  }, [network.icon, network.networkId]);

  useEffect(() => {
    if (window.Notification && userId && Notification.permission === 'granted') {
      (async function () {
        const deviceToken = await requestToken();
        if (deviceToken) {
          dispatch(deviceTokenReceived(deviceToken));
          checkDevice(deviceToken, userId);
        }
      })();
    }
  }, [checkDevice, dispatch, userId]);

  return {
    checkDevice,
  };
}
