import { combineReducers } from 'redux';

import authReducer from './authReducer';
import bannerReducer from './bannerSlice';
import postsReducer from './postsReducer';
import usersReducer from './usersReducer';
import birthdayReducer from './birthdayReducer';
import newPostReducer from './newPostReducer';
import chatReducer from './chatReducer';
import notificationsPanelReducer from './notificationsPanelReducer';
import uiReducer from './uiReducer';
import shortcutReducer from './shortcutSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  banner: bannerReducer,
  posts: postsReducer,
  users: usersReducer,
  birthdays: birthdayReducer,
  newPost: newPostReducer,
  chat: chatReducer,
  notification: notificationsPanelReducer,
  ui: uiReducer,
  shortcut: shortcutReducer,
});

export type RootReducer = typeof rootReducer;

export default rootReducer;
