// import { convertToHTML } from 'draft-convert';
import { serialize } from 'object-to-formdata';
import _ from 'lodash/fp';

import { toast } from 'react-toastify';
import { convertToRaw } from 'draft-js';
import axios, { AxiosResponse, Canceler, CancelTokenSource } from 'axios';
import moment from 'moment';
import i18n from 'i18n';

import { profile as profileApi } from 'lib/api';
import { POST_TYPES, PROFILE_TYPES, REACTION_TYPE } from 'lib/constants';
import { updateSurvey as updateSurveyApi } from 'lib/apiActions';
import { parseBoolean } from 'lib/helper';

import { PostModel } from 'models/post';

import {
  makeGetPostSelector,
  getLoadedPages,
  getLoadedPagesComments,
  selectFilters,
} from 'store/selectors/postSelectors';

import { PostsActions, PostUIActions, FilterTypes, UIActions } from 'store/types/types';
import type { StoreKey } from 'store/types/newPostTypes';
import type { CommentMap, PostState, TabFilter } from 'store/types/postTypes';
import type { AsyncAction } from '.';

//#region TYPES
type PostsRequestParams = { [x: string]: any };
export type OutCanceler = { cancel: Canceler };

type FetchPinnedPostsParams = { postId?: number; pinned?: boolean; widget?: boolean };

type GetPostsAction = (
  page: number,
  params: PostRequestParams,
  callback?: Function,
  outCanceler?: OutCanceler
) => AsyncAction<void>;

type FetchPostAction = (id: number, callback: (value: boolean) => void) => AsyncAction<void>;

type GetLikesAction = (
  params: { itemId: number; itemType: ItemTypes; reactionId?: number },
  cancelCallback: (c: Canceler) => void
) => AsyncAction<Promise<void>>;

type GetScheduledCountAction = (groupId?: number) => AsyncAction<Promise<void>>;

type ReloadPostsAction = (page: number) => AsyncAction<Promise<void>>;

type GetScheduledPostsAction = (
  page: number,
  requestParams: PostsRequestParams,
  callback?: (status?: number) => void
) => AsyncAction<Promise<number>>;

type GetPinnedPostsAction = (pinnedPostsParams: FetchPinnedPostsParams) => AsyncAction<void>;

type SubmitPostAction = (
  storeKey: StoreKey,
  attributes: any,
  resetCallback: (status: number) => void,
  cancelRequest: (tokenSource: CancelTokenSource) => void
) => AsyncAction<Promise<number>>;

type UpdatePostAction = (
  attributes: any,
  callback: (status?: number) => void,
  cancelRequest: (tokenSource: CancelTokenSource) => void
) => AsyncAction<Promise<unknown>>;

type GenericPostAction = (postId: number) => AsyncAction<Promise<void>>;

type PublishPendingPostAction = GenericPostAction;

type DeletePostAction = GenericPostAction;

type SendCongratulationsAction = (
  message: string,
  attributes: any
) => AsyncAction<Promise<boolean>>;

type PinPostAction = (args: { postId: number; widget: boolean }) => AsyncAction<Promise<void>>;

type GetCommentsAction = (
  postId: number,
  page: number,
  pageSize: number
) => AsyncAction<Promise<void>>;

type DeleteCommentAction = (comment: CommentMap[number][number]) => AsyncAction<Promise<void>>;

type UpdateCommentAction = (
  comment: any,
  attachment?: any,
  attachmentType?: string,
  removeAttachment?: boolean,
  descriptionRemove?: string
) => AsyncAction<Promise<number>>;

type CreateCommentAction = (
  comment: any,
  attachment: any,
  attachmentType: string
) => AsyncAction<Promise<number>>;

type ToggleCommentingAction = (postId: number) => AsyncAction<Promise<void>>;

type UpdateSurveyAction = (postId: number, survey: Survey) => AsyncAction<void>;
//#endregion

const getPost = makeGetPostSelector();

// PRIVATE
async function _fetchComments(
  postId: number,
  page: number,
  pageSize: number = 4
): Promise<(CommentInfo & { _pageComment: number })[] | null> {
  let { data } = await axios.get<CommentInfo[]>(
    `/api/Comment/getComment?itemId=${postId}&itemType=1&pageNumber=${page}&pageSize=${pageSize}`
  );
  if (data) {
    return data.map(comment => ({ ...comment, ...{ _pageComment: page } }));
  }
  return null;
}

function _fetchPosts(
  page = 1,
  state: RootState,
  requestParams: PostsRequestParams = { profileTypes: [1, 2, 3] },
  pending = false,
  outCanceler = {} as OutCanceler
): Promise<AxiosResponse<PostInfo[]>> {
  const { postTypes, mediaType, query } = selectFilters(state);
  const newFeed = parseBoolean(state.auth.featureToogle['new_feed']);
  const isSearchTab = state.posts.currentTab === 'search';

  let params = {
    pageNumber: page,
    pageSize: 10,
    profileTypes: requestParams.profileTypes,
    pending,
    profileId: requestParams.profileId,
  };

  if (isSearchTab || !newFeed) {
    params = Object.assign(params, { mediaType, types: postTypes, postText: query });
  }

  const promise = axios.get('/api/post/getPost', {
    params,
    cancelToken: new axios.CancelToken(c => {
      outCanceler.cancel = c;
    }),
  });

  return promise;
}

function _fetchPostId(id: number): Promise<AxiosResponse<PostInfo[]>> {
  return axios.get(`/api/post/getPost?postId=${id}&orderDirection=desc&pageNumber=1&pageSize=1`);
}

function _fetchPinnedPosts({
  postId,
  pinned,
  widget,
}: FetchPinnedPostsParams): Promise<AxiosResponse<PostInfo[]>> {
  return axios.get('/api/post/getPost', { params: { postId, pinned, sidebar: widget } });
}

async function _fetchSharedPost(id: number): Promise<PostInfo> {
  const { data } = await axios.get(`/api/Post/getSharedPost?basePostId=${id}`);
  return data;
}

async function _fetchPostById(id: number): Promise<PostInfo> {
  const response = await axios.get(`/api/post/getPost?postId=${id}`);
  return response.data[0];
}

async function _deletePost(postId: number, state: RootState) {
  const post = new PostModel({ postId }, state);
  const page = post.attributes._page;
  const { status } = await post.destroy();
  return { status, page };
}

function _postsFetching() {
  return { type: PostsActions.POSTS_FETCHING };
}

function _updatePost(payload: PostInfo) {
  return {
    type: PostsActions.POST_UPDATED,
    payload: { ...payload, ...{ _edited: true } },
  };
}

export function _editComment(payload: CommentInfo) {
  return {
    type: PostsActions.COMMENT_EDITING,
    payload: { ...payload, ...{ _isEditing: true } },
  };
}

// ACTIONS
export function resetPosts() {
  return { type: PostsActions.POST_RESET, payload: { post: false } };
}
export function postInserted() {
  return {
    type: PostsActions.POST_INSERTED,
  };
}

export function postFetched(payload: PostInfo[]) {
  return {
    type: PostsActions.POST_FETCHED,
    payload,
  };
}

export function postError(errorDescription: string) {
  return {
    type: PostsActions.POST_ERROR,
    payload: { errorDescription },
  };
}

export function resetPostError() {
  return {
    type: PostsActions.RESET_ERROR,
  };
}

export function postDeleted(payload: { postId: number }) {
  return {
    type: PostsActions.POST_DELETED,
    payload,
  };
}

export function resetComments(postId: number) {
  return {
    type: PostsActions.RESET_COMMENTS,
    payload: { postId },
  };
}

export function cancelEditComment(payload: CommentInfo) {
  return {
    type: PostsActions.COMMENT_EDIT_CANCEL,
    payload: { ...payload, ...{ _isEditing: false } },
  };
}

export function pendingPostDeleted(postId: number) {
  return {
    type: PostsActions.PENDING_POST_DELETED,
    payload: { postId },
  };
}

export function bookmarkPost(postId: number, saved: boolean) {
  return {
    type: PostsActions.POST_BOOKMARKED,
    payload: { postId, saved },
  };
}

export const updateFeedFilters = (
  filters: PostState['filters'] = { postTypes: POST_TYPES.all, query: '', mediaType: 0 }
) => {
  return {
    type: FilterTypes.UPDATE,
    payload: filters,
  };
};

export const clearFeedFilters = () => ({
  type: FilterTypes.UPDATE,
  payload: {
    query: '',
    mediaType: null,
    postTypes: POST_TYPES.all,
  },
});

export function tabChanged(currentTab: TabFilter) {
  return {
    type: PostsActions.TAB_CHANGED,
    payload: currentTab,
  };
}

// POST
export const getPosts: GetPostsAction =
  (page = 1, params, callback = _.noop, outCanceler) =>
  (dispatch, getState) => {
    dispatch(_postsFetching());
    _fetchPosts(page, getState(), params, undefined, outCanceler)
      .then(({ data, status }) => {
        if (status === 200) {
          dispatch({
            type: PostsActions.POSTS_FETCHED,
            payload: { posts: data, page },
          });
        }

        callback(status, data.length >= 10);
      })
      .catch(error => {
        callback(error);
        if (axios.isCancel(error)) {
          console.info(error.message);

          return;
        }

        console.error(`Posts not fetched with status ${error.status}`);
        toast.error(i18n.t('post.errorExecute'), {
          position: 'top-right',
          hideProgressBar: true,
          autoClose: 1500,
        });
      });
  };

export const fetchPost: FetchPostAction = (id, callback) => async dispatch => {
  dispatch(_postsFetching());
  _fetchPostId(id)
    .then(({ data: posts }) => dispatch(postFetched(posts)))
    .then(() => callback(true))
    .catch(() => callback(false));
};

export const getLikes: GetLikesAction = (params, cancelCallback) => async dispatch => {
  const likes = await profileApi.getUsersWhoLiked(params, cancelCallback);

  if (!likes || typeof likes === 'number') return;

  const { itemId, itemType } = params;

  dispatch({
    type: PostsActions.GET_LIKES,
    payload: { itemId, itemType, likes },
  });
};

export const getScheduledCount: GetScheduledCountAction = groupId => async dispatch => {
  axios.get('/api/Post/getScheduledCount', { params: { groupId } }).then(({ data }) => {
    dispatch({
      type: PostsActions.PENDING_POST_COUNT,
      payload: { count: data },
    });
  });
};

export const reloadPosts: ReloadPostsAction =
  (page = 1) =>
  async (dispatch, getState) => {
    dispatch(resetPosts());
    _fetchPosts(page, getState()).then(({ data }) => {
      dispatch({
        type: PostsActions.POSTS_FETCHED,
        payload: { posts: data, page },
      });
    });
  };

export const getSharedPost = (parentPostId: number) => async (dispatch: AppDispatch) => {
  const post = await _fetchSharedPost(parentPostId);
  dispatch({
    type: PostsActions.POST_SHARED_FETCHED,
    payload: {
      post,
      parentPostId,
    },
  });
};

export const getScheduledPosts: GetScheduledPostsAction =
  (page = 1, requestParams, callback = () => {}) =>
  async (dispatch, getState) => {
    dispatch({
      type: UIActions.DRAWER_CONTENT_LOADING_STATE,
      isLoading: true,
    });
    dispatch({ type: UIActions.DRAWER_PENDING_POST_RESET });
    dispatch({ type: PostsActions.PENDING_POST_RESET });

    const { data, status } = await _fetchPosts(page, getState(), requestParams, true);
    if (status === 200) {
      dispatch({
        type: UIActions.DRAWER_PENDING_POSTS_FETCHED,
        payload: data,
      });
      dispatch({
        type: PostsActions.PENDING_POSTS_FETCHED,
        payload: { posts: data, page },
      });
    }
    dispatch({
      type: UIActions.DRAWER_CONTENT_LOADING_STATE,
      isLoading: false,
    });

    callback(status);
    return status;
  };

export const getPinnedPosts: GetPinnedPostsAction =
  (pinnedPostsParams = {}) =>
  async dispatch => {
    const { data } = await _fetchPinnedPosts(pinnedPostsParams);

    dispatch({
      type: PostsActions.PINNED_POSTS_FETCHED,
      payload: { posts: data || [] },
    });
  };

export const submitPost: SubmitPostAction =
  (storeKey, attributes, resetCallback, cancelRequest) => async (dispatch, getState) => {
    const { user } = getState().auth.userInfo;
    const { targets, visibility, profile } = getState().newPost.posts[storeKey]!;
    const { _extra, sendToFollowers } = attributes;
    const post = new PostModel({
      fromId: profile?.profileId ?? user.userId,
      fromType: profile?.profileType ?? PROFILE_TYPES.user,
      type: attributes.type,
      description: JSON.stringify(convertToRaw(_extra.contentState)),
      descriptionText: _extra.contentState.getPlainText(),
      pinned: attributes._extra.pinned ?? false,
      blockCommenting: _extra.blockCommenting,
      _extra: { ..._extra, ...{ targetProfileIds: targets, visibility, sendToFollowers } },
    });

    if (_extra.scheduledDate) {
      Object.assign(post.attributes, { scheduledDate: _extra.scheduledDate });
    }

    if (cancelRequest) cancelRequest(post.cancelSource);

    return post
      .save()
      .then(({ status, postInfo }) => {
        if (status === 200) {
          const { currentTab } = getState().posts;
          const newFeed = getState().auth.featureToogle['new_feed']?.toLowerCase() === 'true';

          const isNews =
            postInfo.post.fromType === PROFILE_TYPES.publisher ||
            postInfo.post.fromType === PROFILE_TYPES.group;

          if (isNews) {
            dispatch(tabChanged('news'));
          } else {
            dispatch(tabChanged('my-feed'));
          }

          if (
            !newFeed ||
            (isNews && currentTab === 'news') ||
            (!isNews && currentTab === 'my-feed')
          ) {
            dispatch({
              type: PostsActions.POST_CREATED,
              payload: { post: { ...postInfo, ...{ _new: true } } },
            });
          }
        }

        resetCallback(status);
      })
      .catch(err => {
        const { status } = err;

        // console.log('status catch create post: ', err.response.data);

        resetCallback(status);
        if (!axios.isCancel(err)) dispatch(postError(err.response.data));

        return err;
      });
  };

export const updatePost: UpdatePostAction =
  (attributes, callback, cancelRequest) => async dispatch => {
    const postModel = new PostModel(attributes);
    if (cancelRequest) cancelRequest(postModel.cancelSource);

    try {
      const { status, postInfo } = await postModel.save();

      if (status !== 200 && status !== 202) {
        throw new Error('Failed to save post');
      }

      const { surveyInfo } = attributes._extra;
      let survey: Survey | number | undefined = postInfo.survey;
      if (surveyInfo) {
        survey = await updateSurveyApi(surveyInfo.id, surveyInfo);

        if (!survey || typeof survey !== 'object') {
          throw new Error('Failed to save survey');
        }
      }

      const updatedPost = { ...postInfo, survey: { ...survey }, _new: false };
      if (updatedPost.post.scheduledDate && updatedPost.post.pending) {
        dispatch({
          type: PostsActions.PENDING_POST_UPDATED,
          payload: { ...updatedPost, _edited: true },
        });
      } else {
        dispatch(_updatePost(updatedPost));
      }

      callback(status);
    } catch (error) {
      callback();

      return error;
    }
  };

export const updateSinglePost = (id: number) => async (dispatch: AppDispatch) => {
  _fetchPostById(id).then(postModel => {
    const updatedPost = { ...postModel, ...{ _new: false } };
    dispatch(_updatePost(updatedPost));
  });
};

export const publishPendingPost: PublishPendingPostAction =
  postId => async (dispatch, getState) => {
    const { user } = getState().auth.userInfo;
    const data = {
      id: postId.toString(),
      networkId: user.networkId.toString(),
      userId: user.userId.toString(),
      requestOrigin: 'scheduler',
    };

    const { status } = await axios.put('/api/post/publishPendingPost', data);
    if (status === 200) {
      dispatch({
        type: PostsActions.PENDING_POST_SENT,
        payload: { postId },
      });

      dispatch({
        type: UIActions.DRAWER_PENDING_POST_DELETED,
        payload: { postId },
      });
    }
  };
export const deletePost: DeletePostAction = postId => async (dispatch, getState) => {
  const { status, page } = await _deletePost(postId, getState());

  if (status === 200) {
    dispatch({
      type: PostUIActions.HIDE,
      payload: { postId },
    });

    const state = getState();
    const pages = getLoadedPages(state).filter(p => p >= page);

    const posts = await Promise.all(pages.map(page => _fetchPosts(page, getState())));

    dispatch({
      type: PostsActions.POSTS_FETCHED,
      payload: {
        posts: _.flow(_.flatten, _.compact)(posts),
      },
    });

    // This is to only remove the post from the store,
    // after the hide animation
    await new Promise(r => setTimeout(r, 300));
    dispatch({
      type: PostsActions.POST_DELETED,
      payload: { postId },
    });
  } else {
    toast.error(i18n.t('post.errorDelete'));
  }
};

export const deletePendingPost: DeletePostAction = postId => async (dispatch, getState) => {
  const { status } = await _deletePost(postId, getState());

  if (status === 200) {
    dispatch(pendingPostDeleted(postId));

    dispatch({
      type: UIActions.DRAWER_PENDING_POST_DELETED,
      payload: { postId },
    });
  }
};

export const sendCongratulations: SendCongratulationsAction =
  (message, attributes) => async (dispatch, getState) => {
    const { user } = getState().auth.userInfo;
    const { _extra } = attributes;

    const post = new PostModel({
      fromId: user.userId,
      fromType: 1,
      type: 1,
      description: message,
      descriptionText: message,
      _extra: { ..._extra, ...{ targetUserIds: [_extra.id] } },
    });

    const { status } = await post.save();

    if (status === 200) {
      dispatch(postInserted());
      return true;
    }
    return false;
  };

export const like =
  (postId: number, reaction?: ReactionLiteral): AsyncAction<Promise<void>> =>
  async (dispatch, getState) => {
    const { user } = getState().auth.userInfo;
    const post = getPost(getState(), postId);
    const likeCount = post.likeCount + 1;
    const strReaction = reaction ?? 'like';
    const reactionCount = [...post.reactionCount];
    const reactionIndex = post.reactionCount.findIndex(r => r.name === strReaction);

    if (reactionIndex > -1) {
      const likeReaction = { ...reactionCount[reactionIndex] };
      likeReaction.reactionCount += 1;
      reactionCount[reactionIndex] = likeReaction;
      reactionCount.forEach(rc => (rc.totalCount += 1));
    } else {
      reactionCount.push({
        name: reaction ?? 'like',
        reactionCount: 1,
        totalCount: likeCount,
        reactionId: REACTION_TYPE[strReaction],
        deleted: false,
      });
    }

    const payload = {
      liked: true,
      likeCount,
      post: {
        postId,
      },
      reaction: {
        itemId: postId,
        itemType: 1,
        name: strReaction,
        reactionId: REACTION_TYPE[strReaction],
        userId: user.userId,
      },
      reactionCount,
    } as PostInfo;

    dispatch(_updatePost(payload));

    const resp = await axios.post(`/api/Like/sendLike`, {
      itemId: post.post.postId,
      itemType: 1,
      userId: user.userId,
      reactionId: REACTION_TYPE[strReaction],
    });
    if (resp.status !== 200) {
      dispatch(_updatePost({ ...post }));
    }
  };

export const dislike: GenericPostAction = postId => async (dispatch, getState) => {
  const post = getPost(getState(), postId);
  const strReaction = post.reaction?.name || 'like';

  const reactionCount = structuredClone(post.reactionCount) as Reaction[];

  const reactionIndex = reactionCount.findIndex(r => r.name === strReaction);

  const likeReaction = reactionCount[reactionIndex];
  reactionCount.forEach(rc => (rc.totalCount -= 1));

  if (likeReaction.reactionCount === 1) {
    reactionCount.splice(reactionIndex, 1);
  } else {
    likeReaction.reactionCount -= 1;
  }

  const payload = {
    liked: false,
    likeCount: post.likeCount - 1,
    post: {
      postId,
    },
    reaction: undefined,
    reactionCount,
  } as PostInfo;

  dispatch(_updatePost(payload));

  const { user } = getState().auth.userInfo;
  const resp = await axios.delete(
    `/api/Like/removeLike?itemId=${post.post.postId}&itemType=1&userId=${user.userId}`
  );
  if (resp.status !== 200) {
    dispatch(_updatePost({ ...post }));
  }
};

export const changeReaction =
  ({
    postId,
    reaction,
  }: {
    postId: number;
    reaction?: ReactionLiteral;
  }): AsyncAction<Promise<void>> =>
  async (dispatch, getState) => {
    const { user } = getState().auth.userInfo;
    const post = getPost(getState(), postId);

    let likeCount = post.likeCount;
    const strReaction = reaction ?? 'like';
    const reactionCount = structuredClone(post.reactionCount) as Reaction[];

    if (!post.reaction) {
      likeCount += 1;
      const reactionIndex = post.reactionCount.findIndex(r => r.name === strReaction);
      reactionCount.forEach(rc => (rc.totalCount = likeCount));

      if (reactionIndex > -1) {
        const currentReaction = reactionCount[reactionIndex];
        currentReaction.reactionCount += 1;
      } else {
        reactionCount.push({
          name: reaction ?? 'like',
          reactionCount: 1,
          totalCount: likeCount,
          reactionId: REACTION_TYPE[strReaction],
          deleted: false,
        });
      }
    } else {
      if (strReaction === post.reaction.name) return;

      const currentIndex = post.reactionCount.findIndex(r => r.name === post.reaction!.name);
      const currentReaction = reactionCount[currentIndex];
      const newIndex = post.reactionCount.findIndex(r => r.name === strReaction);

      if (currentReaction.reactionCount > 1) {
        currentReaction.reactionCount -= 1;
      } else {
        reactionCount.splice(currentIndex, 1);
      }

      if (newIndex > -1) {
        const newReaction = reactionCount[newIndex];
        newReaction.reactionCount += 1;
      } else {
        reactionCount.push({
          name: reaction ?? 'like',
          reactionCount: 1,
          totalCount: likeCount,
          reactionId: REACTION_TYPE[strReaction],
          deleted: false,
        });
      }
    }

    const payload = {
      liked: true,
      likeCount,
      post: {
        postId,
      },
      reaction: {
        itemId: postId,
        itemType: 1,
        name: strReaction,
        reactionId: REACTION_TYPE[strReaction],
        userId: user.userId,
      },
      reactionCount,
    } as PostInfo;

    dispatch(_updatePost(payload));

    const resp = await axios.patch(`/api/Like/changeReaction`, {
      itemId: post.post.postId,
      itemType: 1,
      userId: user.userId,
      reactionId: REACTION_TYPE[strReaction],
    });
    if (resp.status !== 200) {
      dispatch(_updatePost({ ...post }));
    }
  };

export const pinPost: PinPostAction =
  ({ postId, widget }) =>
  async (dispatch, getState) => {
    const post = getPost(getState(), postId);
    const clonedPost = _.cloneDeep(post);

    if (widget) {
      clonedPost.post.bulletin = true;
    } else {
      clonedPost.post.pinned = true;
      clonedPost.post.pinnedDate = moment().format();
    }

    dispatch({
      type: PostsActions.PINNED_POST_SET,
      payload: { post: clonedPost },
    });
    dispatch(_updatePost(clonedPost));

    try {
      await axios.post('/api/post/setPinnedPost', null, {
        params: { postId, sidebar: widget },
      });
    } catch {
      dispatch({
        type: PostsActions.PINNED_POST_REMOVED,
        payload: { postId },
      });
      dispatch(_updatePost(post));
    }
  };

export const unpinPost: PinPostAction =
  ({ postId, widget }) =>
  async (dispatch, getState) => {
    const post = getPost(getState(), postId);
    const clonedPost = _.cloneDeep(post);
    if (widget) clonedPost.post.bulletin = false;
    else {
      clonedPost.post.pinned = false;
      clonedPost.post.pinnedDate = undefined;
    }

    if (!(post.post.pinned && post.post.bulletin)) {
      dispatch({
        type: PostsActions.PINNED_POST_REMOVED,
        payload: { postId },
      });
    }
    dispatch(_updatePost(clonedPost));

    try {
      await axios.delete('/api/post/removePinnedPost', { params: { postId, sidebar: widget } });
    } catch {
      dispatch({
        type: PostsActions.PINNED_POST_SET,
        payload: { post },
      });
      dispatch(_updatePost(post));
    }
  };

export const getSavedPosts =
  (page = 1) =>
  async (dispatch: AppDispatch) => {
    const response = await axios.get(`/api/post/getPost`, {
      params: { onlySaved: true, pageNumber: page },
    });

    if (response.status === 200) {
      dispatch({
        type: PostsActions.POSTS_FETCHED,
        payload: { posts: response.data, page },
      });
    }

    return response.status;
  };

export const removeSavedPost = (postId: number) => async (dispatch: AppDispatch) => {
  dispatch({
    type: PostUIActions.HIDE,
    payload: { postId },
  });

  await new Promise(r => setTimeout(r, 1000));
  dispatch({
    type: PostsActions.POST_DELETED,
    payload: { postId },
  });
};

// COMMENT
export const getComments: GetCommentsAction =
  (postId: number, page: number, pageSize = 4) =>
  async dispatch => {
    const comments = await _fetchComments(postId, page, pageSize);
    dispatch({
      type: PostsActions.GET_COMMENTS,
      payload: {
        postId,
        comments,
      },
    });
  };

export const deleteComment: DeleteCommentAction = comment => async (dispatch, getState) => {
  const page = comment._pageComment!;
  const { commentId } = comment.comment;
  const commentPostId = comment.comment.itemId;

  const resp = await axios.delete(`/api/Comment/deleteComment?commentId=${commentId}`);
  if (resp.status === 200) {
    dispatch({
      type: PostUIActions.HIDE_COMMENT,
      payload: { commentId },
    });

    const state = getState();
    const pages = getLoadedPagesComments(state, commentPostId).filter(c => c >= page);

    Promise.all(pages.map(page => _fetchComments(commentPostId, page, 4))).then(comments => {
      dispatch({
        type: PostsActions.GET_COMMENTS,
        payload: {
          comments: _.flow(_.flatten, _.compact)(comments),
        },
      });
    });

    // This is to only remove the post from the store,
    // after the hide animation
    await new Promise(r => setTimeout(r, 1000));

    dispatch({
      type: PostsActions.COMMENT_DELETED,
      payload: { comment },
    });

    const post = getPost(getState(), commentPostId);

    const payload = {
      commentCount: post.commentCount - 1,
      post: {
        postId: commentPostId,
      },
    } as PostInfo;

    dispatch(getComments(commentPostId, pages[0], 2));
    dispatch(_updatePost(payload));
  } else {
    toast.error(i18n.t('comments.errorDelete'));
  }
};
export const updateComment: UpdateCommentAction =
  (comment, attachment, attachmentType, removeAttachment, descriptionRemove) => async dispatch => {
    const { userId } = comment;
    const formData = serialize(comment);

    if (attachment) {
      if (attachmentType === 'LinkInfo') {
        formData.append(`${attachmentType}.Url`, attachment.url);
        formData.append(`${attachmentType}.ImageUrl`, attachment.images[0]);
        formData.append(`${attachmentType}.Title`, attachment.title);
        formData.append(`${attachmentType}.Description`, attachment.description);
        formData.append(`${attachmentType}.UserId`, userId.toString());
        formData.append(`${attachmentType}.ItemType`, '1'); // TODO: ser dinamico quando houver os outros tipos de posts
      } else {
        formData.append(attachmentType!, attachment, attachment.name);
      }
    }

    if (removeAttachment && comment.removeMedia) {
      formData.append('remove.mediaId', comment.removeMedia);
      formData.append('remove.description', descriptionRemove!);
    }

    const { data, status } = await axios.put('/api/comment/updateComment', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    dispatch({
      type: PostsActions.COMMENT_UPDATED,
      payload: { data: { ...data, ...{ _isEditing: false } } },
    });

    return status;
  };

export const createComment: CreateCommentAction =
  (comment, attachment, attachmentType) => async (dispatch, getState) => {
    const { userId } = comment;
    const formData = serialize(comment);

    if (attachment) {
      if (attachmentType === 'LinkInfo') {
        formData.append(`${attachmentType}.Url`, attachment.url);
        formData.append(`${attachmentType}.ImageUrl`, attachment.images[0]);
        formData.append(`${attachmentType}.Title`, attachment.title);
        formData.append(`${attachmentType}.Description`, attachment.description);
        formData.append(`${attachmentType}.UserId`, userId.toString());
        formData.append(`${attachmentType}.ItemType`, '1'); // TODO: ser dinamico quando houver os outros tipos de posts
      } else {
        formData.append(attachmentType, attachment, attachment.name);
      }
    }

    const { data, status } = await axios.post('/api/comment/createComment', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (status === 200) {
      dispatch({
        type: PostsActions.CREATE_COMMENTS,
        payload: {
          comment: { ...data, ...{ _isEditing: false, _new: true } },
        },
      });
      const postId = comment.itemId;

      const post = getPost(getState(), postId);

      const payload = {
        commentCount: post.commentCount + 1,
        post: {
          postId: Number(postId),
        },
      } as PostInfo;

      dispatch(_updatePost(payload));
    }

    return status;
  };

export const toggleCommenting: ToggleCommentingAction = postId => async dispatch => {
  return await axios
    .post('/api/post/toggleCommenting', `${postId}`, {
      headers: { 'Content-Type': 'text/plain' },
    })
    .then(response => {
      if (response.status === 200) {
        dispatch({
          type: PostsActions.TOGGLE_COMMENTING,
          payload: { postId },
        });
      }
    })
    .catch(error => {
      console.log(`Error ${error.status}`);
    });
};

export const updateSurvey: UpdateSurveyAction = (postId, survey) => (dispatch, getState) => {
  const post = { ...getPost(getState(), postId), survey };

  dispatch(_updatePost(post));
};

// UI
export const focusCommentField = (postId: number) => ({
  type: PostUIActions.FOCUS_COMMENT_FIELD,
  payload: { postId },
});

export const blurCommentField = (postId: number) => ({
  type: PostUIActions.BLUR_COMMENT_FIELD,
  payload: { postId },
});

export const edit = (postId: number) => ({
  type: PostUIActions.EDIT,
  payload: { postId },
});

export const cancelEdit = (postId: number) => ({
  type: PostUIActions.CANCEL_EDIT,
  payload: { postId },
});

export const openGalleryModal = (postId: number, galleryActiveIndex: number) => ({
  type: PostUIActions.OPEN_GALLERY_MODAL,
  payload: { postId, galleryActiveIndex },
});

export const closeGalleryModal = (postId: number) => ({
  type: PostUIActions.CLOSE_GALLERY_MODAL,
  payload: { postId },
});

export const openPinnedPostDrawer = (postId: number) => ({
  type: PostUIActions.PINNED_DRAWER_OPENED,
  payload: { postId },
});

export const closePinnedPostDrawer = (postId: number) => ({
  type: PostUIActions.PINNED_DRAWER_CLOSED,
  payload: { postId },
});
