import { useMemo } from 'react';
import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { ModalFuncProps } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';

function useModalFuncProps() {
  const theme = useTheme();

  const modalFuncProps: ModalFuncProps = useMemo(
    () => ({
      okButtonProps: {
        danger: true,
        className: css`
          &.ant-btn-dangerous.ant-btn-primary {
            background-color: ${theme.colors.danger};
            border-color: ${theme.colors.danger};
          }
        `,
      },
      icon: (
        <i role="img" className="anticon">
          <FontAwesomeIcon
            icon={faCircleQuestion}
            className={css`
              color: ${theme.colors.danger};
            `}
          />
        </i>
      ),
    }),
    [theme.colors.danger]
  );

  return modalFuncProps;
}

export default useModalFuncProps;
