// GLOBAL
import React, { useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Popover } from 'antd';
import { TooltipPlacement } from 'antd/lib/tooltip';

// UTILS
import { PROFILE_TYPES } from 'lib/constants';
import { useSelector } from 'store';
import { fetchUser, fetchUserContacts } from 'store/actions/usersActions';
import { selectUser, selectUserContacts } from 'store/selectors/usersSelectors';

// LOCAL COMPONENTS
import UserInfo from './UserInfo';

//#region TYPES
interface UserHoverCardProps {
  userId: number;
  profileType?: ProfileTypes;
  children?: React.ReactNode;
  placement?: TooltipPlacement;
}
//#endregion

const UserHoverCard: React.VFC<UserHoverCardProps> = ({
  userId,
  profileType = PROFILE_TYPES.user,
  children,
  placement = 'top',
}) => {
  const [visible, setVisible] = useState(false);

  // REDUX
  const dispatch: AppDispatch = useDispatch();
  const rootUser = useSelector(state => selectUser(state, userId));
  const userContacts = useSelector(state => selectUserContacts(state, userId));

  const onVisibleChange = (nextVisible: boolean) => {
    if (!rootUser || !userContacts) {
      const userFetch = !rootUser ? dispatch(fetchUser(userId)) : Promise.resolve();
      const contactsFetch = !userContacts ? dispatch(fetchUserContacts(userId)) : Promise.resolve();
      Promise.all<any>([userFetch, contactsFetch]).then(() => setVisible(nextVisible));
    } else {
      setVisible(nextVisible);
    }
  };

  const userInfo = useMemo(
    () => (rootUser ? <UserInfo {...rootUser} {...userContacts} /> : null),
    [rootUser, userContacts]
  );

  return (
    <>
      {profileType === PROFILE_TYPES.user ? (
        <Popover
          content={userInfo}
          title={null}
          visible={visible}
          onVisibleChange={onVisibleChange}
          destroyTooltipOnHide={false}
          mouseEnterDelay={0.6}
          placement={placement}
        >
          {children}
        </Popover>
      ) : (
        children
      )}
    </>
  );
};

export default UserHoverCard;
