import { createWithEqualityFn as create } from 'zustand/traditional';
import { devtools, NamedSet } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import produce from 'immer';

import { StoreSlice } from 'types';
import { isSameLength } from 'lib/helper';

export interface GroupSlice {
  groupId: number;
  groupInfo: PageInfo;
  groupAdminsCount: number;
  groupAdmins: UserInfo[];
  groupUsersCount: number;
  groupUsers: UserInfo[];
}

export type ActionsSlice = {
  addAdmins: (admins: UserInfo[]) => void;
  setParticipating: (isParticipating: boolean) => void;
  setSelectedGroup: (state: Omit<GroupSlice, 'groupId'>) => void;
  updateGroup: (group: Partial<Page>) => void;
  updateOwner: (owner: Partial<User>) => void;
};

export type GroupState = ReturnType<typeof createStore>;

const initialState: GroupSlice = {
  groupId: 0,
  groupInfo: {} as PageInfo,
  groupAdminsCount: 0,
  groupAdmins: [],
  groupUsersCount: 0,
  groupUsers: [],
};

const createActionsSlice: StoreSlice<ActionsSlice, GroupSlice> = set => ({
  addAdmins: admins =>
    set(prev => ({ groupAdmins: prev.groupAdmins.concat(admins) }), false, 'addAdmins'),
  setParticipating: isParticipating =>
    set(
      produce<GroupSlice>(state => void (state.groupInfo.participating = isParticipating)),
      false,
      'setParticipating'
    ),
  setSelectedGroup: groupState =>
    set({ groupId: groupState.groupInfo.page.pageId, ...groupState }, false, 'setSelectedGroup'),
  updateGroup: group =>
    set(
      produce<GroupSlice>(
        state => void (state.groupInfo.page = { ...state.groupInfo.page, ...group })
      ),
      false,
      'updateGroup'
    ),
  updateOwner: owner =>
    set(
      produce<GroupSlice>(
        state => void (state.groupInfo.owner = { ...state.groupInfo.owner, ...owner })
      ),
      false,
      'updateOwner'
    ),
});

const createStore = (set: NamedSet<any>) => ({
  ...initialState,
  ...createActionsSlice(set),
});

export const useGroupStore = create<GroupSlice & ActionsSlice>()(
  devtools(createStore, { name: 'GroupStore' })
);

export const useGroupInfo = () => useGroupStore(state => state.groupInfo);
export const useGroupAdmins = () => useGroupStore(state => state.groupAdmins, isSameLength);
export const useGroupUsers = () => useGroupStore(state => state.groupUsers, isSameLength);
export const useGroupCounters = () =>
  useGroupStore(
    ({ groupAdminsCount, groupUsersCount }) => ({ groupAdminsCount, groupUsersCount }),
    shallow
  );

const actionsSelector = (state: GroupState) => ({
  addAdmins: state.addAdmins,
  setParticipating: state.setParticipating,
  setSelectedGroup: state.setSelectedGroup,
  updateGroup: state.updateGroup,
  updateOwner: state.updateOwner,
});
export const useActions = () => useGroupStore(actionsSelector, shallow);
