import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const tabs = (theme: Themes.DefaultTheme) => css`
  &.ant-tabs {
    color: ${theme.colors.gray9};
  }
`;
export const headerIcon = css`
  margin-right: 8px;
`;

export const modalWrapper = css`
  .ant-modal-header {
    padding-top: 24px;
    padding-bottom: 0;
    border: 0;
  }
  .ant-modal-body {
    padding: 24px;
  }
  .ant-modal-close-x {
    width: 72px;
    height: 72px;
    line-height: 72px;
  }
`;

export const ModalHeader = styled.div`
  height: 24px;
  font-size: 16px;
  line-height: 24px;
`;
