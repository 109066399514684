import React, { FormEvent, useReducer, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { shallow } from 'zustand/shallow';
import type { EditorState } from 'draft-js';
import type { MentionData } from '@draft-js-plugins/mention';
import loadable from '@loadable/component';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/pro-solid-svg-icons';
import { v4 } from 'uuid';
import { debounce } from 'lodash/fp';
import { resetPosts } from 'store/actions/postsActions';

import { useAuth, usePermissions } from 'hooks';
import { useSelector } from 'store';
import { getEntities } from 'lib/helper';
import type { UploadButtonProps } from 'components/common/Attachments/AttachmentsActions';
import type { NewSurveyRef, SurveyData } from 'components/Feed/Survey/NewSurvey';
import PostScheduling from 'components/layout/Common/Modal/PostScheduling/PostScheduling';

import PostEditorPlugin from '../PostEditorPlugin/PostEditorPlugin';
import { useComposerStore } from '../composerContext';
import * as S from './PostEditorStyles';

const NewSurvey = loadable(() => import('components/Feed/Survey/NewSurvey'));

export type PostEditorProps = {
  onSubmitPost: (surveyInfo?: SurveyInfo) => void;
};

export default function PostEditor({ onSubmitPost }: PostEditorProps) {
  const { t } = useTranslation('feed');
  const { networkId, networkSettings } = useAuth();

  const [
    { currentLink, mentionsOpen, suggestions, scheduleOpen, errorMessage, loadingPreview },
    setState,
  ] = useReducer(reducer, initialState);

  const sendPostError = useSelector(state => state.posts.error);
  const textDescriptionError = useSelector(state => state.posts.errorDescription);

  // console.log('sendPostError:', sendPostError);

  const getPostDescriptionError = () => {
    if (textDescriptionError === 'Target users list cannot be empty for private posts') {
      // return t('default:post.addPersonSendPost');

      return <S.ErrorMessage>{t('default:post.addPersonSendPost')}</S.ErrorMessage>;

      // setState({
      //   errorMessage: t('default:post.addPersonSendPost'),
      // });
    } else {
      // return t('default:post.errorSend');
      return <S.ErrorMessage>{t('default:post.errorSend')}</S.ErrorMessage>;

      // setState({
      //   errorMessage: t('default:post.errorSend'),
      // });
    }
  };

  const surveyRef = useRef<NewSurveyRef>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const [editorState, setEditorState] = useComposerStore(
    state => [state.editorState, state.setEditorState],
    shallow
  );
  const [attachments, setAttachments, removeAttachment, resetAttachments] = useComposerStore(
    state => [
      state.attachments,
      state.setAttachments,
      state.removeAttachment,
      state.resetAttachments,
    ],
    shallow
  );
  const attachmentType = useComposerStore(state => state.attachmentType);
  const setScheduleDate = useComposerStore(state => state.setScheduleDate);
  const [surveyOpen, setSurveyOpen] = useComposerStore(
    state => [state.surveyOpen, state.setSurveyOpen],
    shallow
  );
  const isSending = useComposerStore(state => state.isSending);

  const previewLink = useRef(
    debounce(350, async (link: string) => {
      setState({ loadingPreview: true });

      const previewApi = await import('lib/api/preview');
      const preview = await previewApi.previewLink(link);

      setState({ loadingPreview: false });

      if (!preview || typeof preview === 'number') {
        return;
      }

      setAttachments<'link'>([preview], 'link');
    })
  ).current;

  const getErrorMessage = (forbiddenExtensions: string[], maxFileSize: number) => {
    return t('default:files.forbiddenFiles', {
      extensions: forbiddenExtensions.join(', '),
      size: maxFileSize,
    });
  };

  const editorChangeHandler = (newEditorState: EditorState, linksList: string[]) => {
    const linkEntity = getEntities(newEditorState, 'LINK').shift();

    if (linkEntity || linksList.length > 0) {
      const url = linkEntity?.entity.getData().url ?? linksList.pop();

      if (url !== currentLink) {
        setState({ currentLink: url });
        previewLink(url);
      }
    } else {
      previewLink.cancel();
      if (attachmentType === 'link') {
        setState({ currentLink: '' });
        resetAttachments();
      }
    }

    setEditorState(newEditorState);
  };

  const fileUploaderHandler: UploadButtonProps['onFileUploaded'] = (
    acceptedFiles,
    fileType,
    rejectedFiles
  ) => {
    const { maxPhotosPermittedInPost, maxPostFileSize } = networkSettings;

    if (acceptedFiles.length > 0) {
      // resetPostError();
      setState({ errorMessage: null });

      const totalFileCount = acceptedFiles.length + attachments.length;
      let allowedFiles = acceptedFiles;

      if (totalFileCount >= maxPhotosPermittedInPost && fileType === 'image') {
        allowedFiles = acceptedFiles.slice(0, maxPhotosPermittedInPost - attachments.length);
      }

      const newFiles = allowedFiles.map<PostFile>(file => ({
        blob: file,
        name: file.name,
        size: file.size,
        preview: URL.createObjectURL(file),
        index: v4(),
        new: true,
      }));

      setAttachments(newFiles, fileType);
    } else if (rejectedFiles && rejectedFiles.length > 0) {
      resetAttachments();

      if (fileType === 'video') {
        setState({
          errorMessage: t('default:files.videoSizeAttachment', {
            size: maxPostFileSize,
          }),
        });
      } else {
        setState({
          errorMessage: t('default:files.forbiddenAttachment'),
        });
      }
    }
  };

  const rejectedFileHandler = () => {
    resetAttachments();
    setState({
      errorMessage: getErrorMessage(
        networkSettings.forbiddenExtensions,
        networkSettings.maxPostFileSize
      ),
    });
  };

  const removeFileHandler = (attachment: PostFile) => {
    URL.revokeObjectURL(attachment.preview);

    removeAttachment(attachment.index as string);
  };

  const resetAttachmentsHandler = () => {
    resetAttachments();
    setState({ currentLink: '' });
  };

  const searchMentionsHandler = useRef(
    debounce(350, async ({ value }: { value: string }) => {
      const mentionApi = await import('lib/api/mention');
      const mentionResult = await mentionApi.getUserList({ name: value });

      if (!mentionResult.ok) return [];

      const mentionList = mentionResult.data;

      const suggestions = mentionList.map(mention => ({
        userId: mention.userId,
        name: mention.name,
      }));

      setState({ suggestions });
    })
  ).current;

  const scheduleHandler = (date: string) => {
    setScheduleDate(date);

    // Fazer esperar consolidar o estado antes de enviar a requisição
    setTimeout(() => {
      formRef.current?.requestSubmit();
    }, 100);
  };

  const onSubmit = (data: SurveyData | FormEvent<HTMLFormElement>) => {
    let surveyInfo: SurveyInfo | undefined = undefined;

    if ('preventDefault' in data) {
      data.preventDefault();
    } else {
      surveyInfo = {
        ...data,
        companyNetworkId: networkId,
        endDate: data.endDate!.format('YYYY-MM-DDTHH:mm:ss'),
      };
    }

    onSubmitPost(surveyInfo);
  };

  const canSend = editorState.getCurrentContent().getPlainText().trim().length > 0;

  return (
    <>
      <div>
        <PostEditorPlugin
          editorProps={{
            editorState: editorState,
            editorDisabled: isSending,
            onTextChange: editorChangeHandler,
          }}
          attachmentProps={{
            attachmentsDisabled: surveyOpen || isSending,
            attachments,
            attachmentType,
            loading: loadingPreview,
            onFileUploaded: fileUploaderHandler,
            onFileRejected: rejectedFileHandler,
            onFileRemoved: removeFileHandler,
            resetAttachments: resetAttachmentsHandler,
          }}
          surveyProps={{
            surveyDisabled: isSending,
            onSurveyClick: () => setSurveyOpen(!surveyOpen),
          }}
          mentionProps={{
            mentionsOpen,
            mentionSuggestions: suggestions,
            onMentionSearch: searchMentionsHandler,
            onMentionOpen: open => {
              setState(({ suggestions }) => ({
                mentionsOpen: open,
                suggestions: !open ? [] : suggestions,
              }));
            },
          }}
        />

        {errorMessage && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
        {sendPostError && getPostDescriptionError()}

        <form onSubmit={surveyRef.current?.handleSubmit(onSubmit) ?? onSubmit} ref={formRef}>
          {surveyOpen && (
            <NewSurvey
              isValid
              isOwnForm={false}
              onSave={() => {}}
              onCancel={() => setSurveyOpen(false)}
              ref={surveyRef}
            />
          )}

          <SubmitPanel canSend={canSend} onSchedule={() => setState({ scheduleOpen: true })} />
        </form>
      </div>
      <PostScheduling
        show={scheduleOpen}
        loading={isSending}
        onDateSelected={scheduleHandler}
        onClose={() => setState({ scheduleOpen: false })}
      />
    </>
  );
}

function SubmitPanel({ canSend, onSchedule }: { canSend: boolean; onSchedule: () => void }) {
  const { t } = useTranslation('feed');

  const { networkFunctions } = useAuth();
  const [canCreatePostSchedule] = usePermissions('CreatePostSchedule');

  const isSending = useComposerStore(state => state.isSending);

  return (
    <S.SubmitWrapper>
      <Button
        disabled={!canSend}
        loading={isSending}
        block
        type="primary"
        htmlType="submit"
        size="large"
      >
        {t('editor.post')}
      </Button>
      {networkFunctions.postScheduling && canCreatePostSchedule && (
        <Button
          disabled={!canSend || isSending}
          size="large"
          htmlType="button"
          icon={<FontAwesomeIcon icon={faCalendar} />}
          onClick={onSchedule}
        />
      )}
    </S.SubmitWrapper>
  );
}

type State = typeof initialState;
type StateValue = Partial<State> | ((prevState: State) => Partial<State>);

const initialState = {
  mentionsOpen: false,
  scheduleOpen: false,
  currentLink: '',
  suggestions: [] as MentionData[],
  errorMessage: null as null | string,
  loadingPreview: false,
  sendPostError: false,
  resetPosts,
};

function reducer(state: State, value: StateValue) {
  if (typeof value === 'function') {
    return { ...state, ...value(state) };
  }

  return { ...state, ...value };
}
