import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Menu } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { faCheck, faComment, faHighlighter } from '@fortawesome/pro-light-svg-icons';

import { useAuth, usePermissions } from 'hooks';
import { useSelector } from 'store';
import { StoreKey } from 'store/types/newPostTypes';

import * as S from './PostModifierMenuStyles';

export interface PostModifierMenuRef {
  resetModifiers: () => void;
}

export interface ChangeEvent {
  blockCommenting: boolean;
  highlight: boolean;
}

export interface PostModifierMenuProps {
  storeKey: StoreKey;
  renderButton?: () => React.ReactNode;
  onChange?: (modifiers: ChangeEvent) => void;
}

const PostModifierMenu = forwardRef<PostModifierMenuRef, PostModifierMenuProps>(
  ({ storeKey, renderButton, onChange }, ref) => {
    const { t } = useTranslation();
    const { networkSettings } = useAuth();
    const [createFixedPost] = usePermissions('CreateFixedPost');

    const postVisibility = useSelector(state => state.newPost.posts[storeKey]?.visibility);

    const [blockCommenting, setBlockCommenting] = useState(false);
    const [highlight, setHighlight] = useState(false);

    const showHighlightPost = createFixedPost && postVisibility === 'public';

    const checkIcon = <FontAwesomeIcon icon={faCheck} className="anticon check-icon" />;

    useImperativeHandle(ref, () => ({
      resetModifiers() {
        setBlockCommenting(false);
        setHighlight(false);
        onChange?.({ blockCommenting: false, highlight: false });
      },
    }));

    const toggleBlockCommentHandler = () => {
      setBlockCommenting(prevState => {
        const result = !prevState;

        onChange?.({ blockCommenting: result, highlight });
        return result;
      });
    };

    const toggleHighlightPostHandler = () => {
      setHighlight(prevState => {
        const result = !prevState;

        onChange?.({ blockCommenting, highlight: result });
        return result;
      });
    };

    const menuOverlay = (
      <Menu>
        {networkSettings.blockCommenting && (
          <Menu.Item
            css={S.arrowMenuItem(blockCommenting)}
            icon={<FontAwesomeIcon icon={faComment} className="anticon" />}
            onClick={toggleBlockCommentHandler}
          >
            {t('post.disableComment')} {checkIcon}
          </Menu.Item>
        )}
        {showHighlightPost && (
          <Menu.Item
            css={S.arrowMenuItem(highlight)}
            icon={<FontAwesomeIcon icon={faHighlighter} className="anticon" />}
            onClick={toggleHighlightPostHandler}
          >
            {t('post.highlightPost')} {checkIcon}
          </Menu.Item>
        )}
      </Menu>
    );

    return (
      <Dropdown overlay={menuOverlay} trigger={['click']} placement="bottomRight">
        {renderButton?.() ?? (
          <Button
            type="text"
            size="small"
            icon={<FontAwesomeIcon icon={faChevronDown} className="anticon" />}
          />
        )}
      </Dropdown>
    );
  }
);

export default PostModifierMenu;
