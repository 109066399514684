import styled from '@emotion/styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export const Header = styled.header`
  display: flex;
  padding: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray4};

  div {
    flex: 1;
  }
  button {
    padding: 0;
    color: ${({ theme }) => theme.colors.gray9};
    cursor: pointer;
    background: transparent;
    border: 0;
    outline: 0;

    :hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;
export const Body = styled.section`
  flex: 1;
  overflow-y: auto;
`;
export const Footer = styled.footer`
  position: sticky;
  bottom: 0;
  z-index: 1;
  padding: 12px;
  background-color: #fff;
  box-shadow: 0 -5px 12px -5px rgba(0, 0, 0, 0.2);
`;
