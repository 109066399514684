import React, { useCallback, useState } from 'react';
import { useDispatch, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// GLOBAL COMPONENTS
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMobile } from '@fortawesome/pro-regular-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Typography, Divider, Button } from 'antd';
import { ParagraphProps } from 'antd/lib/typography/Paragraph';
import { Link } from 'react-router-dom';

import { PROFILE_TYPES } from 'lib/constants';
// ACTIONS
import { useSelector } from 'store';
import { openChatRoom } from 'store/actions/chatActions';
import { canUseFunction } from 'store/selectors/authSelectors';
import { UsersActions } from 'store/types/types';
import useAuth from 'hooks/useAuth';
import { buildPhoneMask, parseBoolean } from 'lib/helper';

// LOCAL COMPONENTS
import Avatar from '../layout/Common/ProfileImage/ProfileImage';

//#region TYPES
interface InfoProps extends ParagraphProps {
  label?: string;
  icon?: IconDefinition;
  children?: React.ReactNode;
}

type UserInfoProps = UserInfo & UserContacts;
//#endregion

//#region STYLES
const infoIcon = css`
  margin-right: 8px;
`;
const infoText = css`
  &.ant-typography {
    margin-bottom: 0;
    font-weight: 400;
    white-space: nowrap;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  padding: 12px 16px;
  margin: -12px -16px 12px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px 4px 0 0;

  .ant-divider {
    color: ${({ theme }) => theme.colors.gray6};
  }
`;
const HeaderInfoWrapper = styled.div`
  margin-left: 8px;
`;
const HeaderInfoRow = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  white-space: nowrap;
`;
const UserName = styled.div`
  font-weight: 600;
`;
const InfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 552px;
`;
const InfoStyled = styled.div`
  display: flex;
  flex-basis: 50%;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  height: 24px;
  padding-right: 12px;
`;
const InfoLabel = styled.div`
  margin-right: 8px;
  font-weight: 600;
`;
//#endregion

const Info: React.VFC<InfoProps> = ({ label, icon, children, ...attrs }) => (
  <InfoStyled>
    {label ? <InfoLabel>{label}</InfoLabel> : null}
    {icon ? <FontAwesomeIcon css={infoIcon} className="anticon" icon={icon} /> : null}
    <Typography.Paragraph {...attrs} css={infoText}>
      {children}
    </Typography.Paragraph>
  </InfoStyled>
);
const InfoCopyable: React.VFC<InfoProps> = ({ children, ...attrs }) => (
  <Info copyable {...attrs}>
    {children}
  </Info>
);

const UserInfo: React.VFC<UserInfoProps> = ({
  user,
  occupation,
  department,
  companyUnit,
  phone,
  workphoneExtension,
  follow,
  online,
}) => {
  const { t } = useTranslation();
  const { userInfo } = useAuth();
  const dispatch: AppDispatch = useDispatch();
  const chatFunction = useSelector(state => canUseFunction(state, 'chat'), shallowEqual);
  const [following, setFollowing] = useState(follow);

  const mailViewHide = useSelector(store =>
    parseBoolean(store.auth.featureToogle['mail_view_hide'])
  );

  const dispatchUser = useCallback(
    follow => {
      dispatch({
        type: UsersActions.FETCH_USER,
        payload: {
          userId: user.userId,
          user: {
            user,
            occupation,
            department,
            companyUnit,
            follow,
            online,
          },
        },
      });
    },
    [dispatch, user, occupation, department, companyUnit, online]
  );

  const sendFollow = useCallback(() => {
    if (!following) {
      axios.post(`/api/Profile/followUser?userToFollow=${user.userId}`).then(() => {
        setFollowing(true);
        dispatchUser(true);
      });
    } else {
      axios.delete(`/api/Profile/unfollowUser?userToUnfollow=${user.userId}`).then(() => {
        setFollowing(false);
        dispatchUser(false);
      });
    }
  }, [following, user, dispatchUser]);

  return (
    <div>
      {/* HEADER */}
      <HeaderWrapper>
        <Avatar
          profile={{
            profileId: user.userId,
            name: user.name,
            profileType: PROFILE_TYPES.user,
            mediaId: user.mediaId,
          }}
        />
        <HeaderInfoWrapper>
          <HeaderInfoRow>
            <UserName>{user.name}</UserName>
          </HeaderInfoRow>
          <HeaderInfoRow>
            {occupation && <div>{occupation.description}</div>}
            {occupation && department && <Divider type="vertical" />}
            {department && department.description}
          </HeaderInfoRow>
        </HeaderInfoWrapper>
      </HeaderWrapper>

      {/* INFO */}
      <InfoWrapper>
        {mailViewHide && <InfoCopyable icon={faEnvelope}>{user.email}</InfoCopyable>}
        {phone && <InfoCopyable icon={faMobile}>{buildPhoneMask(phone)}</InfoCopyable>}
        {companyUnit && <Info icon={faMapMarkerAlt}>{companyUnit.description}</Info>}
        {workphoneExtension && (
          <Info label={t('common.phoneExtension')}>#{workphoneExtension}</Info>
        )}
      </InfoWrapper>

      {/* ACTIONS */}
      <div style={{ marginTop: 12 }}>
        <Link to={`/users/${user.userId}`}>{t('common.viewProfile')}</Link>
        <Divider type="vertical" />
        {chatFunction && user.userId !== userInfo.user.userId && (
          <Button
            style={{ padding: 0 }}
            type="link"
            onClick={() => dispatch(openChatRoom({ userId: user.userId }))}
          >
            {t('common.openChat')}
          </Button>
        )}
        {user.userId !== userInfo.user.userId && (
          <>
            <Divider type="vertical" />
            <Button style={{ padding: 0 }} type="link" onClick={() => sendFollow()}>
              {following ? t('follow.unfollow') : t('follow.follow')}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default UserInfo;
