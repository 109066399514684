class DesktopNotifications {
  static notifyChat(
    title: string,
    message: string,
    onClick: (event: Event) => void,
    data?: any,
    profileImage?: string
  ) {
    if (!window.Notification) return null;

    const n = new Notification(title, {
      body: message,
      icon: profileImage,
      data,
    });

    n.addEventListener('click', onClick);

    return n;
  }
}

export default DesktopNotifications;
