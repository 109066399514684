import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import { truncateString } from 'lib/helper';
import FileIcon from '../FileIcon';
import * as S from './ChatMediaPreviewStyles';

//#region TYPES
export interface ChatMediaPreviewProps {
  visible: boolean;
  files: ChatFile[];
  onRemoveFile: (file: ChatFile) => void;
}

interface PreviewFileProps {
  file: ChatFile;
  onRemoveFile: (file: ChatFile) => void;
}
//#endregion

const ChatMediaPreview = ({ visible, files, onRemoveFile }: ChatMediaPreviewProps) => {
  return (
    <S.Preview visible={visible}>
      {files.map(file => (
        <PreviewFile key={file.index} file={file} onRemoveFile={onRemoveFile} />
      ))}
    </S.Preview>
  );
};

export default ChatMediaPreview;

const PreviewFile = ({ file, onRemoveFile }: PreviewFileProps) => {
  const fileExt = file.name.split('.').pop()!;

  const removeFileHandler = (file: ChatFile) => {
    return () => onRemoveFile(file);
  };

  if (file.blob.type.includes('image')) {
    return (
      <S.ImageWrapper>
        <img src={file.preview} alt={file.name} />
        <S.Button onClick={removeFileHandler(file)}>
          <FontAwesomeIcon icon={faTimes} style={{ fontSize: 8 }} />
        </S.Button>
      </S.ImageWrapper>
    );
  }

  return (
    <S.PreviewContainer>
      <Tooltip title={file.name} overlayStyle={{ fontSize: '0.7rem' }}>
        <S.IconWrapper>
          <FileIcon fileExt={fileExt} />
          <S.Button onClick={removeFileHandler(file)}>
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: 8 }} />
          </S.Button>
        </S.IconWrapper>
      </Tooltip>
      {truncateString(file.name, 10)}
    </S.PreviewContainer>
  );
};
