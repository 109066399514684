import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from 'antd';

import { computedSize } from 'lib/helper';

// #region STYLES
const previewIcon = css`
  width: 1em;
  height: 1em;
  margin-right: 4px;
`;
const previewDelete = css`
  float: right;
  height: auto;
  padding: 0;
  margin-left: 4px;
  font-size: inherit;
  font-weight: 600;
  white-space: nowrap;
  border: 0;
`;

const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray9};

  ${({ theme }) => theme.typography.fontxSmall()};
`;
const PreviewName = styled.div`
  flex-grow: 1;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & > div {
    flex-grow: 1;
  }
`;
// #endregion

const FilePreview = ({ file, onClickDelete, disable }) => {
  const { t } = useTranslation();

  function handleFileRemoved() {
    URL.revokeObjectURL(file.preview);
  }

  return (
    <PreviewContainer key={file.name}>
      <FontAwesomeIcon css={previewIcon} icon={faPaperclip} />
      <PreviewName>
        {file.name} ({computedSize(file.size)})
      </PreviewName>
      <Button
        css={previewDelete}
        type="link"
        danger
        disabled={disable}
        onClick={onClickDelete || handleFileRemoved}
        onKeyPress={onClickDelete || handleFileRemoved}
        tabIndex={-1}
      >
        {t('files.removeAttachment')}
      </Button>
    </PreviewContainer>
  );
};

FilePreview.defaultProps = {
  onClickDelete: null,
  disable: false,
};

export default FilePreview;
