import { Fragment, useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import moment from 'moment';
import styled from '@emotion/styled';
import { Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons';

import { useAuth } from 'hooks';
import { useDispatch } from 'store';
import { updateSurvey } from 'store/actions/postsActions';
import { setUserOption, getUserBySurveyOption } from 'lib/apiActions';

import UsersList from 'components/common/UsersList';

import SurveyOption from './SurveyOption';
import { Fieldset } from './Styles';

//#region TYPES
export interface SurveyProps {
  postInfo: PostInfo;
}
//#endregion

//#region STYLES
const Container = styled.div`
  padding-top: 1em;
  margin-top: 1em;
  border-top: 1px solid ${({ theme }) => theme.colors.gray4};

  ${({ theme }) => theme.typography.fontNormal()};

  > h3 {
    margin: 0;
  }

  > small {
    color: ${({ theme }) => theme.colors.gray6};

    ${({ theme }) => theme.typography.fontSmall()};
  }
`;

const FieldsetSurvey = styled(Fieldset)`
  padding: 2em 0.8em;
  ${({ theme }) => theme.typography.fontSmall(1)};

  .survey-body {
    margin-bottom: 0.7em;
  }

  .survey-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.colors.gray8};
    ${({ theme }) => theme.typography.fontxSmall()};

    b {
      color: ${({ theme }) => theme.colors.primary};
    }

    svg {
      margin-right: 0.1em;
    }
  }
`;
//#endregion

const Survey: VFC<SurveyProps> = ({ postInfo }) => {
  const { survey, post } = postInfo;
  const isSelected = survey.selectedUserOption > 0;
  const hasEnded = moment() > moment(survey.endDate);
  const endTranslationKey = hasEnded ? 'surveyEnded' : 'surveyEnds';

  const { t } = useTranslation();
  const { userId } = useAuth();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [users, setUsers] = useState<{ user: User }[]>([]);

  const castVote = async (surveyOptionId: number) => {
    setIsLoading(true);
    const surveyUpdated = await setUserOption({
      surveyId: survey.id,
      surveyOptionId,
      userId,
    });
    setIsLoading(false);

    if (!surveyUpdated || typeof surveyUpdated !== 'object') {
      toast(t('survey.errors.castVote'), { type: 'error' });
      return;
    }

    dispatch(updateSurvey(post.postId, surveyUpdated));
  };

  const showVoters = async (surveyOptionId: number) => {
    setIsLoading(true);
    const users = await getUserBySurveyOption(surveyOptionId);
    if (users.length > 0) {
      setUsers(users.map(user => ({ user })));
      setModalVisible(true);
    }
    setIsLoading(false);
  };

  const optionClickHandler = async (surveyOptionId: number) => {
    if (isLoading) return;

    if (!isSelected && !hasEnded) {
      castVote(surveyOptionId);
    } else if (!survey.anonymous) {
      showVoters(surveyOptionId);
    }
  };

  if (!post.survey) return null;
  return (
    <Container>
      <h3>{t('survey.survey')}</h3>
      <small>{hasEnded ? t('survey.surveyHintEnd') : t('survey.surveyHint')}</small>
      <FieldsetSurvey>
        <h5>{survey.title}</h5>
        <div className="survey-body">
          {survey.options?.map(option => (
            <SurveyOption
              key={option.id}
              anonymous={survey.anonymous}
              hasEnded={hasEnded}
              option={option}
              selected={isSelected}
              selectedOption={survey.selectedUserOption}
              onOptionClick={optionClickHandler}
            />
          ))}
        </div>
        <div className="survey-footer">
          <div>
            {(isSelected || hasEnded) && (
              <b>{t('survey.votes', { count: survey.totalAnswers })} </b>
            )}
            <span>
              {t(`survey.${endTranslationKey}`, {
                date: moment(survey.endDate).format(t('formats.fullDatetime')),
              })}
            </span>
          </div>
          <div>
            {survey.anonymous && (
              <Fragment>
                <FontAwesomeIcon icon={faEyeSlash} /> {t('survey.anonymousSurvey')}
              </Fragment>
            )}
          </div>
        </div>
      </FieldsetSurvey>
      <Modal
        destroyOnClose
        title={t('survey.personsVoted')}
        visible={modalVisible}
        footer={null}
        onCancel={() => setModalVisible(false)}
      >
        <div>
          <UsersList userInfoList={users} />
        </div>
      </Modal>
    </Container>
  );
};

export default Survey;
