export default {
  editor: {
    createPost: 'Create post',
    editPost: 'Edit post',
    linkPlaceholder: 'Paste or type a link and press enter',
    placeholder: 'What are your thoughts?',
    post: 'Post',
    toolbar: {
      bold: 'Bold',
      italic: 'Italic',
      underline: 'Underline',
    },
  },
  prompt: {
    discardPost: 'If you leave now, your post will not be saved.',
    discardTitle: 'Discard this post',
  },
  toast: {
    successPost: 'Posted with success',
  },
};
