import { css, keyframes, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { fadeIn, fadeOut } from 'react-animations';

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeOutAnimation = keyframes`${fadeOut}`;

export const blankState = (theme: Theme) => css`
  .ant-result-title,
  .ant-result-icon .anticon {
    color: ${theme.colors.gray6};
  }
`;

export const NewPostWrapper = styled.div`
  position: sticky;
  top: 80px;
  z-index: 500;
  display: flex;
  justify-content: center;
  margin-top: -41px;
  margin-bottom: 9px;
  animation-timing-function: ease-in-out;

  &.enter-active {
    animation: 300ms ${fadeInAnimation};
  }
  &.exit-active {
    animation: 300ms ${fadeOutAnimation};
  }
`;
