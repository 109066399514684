import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-basis: 100%;
  flex-direction: column;
  .check-box-group .ant-radio-wrapper {
    display: block;
    color: $gray-label;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block-end: 1em;

  > * {
    margin: 0;
  }

  > button {
    padding: 0;
    height: auto;
  }
`;

export const SectionTitle = styled.h6`
  color: ${({ theme }) => theme.colors.gray8};
  text-transform: uppercase;
`;
export const AppContainer = styled.div`
  margin-top: auto;

  .app {
    margin-bottom: 8px;
  }

  button {
    padding: 0;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.gray10};

    ${({ theme }) => theme.typography.fontSmall()};
  }
`;

export const Label = styled.div`
  margin-bottom: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray9};

  ${({ theme }) => theme.typography.fontNormal()}
`;

export const Icon = styled.div`
  display: inline-block;
  width: 28px;
  height: 28px;
  margin-right: 8px;
  color: #fff;
  background: ${({ theme }) => theme.colors.gray3};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  ${({ theme }) => theme.typography.fontNormal(1.75)};
`;

export const TextButton = styled.span`
  font-weight: 600;
  ${({ theme }) => theme.typography.fontSmall(1.75)};
  color: ${({ theme }) => theme.colors.gray10};
`;
