import React, { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EditorState, ContentState } from 'draft-js';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import produce from 'immer';
import { useAsyncCallback } from 'react-async-hook';
import { debounce } from 'lodash/fp';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { v4 } from 'uuid';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';

import { Button, Dropdown, Menu, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faPollH } from '@fortawesome/pro-solid-svg-icons';
import { faSpinnerThird, faSmile } from '@fortawesome/pro-regular-svg-icons';

import { useAuth, usePermissions, useSingle } from 'hooks';
import { submitPost, resetPostError, getScheduledCount } from 'store/actions/postsActions';
import {
  addTarget,
  changeProfile,
  changeVisibility,
  initNewPostStore,
} from 'store/actions/newPostActions';
import http from 'lib/httpClient';
import { PROFILE_TYPES } from 'lib/constants';

import { useGroupInfo, useGroupAdmins } from 'components/Groups/GroupStore';
import TextEditor from 'components/layout/Common/TextEditor/TextEditor';
import PostModifierMenu from 'components/layout/Common/PostModifierMenu/PostModifierMenu';
import * as customNotification from 'components/layout/Common/CustomNotification';
import AttachmentsActions from 'components/layout/Main/AttachmentsActions/AttachmentsActions';
import AttachmentsPreview from 'components/layout/Main/AttachmentsPreview/AttachmentsPreview';
import PostFilterModal from 'components/modal/PostFilterModal/PostFilterModal';
import NewSurvey from 'components/Feed/Survey/NewSurvey';

import { emojiThemePost } from 'styles/draftjs/emojiTheme';
import {
  ActionButton,
  ActionsWrapper,
  ContainerPreview,
  ErrorMessage,
  PostButton,
  SurveyContainer,
  Wrapper,
} from 'components/layout/Main/NewPost/NewPostStyles';

import ProfileSwitch from './ProfileSwitch';
import GroupScopeSelector from './GroupScopeSelector';

import * as S from './NewPostGroupStyles';

const PostSchedulingModal = React.lazy(() =>
  import('components/layout/Common/Modal/PostScheduling/PostScheduling')
);

// #region useReducer
const defaultValues = {
  currentLink: '',
  editorState: EditorState.createEmpty(),
  errorMessage: '',
  files: [],
  fileType: null,
  isFocused: false,
  isMount: false,
  overLimit: false,
  sending: false,
  showFiltersModal: false,
  showNewSurvey: false,
  showScheduleModal: false,
  suggestions: [],
  selectedTargets: [],
};
const actions = {
  setEditorState(state, payload) {
    state.editorState = payload.editorState;
    state.overLimit = payload.overLimit;
  },
  setLinkPreview(state, payload) {
    state.currentLink = payload.currentLink;
    state.files = [payload.preview];
    state.fileType = payload.fileType;
  },
  resetFiles(state) {
    state.currentLink = '';
    state.files = [];
    state.fileType = null;
  },
  setState(state, payload) {
    return { ...state, ...payload };
  },
};
const reducer = (state, action) => {
  const fn = actions[action.type];

  if (fn) return produce(state, draft => fn(draft, action.payload));

  return state;
};
// #endregion

function makeEmojiPlugin(theme) {
  const emojiPlugin = createEmojiPlugin({
    theme: emojiThemePost(theme),
    selectButtonContent: <FontAwesomeIcon icon={faSmile} />,
  });

  return emojiPlugin;
}

async function previewLink(link) {
  const preview = await http.previewLink(link);

  return preview;
}
async function getMentions(params) {
  const { data } = await axios.get('/api/Mention/getUserList', { params });

  return data;
}

const NEW_POST_GROUP = 'NEW_POST_GROUP';
/**
 *
 * @param {Object} props
 * @param {import('models/group').PageInfo} props.groupInfo
 * @param {string} props.storeKey
 */
function NewPostGroup() {
  const theme = useTheme();
  const emojiPlugin = useMemo(() => makeEmojiPlugin(theme), [theme]);

  const [state, dispatch] = useReducer(reducer, defaultValues);
  const groupInfo = useGroupInfo();
  const groupAdmins = useGroupAdmins();
  const { t } = useTranslation();
  const { userInfo, userPermissions, networkFunctions, networkSettings } = useAuth();
  const [createPublicPostForPage, createFixedPost, createSurvey] = usePermissions(
    'createPublicPostForPage',
    'CreateFixedPost',
    'CreatePoll'
  );

  const [showScheduleModal, setShowScheduleModal] = useState(false);

  const cancelToken = useRef();
  const postModifiers = useRef({ pinned: false, blockCommenting: false });
  const textEditorRef = useRef(null);
  const filterModalRef = useRef(null);
  const arrowMenuRef = useRef(null);

  // #region REDUX
  const reduxDispatch = useDispatch();
  const sendError = useSelector(state => state.posts.error);
  const newPost = useSelector(state => state.newPost.posts[NEW_POST_GROUP]);
  // #endregion

  const { userId, name: userName } = userInfo.user;
  const { pageId, name: pageName, public: isPagePublic } = groupInfo.page;

  const newPostVisibility = newPost?.visibility;
  const currentProfileType = newPost?.profile.profileType;
  let disableSendPost =
    !state.editorState.getCurrentContent().getPlainText().trim().length > 0 || state.overLimit;
  if (newPostVisibility === 'private') {
    disableSendPost = disableSendPost || state.selectedTargets.length === 0;
  }
  const isAdmin = useMemo(
    () => userId === groupInfo.owner.userId || groupAdmins?.some(adm => userId === adm.user.userId),
    [groupAdmins, groupInfo.owner.userId, userId]
  );

  const userProfile = useMemo(
    () => ({
      profileId: userId,
      name: userName,
      profileType: PROFILE_TYPES.user,
    }),
    [userName, userId]
  );
  const groupProfile = useMemo(
    () => ({
      profileId: pageId,
      name: pageName,
      profileType: PROFILE_TYPES.group,
    }),
    [pageName, pageId]
  );

  // #region FUNCTIONS
  /** @type {(newState: typeof defaultValues) => void} */
  const setState = useCallback(newState => {
    dispatch({ type: 'setState', payload: newState });
  }, []);

  const getMessage = useCallback(
    (forbiddenExtensions, maxFileSize) => {
      return t('files.forbiddenFiles', {
        extensions: forbiddenExtensions.join(', '),
        size: maxFileSize,
      });
    },
    [t]
  );

  const previewLinkAsync = useAsyncCallback(async link => {
    const preview = await previewLink(link);

    if (preview) {
      preview.authorId = userId;
      dispatch({
        type: 'setLinkPreview',
        payload: { currentLink: link, preview, fileType: 'link' },
      });
    }
  });
  const debouncedPreviewLink = useSingle(() => debounce(300, previewLinkAsync.execute));

  const searchMentionsAsync = useCallback(
    async name => {
      const params = { name };
      if (!isPagePublic) {
        params.pageId = pageId;
      }

      const forAdmin =
        !isPagePublic && currentProfileType === PROFILE_TYPES.user && newPostVisibility === 'admin';

      if (!forAdmin) {
        const mentions = await getMentions(params);

        if (mentions) {
          const suggestions = mentions.map(mention => ({
            userId: mention.userId,
            name: mention.name,
          }));

          setState({ suggestions });
        }
      }
    },
    [currentProfileType, isPagePublic, newPostVisibility, pageId, setState]
  );
  const debouncedSearchMentions = useMemo(
    () => debounce(300, searchMentionsAsync),
    [searchMentionsAsync]
  );

  const reset = () => {
    setState({ editorState: EditorState.createEmpty(), showNewSurvey: false, sending: false });
    dispatch({ type: 'resetFiles' });
    arrowMenuRef.current?.resetModifiers();
    if (currentProfileType === PROFILE_TYPES.group && isPagePublic) {
      reduxDispatch(changeVisibility(NEW_POST_GROUP, createPublicPostForPage ? 'public' : 'group'));
      reduxDispatch(addTarget(NEW_POST_GROUP, []));
    } else {
      reduxDispatch(changeVisibility(NEW_POST_GROUP, 'group'));
      reduxDispatch(addTarget(NEW_POST_GROUP, [pageId]));
    }
  };

  const resetPost = (date, status) => {
    switch (status) {
      case 200:
        reset();
        break;
      case 202: {
        if (date) {
          customNotification.renderToast({
            title: t('post.schedule.success'),
            type: customNotification.TYPE_POST_SCHEDULED,
          });
          // getScheduledCount();
        } else {
          const attributes = {
            type: customNotification.TYPE_VIDEO_PROCESSING,
            title: t('post.processingVideoTitle'),
            body: t('post.processingVideo'),
          };
          customNotification.renderToast(attributes);
        }
        reset();

        break;
      }
      default:
        setState({ sending: false });
        break;
    }
  };

  const selectedTargets = useCallback(
    selectedUsers => {
      setState({ selectedTargets: selectedUsers });
    },
    [setState]
  );
  // #endregion

  // #region init new post effects
  useEffect(() => {
    reduxDispatch(initNewPostStore(NEW_POST_GROUP));
    reduxDispatch(changeVisibility(NEW_POST_GROUP, 'public'));
    reduxDispatch(addTarget(NEW_POST_GROUP, []));
  }, [reduxDispatch]);
  useEffect(() => {
    reduxDispatch(changeProfile(NEW_POST_GROUP, isAdmin ? groupProfile : userProfile));
    setState({ isMount: true });
  }, [groupProfile, isAdmin, reduxDispatch, setState, userProfile]);
  useEffect(() => {
    setState({ showFiltersModal: newPostVisibility === 'private' });
    filterModalRef.current?.clearAll();
    arrowMenuRef.current?.resetModifiers();
  }, [newPostVisibility, setState]);
  // #endregion

  // #region HANDLERS
  const handleSendPost = ({ surveyInfo, scheduledDate }) => {
    const { editorState, files, fileType } = state;
    const contentState = editorState.getCurrentContent();
    const { pinned, blockCommenting } = postModifiers.current;

    setState({ errorMessage: '', sending: true });

    let typePost;
    switch (newPostVisibility) {
      case 'public': {
        typePost = 1;
        break;
      }
      case 'group':
      case 'admin': {
        typePost = 4;
        break;
      }
      default:
        typePost = 1;
        break;
    }

    const attributes = {
      _extra: {
        contentState,
        files: [...files].reverse(),
        fileType,
        scheduledDate,
        pinned,
        blockCommenting,
        surveyInfo,
      },
      type: typePost,
    };
    return reduxDispatch(
      submitPost(
        NEW_POST_GROUP,
        attributes,
        status => resetPost(scheduledDate, status),
        cancelTokenSource => {
          cancelToken.current = cancelTokenSource;
        }
      )
    );
  };

  const handleChange = (newEditorState, linksContent, overLimit) => {
    const { editorState, currentLink, fileType } = state;

    if (!fileType) {
      if (newEditorState.getCurrentContent() !== editorState.getCurrentContent()) {
        if (linksContent.length > 0) {
          const link = linksContent.pop();
          if (link !== currentLink) {
            debouncedPreviewLink(link);
          }
        } else {
          debouncedPreviewLink.cancel();
          if (fileType === 'link') {
            dispatch({ type: 'resetFiles' });
          }
        }
      }
    }

    dispatch({ type: 'setEditorState', payload: { editorState: newEditorState, overLimit } });
  };
  const handleSearchMention = ({ value }) => {
    if (value) debouncedSearchMentions(value);
    else debouncedSearchMentions.cancel();
  };

  const handleProfileSwitch = () => {
    textEditorRef.current.focus();
  };

  const handleScopeChanged = postVisibility => {
    const { editorState } = state;
    if (!isPagePublic && ['admin'].some(val => val === postVisibility)) {
      const contentState = editorState.getCurrentContent();
      const newContentState = ContentState.createFromText(contentState.getPlainText());

      setState({ editorState: EditorState.createWithContent(newContentState) });
    }
  };

  const handleMenuClick = ({ key }) => {
    if (key === 'schedule') {
      setShowScheduleModal(true);
    }
  };

  const handleSendPostSchedule = async date => {
    await handleSendPost({ scheduledDate: date });

    reduxDispatch(getScheduledCount(pageId));
    setShowScheduleModal(false);
  };

  const handleCancelPostSchedule = () => {
    if (cancelToken.current) cancelToken.current.cancel();
    setShowScheduleModal(false);
  };

  const handleEditFilters = useCallback(() => {
    setState({ showFiltersModal: true });
  }, [setState]);

  const handleFiltersClose = useCallback(() => {
    setState({ showFiltersModal: false });
  }, [setState]);

  const arrowMenuChangeHandler = modifiers => {
    postModifiers.current = {
      pinned: modifiers.highlight,
      blockCommenting: modifiers.blockCommenting,
    };
  };
  // #endregion

  // #region FILES
  const handleFileAdded = (acceptedFiles, type) => {
    const { fileType, files } = state;
    const { maxPhotosPermittedInPost, maxPostFileSize } = networkSettings;

    if (acceptedFiles && acceptedFiles.length > 0) {
      reduxDispatch(resetPostError());
      setState({ errorMessage: '' });

      const totalFileCount = acceptedFiles.length + files.length;
      /** @type {typeof files} */
      let newFiles = acceptedFiles;

      if (totalFileCount >= maxPhotosPermittedInPost && type === 'image') {
        newFiles = newFiles.slice(0, maxPhotosPermittedInPost - files.length);
      }

      newFiles = newFiles.map(file => ({
        blob: file,
        name: file.name,
        size: file.size,
        preview: URL.createObjectURL(file),
        index: v4(),
        new: true,
      }));

      if (fileType === type && type === 'image') {
        setState({
          files: [...files, ...newFiles],
        });
      } else {
        setState({
          files: newFiles,
          fileType: type,
        });
      }
    } else if (type === 'image') {
      dispatch({ type: 'resetFiles' });
      if (fileType === 'video') {
        setState({
          errorMessage: t('default:files.videoSizeAttachment', {
            size: maxPostFileSize,
          }),
        });
      } else {
        setState({
          errorMessage: t('default:files.forbiddenAttachment'),
        });
      }
    }
  };
  const handleMediaRemoved = removedFile => {
    const { files, fileType } = state;
    const newFiles = files.filter(file => file.index !== removedFile.index);

    if (newFiles.length > 0) {
      setState({
        files: newFiles,
        fileType,
      });
    } else {
      dispatch({ type: 'resetFiles' });
    }
  };
  const handleRejectedFile = () => {
    reduxDispatch(resetPostError());
    dispatch({ type: 'resetFiles' });

    setState({
      errorMessage: getMessage(
        networkSettings.forbiddenExtensions,
        networkSettings.maxPostFileSize
      ),
    });
  };

  // #endregion

  // #region RENDERS
  const renderScopeSelector = disabled => {
    return (
      <>
        <GroupScopeSelector
          disabled={disabled}
          storeKey={NEW_POST_GROUP}
          onScopeChanged={handleScopeChanged}
        />
        {newPostVisibility === 'private' && (
          <div style={{ display: 'inline-block', marginLeft: '0.5em' }}>
            <Tooltip title={renderTargetTooltip()}>
              <PostFilterModal.EditFilterButton
                count={state.selectedTargets.length}
                onClick={handleEditFilters}
              />
            </Tooltip>
            <PostFilterModal
              storeKey={NEW_POST_GROUP}
              visible={state.showFiltersModal}
              selectedUsers={selectedTargets}
              onClose={handleFiltersClose}
              ref={filterModalRef}
            />
          </div>
        )}
      </>
    );
  };

  const renderErrorMessage = () => {
    const { errorMessage } = state;
    if (!errorMessage) {
      return null;
    }
    return (
      <ContainerPreview>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </ContainerPreview>
    );
  };
  const renderSendError = () => {
    if (!sendError) {
      return null;
    }
    return (
      <ContainerPreview>
        <ErrorMessage>{t('post.errorSend')}</ErrorMessage>
      </ContainerPreview>
    );
  };

  const renderTargetTooltip = () => {
    const { selectedTargets } = state;
    const firstThree = selectedTargets.slice(0, 3).map(target => target.user.name);

    return t('filter.tooltipCount_interval', {
      postProcess: 'interval',
      targets: firstThree.join(', '),
      count: selectedTargets.length,
      restCount: selectedTargets.length - 3,
    });
  };
  // #endregion

  const showArrowMenu =
    networkSettings.blockCommenting || (createFixedPost && newPostVisibility === 'public');

  if (state.isMount) {
    return (
      <div>
        <Wrapper>
          <div className="editor-wrapper">
            <ProfileSwitch
              editorFocused={state.isFocused}
              storeKey={NEW_POST_GROUP}
              onProfileSwitch={handleProfileSwitch}
            />
            <TextEditor
              className="editor"
              editorState={state.editorState}
              hasPermission
              onBlur={() => setState({ isFocused: false })}
              onChange={handleChange}
              onFocus={() => setState({ isFocused: true })}
              onSearchChange={handleSearchMention}
              onSuggestionsClose={() => setState({ suggestions: [] })}
              placeholder={t('groups.editorPlaceholder')}
              plugins={[emojiPlugin]}
              suggestions={state.suggestions}
              ref={textEditorRef}
            />
            <emojiPlugin.EmojiSuggestions />
            {showArrowMenu && (
              <div className="arrow-container">
                <PostModifierMenu
                  storeKey={NEW_POST_GROUP}
                  onChange={arrowMenuChangeHandler}
                  ref={arrowMenuRef}
                />
              </div>
            )}
          </div>
          {previewLinkAsync.loading ? (
            <div style={{ position: 'relative', height: 96 }}>
              <div
                style={{
                  position: 'absolute',
                  margin: 0,
                  top: '50%',
                  left: '50%',
                  transform: 'translateY(-50%, -50%)',
                }}
              >
                <FontAwesomeIcon icon={faSpinnerThird} style={{ fontSize: '35px' }} spin />
              </div>
            </div>
          ) : (
            <AttachmentsPreview
              files={state.files}
              fileType={state.fileType}
              handleFileAdded={handleFileAdded}
              handleMediaRemoved={handleMediaRemoved}
              resetFiles={() => dispatch({ type: 'resetFiles' })}
            />
          )}
          {renderErrorMessage()}
          {renderSendError()}
          {!state.showNewSurvey && (
            <ActionsWrapper>
              <AttachmentsActions
                files={state.files}
                handleFileAdded={handleFileAdded}
                handleRejectedFile={handleRejectedFile}
              />

              <emojiPlugin.EmojiSelect />

              {networkFunctions.polls && createSurvey && (
                <Tooltip placement="bottom" title={t('common.survey')}>
                  <ActionButton
                    onClick={() => setState({ showNewSurvey: true, fileType: 'survey' })}
                  >
                    <FontAwesomeIcon icon={faPollH} />
                  </ActionButton>
                </Tooltip>
              )}

              {renderScopeSelector()}

              <Button.Group style={{ marginLeft: 'auto' }}>
                <PostButton
                  type="primary"
                  disabled={disableSendPost}
                  loading={state.sending}
                  onClick={handleSendPost}
                >
                  {t('post.sendPost')}
                </PostButton>
                {userPermissions.CreatePostSchedule && networkFunctions.postScheduling && (
                  <Dropdown
                    disabled={disableSendPost || state.sending}
                    placement="bottomRight"
                    trigger={['click']}
                    overlay={
                      <Menu onClick={handleMenuClick}>
                        <Menu.Item key="schedule">{t('post.schedule.schedulePost')}</Menu.Item>
                      </Menu>
                    }
                  >
                    <S.DropdownButton type="primary">
                      <FontAwesomeIcon icon={faCaretDown} />
                    </S.DropdownButton>
                  </Dropdown>
                )}
              </Button.Group>
              <PostSchedulingModal
                show={showScheduleModal}
                loading={state.sending}
                onDateSelected={handleSendPostSchedule}
                onClose={handleCancelPostSchedule}
              />
            </ActionsWrapper>
          )}
          <CSSTransition in={state.showNewSurvey} timeout={50} mountOnEnter unmountOnExit>
            <SurveyContainer>
              <emojiPlugin.EmojiSelect />

              <NewSurvey
                isValid={!disableSendPost}
                loading={state.sending}
                renderScopeSelector={renderScopeSelector.bind(this, state.sending)}
                onCancel={() => setState({ showNewSurvey: false, fileType: null })}
                onSave={handleSendPost}
              />
            </SurveyContainer>
          </CSSTransition>
        </Wrapper>
      </div>
    );
  }
  return null;
}

export default NewPostGroup;
