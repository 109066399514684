import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import RDocViewer, { PDFRenderer, HTMLRenderer, IDocument } from 'react-doc-viewer';
import { Button } from 'antd';

import * as S from './DocViewerStyles';

//#region TYPES
export type Document = IDocument & { downloadUri?: string; fileName?: string };

export interface DocViewerProps {
  documents: Document[];
}
//#endregion

const ViewerRenderers = [PDFRenderer, HTMLRenderer];

const imgTypes = ['png', 'jpg', 'jpeg', 'gif', 'tiff', 'bmp', 'webp'];

const DocViewer = ({ documents }: DocViewerProps) => {
  const { t } = useTranslation();

  const hasRenderer = useMemo(
    () =>
      documents.some(doc =>
        ViewerRenderers.some(renderer => renderer.fileTypes.indexOf(doc.fileType!) >= 0)
      ),
    [documents]
  );

  const imgRenderer = useMemo(
    () => documents.some(doc => imgTypes.indexOf(doc.fileType!) >= 0),
    [documents]
  );

  if (imgRenderer) {
    return (
      <div>
        <S.ImgRenderer src={documents[0].uri} alt={documents[0].fileName} />
      </div>
    );
  }

  if (!hasRenderer) {
    return (
      <div>
        <p>{t('viewer.cannotViewDocument', { document: documents[0].fileName })}</p>
        <Button
          type="primary"
          ghost
          href={documents[0].downloadUri}
          download={documents[0].fileName}
        >
          {t('viewer.download')}
        </Button>
      </div>
    );
  }

  return (
    <RDocViewer
      documents={documents}
      pluginRenderers={ViewerRenderers}
      config={{ header: { disableHeader: true } }}
    />
  );
};

export default DocViewer;
