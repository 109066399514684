const widgetsEn = {
  information: 'Information',
  birthday: {
    birthdays: 'Birthdays',
    congratulate: 'Congratulate',
    happyBirthday: 'Congratulations {{user}}. Happy birthday!',
    incomingBirthdays: 'There is no incoming birthdays',
    otherBirthdays: 'View more',
    noBirthdays: 'Too bad, there are no birthdays this month',
    widgetTitle: "{{month}}'s birthdays",
  },
  calendar: {
    accepted: 'Accepted',
    add: 'Add',
    calendar: 'Calendar',
    connect: 'Connect your Google Calendar',
    declined: 'Declined',
    empty: 'Nothing for today',
    emptyHint: 'Your scheduled events will be shown here.',
    myCalendar: 'My calendar',
    needsAction: 'Unseen',
    tentative: 'Maybe',
    today: '[Today], MMMM Do',
  },
  config: {
    configTitle: 'Organize your widgets',
    configSubtitle: 'Activate which widgets you want to see in home page',
  },
  group: {
    about: 'About the group',
    addDescription: 'Add description',
    addLink: 'Add link',
    administrator: 'Administrator',
    administrator_plural: 'Administrators',
    emptyFiles: "Files in the group's posts will be stored here",
    manager: 'Group manager',
    members: 'Group members',
    membersCount: 'See all ({{count}})',
    noDescription: 'No description',
  },
  groupList: {
    joinedGroups: 'My Groups',
    seeOtherGroupsIJoined: 'View more',
  },
  manager: {
    addManager: 'Add a manager',
    addManagerDescription: 'Add the person that is responsible of your team leadership',
    delete: 'Delete',
    deleteManagerModalContent: 'Do you wish to delete the manager?',
    deleteManagerModalTitle: 'Delete manager',
    organizationalChart: 'Organizational chart',
  },
  post: {
    pinnedPosts: 'Bulletin',
    post: 'Post',
  },
  user: {
    addExtension: 'Add an extension',
    contactNumber: 'Contact number',
    change: 'Change',
    emptyField: 'Empty field',
    lessInformation: 'Show less information',
    moreInformation: 'Show more information',
    personal: 'Personal',
    yourBirthday: 'Your birthday',
    yourCompany: 'Your company',
    yourDepartment: 'Your department',
    yourEmail: 'Your email',
    yourLinkedIn: 'Your linkedin',
    yourName: 'Your name',
    yourOccupation: 'Your occupation',
    yourUnit: 'Where is your unit?',
    yourWhatsapp: 'Your whatsapp',
  },
};

export default widgetsEn;
