import { css } from '@emotion/react';
import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { down } from 'styled-breakpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const selected = ({
  theme,
  isNewComposer,
}: {
  theme: Themes.DefaultTheme;
  isNewComposer: boolean;
}) => css`
  color: ${isNewComposer ? theme.colors.gray9 : theme.colors.primary};
`;

export const Icon = styled(FontAwesomeIcon, {
  shouldForwardProp: prop => (prop === 'icon' ? true : isPropValid(prop)),
})<{
  selected: boolean;
  isNewComposer: boolean;
}>`
  margin-right: 5px;
  ${props => props.selected && selected(props)};
  ${({ theme }) => theme.typography.fontSmall('20px')};
`;

export const Label = styled.span<{ selected: boolean; isNewComposer: boolean }>`
  font-weight: ${props => (props.selected ? 600 : 400)};

  ${down('notebook')} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  ${props => props.selected && selected(props)};
  ${({ theme }) => theme.typography.fontSmall('20px')};
`;

export const Button = styled.div<{ isNewComposer: boolean }>`
  position: initial !important;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 11px 11px;
  color: ${({ theme }) => theme.colors.gray4};
  cursor: default;
  background: none !important;
  border: 1px solid
    ${({ theme, ...props }) => (props.isNewComposer ? theme.colors.gray9 : theme.colors.primary)};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

  ${down('notebook')} {
    max-width: 100px;
  }

  .caret {
    margin-left: 6px;
    ${selected};
  }
`;
