import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, last } from 'lodash/fp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { faFilter } from '@fortawesome/pro-solid-svg-icons';
import { Result } from 'antd';

import { getPosts as getPostsSelector } from 'store/selectors/postSelectors';
import Post from 'components/layout/Main/Posts/Post/Post';
import PostSkeleton from 'components/layout/Main/Posts/Post/PostSkeleton';

import * as S from './PostsStyles';
import { usePosts } from './usePosts';

type PostsProps = {
  profileType?: ProfileTypes | ProfileTypes[];
};

function Posts({ profileType }: PostsProps) {
  const { t } = useTranslation();
  const posts = useSelector(getPostsSelector);

  const { pages, loading, hasMore, hasError, loadPosts } = usePosts({
    profileType,
  });

  const onInfinityScroll = () => {
    const nextPage = isEmpty(pages) ? 1 : last(pages)! + 1;
    if (!loading) loadPosts(nextPage);
  };

  return (
    <>
      <InfiniteScroll
        initialLoad={false}
        threshold={1024}
        loadMore={onInfinityScroll}
        hasMore={!loading && hasMore}
      >
        {posts.map(postInfo => (
          // @ts-expect-error
          <Post key={postInfo.post.postId} postId={postInfo.post.postId} />
        ))}

        {loading && hasMore && <LoadingSkeleton count={pages.length > 1 ? 1 : 5} />}
      </InfiniteScroll>
      {!loading && posts.length === 0 && (
        <Result
          status={hasError ? 'error' : 'info'}
          css={S.blankState}
          icon={hasError ? undefined : <FontAwesomeIcon className="anticon" icon={faFilter} />}
          title={hasError ? t('post.errors.feedError') : t('post.errors.filterEmpty')}
          subTitle={hasError ? undefined : t('post.errors.filterEmptySub')}
        />
      )}
    </>
  );
}

export default Posts;

function LoadingSkeleton({ count }: { count: number }) {
  return (
    <div key={0}>
      {Array.from(Array(count)).map((v, i) => (
        <PostSkeleton key={i} />
      ))}
      <div style={{ textAlign: 'center' }}>
        <FontAwesomeIcon icon={faSpinnerThird} spin />
      </div>
    </div>
  );
}
