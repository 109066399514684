import axios from 'axios';
import { createCancelToken, CancelCallback, Result, errorResult } from '../apiActions';
import { AUTH_API } from '../constants';

const authApi = axios.create({ baseURL: AUTH_API });

type FindDomainUserParams = {
  username: string;
  networkId: number;
};

export async function findDomainUser(
  params: FindDomainUserParams,
  cancelCallback?: CancelCallback
): Promise<Result<UserSecurity>> {
  const cancelToken = createCancelToken(cancelCallback);

  try {
    const { status, data } = await authApi.post('/api/security/findDomainUser', params, {
      cancelToken,
    });

    return { ok: true, status: status, data };
  } catch (error) {
    return errorResult(error);
  }
}

type GenerateVerificationCodeParams = {
  userId: number;
};

export async function generateVerificationCode(
  params: GenerateVerificationCodeParams,
  cancelCallback?: CancelCallback
): Promise<Result<void>> {
  const cancelToken = createCancelToken(cancelCallback);

  try {
    const { status } = await authApi.post('/api/Security/generateVerificationCode', params, {
      cancelToken,
    });

    return { ok: true, status };
  } catch (error) {
    return errorResult(error);
  }
}

type VerifyCodeParams = {
  userId: number;
  networkId: number;
  verificationCode: string;
};

export async function verifyCode(
  params: VerifyCodeParams,
  cancelCallback?: CancelCallback
): Promise<Result<string>> {
  const cancelToken = createCancelToken(cancelCallback);

  try {
    const { status, data } = await authApi.post('/api/Security/verifyCode', params, {
      cancelToken,
    });

    return { ok: true, status, data };
  } catch (error) {
    return errorResult(error);
  }
}
