import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { GTAG_ID } from 'lib/constants';
import useAuth from './useAuth';

const { gtag } = window;

function useGAConfig() {
  const location = useLocation();
  const { userId, networkId } = useAuth();

  useEffect(() => {
    if (userId > 0 && networkId > 0) {
      gtag('config', GTAG_ID!, { user_id: userId.toString() });
      gtag('set', 'user_properties', {
        network_id: networkId.toString(),
      });
    }
  }, [networkId, userId]);

  useEffect(() => {
    let configParams = { page_path: location.pathname };
    if (userId > 0) {
      configParams = Object.assign(configParams, { user_id: userId.toString() });
    }

    gtag('config', GTAG_ID!, configParams);
  }, [location.pathname, userId]);
}

export default useGAConfig;
