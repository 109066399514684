import { useFeatureToggle } from 'hooks';
import { StoreKey } from 'store/types/newPostTypes';
import NewPost from 'components/layout/Main/NewPost/NewPost';
import NewPostGroup from 'components/Groups/NewPost/NewPostGroup';
import Composer from './PostComposer/Composer';

type ComposerWrapperProps = {
  storeKey: StoreKey;
};

export default function ComposerWrapper({ storeKey }: ComposerWrapperProps) {
  const [newComposer] = useFeatureToggle('new_composer');

  if (newComposer) {
    return <Composer storeKey={storeKey} />;
  }

  if (storeKey === 'NEW_POST') return <NewPost storeKey={storeKey} />;

  return <NewPostGroup />;
}
