import store from 'store';
import { userAuthenticated } from 'store/actions/authActions';

import { getApiToken, isApiTokenValid } from './Auth';

// STORE

export const renewApiToken = async () => {
  const newToken = await getApiToken();

  store.dispatch(userAuthenticated({ token: newToken }));
  return newToken;
};

export const getOrRenewApiToken = async () => {
  const { token } = store.getState().auth;

  if (!isApiTokenValid(token)) {
    return renewApiToken();
  }

  return token!;
};
