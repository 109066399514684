import { Modal as AntModal } from 'antd';
import styled from '@emotion/styled';

export const Modal = styled(AntModal)`
  .ant-modal-body {
    padding: 20px 24px;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 14px;

  > div {
    flex: 1;
  }
`;
