import React, { Suspense } from 'react';
import { Modal, Tabs, Spin } from 'antd';

import { ClassNames } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-solid-svg-icons';

// GLOBAL UTILS
import { useTranslation } from 'react-i18next';

import { useAuth, useFeatureToggle } from 'hooks';

import { ModalHeader, headerIcon, tabs, modalWrapper } from './SettingsStyles';

// PANES
const SecurityPane = React.lazy(() => import('./settings/SecurityPane'));
const NotificationPane = React.lazy(() => import('./settings/Notifications'));
const LanguagePane = React.lazy(() => import('./settings/Language'));

type SettingsModalProps = {
  visible: boolean;
  onCancel: () => void;
};

const SettingsTitle = () => {
  const { t } = useTranslation();
  return (
    <ModalHeader>
      <FontAwesomeIcon css={headerIcon} icon={faCog} />
      {t('common.settings')}
    </ModalHeader>
  );
};

const Settings = () => {
  const { t } = useTranslation();
  const { userInfo } = useAuth();
  const { authType } = userInfo.user;
  const [enableNewLoginToken] = useFeatureToggle('new_login_token');

  return (
    <Tabs css={tabs} type="card" destroyInactiveTabPane>
      <Tabs.TabPane tab={t('notification.title')} key="2">
        <Suspense fallback={<Spin />}>
          <NotificationPane />
        </Suspense>
      </Tabs.TabPane>
      <Tabs.TabPane tab={t('common.language')} key="4">
        <Suspense fallback={<Spin />}>
          <LanguagePane />
        </Suspense>
      </Tabs.TabPane>
      {(authType === 'DEFAULT' || authType === 'LOCAL' || enableNewLoginToken) && (
        <Tabs.TabPane tab={t('settings:security.tabTitle')} key="1">
          <Suspense fallback={<Spin />}>
            <SecurityPane />
          </Suspense>
        </Tabs.TabPane>
      )}
    </Tabs>
  );
};

const SettingsModal = ({ visible, onCancel }: SettingsModalProps) => {
  return (
    <ClassNames>
      {({ css }) => (
        <Modal
          destroyOnClose
          footer={null}
          onCancel={onCancel}
          style={{ marginTop: '-5rem' }}
          title={<SettingsTitle />}
          visible={visible}
          width={720}
          wrapClassName={css`
            ${modalWrapper}
          `}
        >
          <Settings />
        </Modal>
      )}
    </ClassNames>
  );
};

export default SettingsModal;
