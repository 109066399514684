import React from 'react';
import { rem } from 'polished';

import styled from '@emotion/styled';
import { Card } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';

import UserHoverCard from 'components/UserHoverCard';
import ProfileImage from 'components/layout/Common/ProfileImage/ProfileImage';

//#region TYPES
interface FilterCardProps {
  selected?: boolean;
  user: UserInfo;
  onClick: (userId: number) => void;
}
//#endregion

//#region STYLES
const CardStyled = styled(Card)`
  position: relative;
  &.ant-card-bordered {
    background-color: ${({ theme }) => theme.colors.primary1};
    border: solid 1px ${({ theme }) => theme.colors.primary};
  }

  .ant-card-body {
    padding: 10px;
  }

  svg {
    position: absolute;
    top: 4px;
    right: 4px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;

const CardBody = styled.div`
  display: flex;
  align-items: center;

  .content {
    margin-left: 7px;
    overflow: hidden;
    text-overflow: ellipsis;

    strong {
      font-size: ${rem(13)};
      white-space: nowrap;
    }

    div {
      overflow: hidden;
      font-size: ${rem(12)};
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;

//#endregion

const FilterCard: React.VFC<FilterCardProps> = ({ selected = false, user, onClick }) => {
  const handleItemClick = () => {
    onClick(user.user.userId);
  };

  return (
    <CardStyled bordered={selected} hoverable onClick={handleItemClick}>
      <UserHoverCard userId={user.user.userId}>
        <CardBody>
          <ProfileImage
            profile={{
              name: user.user.name,
              profileId: user.user.userId,
              profileType: 1,
              mediaId: user.user.mediaId,
            }}
          />
          <div className="content">
            <strong>{user.user.name}</strong>
            {(user.occupation || user.department) && (
              <div>
                {user.occupation?.description} • {user.department?.description}
              </div>
            )}
          </div>
        </CardBody>
      </UserHoverCard>
      <FontAwesomeIcon icon={selected ? faCheckCircle : faCircle} size="sm" />
    </CardStyled>
  );
};

export default React.memo(FilterCard);
