import jwtDecode from 'jwt-decode';
import axios from 'axios';

import { getLegacySessionToken, getApiToken, AuthError } from 'lib/Auth';
import { fileAsBase64 } from 'lib/helper';
import { account } from 'lib/api';
import Routes from 'routes';

import { fetchShortcuts } from 'store/reducers/shortcutSlice';
import { AsyncAction } from 'store/actions';
import { ChatActions, PostsActions } from 'store/types/types';
import { AccountPayload, AuthActions, AuthActionType } from 'store/types/authTypes';
import { ChatActionType } from 'store/types/chatTypes';
import { API_MEDIA } from 'lib/constants';

//#region TYPES
type AuthenticateBusinessAction = (args?: {
  firstLogin?: boolean;
  deviceToken?: string;
}) => AsyncAction<Promise<void>, AuthActionType>;

type LoginAction = (
  loginData: LoginData,
  callback: LoginCallback
) => AsyncAction<Promise<void>, AuthActionType>;
type LogoutAction = () => AsyncAction<void, AuthActionType | ChatActionType>;

type LoginLegacyAction = () => AsyncAction<Promise<void>, AuthActionType>;

export type LoginCallbackArgs = { response?: Response; message: string };
export type LoginCallback = (status: boolean, args: LoginCallbackArgs | AuthError) => void;

type UserAuthenticatedPayload = {
  token?: string | null;
  deviceToken?: string;
  firstLogin?: boolean;
  intervalId?: number;
};
//#endregion

export function userAuthenticated(
  payload: UserAuthenticatedPayload = {
    token: null,
    intervalId: 0,
  }
): AuthActionType {
  const { token, deviceToken, firstLogin, intervalId } = payload;

  return {
    type: AuthActions.USER_AUTHENTICATED,
    payload: { token, deviceToken, firstLogin, intervalId },
  };
}

export function userInfoReceived(
  userInfo: UserInfo,
  networkId: number,
  userId: number,
  userPermissions: KeyValue[],
  network: Network,
  networkSettings: NetworkSettings,
  networkFunctions: NetworkFunctions,
  isSystemMasterAdmin: boolean
): AuthActionType {
  return {
    type: AuthActions.USER_INFO_RECEIVED,
    payload: {
      userInfo,
      networkId,
      userId,
      userPermissions,
      network,
      networkSettings,
      networkFunctions,
      isSystemMasterAdmin,
    },
  };
}

export function featureToogleReceived(featureToogle: FeatureToogle): AuthActionType {
  return {
    type: AuthActions.FEATURE_TOOGLE_RECEIVED,
    payload: featureToogle,
  };
}

export function userOnboarded(): AuthActionType {
  return { type: AuthActions.USER_ONBOARDED };
}

export function userUpdated(userInfo: UserInfo): AuthActionType {
  return {
    type: AuthActions.AUTH_USER_UPDATED,
    payload: userInfo,
  };
}

export function themeUpdated(theme: string): AuthActionType {
  return {
    type: AuthActions.THEME_UPDATED,
    theme,
  };
}

export function networkUpdated(network: Network): AuthActionType {
  return {
    type: AuthActions.NETWORK_UPDATED,
    network,
  };
}

export function faviconUpdated(favicon: string | Nullable): AuthActionType {
  return {
    type: AuthActions.FAVICON_UPDATED,
    payload: favicon,
  };
}

export function deviceTokenReceived(deviceToken: string): AuthActionType {
  return {
    type: AuthActions.DEVICE_TOKEN_RECEIVED,
    deviceToken,
  };
}

export function accountReceived(account: AccountPayload): AuthActionType {
  return {
    type: AuthActions.ACCOUNT_RECEIVED,
    payload: account,
  };
}

export const authenticateBusiness: AuthenticateBusinessAction =
  (args = { firstLogin: false }) =>
  async (dispatch, getState) => {
    const { firstLogin, deviceToken } = args;

    let apiToken = getState().auth.token;
    apiToken = apiToken ?? (await getApiToken());

    if (apiToken) {
      dispatch(userAuthenticated({ token: apiToken, firstLogin, deviceToken }));

      const decoded = jwtDecode<Record<string, string>>(apiToken);

      const userPermissions = JSON.parse(decoded.userPermissions) as KeyValue[];
      const networkSettings = JSON.parse(decoded.networkSettings) as NetworkSettings;
      const networkFunctions = JSON.parse(decoded.networkFunctions) as NetworkFunctions;
      const { userId, networkId } = decoded;
      const isSystemMasterAdmin = /true/i.test(decoded.isSystemMasterAdmin);

      const accountInfo = await account.getAccountInfo();

      if (!accountInfo || typeof accountInfo === 'number') {
        dispatch(logout());
        return;
      }

      const { network, userInfo, featureToggle, userSecurity } = accountInfo;

      if (userInfo.user.deleted) {
        dispatch(logout());
        return;
      }

      delete userInfo.user.password;

      dispatch(
        userInfoReceived(
          userInfo,
          parseInt(networkId, 10),
          parseInt(userId, 10),
          userPermissions,
          network,
          networkSettings,
          networkFunctions,
          isSystemMasterAdmin
        )
      );

      dispatch(fetchShortcuts());
      dispatch(featureToogleReceived(featureToggle));
      dispatch(accountReceived({ userSecurity }));

      if (network.icon) {
        let favicon: string | null = null;
        const { data: iconFile } = await axios.get<Blob>(`${API_MEDIA}/api/media/getMedia`, {
          params: { mediaId: network.icon, acess_token: apiToken },
          responseType: 'blob',
        });

        favicon = await fileAsBase64(iconFile);
        dispatch(faviconUpdated(favicon));
      }
    }
  };

export const login: LoginAction = (loginData, callback) => async dispatch => {
  const { userName, password, recaptchaToken, tenant, firstLogin = false, deviceToken } = loginData;

  try {
    const response = await fetch(Routes.authenticatePath, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({
        userName,
        password,
        token: recaptchaToken,
        tenant,
        actionType: 'Login',
        platform: 'WebBrowser',
        deviceToken,
      }),
    });
    const resToken = await response.text();
    if (response.ok) {
      localStorage.setItem('session-token', resToken);
      dispatch(authenticateBusiness({ firstLogin, deviceToken }));
      callback(true, { response, message: response.statusText });
    } else {
      throw new AuthError(response.statusText, response);
    }
  } catch (error) {
    return callback(false, error as AuthError);
  }
};

export const loginLegacy: LoginLegacyAction = () => async dispatch => {
  getLegacySessionToken().then(async ({ data: { key: credentialKey } }) => {
    const { data: token } = await axios.post(Routes.authenticateWithKeyPath, `"${credentialKey}"`, {
      headers: { 'Content-Type': 'application/json' },
    });

    localStorage.setItem('session-token', token);
    dispatch(authenticateBusiness());
  });
};

export const logout: LogoutAction = () => dispatch => {
  localStorage.removeItem('session-token');
  sessionStorage.removeItem('session-token');
  dispatch(userAuthenticated());
  dispatch({ type: ChatActions.CLEAR_LIST });
  //@ts-expect-error
  dispatch({ type: PostsActions.POST_REDUCER_RESET });
};
