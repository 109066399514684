import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const postEntering = css`
  transition: all 0.4s ease-in-out;
`;
export const postLeaving = css`
  height: 0 !important;
  margin-bottom: 0;
  transition: all 0.5s ease-out;
`;
export const postCss = (isEntering?: boolean, isLeaving?: boolean) => css`
  margin-bottom: 24px;

  ${isEntering && postEntering};
  ${isLeaving && postLeaving};
`;
export const postAttachment = css`
  margin-top: 16px;
  transition: opacity 0.3s ease-in;
  transition-delay: 0.8s;
`;

export const PostContainer = styled.div`
  ${postCss()};
`;
export const PostInnerContainer = styled.div<{ publishing?: boolean }>`
  background-color: #fff;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
  box-shadow: 0 4px 12px -4px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-out;

  ${props =>
    props.publishing &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;
export const PostContentWrapper = styled.div`
  z-index: 1;
  padding: 1em;
  transition: opacity 0.4s ease-in-out;
`;
export const PostContent = styled.div`
  padding: 1em 0;
  color: ${({ theme }) => theme.colors.gray10};
  word-break: break-word;
  white-space: pre-wrap;

  ${({ theme }) => theme.typography.fontSmall()};
`;
export const PostTags = styled.div`
  display: flex;
  margin-top: 16px;

  /* .post__text + & {
    margin-top: 16px;
  } */
`;
