import { css as styles } from '@emotion/css';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const toolbarStyles = {
  toolbar: styles`
    position: relative;
    max-height: 56px;
    padding: 0.5em;
    border: 1px solid var(--gray2);
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(142, 142, 142, 0.08);
  `,
};

export const buttonStyles = {
  buttonWrapper: styles`
    display: inline-block;
  `,
  button: styles`
    padding: 0;
    padding-top: 8px;
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 50px;
    cursor: pointer;
    color: var(--gray8);
    background: none;
    transition: background-color ease-in-out 300ms;

    :hover,
    :focus {
      color: var(gray10);
      background-color: var(--gray2);

      svg {
        fill: var(--gray10);
      }
    }

    svg {
      fill: var(--gray8);
    }
  `,
  active: styles`
    background-color: var(--gray2);
  `,
};

export const emojiButton = styles`
  ${buttonStyles.button}
  position: relative;
  bottom: 3px
`;

export const editor = css`
  .public-DraftEditor-content {
    -moz-appearance: textfield-multiline;
    -webkit-appearance: textarea;
    overflow: auto;
  }

  height: 286px;
`;

export const ToolbarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 16px;
`;

export const ControlsToolbar = styled.div`
  ${({ theme }) => css`
    padding: 0.5em;
    border: 1px solid ${theme.colors.gray2};
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(142, 142, 142, 0.08);

    button {
      color: ${theme.colors.gray8};

      :hover,
      :focus {
        background-color: ${theme.colors.gray2};
      }
    }
  `}
`;

export const DummyDiv = styled.div`
  display: inline-block;
`;
