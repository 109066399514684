import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

export const modal = (theme: Theme) => css`
  .ant-modal-header {
    border-bottom: 0;
  }

  .ant-modal-close {
    color: ${theme.colors.gray10};
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  > .name-wrapper {
    margin-right: auto;
  }
`;

export const ScopeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const PostAsNewsContainer = styled.div`
  > label {
    cursor: pointer;
  }
`;
