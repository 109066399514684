import axios from 'axios';
import { NotificationPanelActions } from '../types/types';

const fetchNotifications = (id, page) =>
  axios.get(`/api/Notification/getNotification?userId=${id}&pageNumber=${page}&pageSize=20`);
const fetchCount = id => axios.get(`/api/Notification/getCount?userId=${id}`);
const fetchViewed = id => axios.put(`/api/Notification/markAsViewed?userId=${id}`);
const fetchRead = (id, itemId, itemType, notificationType) =>
  axios.put(
    `/api/Notification/markAsRead?userId=${id}&itemId=${itemId}&itemType=${itemType}&notificationType=${notificationType}`
  );
const fetchBirthdayUser = id => axios.get(`/api/profile/getUser?userId=${id}`);

// COUNT
export const getCount = id => dispatch => {
  fetchCount(id)
    .then(({ data }) => {
      dispatch({
        type: NotificationPanelActions.FETCH_COUNT,
        payload: { unreadCount: data },
      });
    })
    .catch(error => {
      console.log(`Count not fetched with status ${error.status}`);
    });
};

export const getBirthdayUser = async id => {
  try {
    const { data } = await fetchBirthdayUser(id);
    return data.users[0];
  } catch (error) {
    console.log(`Count not fetched with status ${error.status}`);
  }
};

export const setViewed = id => dispatch => {
  fetchViewed(id)
    .then(() => {
      dispatch({
        type: NotificationPanelActions.VIEWED,
      });
    })
    .catch(error => {
      console.log(`Notifications not viewed with status ${error.status}`);
    });
};

export const getNotifications =
  (id, page, callback = () => {}) =>
  dispatch => {
    dispatch({ type: NotificationPanelActions.FETCHING_NOTIFICATIONS });
    fetchNotifications(id, page)
      .then(({ status, data }) => {
        dispatch({
          type: NotificationPanelActions.FETCH_NOTIFICATIONS,
          payload: { list: data.notifications },
        });
        dispatch(setViewed(id));
        callback(status);
      })
      .catch(error => {
        console.log(`Notifications not fetched with status ${error.status}`);
        callback(error.status);
      })
      .finally(() => {
        dispatch({ type: NotificationPanelActions.FETCH_COMPLETED });
      });
  };

export const clearNotifications = () => ({
  type: NotificationPanelActions.CLEAR,
});

export const setRead = (id, itemId, itemType, notificationType) => dispatch => {
  fetchRead(id, itemId, itemType, notificationType)
    .then(() => {
      dispatch(clearNotifications());
    })
    .catch(error => {
      console.log(`Read not fetched with status ${error.status}`);
    });
};

export const setReloadNotificationPage = () => ({
  type: NotificationPanelActions.RELOAD_NOTIFICATIONS,
});
