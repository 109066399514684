class SeniorService {
  private static _instance: SeniorService;

  private _token: Promise<any>;

  private handleEvent: null | ((e: MessageEvent<any>) => void) = null;

  constructor() {
    this._token = this.createPromiseMessageEvent();
  }

  static init() {
    if (!this._instance) this._instance = new SeniorService();
  }

  static get instance() {
    return this._instance;
  }

  private createPromiseMessageEvent() {
    return new Promise(resolve => {
      this.handleEvent = (e: MessageEvent<any>) => {
        if (e.origin.includes('senior.com.br')) {
          resolve(e.data.token);
          window.removeEventListener('message', this.handleEvent!);
        }
      };

      window.addEventListener('message', this.handleEvent);
    });
  }

  destroy() {
    window.removeEventListener('message', this.handleEvent!);
  }

  get token(): Promise<any> {
    return this._token;
  }
}

if (window.top !== window.self) SeniorService.init();

export default SeniorService;
