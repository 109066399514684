import React from 'react';
import { useAuth } from 'hooks';
import { PROFILE_TYPES } from 'lib/constants';
import type { StoreKey } from 'store/types/newPostTypes';
import ProfileImage from 'components/layout/Common/ProfileImage/ProfileImage';
import ProfileSwitch from 'components/Groups/NewPost/ProfileSwitch';

type ComposerProfileWrapperProps = {
  storeKey: StoreKey;
};

export default function ComposerProfileWrapper({ storeKey }: ComposerProfileWrapperProps) {
  const { userInfo } = useAuth();

  if (storeKey === 'NEW_POST') {
    return (
      <ProfileImage
        profile={{
          profileId: userInfo.user.userId,
          name: userInfo.user.name,
          profileType: PROFILE_TYPES.user,
        }}
      />
    );
  }

  return <ProfileSwitch storeKey={storeKey} />;
}
