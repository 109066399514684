import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getBanner } from 'lib/apiActions';
import { BannerState } from 'store/types/bannerTypes';

const initialState: BannerState = {
  banners: [],
  hidden: false,
  status: 'pending',
};

export const fetchPublishedBanners = createAsyncThunk<BannerInfo[]>(
  'banner/fetchPublishedBanners',
  async (_, thunkApi) => {
    const bannerDataset = await getBanner({ onlyPublished: true });

    if (bannerDataset == null || typeof bannerDataset === 'number')
      return thunkApi.rejectWithValue(bannerDataset);

    return bannerDataset.banners;
  }
);

const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    addBanner(state, action: PayloadAction<BannerInfo>) {
      state.banners.push(action.payload);
    },
    removeBanner(state, action: PayloadAction<number>) {
      state.banners.splice(action.payload, 1);
    },
    removeBannerFromId(state, action: PayloadAction<number>) {
      state.banners = state.banners.filter(bi => bi.banner.id !== action.payload);
    },
    toggleBannerVisible(state) {
      state.hidden = !state.hidden;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchPublishedBanners.pending, state => {
      state.status = 'pending';
    });

    builder.addCase(fetchPublishedBanners.fulfilled, (state, { payload }) => {
      state.banners = payload || [];
      state.status = 'succeeded';
    });

    builder.addCase(fetchPublishedBanners.rejected, state => {
      state.status = 'failed';
    });
  },
});

export const { addBanner, removeBanner, removeBannerFromId, toggleBannerVisible } =
  bannerSlice.actions;

export default bannerSlice.reducer;
