import React, { createRef } from 'react';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { EditorState, ContentState } from 'draft-js';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import { withTranslation } from 'react-i18next';
import debounce from 'lodash/fp/debounce';
import { v4 } from 'uuid';
import axios from 'axios';
import { withTheme } from '@emotion/react';

import { CSSTransition } from 'react-transition-group';

// GLOBAL COMPONENTS
import { Menu, Dropdown, Button, Tooltip, Switch } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown, faPollH, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

// ACTIONS
import {
  initNewPostStore,
  changeVisibility,
  addTarget,
  changeProfile,
} from 'store/actions/newPostActions';
import * as postActions from 'store/actions/postsActions';

import http from 'lib/httpClient';
import { PROFILE_TYPES } from 'lib/constants';

// COMPONENTS
import ProfileImage, { profileTypes } from 'components/layout/Common/ProfileImage/ProfileImage';
import TextEditor from 'components/layout/Common/TextEditor/TextEditor';
import PostScopeSelector from 'components/layout/Common/PostScopeSelector/PostScopeSelector';
import PostSchedulingModal from 'components/layout/Common/Modal/PostScheduling/PostScheduling';
import PostModifierMenu from 'components/layout/Common/PostModifierMenu/PostModifierMenu';
import AttachmentsPreview from 'components/layout/Main/AttachmentsPreview/AttachmentsPreview';
import AttachmentsActions from 'components/layout/Main/AttachmentsActions/AttachmentsActions';
import * as customNotification from 'components/layout/Common/CustomNotification';
import PostFilterModal from 'components/modal/PostFilterModal/PostFilterModal';
import NewSurvey from 'components/Feed/Survey/NewSurvey';

import { emojiThemePost } from 'styles/draftjs/emojiTheme';

import * as S from './NewPostStyles';

/**
 * @typedef {Object} Props
 * @property {string} className
 * @property {string} content
 * @property {string} storeKey
 */
/**
 * @typedef {Object} State
 * @property {string} currentLink
 * @property {EditorState} editorState
 * @property {string} errorMessage
 * @property {Array<{blob: File, name: string, size: number, preview: string, index: string | number, new: boolean}>} files
 * @property {string | null} fileType
 * @property {boolean} overLimit
 * @property {boolean} previewLoading
 * @property {boolean} sending
 * @property {boolean} showScheduleModal
 * @property {Array<{userId: number, name: string}>} suggestions
 */

class NewPost extends React.Component {
  /**
   *
   * @param {Props} props
   */
  constructor(props) {
    super(props);
    const { content, theme } = props;

    /** @type {Props} */
    this.props = this.props || {};
    /** @type {State} */
    this.state = {
      currentLink: '',
      editorState: EditorState.createWithContent(ContentState.createFromText(content)),
      errorMessage: '',
      files: [],
      fileType: null,
      overLimit: false,
      previewLoading: false,
      sending: false,
      showFiltersModal: false,
      showNewSurvey: false,
      showScheduleModal: false,
      suggestions: [],
      selectedTargets: [],
      postAsNews: false,
      error: false,
    };
    this.FilterModalRef = createRef();
    this.arrowMenuRef = createRef();

    this.selectedTargets = this.selectedTargets.bind(this);
    this.debouncedPreviewLink = debounce(500, this.previewLink);
    this.debouncedSearchMention = debounce(400, this.handleSearchMentions);

    this.emojiPlugin = createEmojiPlugin({
      theme: emojiThemePost(theme),
      selectButtonContent: <FontAwesomeIcon icon={faSmile} />,
    });

    this.postOptions = (
      <Menu onClick={this.handleMenuClick}>
        <Menu.Item key="schedule">{props.t('post.schedule.schedulePost')}</Menu.Item>
      </Menu>
    );

    this.postModifier = { pinned: false, blockCommenting: false };
  }

  static defaultProps = {
    content: '',
    className: '',
  };

  componentDidMount() {
    const { initNewPostStore, storeKey, userPermissions, changeProfile, user } = this.props;
    initNewPostStore(storeKey, !!userPermissions.CreatePublicPost ? 'public' : 'followers');
    changeProfile(storeKey, {
      profileId: user.userId,
      profileType: PROFILE_TYPES.user,
    });
  }
  componentDidUpdate(prevProps) {
    const { visibility } = this.props;

    if (prevProps.visibility !== visibility) {
      this.setState({ showFiltersModal: visibility === 'private' });
      this.FilterModalRef.current?.clearAll();
      this.arrowMenuRef.current?.resetModifiers();
    }
  }

  getMessage(forbiddenExtensions, maxFileSize) {
    const { t } = this.props;

    return t('files.forbiddenFiles', {
      extensions: forbiddenExtensions.join(', '),
      size: maxFileSize,
    });
  }

  previewLink = async link => {
    const { user } = this.props;
    this.setState({ previewLoading: true });

    try {
      const preview = await http.previewLink(link);

      if (!preview) {
        this.setState({
          currentLink: link,
          files: [{ url: link }],
          fileType: 'pureLink',
        });
      }

      preview.authorId = user.userId;
      this.setState({
        currentLink: link,
        files: [preview],
        fileType: 'link',
      });
    } catch {
      this.setState({
        currentLink: link,
        files: [{ url: link }],
        fileType: 'pureLink',
      });
    } finally {
      this.setState({ previewLoading: false });
    }
  };

  resetPost = (date, status) => {
    const { t, getScheduledCount } = this.props;
    switch (status) {
      case 200:
        this.reset();
        break;
      case 202: {
        if (date) {
          customNotification.renderToast({
            title: t('post.schedule.success'),
            type: customNotification.TYPE_POST_SCHEDULED,
          });
          getScheduledCount();
        } else {
          const attributes = {
            type: customNotification.TYPE_VIDEO_PROCESSING,
            title: t('post.processingVideoTitle'),
            body: t('post.processingVideo'),
          };
          customNotification.renderToast(attributes);
        }
        this.reset();

        break;
      }
      default:
        this.setState({ sending: false, error: false });
        break;
    }
  };

  reset = () => {
    const { initNewPostStore, storeKey, userPermissions } = this.props;
    this.setState({
      editorState: EditorState.createEmpty(),
      sending: false,
      showNewSurvey: false,
    });
    this.resetFiles();
    initNewPostStore(storeKey, !!userPermissions.CreatePublicPost ? 'public' : 'followers');
    this.arrowMenuRef.current?.resetModifiers();
  };

  resetFiles = () => {
    this.setState({
      currentLink: '',
      fileType: null,
      files: [],
    });
  };

  selectedTargets(selectedUsers) {
    this.setState({ selectedTargets: selectedUsers });
  }

  // #region POST HANDLERS
  handleChange = (newEditorState, linksContent, overLimit) => {
    const { editorState, currentLink, fileType } = this.state;

    if (!fileType) {
      if (newEditorState.getCurrentContent() !== editorState.getCurrentContent()) {
        if (linksContent.length > 0) {
          const link = linksContent.pop();
          if (link !== currentLink) {
            this.debouncedPreviewLink(link);
          }
        } else {
          this.debouncedPreviewLink.cancel();
          if (fileType === 'link') {
            this.setState({ currentLink: '' });
            this.resetFiles();
          }
        }
      }
    }

    this.setState({ editorState: newEditorState, overLimit });
  };
  handleSearchMentions = ({ value }) => {
    if (value.length > 0) {
      const params = { name: value };

      axios.get('/api/Mention/getUserList', { params }).then(({ data }) => {
        if (data) {
          const suggestions = data.map(mention => ({
            userId: mention.userId,
            name: mention.name,
          }));

          this.setState({ suggestions });
        }
      });
    }
  };
  handleSendPost = ({ surveyInfo, scheduledDate }) => {
    const { editorState, files, fileType } = this.state;
    const { submitPost, storeKey, visibility } = this.props;
    const { pinned, blockCommenting } = this.postModifier;

    const contentState = editorState.getCurrentContent();

    this.setState({ errorMessage: '', sending: true });

    let typePost;
    switch (visibility) {
      case 'public': {
        typePost = 1;
        break;
      }
      case 'private': {
        typePost = 2;
        break;
      }
      case 'followers': {
        typePost = 3;
        break;
      }
      default:
        typePost = 1;
        break;
    }

    const attributes = {
      _extra: {
        contentState,
        files: [...files].reverse(),
        fileType: fileType === 'pureLink' ? 'link' : fileType,
        pinned,
        scheduledDate,
        blockCommenting,
        surveyInfo,
      },
      type: typePost,
      sendToFollowers: visibility === 'followers',
    };
    return submitPost(storeKey, attributes, this.resetPost.bind(null, scheduledDate), s => {
      this.cancelToken = s;
    });
  };

  arrowMenuChangeHandler = modifiers => {
    this.postModifier = { pinned: modifiers.highlight, blockCommenting: modifiers.blockCommenting };
  };

  changeProfileHandler = checked => {
    const { storeKey, profile, changeProfile } = this.props;

    this.setState({ postAsNews: checked });

    changeProfile(storeKey, {
      ...profile,
      profileType: checked ? PROFILE_TYPES.publisher : PROFILE_TYPES.user,
    });
  };
  // #endregion

  // #region POST SCHEDULING HANDLERS
  handlePostSchedule = async date => {
    await this.handleSendPost({ scheduledDate: date });

    this.setState({ showScheduleModal: false });
  };
  handleCancelPostSchedule = () => {
    if (this.cancelToken) this.cancelToken.cancel();
    this.setState({ showScheduleModal: false });
  };

  handleMenuClick = ({ key }) => {
    if (key === 'schedule') {
      this.setState({ showScheduleModal: true });
    }
  };
  // #endregion

  //#region FILTERS HANDLERS
  handleEditFilters = () => {
    this.setState({ showFiltersModal: true });
  };

  handleFiltersClose = () => {
    this.setState({ showFiltersModal: false });
  };
  //#endregion

  // #region FILES HANDLERS
  handleFileAdded = (acceptedFiles, type) => {
    const {
      resetPostError,
      networkSettings: { maxPhotosPermittedInPost },
      t,
    } = this.props;
    const { fileType, files } = this.state;

    if (acceptedFiles?.length > 0) {
      resetPostError();
      this.setState({ errorMessage: '' });

      const totalFileCount = acceptedFiles.length + files.length;
      /** @type {typeof files} */
      let newFiles = acceptedFiles;

      if (totalFileCount >= maxPhotosPermittedInPost && type === 'image') {
        newFiles = newFiles.slice(0, maxPhotosPermittedInPost - files.length);
      }

      newFiles = newFiles.map(file => ({
        blob: file,
        name: file.name,
        size: file.size,
        preview: URL.createObjectURL(file),
        index: v4(),
        new: true,
      }));

      if (fileType === type && type === 'image') {
        this.setState({
          files: [...files, ...newFiles],
        });
      } else {
        this.setState({
          files: newFiles,
          fileType: type,
        });
      }
    } else if (type === 'image') {
      this.resetFiles();
      this.setState({
        errorMessage: t('files.forbiddenAttachment'),
      });
    }
  };
  handleMediaRemoved = removedFile => {
    const { files, fileType } = this.state;
    const newFiles = files.filter(file => file.index !== removedFile.index);

    this.setState({
      files: newFiles,
      fileType: newFiles.length > 0 ? fileType : null,
    });
  };
  handleRejectedFile = () => {
    const { networkSettings, resetPostError } = this.props;

    resetPostError();
    this.resetFiles();

    this.setState({
      errorMessage: this.getMessage(
        networkSettings.forbiddenExtensions,
        networkSettings.maxPostFileSize
      ),
    });
  };
  // #endregion

  // #region RENDERS
  renderScopeSelector(disabled) {
    const { storeKey, visibility } = this.props;
    const { selectedTargets, showFiltersModal } = this.state;

    return (
      <>
        <PostScopeSelector disabled={disabled} storeKey={storeKey} />
        {visibility === 'private' && (
          <div style={{ display: 'inline-block', marginLeft: '0.5em' }}>
            <Tooltip title={this.renderTargetTooltip()}>
              <PostFilterModal.EditFilterButton
                count={selectedTargets.length}
                onClick={this.handleEditFilters}
              />
            </Tooltip>
            <PostFilterModal
              storeKey={storeKey}
              visible={showFiltersModal}
              selectedUsers={this.selectedTargets}
              onClose={this.handleFiltersClose}
              ref={this.FilterModalRef}
            />
          </div>
        )}
      </>
    );
  }

  renderErrorMessage() {
    const { errorMessage } = this.state;
    if (!errorMessage) {
      return null;
    }
    return (
      <S.ContainerPreview>
        <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
      </S.ContainerPreview>
    );
  }
  renderSendError() {
    const { t, sendError } = this.props;
    if (!sendError) {
      return null;
    }
    return (
      <S.ContainerPreview>
        <S.ErrorMessage>{t('post.errorSend')}</S.ErrorMessage>
      </S.ContainerPreview>
    );
  }

  renderTargetTooltip() {
    const { t } = this.props;
    const { selectedTargets } = this.state;
    const firstThree = selectedTargets.slice(0, 3).map(target => target.user.name);

    return t('filter.tooltipCount_interval', {
      postProcess: 'interval',
      targets: firstThree.join(', '),
      count: selectedTargets.length,
      restCount: selectedTargets.length - 3,
    });
  }

  // #endregion

  render() {
    const {
      forwardedRef,
      className,
      user,
      t,
      visibility,
      storeKey,
      userPermissions,
      networkFunctions,
      networkSettings,
      profile,
      featureToggle,
    } = this.props;
    const {
      editorState,
      files,
      fileType,
      overLimit,
      previewLoading,
      sending,
      showNewSurvey,
      showScheduleModal,
      suggestions,
      selectedTargets,
      postAsNews,
    } = this.state;
    let disableSendPost =
      !editorState.getCurrentContent().getPlainText().trim().length > 0 || overLimit;
    if (visibility === 'private') {
      disableSendPost = disableSendPost || selectedTargets.length === 0;
    }
    const popoverVisbility = {};
    if (!disableSendPost) popoverVisbility.visible = false;
    const { EmojiSuggestions, EmojiSelect } = this.emojiPlugin;

    const showArrowMenu =
      networkSettings.blockCommenting ||
      (userPermissions.CreateFixedPost && visibility === 'public');

    return (
      <S.Wrapper ref={forwardedRef} className={className}>
        <div className="editor-wrapper">
          <ProfileImage
            css={S.avatar}
            profile={{
              profileId: user.userId,
              name: user.name,
              profileType: profileTypes.user,
            }}
          />
          <TextEditor
            editorState={editorState}
            suggestions={suggestions}
            onChange={this.handleChange}
            onSearchChange={this.debouncedSearchMention}
            onSuggestionsClose={() => this.setState({ suggestions: [] })}
            className="editor"
            placeholder={`${t('post.tellUsSomething')} ${user.name.substr(
              0,
              user.name.indexOf(' ')
            )}...`}
            hasPermission
            plugins={[this.emojiPlugin]}
          />
          <EmojiSuggestions />
          {showArrowMenu && (
            <div className="arrow-container">
              <PostModifierMenu
                storeKey={storeKey}
                onChange={this.arrowMenuChangeHandler}
                ref={this.arrowMenuRef}
              />
            </div>
          )}
        </div>
        {previewLoading ? (
          <div style={{ position: 'relative', height: 96 }}>
            <div
              style={{
                position: 'absolute',
                margin: 0,
                top: '50%',
                left: '50%',
                transform: 'translateY(-50%, -50%)',
              }}
            >
              <FontAwesomeIcon icon={faSpinnerThird} spin style={{ fontSize: '35px' }} />
            </div>
          </div>
        ) : (
          <AttachmentsPreview
            loading={sending}
            files={files}
            fileType={fileType}
            handleFileAdded={this.handleFileAdded}
            handleMediaRemoved={this.handleMediaRemoved}
            resetFiles={this.resetFiles}
          />
        )}
        {this.renderErrorMessage()}
        {this.renderSendError()}

        {userPermissions.CreateNews && featureToggle.new_feed.toLowerCase() === 'true' && (
          <S.BulletinWrapper>
            <Switch
              checked={profile?.profileType === PROFILE_TYPES.publisher}
              size="small"
              onChange={this.changeProfileHandler}
            />{' '}
            <label role="button" onClick={() => this.changeProfileHandler(!postAsNews)}>
              {t('post.postAsNews')}
            </label>
          </S.BulletinWrapper>
        )}

        {!showNewSurvey && (
          <S.ActionsWrapper>
            <AttachmentsActions
              handleFileAdded={this.handleFileAdded}
              handleRejectedFile={this.handleRejectedFile}
              files={files}
            />
            <EmojiSelect />

            {networkFunctions.polls && userPermissions.CreatePoll && (
              <Tooltip placement="bottom" title={t('common.survey')}>
                <S.ActionButton
                  onClick={() => this.setState({ showNewSurvey: true, fileType: 'survey' })}
                >
                  <FontAwesomeIcon icon={faPollH} />
                </S.ActionButton>
              </Tooltip>
            )}

            {this.renderScopeSelector()}

            <S.RightSide>
              <S.BtnActions>
                <S.PostButton
                  type="primary"
                  disabled={disableSendPost}
                  loading={sending}
                  onClick={this.handleSendPost}
                >
                  {t('post.sendPost')}
                </S.PostButton>
                {networkFunctions.postScheduling && userPermissions.CreatePostSchedule && (
                  <Dropdown
                    disabled={disableSendPost || sending}
                    overlay={this.postOptions}
                    placement="bottomRight"
                    trigger={['click']}
                  >
                    <Button className="dropdown-button" type="primary">
                      <FontAwesomeIcon icon={faCaretDown} />
                    </Button>
                  </Dropdown>
                )}
              </S.BtnActions>
            </S.RightSide>
          </S.ActionsWrapper>
        )}

        <CSSTransition in={showNewSurvey} timeout={50} mountOnEnter unmountOnExit>
          <S.SurveyContainer>
            <EmojiSelect />

            <NewSurvey
              isValid={!disableSendPost}
              loading={sending}
              renderScopeSelector={this.renderScopeSelector.bind(this, sending)}
              onCancel={() => this.setState({ showNewSurvey: false, fileType: null })}
              onSave={this.handleSendPost}
            />
          </S.SurveyContainer>
        </CSSTransition>

        <PostSchedulingModal
          loading={sending}
          show={showScheduleModal}
          onDateSelected={this.handlePostSchedule}
          onClose={this.handleCancelPostSchedule}
        />
      </S.Wrapper>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { storeKey } = ownProps;
  const newPostStore = state.newPost.posts[storeKey];

  return {
    networkSettings: state.auth.networkSettings,
    userPermissions: state.auth.userPermissions,
    networkFunctions: state.auth.networkFunctions,
    featureToggle: state.auth.featureToogle,
    sendError: state.posts.error,
    pinnedCount: state.posts.pinnedCount,
    user: state.auth.userInfo.user,
    userInfo: state.auth.userInfo,
    visibility: newPostStore?.visibility,
    profile: newPostStore?.profile,
  };
};
const mapActionsToProps = {
  initNewPostStore,
  changeVisibility,
  addTarget,
  changeProfile,
  ...postActions,
};

const NewPostComposed = compose(
  connect(mapStateToProps, mapActionsToProps),
  withTheme,
  withTranslation()
)(NewPost);
export default NewPostComposed;
