import { useAsync } from 'react-async-hook';
import axios from 'axios';

async function fetchPermissions() {
  const { data } = await axios.get<KeyValue[]>('api/role/getPermission');

  return data;
}

function useFetchPermissions() {
  const asyncPermissions = useAsync(fetchPermissions, []);

  return asyncPermissions;
}

export default useFetchPermissions;
