import React, { useState } from 'react';
import loadable from '@loadable/component';
import { Helmet } from 'react-helmet';

import UsernameStep from './UsernameStep';

import * as S from './LoginStyles';

const PasswordStep = loadable(
  () => import(/* webpackChunkName: "PasswordStep" */ './PasswordStep')
);

const VerificationStep = loadable(
  () => import(/* webpackChunkName: "VerificationStep" */ './VerificationStep')
);

export default function Login() {
  const [step, setStep] = useState(0);

  const Component = steps[step];

  return (
    <>
      <Helmet title="Login" />
      <S.Left />
      <S.Right>
        <S.Centered>
          <Component
            onNext={() => setStep(prev => prev + 1)}
            onBack={() => setStep(prev => prev - 1)}
          />
        </S.Centered>
      </S.Right>
    </>
  );
}

const steps = [UsernameStep, PasswordStep, VerificationStep] as const;
