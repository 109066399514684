import axios from 'axios';
import { statusError } from 'lib/apiActions';
import { LINK_PREVIEW_API } from 'lib/constants';

export async function previewLink(url: string): Promise<LinkPreview | number | undefined> {
  try {
    const { data } = await axios.post(LINK_PREVIEW_API!, JSON.stringify(url), {
      headers: { 'Content-Type': 'text/plain' },
    });

    return data;
  } catch (error) {
    return statusError(error);
  }
}
