import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const editorAvatar = css`
  margin-right: 8px;
`;
export const commentsWrapper = css`
  margin: 0;
  margin-top: 16px;
  margin-bottom: -6px;

  .ant-list-item {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .ant-list-empty-text {
    display: none;
  }
  .ant-spin {
    position: static !important;
    padding: 24px 0;
  }
  .ant-spin .ant-spin-dot {
    position: static !important;
    margin: 0 !important;
  }
`;

export const BorderComments = styled.div`
  border-top: 1px ridge ${({ theme }) => theme.colors.gray4};
`;
export const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0 0 8px 8px;
`;
export const AvatarWrapper = styled.div`
  float: left;
`;
export const EditorWrapper = styled.div`
  float: left;
  width: 100%;
  padding-left: 40px;
  margin-left: -40px;
`;
