import { fileAsBase64 } from 'lib/helper';

interface CropImgOptions {
  rotation?: number;
  fileName?: string;
}

interface ResizeImgOptions {
  fileName?: string;
  quality?: number;
}

const createImage = (src: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', reject);
    image.setAttribute('crossOrigin', 'anonymous');
    image.src = src;
  });

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

const getCroppedImg = async (
  imgSrc: string,
  crop: import('react-easy-crop/types').Area,
  { rotation = 0, fileName = 'cropped.jpg' }: CropImgOptions = {}
): Promise<File> => {
  const image = await createImage(imgSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d')!;

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = crop.width;
  canvas.height = crop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - crop.x,
    0 - safeArea / 2 + image.height * 0.5 - crop.y
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (blob)
        resolve(new File([blob], fileName, { type: 'image/jpeg', lastModified: Date.now() }));
      else reject(new Error('Failed to return cropped image'));
    }, 'image/jpeg');
  });
};

export async function resizeImage(
  src: string | Blob,
  size: number,
  { fileName = 'resized.jpg', quality = 0.8 }: ResizeImgOptions = {}
): Promise<File> {
  function calculateSize(img: HTMLImageElement): [number, number] {
    let w = img.width;
    let h = img.height;

    if (w > h) {
      if (w > size) {
        h = Math.round((h * size) / w);
        w = size;
      }
    } else {
      if (h > size) {
        w = Math.round((w * size) / h);
        h = size;
      }
    }

    return [w, h];
  }

  let srcString: string;
  if (typeof src === 'string') srcString = src;
  else srcString = await fileAsBase64(src);

  const image = await createImage(srcString);
  const [newWidth, newHeight] = calculateSize(image);

  const canvas = document.createElement('canvas');
  canvas.width = newWidth;
  canvas.height = newHeight;

  const ctx = canvas.getContext('2d')!;
  ctx.drawImage(image, 0, 0, newWidth, newHeight);

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      blob => {
        if (blob) {
          resolve(new File([blob], fileName, { type: 'image/jpeg', lastModified: Date.now() }));
        } else reject(new Error('Failed to resize image'));
      },
      'image/jpeg',
      quality
    );
  });
}

export async function cropWithImage(
  image: HTMLImageElement,
  cropArea: import('react-easy-crop/types').Area
): Promise<File> {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  canvas.width = cropArea.width;
  canvas.height = cropArea.height;
  const ctx = canvas.getContext('2d')!;

  ctx.drawImage(
    image,
    cropArea.x * scaleX,
    cropArea.y * scaleY,
    cropArea.width * scaleX,
    cropArea.height * scaleY,
    0,
    0,
    cropArea.width,
    cropArea.height
  );

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      blob => {
        if (blob)
          resolve(new File([blob], 'cropped.png', { type: 'image/png', lastModified: Date.now() }));
        else reject(new Error('Failed to return cropped image'));
      },
      'image/png',
      1
    );
  });
}

export default getCroppedImg;
