import React, { useState, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import loadable from '@loadable/component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faComment, faEye } from '@fortawesome/pro-solid-svg-icons';

import { useFeatureToggle } from 'hooks';
import { ITEM_TYPES } from 'lib/constants';
import { useSelector } from 'store';
import { makeGetPostSelector } from 'store/selectors/postSelectors';

import { canUseFunction } from 'store/selectors/authSelectors';

import ReactionCounter from 'components/Reaction/ReactionCounter';

import * as S from './PostInteractionCountersStyles';

const UsersListModal = loadable(
  () =>
    import(
      /* webpackChunkName: "UsersListModal" */ 'components/layout/Common/Modal/PersonList/PersonListContainer'
    )
);

const LikesList = loadable(
  () => import(/* webpackChunkName: "LikesList" */ 'components/layout/Common/LikesList/LikesList')
);

const UserReactionList = loadable(
  () => import(/* webpackChunkName: "UserReactionList" */ 'components/Reaction/UserReactionList')
);

const ViewList = loadable(() => import(/* webpackChunkName: "ViewList" */ '../ViewList'));

//#region TYPES
type CommentHandler = () => void;

interface PostInteractionCountersProps {
  postId: number;
  handleComments?: CommentHandler;
}

interface CounterProps {
  postInfo: PostInfo;
}

interface CommentCountProps extends CounterProps {
  onOpenComments?: CommentHandler;
}

//#endregion

const PostInteractionCounters = (props: PostInteractionCountersProps) => {
  const { postId, handleComments } = props;

  // #region REDUX
  const { current: getPost } = useRef(makeGetPostSelector());
  const post = useSelector(state => getPost(state, postId));
  const commentsFunction = useSelector(state => canUseFunction(state, 'comments'));
  const likesFunction = useSelector(state => canUseFunction(state, 'likes'));
  // #endregion

  return (
    <S.InteractionCounters>
      {likesFunction && <LikeCount postInfo={post} />}
      <S.ViewCommentWrapper>
        <ViewCount postInfo={post} />
        {!post.post.blockCommenting && commentsFunction && (
          <CommentCount postInfo={post} onOpenComments={handleComments} />
        )}
      </S.ViewCommentWrapper>
    </S.InteractionCounters>
  );
};

export default React.memo(PostInteractionCounters);

const CommentCount = ({ postInfo, onOpenComments }: CommentCountProps) => {
  const { commentCount } = postInfo;

  if (commentCount === 0) return null;

  return (
    <S.CounterButton
      type="link"
      icon={<FontAwesomeIcon css={S.icon} className="anticon" icon={faComment} />}
      onClick={onOpenComments}
    >
      {commentCount}
    </S.CounterButton>
  );
};

const LikeCount = ({ postInfo }: CounterProps) => {
  const { t } = useTranslation();
  const [reactions] = useFeatureToggle('reactions');
  const [likesModalVisible, setLikesModalVisible] = useState(false);

  const reactionCount = useMemo(
    () => [...postInfo.reactionCount].sort((ra, rb) => ra.reactionId - rb.reactionId),
    [postInfo.reactionCount]
  );

  const showModalVisibleHandler = () => {
    setLikesModalVisible(true);
  };

  const hideModalVisibleHandler = () => {
    setLikesModalVisible(false);
  };

  if (postInfo.likeCount === 0) return null;

  return (
    <>
      {reactions ? (
        <S.CounterButton
          type="link"
          disabled={postInfo.survey.anonymous}
          onClick={showModalVisibleHandler}
        >
          <ReactionCounter reactionList={reactionCount} />
        </S.CounterButton>
      ) : (
        <S.CounterButton
          type="link"
          icon={<FontAwesomeIcon css={S.icon} className="anticon" icon={faThumbsUp} />}
          onClick={showModalVisibleHandler}
        >
          {postInfo.likeCount}
        </S.CounterButton>
      )}
      <UsersListModal
        visible={likesModalVisible}
        destroyOnClose
        title={t(reactions ? 'post.viewReacted' : 'post.viewLiked')}
        onCancel={hideModalVisibleHandler}
      >
        {reactions ? (
          <UserReactionList
            reactionCountList={reactionCount}
            itemId={postInfo.post.postId}
            itemType={ITEM_TYPES.post}
          />
        ) : (
          <LikesList itemId={postInfo.post.postId} itemType={ITEM_TYPES.post} />
        )}
      </UsersListModal>
    </>
  );
};

const ViewCount = ({ postInfo }: CounterProps) => {
  const { t } = useTranslation();
  const [viewModalVisible, setViewModalVisible] = useState(false);

  const showModalVisibleHandler = () => {
    setViewModalVisible(true);
  };

  const hideModalVisibleHandler = () => {
    setViewModalVisible(false);
  };

  if (postInfo.viewsCount <= 0) return null;

  return (
    <>
      <S.CounterButton
        type="link"
        icon={<FontAwesomeIcon css={S.icon} className="anticon" icon={faEye} />}
        disabled={postInfo.survey.anonymous}
        onClick={showModalVisibleHandler}
      >
        {postInfo.viewsCount}
      </S.CounterButton>
      <UsersListModal
        visible={viewModalVisible}
        title={t('post.viewedPost')}
        onCancel={hideModalVisibleHandler}
        destroyOnClose
      >
        <ViewList postId={postInfo.post.postId} />
      </UsersListModal>
    </>
  );
};
