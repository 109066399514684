import React, { forwardRef, useImperativeHandle, useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { css } from '@emotion/react';
import { Button, Dropdown, Menu, MenuProps } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

import { useAuth, usePermissions } from 'hooks';

import PostSchedulingModal from 'components/layout/Common/Modal/PostScheduling/PostScheduling';

import SurveyForm from './SurveyForm';
import { Container, Fieldset, Header } from './Styles';

//#region TYPES
export interface NewSurveyProps {
  className?: string;
  isOwnForm?: boolean;
  isValid: boolean;
  loading?: boolean;
  renderScopeSelector?: () => React.ReactNode;
  onCancel: () => void;
  onSave: (surveyResult: { surveyInfo: SurveyInfo; scheduledDate?: string }) => void;
}

export interface NewSurveyRef {
  handleSubmit: ReturnType<typeof useForm<SurveyData>>['handleSubmit'];
}

export interface SurveyData {
  title: string;
  options: SurveyOption[];
  endDate: import('moment').Moment | null;
  anonymous: boolean;
}
//#endregion

//#region STYLES
const dropdownButton = css`
  width: 100%;
`;

const closeButton = (theme: Themes.DefaultTheme) => css`
  width: 20px;
  height: 20px;

  && {
    :focus,
    :hover {
      background-color: transparent;
    }
  }

  svg {
    font-size: 1.2rem;
    color: ${theme.colors.gray6};
  }
`;
//#endregion

const NewSurvey = forwardRef<NewSurveyRef, NewSurveyProps>(
  (
    {
      className,
      isOwnForm = true,
      isValid,
      loading = false,
      renderScopeSelector,
      onCancel,
      onSave,
    },
    ref
  ) => {
    const { t } = useTranslation();

    const { networkId } = useAuth();

    const { control, errors, formState, getValues, handleSubmit, register } = useForm<SurveyData>({
      mode: 'all',
      defaultValues: {
        title: '',
        options: [{ description: '' }, { description: '' }],
        endDate: null,
        anonymous: false,
      },
    });

    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    const onSubmit = (data: SurveyData) => {
      onSave({
        surveyInfo: { ...data, companyNetworkId: networkId, endDate: data.endDate!.format() },
      });
    };

    const surveyScheduledHandler = (date: string) => {
      const data = getValues();

      onSave({
        surveyInfo: { ...data, companyNetworkId: networkId, endDate: data.endDate!.format() },
        scheduledDate: date,
      });
    };

    const Form = isOwnForm ? 'form' : 'div';

    return (
      <Fieldset className={className}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <Header>
              <div>
                <h4>{t('survey.survey')}</h4>
                <small>{t('survey.subtitle')}</small>
              </div>
              <Button
                css={closeButton}
                icon={<FontAwesomeIcon icon={faTimes} />}
                type="text"
                onClick={onCancel}
              />
            </Header>
            <SurveyForm
              control={control}
              errors={errors}
              loading={loading}
              register={register}
              renderScopeSelector={renderScopeSelector}
            />
          </Container>
          {isOwnForm && (
            <CreateSurveyButton
              isValid={isValid && formState.isValid}
              loading={loading}
              onSurveyScheduled={surveyScheduledHandler}
            />
          )}
        </Form>
      </Fieldset>
    );
  }
);

export default NewSurvey;

interface CreateSurveyButtonProps {
  isValid: boolean;
  loading: boolean;
  onSurveyScheduled: (date: string) => void;
}

const CreateSurveyButton: VFC<CreateSurveyButtonProps> = ({
  isValid,
  loading,
  onSurveyScheduled,
}) => {
  const { t } = useTranslation();
  const { networkFunctions } = useAuth();
  const [createPostSchedule] = usePermissions('CreatePostSchedule');

  const [modalVisible, setModalVisible] = useState(false);

  const optionClickHandler: MenuProps['onClick'] = event => {
    if (!loading) {
      if (event.key === 'schedule') {
        setModalVisible(true);
      }
    }
  };

  const dateSelectedHandler = (date: string) => {
    setModalVisible(false);
    onSurveyScheduled(date);
  };

  const closeModalHandler = () => {
    setModalVisible(false);
  };

  const options = (
    <Menu onClick={optionClickHandler}>
      <Menu.Item key="schedule">{t('survey.scheduleSurvey')}</Menu.Item>
    </Menu>
  );

  return (
    <>
      <Button.Group size="large" style={{ width: '100%' }}>
        <Button
          css={dropdownButton}
          disabled={!isValid}
          htmlType="submit"
          loading={loading}
          style={{ border: 0, borderRadius: 0 }}
          type="primary"
        >
          {t('survey.createSurvey')}
        </Button>
        {networkFunctions.postScheduling && createPostSchedule && (
          <Dropdown
            disabled={!isValid}
            overlay={options}
            placement="bottomRight"
            trigger={['click']}
          >
            <Button
              htmlType="button"
              icon={<FontAwesomeIcon icon={faCaretDown} className="anticon" />}
              style={{ border: 0, borderRadius: 0 }}
              type="primary"
            />
          </Dropdown>
        )}
      </Button.Group>
      <PostSchedulingModal
        loading={loading}
        show={modalVisible}
        onClose={closeModalHandler}
        onDateSelected={dateSelectedHandler}
      />
    </>
  );
};
