import React, { PureComponent } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons';

import { urlDomain } from 'lib/helper';
import ImageAttachment from '../ImageAttachment/ImageAttachment';
import * as S from './LinkAttachmentStyles';

export type LinkAttachmentProps = {
  className?: string;
  clickable?: boolean;
  description?: string;
  disable?: boolean;
  image?: string;
  imageClassName?: string;
  newPost?: boolean;
  title: string;
  url: string;
  onRemove?: () => void;
};

export type LinkAttachmentState = {
  imgLoaded: boolean;
};

class LinkAttachment extends PureComponent<LinkAttachmentProps, LinkAttachmentState> {
  constructor(props: LinkAttachmentProps) {
    super(props);

    this.state = {
      imgLoaded: true,
    };
  }

  static defaultProps = {
    className: '',
    clickable: true,
    description: '',
    disable: false,
    image: '#',
    imageClassName: '',
    newPost: false,
  };

  handleImgRender = (e: React.MouseEvent<HTMLImageElement>) => {
    e.target.removeEventListener('onerror', null);
    this.setState({ imgLoaded: false });
  };

  onClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    const { clickable } = this.props;
    if (!clickable) {
      e.preventDefault();
    }
  };

  render() {
    const { className, clickable, description, disable, image } = this.props;
    const { imageClassName, newPost, onRemove, title, url } = this.props;
    const { imgLoaded } = this.state;

    return (
      <S.AttachmentContainer className={className}>
        <S.A
          href={clickable ? url : ''}
          target="_blank"
          rel="noopener noreferrer"
          onClick={this.onClick}
        >
          {newPost && (
            <FontAwesomeIcon
              css={S.removeLink}
              icon={faTimesCircle}
              onClick={disable ? undefined : onRemove}
            />
          )}
          {imgLoaded && (
            <S.ImageWrapper>
              <ImageAttachment
                css={S.imageAttachment}
                src={image}
                className={imageClassName}
                onImgError={this.handleImgRender}
              />
            </S.ImageWrapper>
          )}
          <S.LinkInfo>
            <S.LinkSource>{urlDomain(url)}</S.LinkSource>
            <S.LinkTitle>{title}</S.LinkTitle>
            <S.LinkDescription>{description}</S.LinkDescription>
          </S.LinkInfo>
        </S.A>
      </S.AttachmentContainer>
    );
  }
}

export default LinkAttachment;
