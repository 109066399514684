import styled from '@emotion/styled';
import { up } from 'styled-breakpoints';
import { Skeleton } from 'antd';
import { Link } from 'react-router-dom';

export const Wrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 64px;
  padding: 0 1em;
  background-color: #fff;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};
  box-shadow: 0 1px 4px -4px rgba(0, 0, 0, 0.2);
  transition: filter 0.15s ease-out;
  backface-visibility: hidden;

  body.modal-open & {
    padding-right: 8px;
  }

  body.blurContent & {
    filter: blur(2px);
    transition: filter 0.4s ease-in;
  }
`;
export const LogoLink = styled(Link)`
  display: inline-block;
`;
export const LogoWrapper = styled.div`
  height: 32px;
`;
export const Logo = styled.img`
  display: block;
  max-height: 100%;
`;

export const SideColumn = styled.div`
  display: none;

  ${up('tablet')} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
  }
`;

export const SearchSkeleton = styled(Skeleton)`
  .ant-skeleton-paragraph {
    margin-bottom: 0;
  }
`;
