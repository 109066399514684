import styled from '@emotion/styled';

export const Container = styled.div`
  width: 100%;
  max-width: 750px;
  padding: 1em 2em;
  margin: 0 auto;

  .steps {
    margin-bottom: 1.5rem;
  }
`;

export const Content = styled.div`
  .button-container {
    display: flex;
    justify-content: center;
  }
`;
