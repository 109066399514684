import { getApiToken, isApiTokenExpired } from 'lib/Auth';
import { userAuthenticated } from '../actions/authActions';

const actionsQueue = [];

let tokenFetch;

export const apiTokenMiddleware =
  ({ getState }) =>
  next =>
  action => {
    const { token } = getState().auth;

    if (token) {
      if (isApiTokenExpired(token)) {
        actionsQueue.push(action);

        if (!tokenFetch) {
          tokenFetch = getApiToken()
            .then(newToken => next(userAuthenticated(newToken)))
            .then(() => {
              while (actionsQueue.length) {
                next(actionsQueue.shift());
              }
            })
            .then(() => {
              tokenFetch = null;
            });
        }
        return tokenFetch;
      }
    }
    return next(action);
  };

const middlewares = { apiTokenMiddleware };

export default middlewares;
