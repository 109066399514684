import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { down } from 'styled-breakpoints';
import { Button } from 'antd';
import { fadeIn, fadeInDown } from 'react-animations';

import * as mediaStyles from './ChatMedia/ChatMediaStyles';

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeInDownAnimation = keyframes`${fadeInDown}`;

const displayNone = css`
  display: none;
`;
const userOnline = ({ theme, ...props }: { userOnline?: boolean } & Themes.PropsWithTheme) => css`
  color: ${props.userOnline ? theme.colors.success : theme.colors.gray5};
`;
const headerNotification = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`;
const chatOpen = (props: { chatOpen?: boolean }) => css`
  ${!props.chatOpen &&
  css`
    height: 40px;
  `};
`;
const miniChatSize = (
  props: { chatOpen?: boolean; collapsed?: boolean } & Themes.PropsWithTheme
) => css`
  width: ${props.collapsed ? '300px' : '600px'};
  height: ${props.collapsed ? '378px' : '770px'};
  ${chatOpen(props)};

  ${down('desktop')(props)} {
    width: ${props.collapsed ? '300px' : '500px'};
    height: ${props.collapsed ? '378px' : '570px'};
    ${chatOpen(props)};
  }

  ${down('tablet')(props)} {
    width: 300px;
    height: ${props.collapsed ? '378px' : '570px'};
    ${chatOpen(props)};
  }
`;

type MiniChatProps = { collapsed?: boolean; chatOpen?: boolean; listOpen?: boolean };

export const MiniChat = styled.div<MiniChatProps>`
  position: fixed;
  right: ${props => (props.listOpen ? '340px' : '50px')};
  bottom: 0;
  display: flex;
  flex-direction: column;
  color: #fff;
  background-color: #f0f2f5;
  border-radius: 10px 10px 0 0;
  box-shadow: -2px -2px 4px 2px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;

  ${miniChatSize};
`;
export const Header = styled.div<{ newMessageNotification?: boolean }>`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 12px;
  color: #fff;
  cursor: pointer;
  background: ${({ theme, ...props }) =>
    props.newMessageNotification ? theme.colors.primary : theme.colors.gray10};
  border-radius: 8px 8px 0 0;
  ${props => props.newMessageNotification && headerNotification}
`;
export const RoomName = styled.div`
  align-items: center;
  margin: 0;
  overflow: hidden;
  font-weight: 600;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ theme }) => theme.typography.fontSmall()};

  .status-icon {
    width: 8px;
    height: 8px;
    margin-bottom: 3px;
    margin-left: 4px;
    ${userOnline}
  }
`;
export const HeaderActionsContainer = styled.div`
  display: flex;
  margin-left: auto;
  color: #fff;
`;
export const HeaderButton = styled(Button)`
  && {
    padding: 0;
    margin-left: 14px;
    color: #fff;
    cursor: pointer;

    :focus {
      color: #fff;
    }
    :hover {
      color: ${({ theme }) => theme.colors.primary};
      transition: all 0.1s ease-in;
      transform: scale(1.2);
    }
  }
`;

export const MessagesWrapper = styled.div`
  flex-grow: 1;
  padding: 8px;
  overflow-x: hidden;
`;
export const ChatContent = styled.div<{ userCreated?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.userCreated ? 'row-reverse' : 'row')};
  flex-wrap: wrap;
  align-items: flex-start;
  animation: 600ms ${fadeInDownAnimation};

  & + & {
    margin-top: 8px;
  }

  .user-avatar {
    align-self: flex-start;
    margin: ${props => (props.userCreated ? '0 0 0 8px' : '0 8px 0 0')};
  }
`;

export const LeaveChatContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  min-width: 20%;
  max-width: 80%;
  margin: 8px auto;
  font-weight: 600;
  text-align: 'center';
  ${({ theme }) => theme.typography.fontxSmall()};

  .leaveMessage {
    padding: 4px 8px;
    text-align: center;
    background-color: #0cce6b;
    border-radius: 4px;
  }
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100% - 40px);
`;
export const ChatUserName = styled.div<{ userCreated?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.userCreated ? 'row-reverse' : 'row')};

  /* justify-content: space-between; */
  align-items: center;
  align-self: ${props => (props.userCreated ? 'flex-end' : 'flex-start')};
  width: 100%;
  strong {
    margin: 0 5px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.gray10};

    ${({ theme }) => theme.typography.fontSmall()};
  }
  span {
    align-self: center;
    margin-top: 2px;
    color: ${({ theme }) => theme.colors.gray6};
    ${({ theme }) => theme.typography.fontxSmall()};
  }
  .dropdown {
    opacity: 0;
  }
  :hover {
    .dropdown {
      opacity: 1;
    }
  }
`;

export const DropdownText = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.black};
`;

export const ChatWrapper = styled.div<{ userCreated?: boolean; visible?: boolean }>`
  align-self: ${props => (props.userCreated ? 'flex-end' : 'flex-start')};
  float: left;
  padding: ${props => (props.userCreated ? '6px 12px 5px' : '10px 12px')};
  margin-bottom: 6px;
  word-break: break-word;
  background: ${({ theme, ...props }) => (props.userCreated ? theme.colors.gray10 : '#fff')};
  border-radius: 8px;
  box-shadow: 0 2px 4px -4px rgba(0, 0, 0, 0.4);
  ${props => !props.visible && displayNone}
`;

export const ChatText = styled.div<{ userCreated?: boolean }>`
  color: ${props => (props.userCreated ? '#fff' : '#000')};
  word-wrap: break-word;

  ${({ theme }) => theme.typography.fontSmall()}
`;

export const ChatTime = styled.div<{ userCreated?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.userCreated ? 'row-reverse' : 'row')};
  margin-bottom: 9px;
  color: ${({ theme }) => theme.colors.gray6};
  word-wrap: break-word;
  ${props =>
    props.userCreated
      ? css`
          margin-top: 8px;
          margin-right: 35px;
        `
      : css`
          margin-top: 8px;
          margin-left: 35px;
        `};

  ${({ theme }) => theme.typography.fontxxSmall()}
`;

export const MediaWrapper = styled.div<{ userCreated?: boolean; visible?: boolean }>`
  position: sticky;
  display: flex;
  flex-direction: ${props => (props.userCreated ? 'row-reverse' : 'row')};
  width: calc(100% - 40px);
  max-width: calc(100% - 40px);
  ${props =>
    props.userCreated
      ? css`
          right: 35px;
        `
      : css`
          left: 35px;
        `};

  ${props => !props.visible && displayNone};

  ${mediaStyles.FileWrapper} {
    ${props =>
      props.userCreated
        ? css`
            right: -25px;
          `
        : css`
            left: -25px;
          `}
  }
`;

export const ChatEditor = styled.div`
  width: 100%;
  padding: 5px 10px;
  color: #111;
  background-color: #fff;
  border: 1px solid #e3e3e3;
  outline: none;

  .editor-wrapper {
    max-height: 40px;
    overflow: hidden;
  }

  .editor-wrapper .DraftEditor-root {
    ${({ theme }) => theme.typography.fontSmall('18px')}
  }
  .editor-wrapper .public-DraftEditorPlaceholder-inner[style] {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
  }
`;

export const ChatActions = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const SendButton = styled(Button)`
  && {
    padding: 0;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
    animation: 300ms ${fadeInAnimation};
  }
`;

export const MediaContainer = styled.div`
  margin-top: 25px;
`;
