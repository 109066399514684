import styled from '@emotion/styled';
import { Divider } from 'antd';
import { transparentButton } from 'styles/component-styles/buttons';

export const Item = styled.div`
  position: relative;
  width: 100%;
  padding-right: 12px;

  .meatball {
    float: right;
    height: auto;
    padding: 0;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.white};
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;

    :focus {
      color: ${({ theme }) => theme.colors.white};
      visibility: visible;
      opacity: 1;
    }
  }

  :hover .meatball {
    color: ${({ theme }) => theme.colors.white};
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear, opacity 300ms;
  }
`;

export const ItemButton = styled.button`
  ${transparentButton};
  display: flex;
  width: calc(100% - 16px);
`;

export const ItemImageContainer = styled.div`
  position: relative;
  display: inline-block;

  .ant-avatar > img {
    object-fit: cover;
  }
`;

export const ItemInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;
`;

export const ItemInfoTitle = styled.h5`
  display: inline-block;
  max-width: 170px;
  margin: 0;
  overflow: hidden;
  color: #fff;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ theme }) => theme.typography.fontSmall(1.43)}
`;

export const ItemInfoDescription = styled.div`
  max-width: 170px;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.gray5};
  text-overflow: ellipsis;
  white-space: nowrap;
  ${({ theme }) => theme.typography.fontxSmall(1.43)}
`;

export const DividerStyled = styled(Divider)`
  && {
    margin: 9px 0;
    border-top: 1px solid ${({ theme }) => theme.colors.gray9};
  }
`;
