import React, { useRef } from 'react';
import { useAsync } from 'react-async-hook';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { PROFILE_TYPES } from 'lib/constants';

import UserHoverCard from 'components/UserHoverCard';
import ProfileImage from 'components/layout/Common/ProfileImage/ProfileImage';

//#region TYPES
type RoleIdType = number | null;

interface Params {
  networkId: number;
  name: string;
  pageNumber: number;
  roleId?: RoleIdType;
  inRole?: boolean;
}

export type DataSource = {
  key: number;
  name: string;
  job: string | undefined;
  department: string | undefined;
  unit: string | undefined;
};

export type MembersDataSource = {
  count: number;
  dataSource: DataSource[];
};
//#endregion

async function fetchUsers(
  networkId: number,
  name: string,
  pageNumber: number,
  roleId: RoleIdType,
  inRole: boolean
) {
  const params: Params = { networkId, name, pageNumber };
  if (roleId) {
    params.roleId = roleId;
    params.inRole = inRole;
  }
  const { status, data } = await axios.get<UserFetchData>(`api/profile/getUser`, { params });

  if (status !== 200) return null;

  const dataSource = data.users.map(({ user, ...ui }) => ({
    key: user.userId,
    name: user.name,
    job: ui.occupation?.description,
    department: ui.department?.description,
    unit: ui.companyUnit?.description,
  }));

  return { count: data.totalCount, dataSource } as MembersDataSource;
}

const debouncedFetchUsers = AwesomeDebouncePromise(fetchUsers, 400);

function useMembers(
  networkId: number,
  memberName: string,
  pageNumber: number,
  roleId: RoleIdType = null,
  inRole: boolean = true
) {
  const { t } = useTranslation('admin');
  const asyncUsers = useAsync(debouncedFetchUsers, [
    networkId,
    memberName,
    pageNumber,
    roleId,
    inRole,
  ]);

  const { current: columns } = useRef([
    {
      title: t('default:common.collaborator'),
      dataIndex: 'name',
      render: (text: string, { key }: { key: number }) => (
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <UserHoverCard userId={key} placement="right">
            <div>
              <ProfileImage
                profile={{ profileId: key, name: text, profileType: PROFILE_TYPES.user }}
                size="xs"
              />
            </div>
          </UserHoverCard>
          <div style={{ flex: 1, alignSelf: 'center', marginLeft: 12 }}>{text}</div>
        </div>
      ),
    },
    {
      title: t('default:common.occupation'),
      dataIndex: 'job',
    },
    {
      title: t('default:common.department'),
      dataIndex: 'department',
    },
    {
      title: t('default:common.unit'),
      dataIndex: 'unit',
    },
  ]);

  return { columns, asyncUsers };
}

export default useMembers;
