import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const icon = css`
  margin-right: 0.3125em;
  font-size: 0.85em;
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 0.3em;
  ${({ theme }) => theme.typography.fontSmall()};
`;

export const Button = styled.a`
  color: ${({ theme }) => theme.colors.primary};

  :focus,
  :hover {
    color: ${({ theme }) => theme.colors.primary5};
    text-decoration: underline;
  }
`;
export const FileName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
