import React from 'react';
import { List, Skeleton } from 'antd';
import type { PaginationConfig } from 'antd/lib/pagination';

import PersonItem, { PersonItemProps } from '../Modal/PersonList/PersonItem';

type UsersListProps = {
  users: PersonItemProps[];
  pagination?: PaginationConfig;
  loading?: boolean;
  renderActions?: (item: PersonItemProps) => React.ReactNode[];
};

const UsersList = ({ users, pagination, loading, renderActions }: UsersListProps) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={users}
      pagination={pagination}
      loading={loading}
      renderItem={user => (
        <List.Item key={user.userId} actions={renderActions?.(user)}>
          <Skeleton avatar active title={false} loading={loading}>
            <PersonItem {...user} />
          </Skeleton>
        </List.Item>
      )}
    />
  );
};

export default UsersList;
