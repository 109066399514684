import React, { Component } from 'react';
import { connect } from 'react-redux';
// import FastAverageColor from 'fast-average-color';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Carousel } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
  faCircle,
  faImage,
  faSpinner,
} from '@fortawesome/pro-solid-svg-icons';

import { fetchMedia } from 'lib/helper';
import { makeGetPostSelector, makeGetPostUISelector } from 'store/selectors/postSelectors';

// #region STYLES
const Img = styled.img`
  max-width: 100%;
  max-height: 400px;
  margin: 0 auto;
  transition: opacity 0.1s ease-in-out;
  object-fit: contain;

  ${props =>
    props.isModal &&
    css`
      max-height: 100%;
    `}
`;

const ImageButton = styled.button`
  width: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  background: ${props => (props.isModal ? props.theme.colors.black : props.background)};
  border: 0;
  outline: none;
  transition: background-color 0.2s ease, transform 0.3s ease-out;

  ${props =>
    props.isModal &&
    css`
      cursor: default !important;
      transform: none;
    `}

  :focus {
    outline: none;
  }
`;

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: ${({ theme }) => theme.colors.gray2};

  .icon {
    font-size: 128px;
    color: ${({ theme }) => theme.colors.gray4};
  }
  .spinner {
    font-size: 24px;
    color: ${({ theme }) => theme.colors.gray5};
  }
  .icon,
  .spinner {
    opacity: 0.6;
  }
`;

const GalleryControl = styled.button`
  position: absolute;
  top: 50%;
  display: block;
  font-size: 48px;
  color: rgba(0, 0, 0, 0.8);
  text-align: center;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  opacity: 0.2;
  transition: all 0.3s ease;
  transform: translateY(-50%);
  transform-origin: top;

  ${props =>
    props.direction === 'prev'
      ? css`
          left: 24px;
        `
      : css`
          right: 24px;
        `}

  :focus {
    outline: 0;
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;

  .carousel,
  .carousel-item,
  .carousel-inner {
    width: 100%;
    height: 100%;
  }
  .carousel {
    .carousel-item.active,
    .carousel-item-next,
    .carousel-item-prev {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .carousel-item button {
    width: 100%;
    height: 100%;
  }
`;

const ImageGalleryContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: ${props => 100 / props.aspectRatio}%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.gray2};
  border-radius: 8px;
  transition: background-color 00.5s ease;

  ${({ theme, ...props }) =>
    props.isModal &&
    css`
      height: 100%;
      padding-top: 0;
      background: ${theme.colors.black} !important;
      border: 0;
      border-radius: 0;
    `}

  ${props =>
    props.singleImage &&
    css`
      padding-top: 0;
    `}

  :hover {
    ${GalleryControl} {
      opacity: 0.4;
    }
    ${GalleryControl}:hover {
      opacity: 1;
      transition: all 0.15s ease;
      transform: scale(1.2) translateY(-50%);
    }
    ${GalleryControl}:active {
      transition: all 0.1s ease;
      transform: scale(1.1) translateY(-50%);
    }
  }

  .carousel-indicators {
    right: auto;
    bottom: 24px;
    left: 50%;
    margin: 0;
    transform: translateX(-50%);
  }
  .carousel-indicators li {
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-shadow: 0 0 4px -2px rgba(0, 0, 0, 0.5);
  }
  .carousel-indicators li:hover {
    box-shadow: 0 0 6px -2px rgba(0, 0, 0, 1);
    opacity: 1;
    transition: all 0.1s ease;
  }

  /* ant-carousel */
  .ant-carousel {
    width: 100%;
    height: 100%;
  }
  .slick-slider,
  .slick-track,
  .slick-list {
    height: 100%;
  }
  .slick-slide > div {
    height: 100%;
  }
  .slick-slide button {
    display: block !important;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;
// #endregion

class ImageGallery extends Component {
  constructor(props) {
    super(props);
    const { postUI } = props;
    this.initialSlide = postUI.galleryActiveIndex ?? 0;
    this.state = {
      activeIndex: this.initialSlide,
      averageColors: {},
    };
    this.handleClickImage = this.handleClickImage.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.afterChange = this.afterChange.bind(this);
    this.carousel = React.createRef();
  }

  // componentDidMount() {
  //   const fac = new FastAverageColor();
  //   const { images } = this.props;

  //   images.forEach(img => {
  //     const image = new Image();
  //     image.crossOrigin = 'anonymous';
  //     image.addEventListener('load', () => {
  //       const color = fac.getColor(image, { ignoredColor: [255, 255, 255], height: 50 });
  //       const colorEnd = [].concat(color.value.slice(0, 3), 0).join(',');

  //       const gradient = `linear-gradient(to top, rgba(${colorEnd}) 0%, ${color.rgba} 100%) #000`;
  //       this.setState(state => ({
  //         averageColors: { ...state.averageColors, [img.mediaId]: gradient },
  //       }));
  //     });
  //     image.src = this.getImageSrc(img.mediaId);
  //   });
  // }

  getImageSrc(imageId) {
    const { token } = this.props;
    return fetchMedia(imageId, token);
  }

  handleClickImage() {
    const { onClickImage } = this.props;
    const { activeIndex } = this.state;
    if (typeof onClickImage === 'function') onClickImage(activeIndex);
  }

  next() {
    this.carousel.current.next();
  }
  previous() {
    this.carousel.current.prev();
  }
  afterChange(current) {
    this.setState({ activeIndex: current });
  }

  renderImage(image) {
    const { isModal } = this.props;
    const src = this.getImageSrc(image.mediaId);
    if (!src) {
      return (
        <Placeholder>
          <FontAwesomeIcon icon={faImage} className="icon" />
          <FontAwesomeIcon icon={faSpinner} pulse className="spinner" />
        </Placeholder>
      );
    }
    return <Img isModal={isModal} src={src} alt={image.originalFileName} />;
  }
  renderImageButton = () => {
    const { isModal, images } = this.props;
    const { averageColors } = this.state;
    return images.map(image => (
      <ImageButton
        key={image.mediaId}
        type="button"
        isModal={isModal}
        background={averageColors[image.mediaId]}
        onClick={this.handleClickImage}
      >
        {this.renderImage(image)}
      </ImageButton>
    ));
  };
  renderCarouselControls() {
    const { images } = this.props;
    if (images.length === 1) {
      return null;
    }
    return (
      <>
        <GalleryControl
          type="button"
          className="fa-layers"
          onClick={this.previous}
          direction="prev"
        >
          <FontAwesomeIcon icon={faCircle} />
          <FontAwesomeIcon icon={faChevronLeft} inverse transform="shrink-10" />
        </GalleryControl>
        <GalleryControl type="button" className="fa-layers" onClick={this.next} direction="next">
          <FontAwesomeIcon icon={faCircle} />
          <FontAwesomeIcon icon={faChevronRight} inverse transform="shrink-10" />
        </GalleryControl>
      </>
    );
  }
  renderCarousel() {
    const { keyboard } = this.props;

    return (
      <>
        <Carousel
          initialSlide={this.initialSlide}
          keyboard={keyboard}
          interval={false}
          effect="fade"
          afterChange={this.afterChange}
          ref={this.carousel}
        >
          {this.renderImageButton()}
        </Carousel>
        {this.renderCarouselControls()}
      </>
    );
  }
  render() {
    const { images, forceGallery, isModal } = this.props;

    return (
      <ImageGalleryContainer
        isModal={isModal}
        singleImage={images.length === 1}
        aspectRatio={37 / 20}
      >
        {images.length === 1 && !forceGallery ? (
          this.renderImageButton(images[0])
        ) : (
          <Wrapper>{this.renderCarousel()}</Wrapper>
        )}
      </ImageGalleryContainer>
    );
  }
}

ImageGallery.defaultProps = {
  keyboard: false,
};

function makeMapStateToProps() {
  const getPost = makeGetPostSelector();
  const getPostUI = makeGetPostUISelector();

  const mapStateToProps = (state, ownProps) => {
    const { postId } = ownProps;
    const post = getPost(state, postId);
    const postUI = getPostUI(state, postId);
    return {
      images: post?.media ?? [],
      token: state.auth.token,
      postUI,
    };
  };

  return mapStateToProps;
}

export default connect(makeMapStateToProps)(ImageGallery);
