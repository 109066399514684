import { css as style } from '@emotion/css';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { reactionColors } from './reactionHelper';

export const icon = (reaction: ReactionLiteral) => css`
  color: ${reactionColors[reaction].background};
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.33rem;
`;

export const ButtonIcon = styled.button`
  all: unset;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  transition: transform 200ms ease-in-out;

  :hover {
    transform: scale(1.5);
  }
`;

export const tooltip = style`
  .ant-tooltip-arrow {
    display: none;
  }
`;
