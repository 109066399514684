import axios from 'axios';
import Cookies from 'js-cookie';
import { LINK_PREVIEW_API } from './constants';

function redirectTo4bee() {
  // window.location.href = `${LEGACY_4BEE}/Account/LogOn?ReturnUrl=${RETURN_URL}`;
}
function checkAuth4BeeCookie() {
  if (process.env.NODE_ENV === 'test') {
    return;
  }
  const Auth4Bee = Cookies.get('Auth4Bee');
  const sessionToken = localStorage.getItem('session-token');
  if (!Auth4Bee && !sessionToken) {
    redirectTo4bee();
  }
}

const HttpClient = {
  get(url, config) {
    checkAuth4BeeCookie();
    return axios
      .get(url, config)
      .then(resp => resp)
      .catch(err => {
        const result = this.handleError(err);

        return result.then(r => {
          if (r) {
            return this.get(url, config);
          }
          return err;
        });
      });
  },
  post(url, data, config) {
    checkAuth4BeeCookie();
    return axios
      .post(url, data, { withCredentials: true, ...config })
      .then(resp => resp)
      .catch(err => {
        if (err.response && err.response.status === 412) return err.response;
        const result = this.handleError(err);

        return result.then(r => {
          if (r) return this.post(url, data, config);
          return err;
        });
      });
  },
  put(url, data, config) {
    checkAuth4BeeCookie();
    return axios
      .put(url, data, { withCredentials: true, ...config })
      .then(resp => resp)
      .catch(err => {
        const result = this.handleError(err);

        return result.then(r => {
          if (r) {
            return this.post(url, data, config);
          }
          return err;
        });
      });
  },
  delete(url, data, config) {
    checkAuth4BeeCookie();
    return axios
      .delete(url, data, config)
      .then(resp => resp)
      .catch(err => {
        const result = this.handleError(err);

        return result.then(r => {
          if (r) {
            return this.delete(url, data, config);
          }
          return err;
        });
      });
  },

  /**
   * Previews an url
   * @param {string} url
   * @returns {PromiseLike<{title: string, type: string, description: string, images: Array<string>, url: string}>}
   */
  async previewLink(url) {
    const { data } = await axios.post(LINK_PREVIEW_API, JSON.stringify(url), {
      headers: { 'Content-Type': 'text/plain' },
      transformRequest: [
        (_data, headers) => {
          delete headers.common.Authorization;
          return _data;
        },
      ],
    });
    return data;
  },
  async handleError(err) {
    if (err.response) {
      const resp = err.response;
      if (resp.status === 401) {
        return this.login();
      }
    }

    return null;
  },
};

export default HttpClient;
