import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { down, up } from 'styled-breakpoints';

export const selected = ({
  theme,
  isNewComposer,
}: {
  theme: Themes.DefaultTheme;
  isNewComposer: boolean;
}) => css`
  color: ${isNewComposer ? theme.colors.gray9 : theme.colors.primary};
`;
export const scopeSelected = (theme: Themes.DefaultTheme) => css`
  ${down('desktop')({ theme })} {
    max-width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Icon = styled.div<{ as?: React.ElementType }>`
  svg {
    margin-left: 5px;
    ${({ theme }) => theme.typography.fontSmall('20px')};
  }
`;
export const Label = styled.span<{ selected: boolean; isNewComposer: boolean }>`
  overflow: hidden;
  font-weight: ${props => (props.selected ? 600 : 400)};
  text-overflow: ellipsis;
  white-space: nowrap;
  ${props => props.selected && selected(props)};
  ${({ theme }) => theme.typography.fontSmall('20px')};
`;

export const Button = styled.div<{ isNewComposer: boolean }>`
  position: initial !important;
  display: flex;
  align-items: center;
  max-width: 100px;
  height: 32px;
  padding: 11px 11px;
  color: ${({ theme }) => theme.colors.gray4};
  cursor: default;
  background: none !important;
  border: 1px solid
    ${({ theme, ...props }) => (props.isNewComposer ? theme.colors.gray9 : theme.colors.primary)};
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);

  ${up('desktop')} {
    max-width: 200px;
  }

  .prefix {
    margin-right: 3px;
    font-weight: 600;
    ${selected};
    ${({ theme }) => theme.typography.fontSmall('20px')};
  }
  .caret {
    margin-left: 6px;
    ${selected};
  }
`;
