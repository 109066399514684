import { isJSON, parseBoolean } from './helper';

export type StorageType = 'localStorage' | 'sessionStorage';

type StoreProps = {
  key: string;
  value: any;
  replacer?: (key: string, value: any) => any;
};

export type ReturnType<T> = T extends number
  ? number
  : T extends boolean
  ? boolean
  : T extends string
  ? string
  : T extends object
  ? T
  : unknown;

export function get<T>(
  key: string,
  storageType: StorageType,
  reviver?: (key: string, value: any) => any
): ReturnType<T> | undefined {
  const item = window[storageType].getItem(key);

  if (item) {
    if (/^-?\d+$/.test(item)) {
      return parseInt(item) as ReturnType<T>;
    } else if (/^-?\d+(\.\d+)$/.test(item)) {
      return parseFloat(item) as ReturnType<T>;
    } else if (item === 'true' || item === 'false') {
      return parseBoolean(item) as ReturnType<T>;
    } else if (isJSON(item)) {
      return JSON.parse(item, reviver) as ReturnType<T>;
    } else {
      return item as ReturnType<T>;
    }
  }

  return undefined;
}

export function set({ key, value, replacer }: StoreProps, storageType: StorageType) {
  if (value != null) {
    let parsedValue = String(value);

    if (isJSON(value)) parsedValue = JSON.stringify(value, replacer);

    window[storageType].setItem(key, parsedValue);
  }
}

export function remove(key: string, storageType: StorageType) {
  window[storageType].removeItem(key);
}

export function clear(storageType: StorageType) {
  window[storageType].clear();
}
