import { useCallback, useEffect, useState } from 'react';
import { makeSubscription } from 'components/application/Messaging';
import { NotificationMessageType } from 'lib/constants';
import { useDispatch } from 'store';
import { fetchFeedCounters } from 'store/actions/uiActions';

export function useFeedNotification() {
  const dispatch = useDispatch();
  const [newPosts, setNewPosts] = useState(false);

  useEffect(() => {
    const unsubNewPosts = makeSubscription(NotificationMessageType.NewPosts, async () => {
      setNewPosts(true);

      dispatch(fetchFeedCounters());
    });

    const unsubVideoFinished = makeSubscription(NotificationMessageType.VideoFinished, () => {
      setNewPosts(true);

      dispatch(fetchFeedCounters());
    });

    const unsubScheduleExecuted = makeSubscription(NotificationMessageType.ScheduleExecuted, () => {
      setNewPosts(true);

      dispatch(fetchFeedCounters());
    });

    return () => {
      unsubNewPosts();
      unsubVideoFinished();
      unsubScheduleExecuted();
    };
  }, [dispatch]);

  const resetNewPosts = useCallback(() => setNewPosts(false), []);

  return {
    newPosts,
    resetNewPosts,
  };
}
