import { create } from 'zustand';
import { useShallow } from 'zustand/react/shallow';
import { devtools } from 'zustand/middleware';

export type LoginState = {
  username: string;
  userSecurity: UserSecurity;
  keepConnected: boolean;
};

type LoginActions = {
  setUsername: (username: string) => void;
  setUserSecurity: (userSecurity: UserSecurity) => void;
  setKeepConnected: (keepSignedIn: boolean) => void;
  resetState: () => void;
};

const initialState: LoginState = {
  username: '',
  userSecurity: {} as UserSecurity,
  keepConnected: false,
};

export const useLoginStore = create<LoginState & LoginActions>()(
  devtools(
    set => ({
      ...initialState,
      setUsername: username => set(() => ({ username }), false, 'setUsername'),
      setUserSecurity: userSecurity => set(() => ({ userSecurity }), false, 'setUserSecurity'),
      setKeepConnected: keepConnected => set(() => ({ keepConnected }), false, 'setKeepConnected'),
      resetState: () => set(() => initialState, false, 'resetState'),
    }),
    { name: 'LoginStore' }
  )
);

export const useUsername = () =>
  useLoginStore(
    useShallow(state => ({
      username: state.username,
      setUsername: state.setUsername,
    }))
  );

export const useUserSecurity = () =>
  useLoginStore(
    useShallow(state => ({
      userSecurity: state.userSecurity,
      setUserSecurity: state.setUserSecurity,
    }))
  );

export const useKeepConnected = () =>
  useLoginStore(
    useShallow(state => ({
      keepConnected: state.keepConnected,
      setKeepConnected: state.setKeepConnected,
    }))
  );
