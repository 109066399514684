import { css, cx, keyframes } from '@emotion/css';

// #region STYLES
const enter = keyframes`
 0% {
    height: 0;
    opacity: 0;
  }
  50% {
    height: 20px;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const exit = keyframes`
 0% {
    height: 20px;
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    height: 0;
    opacity: 0;
  }
`;

const counterWrapperExitActive = css`
  animation: ${exit} 0.4s ease-out both;
`;
const counterWrapperExit = css`
  && {
    display: block;
  }
`;
const counterWrapperEnterActive = css`
  animation: ${enter} 0.4s ease-in both;
`;
const counterWrapperEnter = css`
  && {
    display: block;
  }
`;
const counterWrapper = (theme: Themes.DefaultTheme) => css`
  display: none;
  height: 24px;
  text-align: right;

  ${theme.typography.fontxSmall('24px')};
`;
const counterOverLimit = (theme: Themes.DefaultTheme) => css`
  color: ${theme.colors.danger};
  transition: color 0.3s ease-in-out;
`;
const counter = (theme: Themes.DefaultTheme) => css`
  color: ${theme.colors.gray7};
  transition: color 0.3s ease-in-out;
`;
// #endregion

const counterTheme = (theme: Themes.DefaultTheme) => {
  const counterCss = counter(theme);
  const counterOverLimitCss = counterOverLimit(theme);
  const counterWrapperCss = counterWrapper(theme);

  return {
    counter: counterCss,
    counterOverLimit: counterOverLimitCss,
    counterWrapper: counterWrapperCss,
    counterWrapperEnter,
    counterWrapperEnterActive: cx(counterWrapperEnter, counterWrapperEnterActive),
    counterWrapperEnterDone: counterWrapperEnter,
    counterWrapperExit,
    counterWrapperExitActive: cx(counterWrapperExit, counterWrapperExitActive),
    counterWrapperExitDone: counterWrapperExit,
  };
};

export default counterTheme;
