import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { up } from 'styled-breakpoints';
import { Button } from 'antd';

import { transparentButton } from 'styles/component-styles/buttons';

export const avatar = css`
  float: left;
  margin-right: 12px;
`;

export const Wrapper = styled.div`
  display: none;
  padding: 16px 16px 16px;
  margin-bottom: 1.1em;
  background-color: #fff;
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  border-radius: 8px;
  box-shadow: 0 8px 16px -8px rgba(0, 0, 0, 0.15);

  .editor-wrapper {
    display: flex;
    margin-bottom: 1rem;
  }

  .editor {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray9};

    ${({ theme }) => theme.typography.fontSmall()};
  }
  .editor .public-DraftEditor-content {
    max-height: 300px;
  }
  .editor .public-DraftEditorPlaceholder-root {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray6};
  }

  .arrow-container {
    align-self: flex-start;
  }

  ${up('tablet')} {
    display: block;
  }
`;

export const BulletinWrapper = styled.div`
  ${({ theme }) => css`
    margin-bottom: 1rem;
    ${theme.typography.fontSmall()}

    > label {
      cursor: pointer;
    }
  `}
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SurveyContainer = styled.div`
  margin-top: 1em;
`;

export const ActionButton = styled.button`
  ${transparentButton};
  margin-right: 1.5em;
  color: ${({ theme }) => theme.colors.primary};
  transition: all 0.2s ease;

  ${({ theme }) => theme.typography.fontNormal('24px')};

  .svg-inline--fa {
    width: 1em;
  }
  :hover {
    transition: all 0.08s ease;
    transform: scale(1.2);
  }
  :active {
    transform: scale(1.1);
  }
`;

export const ContainerPreview = styled.div`
  margin-top: 16px;
`;
export const ErrorMessage = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.danger};

  ${({ theme }) => theme.typography.fontxSmall('20px')}
`;

export const BtnActions = styled(Button.Group)`
  margin-left: 26px;

  .dropdown-button {
    padding: 5px;
  }
`;
export const PostButton = styled(Button)`
  padding: 5px 11px;
  font-weight: bold;
`;
export const RightSide = styled.div`
  display: inline-block;
  margin-left: auto;
`;
