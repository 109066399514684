import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { enableMapSet } from 'immer';

// UTILS
import 'lib/SeniorService';
import 'lib/config/configureGtag';
import 'lib/config/configureAxios';
import 'lib/config/configureHotjar';

import '@fortawesome/fontawesome-pro/css/all.css';

import * as serviceWorker from './serviceWorker';

// STORE
import store from './store';
import './i18n';

// LOCAL COMPONENTS
import App from './App';

enableMapSet();

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
