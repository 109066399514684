export const AuthActions = {
  FAVICON_UPDATED: 'FAVICON_UPDATED',
  NETWORK_UPDATED: 'NETWORK_UPDATED',
  THEME_UPDATED: 'THEME_UPDATED',
  USER_AUTHENTICATED: 'USER_AUTHENTICATED',
  USER_INFO_RECEIVED: 'USER_INFO_RECEIVED',
  USER_ONBOARDED: 'USER_ONBOARDED',
  AUTH_USER_UPDATED: 'AUTH_USER_UPDATED',
  SENIORX_TOKEN_RECEIVED: 'SENIORX_TOKEN_RECEIVED',
  FEATURE_TOOGLE_RECEIVED: 'FEATURE_TOOGLE_RECEIVED',
  DEVICE_TOKEN_RECEIVED: 'DEVICE_TOKEN_RECEIVED',
  ACCOUNT_RECEIVED: 'ACCOUNT_RECEIVED',
  USER_SECURITY_UPDATED: 'USER_SECURITY_UPDATED',
} as const;
export const UserSummaryActions = {
  USER_SUMMARY_FETCHED: 'USER_SUMMARY_FETCHED',
} as const;
export const PostsActions = {
  CREATE_COMMENTS: 'POSTS_CREATE_COMMENTS',
  RESET_COMMENTS: 'RESET_COMMENTS',
  GET_COMMENTS: 'POSTS_GET_COMMENTS',
  COMMENT_DELETED: 'COMMENT_DELETED',
  COMMENT_UPDATED: 'COMMENT_UPDATED',
  COMMENT_UPDATED_LIKE: 'COMMENT_UPDATED_LIKE',
  COMMENT_EDITING: 'COMMENT_EDITING',
  COMMENT_EDIT_CANCEL: 'COMMENT_EDIT_CANCEL',
  POST_CREATED: 'POST_CREATED',
  POST_DELETED: 'POST_DELETED',
  POST_ERROR: 'POST_ERROR',
  POST_FETCH_COMMENT_MEDIA: 'POST_FETCH_COMMENT_MEDIA',
  POST_FETCH_IMAGE: 'POST_FETCH_IMAGE',
  POST_FETCHED: 'POST_FETCHED',
  POST_INSERTED: 'POST_INSERTED',
  POST_UPDATED: 'POST_UPDATED',
  POSTS_FETCHED: 'POSTS_FETCHED',
  POSTS_FETCHING: 'POSTS_FETCHING',
  NEW_POSTS: 'NEW_POSTS',
  RESET_ERROR: 'RESET_ERROR',
  POST_SHARED_FETCHED: 'POST_SHARE_FETCHED',
  POST_RESET: 'POST_RESET',
  GET_LIKES: 'POST__GET_LIKES',
  POST_REDUCER_RESET: 'POST_REDUCER_RESET',

  PINNED_POSTS_FETCHED: 'PINNED_POSTS_FETCHED',
  PINNED_POST_SET: 'PINNED_POST_SET',
  PINNED_POST_REMOVED: 'PINNED_POST_REMOVED',

  POST_SCHEDULED: 'POST_SCHEDULED',
  PENDING_POSTS_FETCHED: 'PENDING_POSTS_FETCHED',
  PENDING_POST_DELETED: 'PENDING_POST_DELETED',
  PENDING_POST_UPDATED: 'PENDING_POST_UPDATED',
  PENDING_POST_SENT: 'PENDING_POST_SENT',
  PENDING_POST_COUNT: 'PENDING_POST_COUNT',
  PENDING_POST_RESET: 'PENDING_POST_RESET',

  POST_BOOKMARKED: 'POST_BOOKMARKED',

  TOGGLE_COMMENTING: 'TOGGLE_COMMENTING',

  TAB_CHANGED: 'TAB_CHANGED',
} as const;
export const UsersActions = {
  FETCH_USER: 'USERS__FETCH_USER',
  FETCH_USER_CONTACTS: 'USERS__FETCH_USER_CONTACTS',
  USER_CONNECTED: 'USER_CONNECTED',
  USER_DISCONNECTED: 'USER_DISCONNECTED',
  USER_UPDATED: 'USER_UPDATED',
  USER_CONTACTS_UPDATED: 'USER_CONTACTS_UPDATED',
} as const;
export const PostUIActions = {
  BLUR_COMMENT_FIELD: 'POST_UI__BLUR_COMMENT_FIELD',
  CANCEL_EDIT: 'POST_UI__CANCEL_EDIT',
  EDIT: 'POST_UI__EDIT',
  FOCUS_COMMENT_FIELD: 'POST_UI__FOCUS_COMMENT_FIELD',
  HIDE_COMMENT: 'COMMENT_UI__HIDE',
  HIDE: 'POST_UI__HIDE',
  OPEN_GALLERY_MODAL: 'POST_UI__OPEN_GALLERY_MODAL',
  CLOSE_GALLERY_MODAL: 'POST_UI__CLOSE_GALLERY_MODAL',
  PINNED_DRAWER_OPENED: 'POST_UI__PINNED_DRAWER_OPENED',
  PINNED_DRAWER_CLOSED: 'POST_UI__PINNED_DRAWER_CLOSED',
} as const;
export const FileActions = {
  FILE_ADDED: 'FILE_ADDED',
  FILE_ADD_MORE: 'FILE_ADD_MORE',
  FILE_REMOVED: 'FILE_REMOVED',
  FILES_RESET: 'FILES_RESET',
} as const;
export const BirthdayActions = {
  BIRTHDAYS_FETCHING: 'BIRTHDAYS_FETCHING',
  BIRTHDAYS_FETCHED: 'BIRTHDAYS_FETCHED',
  USER_BIRTHDAY_REMOVED: 'USER_BIRTHDAY_REMOVED',
} as const;
export const NewPostActions = {
  CHANGE_VISIBILITY: 'NEW_POST__CHANGE_VISIBILITY',
  CHANGE_PROFILE: 'NEW_POST__CHANGE_PROFILE',
  ADD_TARGET: 'NEW_POST__ADD_TARGET',
  INIT: 'NEW_POST__INIT',
} as const;

export const ChatActions = {
  CHAT_NOTIFIED: 'CHAT_NOTIFIED',
  CHAT_SOCKET_CONNECTED: 'CHAT_SOCKET_CONNECTED',
  CHAT_SOCKET_DISCONNECTED: 'CHAT_SOCKET_DISCONNECTED',
  CLEAR_COUNT: 'CLEAR_COUNT',
  CLEAR_LIST: 'CLEAR_LIST',
  CLEAR_MESSAGE: 'CLEAR_MESSAGE',
  CLEAR_MESSAGE_NOTIFICATION: 'CLEAR_MESSAGE_NOTIFICATION',
  CLOSE_CHAT: `CLOSE_CHAT`,
  FETCH_CHAT_LIST: 'FETCH_CHAT_LIST',
  FETCH_MESSAGES: 'FETCH_MESSAGES',
  LOADING_CHAT: 'LOADING_CHAT',
  LOADING_MESSAGES: 'LOADING_MESSAGES',
  LOAD_MORE_MESSAGES_FETCHED: 'LOAD_MORE_MESSAGES_FETCHED',
  MESSAGES_FETCHED: 'MESSAGES_FETCHED',
  MESSAGE_SENT: 'MESSAGE_SENT',
  NEW_CHAT_WINDOW: 'NEW_CHAT_WINDOW',
  NEW_MESSAGE: 'NEW_MESSAGE',
  OPEN_CHAT: 'OPEN_CHAT',
  OPEN_LIST: 'OPEN_LIST',
  ROOM_UPDATED: 'ROOM_UPDATED',
  SENDING_MESSAGE: 'SEND_MESSAGE',
  SET_ACTIVE_ROOM: 'SET_ACTIVE_ROOM',
  TOGGLE_CHAT_COLLAPSED: 'TOGGLE_CHAT_COLLAPSED',
  LEAVE_ROOM: 'LEAVE_ROOM',
  DELETE_MESSAGE: 'DELETE_MESSAGE',
} as const;

export const NotificationPanelActions = {
  FETCH_NOTIFICATIONS: 'FETCH_NOTIFICATIONS',
  COUNT_NOTIFICATIONS: 'COUNT_NOTIFICATIONS',
  FETCHING_NOTIFICATIONS: 'FETCHING_NOTIFICATIONS',
  FETCH_COMPLETED: 'FETCH_COMPLETED',
  VIEWED: `COUNTERS_VIEWED`,
  FETCH_COUNT: 'FETCH_COUNT',
  CLEAR: 'CLEAR',
  RELOAD_NOTIFICATIONS: 'RELOAD_NOTIFICATIONS',
} as const;
export const FilterTypes = {
  UPDATE: 'FILTERS__UPDATE',
} as const;

export const UIActions = {
  DRAWER_OPENED: 'DRAWER_OPENED',
  DRAWER_CLOSED: 'DRAWER_CLOSED',
  DRAWER_CONTENT_LOADING_STATE: 'DRAWER_CONTENT_LOADING_STATE',
  DRAWER_PENDING_POSTS_FETCHED: 'DRAWER_PENDING_POSTS_FETCHED',
  DRAWER_PENDING_POST_CREATED: 'DRAWER_PENDING_POST_CREATED',
  DRAWER_PENDING_POST_DELETED: 'DRAWER_PENDING_POST_DELETED',
  DRAWER_PENDING_POST_EDIT: 'DRAWER_PENDING_POST_EDIT',
  DRAWER_PENDING_POST_CANCEL_EDIT: 'DRAWER_PENDING_POST_CANCEL_EDIT',
  DRAWER_PENDING_POST_ENABLED: 'DRAWER_PENDING_POST_ENABLED',
  DRAWER_PENDING_POST_DISABLED: 'DRAWER_PENDING_POST_DISABLED',
  DRAWER_PENDING_POST_RESET: 'DRAWER_PENDING_POST_RESET',

  SIDER_COLLAPSED: 'SIDER_COLLAPSED',
  SIDER_EXPANDED: 'SIDER_EXPANDED',
  TOGGLE_SIDER_COLLAPSED: 'TOGGLE_SIDER_COLLAPSED',
  TOGGLE_SIDER_PINNED: 'TOGGLE_SIDER_PINNED',

  WIDGET_CONFIG_LOADED: 'WIDGET_CONFIG_LOADED',
  WIDGET_CONFIG_CHANGED: 'WIDGET_CONFIG_CHANGED',

  FEED_CONFIG_CHANGED: 'FEED_TAB_VISIBLE',

  POSTS_COUNT_FETCHED: 'POSTS_COUNT_FETCHED',
} as const;

export const BannerActions = {
  BANNER_ADDED: 'BANNER_ADDED',
  BANNER_REMOVED: 'BANNER_REMOVED',
  BANNERS_FETCHED: 'BANNERS_FETCHED',
  TOGGLE_BANNER_VISIBLE: 'TOGGLE_BANNER_VISIBLE',
} as const;
