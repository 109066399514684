import { FeedUIState, FeedWidgetsUIState, UIActionType } from 'store/types/uiTypes';
import { UIActions } from 'store/types/types';
import { AsyncAction } from 'store/actions';
import { LS_WIDGET_CONFIG } from 'lib/constants';
import { getPostsCounters } from 'lib/api/notification';

export const openDrawer = (): UIActionType => ({
  type: UIActions.DRAWER_OPENED,
});

export const closeDrawer = (): UIActionType => ({
  type: UIActions.DRAWER_CLOSED,
});

export const editPendingPost = (postId: number): UIActionType => ({
  type: UIActions.DRAWER_PENDING_POST_EDIT,
  payload: { postId },
});

export const cancelEditPendingPost = (postId: number): UIActionType => ({
  type: UIActions.DRAWER_PENDING_POST_CANCEL_EDIT,
  payload: { postId },
});

export const enablePendingPost = (postId: number): UIActionType => ({
  type: UIActions.DRAWER_PENDING_POST_ENABLED,
  payload: { postId },
});

export const disablePendingPost = (postId: number): UIActionType => ({
  type: UIActions.DRAWER_PENDING_POST_DISABLED,
  payload: { postId },
});

export const pendingPostDeleted = (postId: number): UIActionType => ({
  type: UIActions.DRAWER_PENDING_POST_DELETED,
  payload: { postId },
});

export const collapseSider = (): UIActionType => ({
  type: UIActions.SIDER_COLLAPSED,
});
export const expandSider = (): UIActionType => ({
  type: UIActions.SIDER_EXPANDED,
});
export const tooggleSiderCollapsed = (): UIActionType => ({
  type: UIActions.TOGGLE_SIDER_COLLAPSED,
});
export const toggleSiderPinned = (): UIActionType => ({
  type: UIActions.TOGGLE_SIDER_PINNED,
});

export const changeFeedConfig = (
  feedConfig: Pick<FeedUIState, 'resultsTabVisible'>
): UIActionType => ({
  type: UIActions.FEED_CONFIG_CHANGED,
  payload: feedConfig,
});

export const setFeedCounters = (feedCounters: Partial<FeedUIState['counters']>): UIActionType => ({
  type: UIActions.POSTS_COUNT_FETCHED,
  payload: feedCounters,
});

type ChangedWidgetConfigActionType = (
  widgetConfig: Partial<FeedWidgetsUIState>
) => AsyncAction<void, UIActionType>;

export const changeWidgetConfig: ChangedWidgetConfigActionType =
  widgetConfig => (dispatch, getState) => {
    const { feedWidgets } = getState().ui;

    const saveWidgetConfig = { ...feedWidgets, ...widgetConfig };
    const jsonWidgetConfig = JSON.stringify(saveWidgetConfig);
    localStorage.setItem(LS_WIDGET_CONFIG, jsonWidgetConfig);

    dispatch({
      type: UIActions.WIDGET_CONFIG_CHANGED,
      payload: widgetConfig,
    });
  };

type LoadWidgetConfigActionType = () => AsyncAction<void, UIActionType>;

export const loadWidgetConfig: LoadWidgetConfigActionType = () => (dispatch, getState) => {
  const serializedWidgetConfig = localStorage.getItem(LS_WIDGET_CONFIG);
  if (serializedWidgetConfig) {
    const { feedWidgets } = getState().ui;
    const lsWidgetConfig = JSON.parse(serializedWidgetConfig);
    const widgetConfig = { ...feedWidgets, ...lsWidgetConfig };

    dispatch({
      type: UIActions.WIDGET_CONFIG_LOADED,
      payload: widgetConfig,
    });
  }
};

type FetchFeedCountersAction = () => AsyncAction<Promise<void>, UIActionType>;

export const fetchFeedCounters: FetchFeedCountersAction = () => async dispatch => {
  const result = await getPostsCounters();

  if (typeof result === 'object') {
    dispatch(setFeedCounters(result));
  }
};
