import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// UTILS
import { useSelector } from 'store';
import { fetchUserContacts } from 'store/actions/usersActions';
import { makeUserContactsSelector } from 'store/selectors/usersSelectors';

const selectUserContacts = makeUserContactsSelector();

const useUserContacts = (userId: number) => {
  // REDUX
  const dispatch: AppDispatch = useDispatch();
  const contacts = useSelector(state => selectUserContacts(state, userId));

  useEffect(() => {
    if (!contacts && userId) dispatch(fetchUserContacts(userId));
  }, [contacts, dispatch, userId]);
  return contacts || null;
};

export default useUserContacts;
