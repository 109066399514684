import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { WritableDraft } from 'immer/dist/internal';
import { useImmer } from 'use-immer';
import { clearFix, rem } from 'polished';
import { useDispatch } from 'react-redux';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  Button,
  Input,
  List,
  Modal,
  Pagination,
  PaginationProps,
  Radio,
  RadioChangeEvent,
  Select,
  Space,
} from 'antd';
import { LabeledValue, SelectValue } from 'antd/lib/select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faUserEdit } from '@fortawesome/pro-solid-svg-icons';

import { useAuth } from 'hooks';
import { getCompany, getUnit, getDepartment, getOccupation, getUser } from 'lib/apiActions';
import { compareTwoStrings, removeAccents } from 'lib/helper';
import { addTarget } from 'store/actions/newPostActions';
import { StoreKey } from 'store/types/newPostTypes';

import FilterCard from './FilterCard';

const { Search } = Input;

//#region TYPES
export interface PostFilterModalRef {
  clearAll: () => void;
}

export interface PostFilterModalProps {
  storeKey: StoreKey;
  visible: boolean;
  selectedUsers?: (selectedUsers: UserInfo[]) => void;
  onClose: React.MouseEventHandler<HTMLElement>;
}

interface FiltersLists {
  companies: LabeledValue[];
  units: LabeledValue[];
  departments: LabeledValue[];
  occupations: LabeledValue[];
}

interface Filter {
  name: string;
  companyId?: number;
  companyUnitId?: number;
  departmentId?: number;
  occupationId?: number;
}

interface SelectableUserInfo extends UserInfo {
  selected: boolean;
}

interface UsersState {
  usersList: SelectableUserInfo[];
  selectedUsers: SelectableUserInfo[];
}

interface EditFilterButtonProps
  extends Pick<React.HTMLAttributes<HTMLSpanElement>, 'onClick' | 'onMouseEnter' | 'onMouseLeave'> {
  count: number;
}
//#endregion

//#region STYLES
const Container = styled.div`
  h2 {
    font-weight: normal;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-top: 12px;

  > div {
    flex: 1 1;
    overflow: hidden;
  }
  > div + div {
    margin-left: 12px;
  }

  label {
    display: block;

    svg {
      margin-right: 5px;
    }
  }

  .ant-select {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 12px;

  button {
    font-weight: normal;
  }
`;

const PaginationContainer = styled.div`
  ${clearFix()};
  margin-top: 12px;

  ul {
    float: right;
  }
`;

const FilterContainer = styled.div`
  height: ${rem(330)};
  padding: 12px;
  margin-top: 12px;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
`;

const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
`;

const FilterButtonContainer = styled.span`
  position: relative;
  display: inline-block;

  .btn-filter {
    transition: all 0.2s ease;
    ${({ theme }) => theme.typography.fontNormal('24px')};

    :hover {
      color: ${({ theme }) => theme.colors.primary};
      transition: all 0.08s ease;
      transform: scale(1.2);
    }
    :active {
      color: ${({ theme }) => theme.colors.primary};
      transform: scale(1.1);
    }
  }

  span {
    position: absolute;
    top: 2px;
    right: 4px;
    display: inline-block;
    width: 12px;
    height: 12px;
    padding-right: 1px;
    font-size: 8px;
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    vertical-align: middle;
    background-color: ${({ theme }) => theme.colors.danger6};
    border-radius: 50%;
  }
`;

const SelectedCount = styled.span<{ visible?: boolean }>`
  margin-left: 8px;
  color: ${({ theme }) => theme.colors.primary};
  transition: visibility 0.2s ease-in-out, opacity 0.2s ease-in-out;
  ${props => {
    if (!props.visible)
      return css`
        visibility: hidden;
        opacity: 0;
      `;
  }};
`;
//#endregion

function sortFiltersList(valueA: KeyValue, valueB: KeyValue) {
  return compareTwoStrings(valueA.description, valueB.description);
}

function filterSort(optionA: any, optionB: any) {
  return compareTwoStrings(optionA.label, optionB.label);
}

function filterOption(input: string, option: any) {
  return removeAccents(option.label.toLowerCase()).indexOf(removeAccents(input.toLowerCase())) >= 0;
}

const PostFilterModal = forwardRef<PostFilterModalRef, PostFilterModalProps>(
  ({ storeKey, visible, selectedUsers, onClose }, ref) => {
    const { t } = useTranslation();
    const { networkId, userId } = useAuth();

    const [loading, setLoading] = useState(false);
    const [view, setView] = useState<'all' | 'selected'>('all');
    const [usersState, setUsersState] = useImmer<UsersState>({ usersList: [], selectedUsers: [] });
    const [filter, setFilter] = useImmer<Filter>({ name: '' });
    const [filtersLists, setFiltersLists] = useImmer<FiltersLists>({
      companies: [],
      units: [],
      departments: [],
      occupations: [],
    });
    const [pagination, setPagination] = useImmer({ page: 1, pageSize: 30, total: 0 });

    const dispatch = useDispatch();

    const searchRef = useRef<Input>(null);

    const clearFilters = () => {
      setUsersState(state => {
        state.selectedUsers = [];
        state.usersList.forEach(user => {
          user.selected = false;
        });
      });

      setFilter(filter => {
        filter.name = '';
        delete filter.companyId;
        delete filter.companyUnitId;
        delete filter.departmentId;
        delete filter.occupationId;
      });

      setPagination(draft => {
        draft.page = 1;
      });

      searchRef.current?.setValue('');
    };

    const addUsersTargets = (targets: number[]) => {
      dispatch(addTarget(storeKey, targets));
    };

    const setSelected = useCallback(
      ({ selectedUsers }: WritableDraft<UsersState>, userInfo: SelectableUserInfo) => {
        const selectedIndex = selectedUsers.findIndex(
          thisUser => thisUser.user.userId === userInfo.user.userId
        );

        if (userInfo.selected) {
          if (selectedIndex === -1) {
            selectedUsers.push(userInfo);
          }
          selectedUsers.sort((userA, userB) => {
            if (userA.user.name.toLowerCase() > userB.user.name.toLowerCase()) return 1;
            else if (userA.user.name.toLowerCase() < userB.user.name.toLowerCase()) return -1;
            else return 0;
          });
        } else {
          selectedUsers.splice(selectedIndex, 1);
        }
      },
      []
    );

    const fetchUsers = useCallback(async () => {
      setLoading(true);

      const usersFetched = await getUser({
        networkId,
        pageNumber: pagination.page,
        pageSize: pagination.pageSize,
        ...filter,
      });

      let selectableUsers: SelectableUserInfo[] = [];
      if (usersFetched.users) {
        selectableUsers = usersFetched.users.users.map(user =>
          Object.assign(user, { selected: false })
        );

        setPagination(draft => {
          draft.total = usersFetched!.users.totalCount;
        });
      }

      setUsersState(draft => {
        draft.usersList = selectableUsers.filter(user => user.user.userId !== userId);
      });

      setLoading(false);
    }, [
      filter,
      networkId,
      pagination.page,
      pagination.pageSize,
      setPagination,
      setUsersState,
      userId,
    ]);

    useImperativeHandle(ref, () => ({
      clearAll() {
        clearFilters();
        addUsersTargets([]);
      },
    }));

    //#region EFFECTS
    useEffect(() => {
      async function getFilters() {
        const [companies, units, departments, occupations] = await Promise.all([
          getCompany(networkId),
          getUnit(networkId),
          getDepartment(networkId),
          getOccupation(networkId),
        ]);

        setFiltersLists(filtersDraft => {
          filtersDraft.companies = companies.sort(sortFiltersList).map(company => ({
            value: company.id,
            label: company.description,
          }));
          filtersDraft.units = units.sort(sortFiltersList).map(unit => ({
            value: unit.id,
            label: unit.description,
          }));
          filtersDraft.departments = departments.sort(sortFiltersList).map(department => ({
            value: department.id,
            label: department.description,
          }));
          filtersDraft.occupations = occupations.sort(sortFiltersList).map(occupation => ({
            value: occupation.id,
            label: occupation.description,
          }));
        });
      }

      if (visible) getFilters();
    }, [networkId, setFiltersLists, visible]);
    useEffect(() => {
      if (visible) {
        fetchUsers();
      }
    }, [fetchUsers, visible]);
    useEffect(() => {
      if (!loading) {
        setUsersState(state => {
          state.usersList.forEach(user => {
            const selectedUser = state.selectedUsers.filter(
              filterUser => filterUser.user.userId === user.user.userId
            )[0];

            user.selected = selectedUser?.selected;
          });
        });
      }
    }, [setUsersState, loading]);
    useEffect(() => {
      if (selectedUsers) selectedUsers(usersState.selectedUsers);
    }, [selectedUsers, usersState.selectedUsers]);
    //#endregion

    //#region HANDLERS
    const handleChangeView = useCallback((e: RadioChangeEvent) => {
      setView(e.target.value);
    }, []);

    const handleSearch = useCallback(
      (value: string) => {
        setFilter(filter => {
          filter.name = value;
        });
        setPagination(draft => {
          draft.page = 1;
        });
      },
      [setFilter, setPagination]
    );

    const handleSelectFilter = useCallback(
      (filterKey: keyof Omit<Filter, 'name'>) => (value: SelectValue) => {
        setFilter(filter => {
          filter[filterKey] = value as number;
        });
        setPagination(draft => {
          draft.page = 1;
        });
      },
      [setFilter, setPagination]
    );

    const handleClearFilter = useCallback(
      (filterKey: keyof Omit<Filter, 'name'>) => () => {
        setFilter(filter => {
          delete filter[filterKey];
        });
        setPagination(draft => {
          draft.page = 1;
        });
      },
      [setFilter, setPagination]
    );

    const handleToggleSelected = useCallback(
      (userId: number) => {
        setUsersState(state => {
          const listIndex = state.usersList.findIndex(userInfo => userInfo.user.userId === userId);

          const userInfo = state.usersList[listIndex];
          userInfo.selected = !userInfo.selected;

          setSelected(state, userInfo);
        });
      },
      [setSelected, setUsersState]
    );

    const handlePagination: PaginationProps['onChange'] = useCallback(
      (page, pageSize) => {
        setPagination(draft => {
          draft.page = page;
          draft.pageSize = pageSize;
        });
      },
      [setPagination]
    );

    const handleSelectAll = () => {
      setUsersState(state => {
        state.usersList.forEach(user => {
          user.selected = true;

          setSelected(state, user);
        });
      });
    };

    const handleUndo = () => {
      addUsersTargets([]);
      clearFilters();
    };

    const handleOk: React.MouseEventHandler<HTMLElement> = event => {
      const targetsIds = usersState.selectedUsers.map(user => user.user.userId);
      addUsersTargets(targetsIds);
      onClose(event);
    };

    const handleClose: React.MouseEventHandler<HTMLElement> = event => {
      addUsersTargets([]);
      clearFilters();
      setView('all');
      onClose(event);
    };
    //#endregion

    return (
      <Modal footer={null} visible={visible} width={680} onCancel={handleClose} onOk={handleOk}>
        <Container>
          <h2>{t('new-post.visibility.private')}</h2>
          <Search
            allowClear
            enterButton={t('filter.search')}
            prefix={<FontAwesomeIcon icon={faSearch} className="anticon" />}
            onSearch={handleSearch}
            ref={searchRef}
          />
          <SelectContainer>
            <div>
              <label htmlFor="company">
                <FontAwesomeIcon icon={faFilter} />
                {t('common.company')}
              </label>
              <Select
                allowClear
                dropdownMatchSelectWidth={false}
                showSearch
                id="company"
                placeholder={t('select')}
                filterOption={filterOption}
                filterSort={filterSort}
                options={filtersLists.companies}
                optionLabelProp="label"
                size="large"
                value={filter.companyId}
                onClear={handleClearFilter('companyId')}
                onSelect={handleSelectFilter('companyId')}
              />
            </div>
            <div>
              <label htmlFor="unit">
                <FontAwesomeIcon icon={faFilter} />
                {t('common.unit')}
              </label>
              <Select
                allowClear
                dropdownMatchSelectWidth={false}
                showSearch
                id="unit"
                placeholder={t('select')}
                filterOption={filterOption}
                filterSort={filterSort}
                options={filtersLists.units}
                optionLabelProp="label"
                size="large"
                value={filter.companyUnitId}
                onClear={handleClearFilter('companyUnitId')}
                onSelect={handleSelectFilter('companyUnitId')}
              />
            </div>
            <div>
              <label htmlFor="department">
                <FontAwesomeIcon icon={faFilter} />
                {t('common.department')}
              </label>
              <Select
                allowClear
                dropdownMatchSelectWidth={false}
                showSearch
                id="department"
                placeholder={t('select')}
                filterOption={filterOption}
                filterSort={filterSort}
                options={filtersLists.departments}
                optionLabelProp="label"
                size="large"
                value={filter.departmentId}
                onClear={handleClearFilter('departmentId')}
                onSelect={handleSelectFilter('departmentId')}
              />
            </div>
            <div>
              <label htmlFor="occupation">
                <FontAwesomeIcon icon={faFilter} />
                {t('common.occupation')}
              </label>
              <Select
                allowClear
                dropdownMatchSelectWidth={false}
                showSearch
                id="occupation"
                placeholder={t('select')}
                filterOption={filterOption}
                filterSort={filterSort}
                options={filtersLists.occupations}
                optionLabelProp="label"
                size="large"
                value={filter.occupationId}
                onClear={handleClearFilter('occupationId')}
                onSelect={handleSelectFilter('occupationId')}
              />
            </div>
          </SelectContainer>
          <ButtonContainer>
            <div>
              <Radio.Group value={view} buttonStyle="solid" onChange={handleChangeView}>
                <Radio.Button value="selected">{t('filter.viewSelected')}</Radio.Button>
                <Radio.Button value="all">{t('filter.viewAll')}</Radio.Button>
              </Radio.Group>
              <SelectedCount visible={usersState.selectedUsers.length > 0}>
                {t('filter.selected', { count: usersState.selectedUsers.length })}
              </SelectedCount>
            </div>
            <Button ghost type="primary" onClick={handleSelectAll}>
              {t('filter.selectAll')}
            </Button>
          </ButtonContainer>
          <PaginationContainer>
            <Pagination
              current={pagination.page}
              pageSize={pagination.pageSize}
              pageSizeOptions={['30', '50', '100']}
              total={pagination.total}
              onChange={handlePagination}
            />
          </PaginationContainer>
          <FilterContainer>
            <List
              dataSource={view === 'all' ? usersState.usersList : usersState.selectedUsers}
              loading={loading}
              grid={{ column: 3, gutter: 8 }}
              renderItem={userInfo => (
                <List.Item>
                  <FilterCard
                    user={userInfo}
                    selected={userInfo.selected}
                    onClick={handleToggleSelected}
                  />
                </List.Item>
              )}
            />
          </FilterContainer>
        </Container>
        <Footer>
          <Space align="end">
            <Button ghost type="primary" onClick={handleUndo} style={{ fontWeight: 'normal' }}>
              {t('filter.undoFilter')}
            </Button>
            <Button
              disabled={usersState.selectedUsers.length === 0}
              type="primary"
              onClick={handleOk}
            >
              Ok
            </Button>
          </Space>
        </Footer>
      </Modal>
    );
  }
);

const EditFilterButton: React.VFC<EditFilterButtonProps> = ({ count, onClick, ...rest }) => {
  return (
    <FilterButtonContainer {...rest}>
      <Button
        className="btn-filter"
        type="link"
        icon={<FontAwesomeIcon icon={faUserEdit} className="anticon" />}
        onClick={onClick}
      />
      {count > 0 && <span>{count > 99 ? 99 : count}</span>}
    </FilterButtonContainer>
  );
};

export default Object.assign(PostFilterModal, { EditFilterButton });
