import React from 'react';
import { debounce, trimCharsEnd } from 'lodash/fp';
import { compose } from '@reduxjs/toolkit';
import { connect, ConnectedProps } from 'react-redux';

import { withTheme, Theme } from '@emotion/react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { WithTranslation, withTranslation } from 'react-i18next';

import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import createCounterPlugin from '@draft-js-plugins/counter';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import linkifyit from 'linkify-it';
import tlds from 'tlds';
import { v4 } from 'uuid';
// eslint-disable-next-line import/extensions
import os from 'platform-detect/os.mjs';

// GLOBAL COMPONENTS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faLevelUpAlt } from '@fortawesome/pro-solid-svg-icons';
import { faSmile } from '@fortawesome/pro-regular-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { Animated } from 'react-animated-css';
import { Button } from 'antd';
import 'draft-js/dist/Draft.css';

// ACTIONS
import {
  updateComment,
  createComment,
  blurCommentField,
  cancelEditComment,
} from 'store/actions/postsActions';
import { makeGetPostSelector } from 'store/selectors/postSelectors';
import { previewLink as previewLinkApi } from 'lib/apiActions';
import * as mentionApi from 'lib/api/mention';
import { fetchMedia, isJSON, deserializeHtml } from 'lib/helper';

// STYLES
import mentionTheme from 'styles/draftjs/mentionTheme';
import counterTheme from 'styles/draftjs/counterTheme';
import { emojiThemeComment as emojiTheme } from 'styles/draftjs/emojiTheme';

// LOCAL COMPONENTS
import { POST_TYPES, PROFILE_TYPES } from 'lib/constants';
import EntrySuggestions from '../TextEditor/EntrySuggestions';
import ImageUploader from '../Uploader/ImageUploader/ImageUploader';
import FileUploader from '../Uploader/FileUploader/FileUploader';
import Preview from '../Preview/ImagePreview/Preview';
import LinkAttachment from '../Attachment/LinkAttachment/LinkAttachment';
import FilePreview from '../Attachment/FilePreview';

import * as S from './CommentTextEditorStyles';

export type CommentTextEditorProps = {
  postId: number;
  groupId?: number | null;
  userId?: number;
  autoFocus?: boolean;
  isEditComment?: boolean;
  placeholder?: string;
  token?: string;
  commentEdit?: CommentInfo;
  commentMediaEdit?: Media[];
  commentLink?: LinkPreview;
  className?: string;
};

export type CommentTextEditorState = {
  comment?: CommentData;
  editorState: EditorState;
  sending: boolean;
  counterVisible: boolean;
  isOverLimit: boolean;
  attachmentEdit?: Attachment;
  attachment?: Attachment;
  attachmentType?: FileType;
  descriptionRemove?: 'link' | 'media';
  mentionOpen: boolean;
  suggestions: Omit<MentionSuggestions, 'photo'>[];
  postDeleted: boolean;
  currentLink?: string;
  errorFile?: string;
  isAttachmentLoading: boolean;
};

type ThemeProps = {
  theme: Theme;
};

type ComposedProps = PropsFromRedux & ThemeProps & WithTranslation & CommentTextEditorProps;

type CommentData = {
  itemId: string;
  itemType: string;
  userId: string;
  description: string;
  descriptionText: string;
};

type CommentFile = {
  index?: string;
  preview?: string;
  name: string;
  size: number;
};

type FileType = 'Image' | 'File' | 'LinkEdit' | 'LinkInfo';
type Attachment = LinkPreview | LinkInfo | CommentFile;

const parseLinksContent = (editorState: EditorState) => {
  const linkify = linkifyit({}, { fuzzyEmail: false });
  linkify.tlds(tlds);

  const links: string[] = [];
  editorState
    .getCurrentContent()
    .getBlockMap()
    .forEach(block => {
      const matches = linkify.match(block?.get('text'));

      if (typeof matches !== 'undefined' && matches !== null) {
        for (let i = 0; i < matches.length; i += 1) {
          links.push(trimCharsEnd('/', matches[i].url));
        }
      }
    });

  return links;
};

class CommentTextEditor extends React.Component<ComposedProps, CommentTextEditorState> {
  // eslint-disable-next-line
  editorRef: React.RefObject<Editor>;
  wrapperRef: React.RefObject<HTMLDivElement>;
  counterTheme: ReturnType<typeof counterTheme>;
  mentionPlugin: ReturnType<typeof createMentionPlugin>;
  counterPlugin: ReturnType<typeof createCounterPlugin>;
  emojiPlugin: ReturnType<typeof createEmojiPlugin>;
  counterLimit: number;
  debouncedPreviewLink: ReturnType<typeof debounce>;
  debouncedSearchMentions: ReturnType<typeof debounce>;

  constructor(props: ComposedProps) {
    super(props);
    const { theme } = props;

    this.editorRef = React.createRef();
    this.wrapperRef = React.createRef();

    this.focus = this.focus.bind(this);
    this.handleRejectedFile = this.handleRejectedFile.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOpenChange = this.handleOpenChange.bind(this);
    this.handleSearchMentions = this.handleSearchMentions.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.bindKey = this.bindKey.bind(this);
    this.submitComment = this.submitComment.bind(this);
    this.cancelEdit = this.cancelEdit.bind(this);
    this.debouncedPreviewLink = debounce(500, this.previewLink);
    this.debouncedSearchMentions = debounce(250, this.handleSearchMentions);

    this.mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      mentionPrefix: '@',
      supportWhitespace: true,
      theme: mentionTheme(theme),
    });

    this.counterTheme = counterTheme(theme);
    this.counterPlugin = createCounterPlugin({
      theme: {
        counterOverLimit: this.counterTheme.counterOverLimit,
      },
    });

    this.emojiPlugin = createEmojiPlugin({
      theme: emojiTheme(theme),
      selectButtonContent: <FontAwesomeIcon icon={faSmile} />,
      useNativeArt: true,
    });

    let editorState: EditorState;
    let commentFile: Attachment | undefined;
    let descriptionRemove: 'media' | 'link' | undefined;
    let fileType: FileType | undefined;

    const { isEditComment, commentEdit, commentMediaEdit, token, commentLink } = this.props;

    if (isEditComment) {
      const textContent = commentEdit!.comment.description;
      if (isJSON(textContent)) {
        editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(textContent)));
      } else {
        editorState = EditorState.createWithContent(
          deserializeHtml(textContent, commentEdit!.mentions)
        );
      }

      const mediaTypes: Record<number, FileType> = {
        1: 'Image',
        4: 'File',
      };

      if (commentMediaEdit) {
        const [media] = commentMediaEdit;
        descriptionRemove = 'media';
        fileType = mediaTypes[media.mediaType];
        commentFile = {
          index: v4(),
          preview: fetchMedia(media.mediaId, token),
          name: media.originalFileName,
          size: media.fileSize,
        };
      }
      if (commentLink) {
        descriptionRemove = 'link';
        fileType = 'LinkEdit';
        commentFile = commentLink;
      }
    } else {
      editorState = EditorState.createEmpty();
    }

    this.state = {
      editorState,
      sending: false,
      counterVisible: false,
      isOverLimit: false,
      attachmentEdit: commentFile,
      attachment: commentFile,
      attachmentType: fileType,
      descriptionRemove,
      mentionOpen: true,
      suggestions: [],
      postDeleted: false,
      isAttachmentLoading: false,
    };

    this.counterLimit = 2048;
  }

  static defaultProps = {
    autoFocus: false,
    isEditComment: false,
    placeholder: '',
    className: '',
  };

  componentDidMount() {
    const { autoFocus, editorFocused } = this.props;
    if (autoFocus || editorFocused) {
      setTimeout(() => {
        this.focus();
        if (autoFocus) {
          const range = document.createRange();
          range.selectNodeContents(this.editorRef.current?.getEditorRef().editor!);
          const selection = window.getSelection();
          selection?.removeAllRanges();
          selection?.addRange(range);
        }
      }, 10);
    }
  }

  getMessage(forbiddenExtensions: string[], maxFileSize: number) {
    const { t } = this.props;
    return t('files.forbiddenFiles', {
      extensions: forbiddenExtensions.join(', '),
      size: maxFileSize,
    });
  }

  focus() {
    this.editorRef.current?.focus();
  }

  handleFile(acceptedFiles: File[]) {
    const { forbiddenExtensions, maxPostFileSize } = this.props;
    if (acceptedFiles && acceptedFiles.length > 0) {
      this.setState({ attachment: acceptedFiles[0] });
    } else {
      this.setState({
        attachment: undefined,
        attachmentType: undefined,
        errorFile: this.getMessage(forbiddenExtensions, maxPostFileSize),
      });
    }
  }

  handleImage(acceptedFiles: File[]) {
    const { t } = this.props;
    if (acceptedFiles && acceptedFiles.length > 0) {
      this.setState({
        attachment: Object.assign(acceptedFiles[0], {
          preview: this.handleUrl(acceptedFiles[0]),
        }),
      });
    } else {
      this.setState({
        attachment: undefined,
        attachmentType: undefined,
        errorFile: t('files.forbiddenAttachment'),
      });
    }
  }

  handleAttachment(acceptedFiles: File[], type: FileType) {
    this.setState({
      attachment: undefined,
      errorFile: '',
      attachmentType: type,
    });
    // eslint-disable-next-line default-case
    switch (type) {
      case 'Image':
        this.handleImage(acceptedFiles);
        break;
      case 'File':
        this.handleFile(acceptedFiles);
        break;
    }
  }

  handleUrl(file: File) {
    return URL.createObjectURL(file);
  }

  handleRejectedFile() {
    const { forbiddenExtensions, maxPostFileSize } = this.props;
    this.setState({
      attachment: undefined,
      attachmentType: undefined,
      errorFile: this.getMessage(forbiddenExtensions, maxPostFileSize),
    });
  }

  handleResetItems() {
    const newState = EditorState.createEmpty();
    this.setState({
      editorState: EditorState.moveFocusToEnd(newState),
      attachment: undefined,
      attachmentType: undefined,
      currentLink: '',
    });
    this.focus();
  }

  //#region EDITOR HANDLERS
  handleSubmit(e: React.KeyboardEvent) {
    if (e.shiftKey) return 'not-handled';
    if (e.ctrlKey || e.metaKey) {
      this.submitComment();
      return 'handled';
    }

    return 'not-handled';
  }

  handleChange(editorState: EditorState) {
    this.onChange(editorState, parseLinksContent(editorState));
  }

  previewLink = (link: string) => {
    previewLinkApi(link).then(preview => {
      if (preview && typeof preview === 'object') {
        this.setState({ attachment: preview, attachmentType: 'LinkInfo' });
      }
    });
  };

  onChange(newEditorState: EditorState, linksContent: string[]) {
    const { currentLink, editorState, attachmentType } = this.state;
    const currentContent = newEditorState.getCurrentContent();

    if (!attachmentType) {
      if (currentContent !== editorState.getCurrentContent()) {
        if (linksContent.length > 0) {
          const link = linksContent.pop();
          if (link !== currentLink) {
            this.debouncedPreviewLink(link);
          }
        } else {
          this.debouncedPreviewLink.cancel();
          this.setState({ isAttachmentLoading: false });
        }
      }
    }

    const charCount = currentContent.getPlainText().replace(/\r?\n|\r/g, '').length;
    const nearLimit = this.counterLimit * 0.9;
    const counterVisible = charCount >= nearLimit;
    const isOverLimit = charCount > this.counterLimit;

    this.setState({ editorState: newEditorState, counterVisible, isOverLimit });
  }

  handleOpenChange(open: boolean) {
    this.setState({ mentionOpen: open });
  }

  handleSearchMentions({ value }: { value: string }) {
    const { postId, postAuthor, postType, postPrivate, page } = this.props;

    const { type: authorType } = postAuthor;

    const forAdmin =
      postType === POST_TYPES.group &&
      postPrivate &&
      authorType === PROFILE_TYPES.user &&
      !page?.public;

    if (!forAdmin) {
      const params: mentionApi.MentionReqParams = {
        name: value,
      };

      if (postPrivate) {
        params.postId = postId;
      }

      if (!page?.public) {
        params.pageId = page?.pageId;
      }

      mentionApi.getMentionsList(params).then(suggestions => {
        if (suggestions && typeof suggestions === 'object') {
          this.setState({ suggestions });
        }
      });
    }
  }

  onBlur() {
    const { blurCommentField, postId } = this.props;
    blurCommentField(postId);
  }

  bindKey(event: React.KeyboardEvent): any {
    const { isEditComment } = this.props;

    if (event.key === 'Escape' && isEditComment) {
      window.getSelection()?.removeAllRanges();
      this.cancelEdit();
    }
  }
  //#endregion

  async submitComment() {
    const { postId, userId, createComment, isEditComment } = this.props;
    const { attachment, attachmentType, editorState, isOverLimit } = this.state;
    const contentState = editorState.getCurrentContent();
    const parsedComment = JSON.stringify(convertToRaw(contentState));
    const canSend = editorState.getCurrentContent().hasText() && !isOverLimit;

    if (!canSend) return;
    const comment = {
      itemId: postId.toString(),
      itemType: '1',
      userId: userId.toString(),
      description: parsedComment,
      descriptionText: contentState.getPlainText(),
    };

    await this.setState({ comment });

    let status;
    this.setState({ sending: true });
    if (isEditComment) {
      await this.editComment(attachment!, attachmentType!);
    } else {
      status = await createComment(comment, attachment, attachmentType!);
      this.setState({ sending: false });
      switch (status) {
        case 200:
          this.handleResetItems();
          break;
        case 412:
          this.setState({ postDeleted: true });
          break;
        default:
          this.handleResetItems();
      }
    }
  }

  async editComment(attachment: Attachment, attachmentType: FileType) {
    const { updateComment, commentEdit, commentMediaEdit } = this.props;
    const { attachmentEdit, descriptionRemove, comment } = this.state;

    this.setState({ sending: true });
    const id = { commentId: commentEdit?.comment.commentId };
    let mediaId;

    if (commentMediaEdit && attachment !== attachmentEdit) {
      mediaId = { removeMedia: commentMediaEdit[0].mediaId };
    }

    const commentUpdated = {
      ...comment,
      ...id,
      ...mediaId,
      originalComment: JSON.stringify(commentEdit),
    };

    if (attachment === null) {
      return updateComment(commentUpdated, null, undefined, true, descriptionRemove);
    }
    if (attachment === attachmentEdit) {
      return updateComment(commentUpdated);
    }
    return updateComment(commentUpdated, attachment, attachmentType, true, descriptionRemove);
  }

  cancelEdit() {
    const { commentEdit, cancelEditComment } = this.props;
    cancelEditComment(commentEdit!);
  }

  renderButtons() {
    const { counterVisible } = this.state;
    const { CharCounter } = this.counterPlugin;
    const { EmojiSelect } = this.emojiPlugin;
    const { networkFunctions } = this.props;

    return (
      <S.ActionsWrapper>
        <Animated
          animationIn="fadeIn"
          animationOut="fadeOut"
          animationInDuration={300}
          animationInDelay={150}
          animationOutDuration={200}
          isVisible={counterVisible}
        >
          <CharCounter limit={this.counterLimit} />
        </Animated>
        {networkFunctions.commentAttachments && (
          <ImageUploader
            onImageAdd={acceptedFiles => this.handleAttachment(acceptedFiles, 'Image')}
            canSendMultiple={false}
          >
            <FontAwesomeIcon icon={faImage} />
          </ImageUploader>
        )}
        {networkFunctions.commentAttachments && (
          <FileUploader
            onFileAdded={acceptedFiles => this.handleAttachment(acceptedFiles, 'File')}
            onFileRejected={this.handleRejectedFile}
          />
        )}

        <EmojiSelect />
      </S.ActionsWrapper>
    );
  }

  renderPreview() {
    const { attachment, attachmentType } = this.state;
    switch (attachmentType) {
      case 'Image': {
        const file = attachment as CommentFile;
        return (
          <Preview
            componentSize="imageSize"
            fileUrl={file.preview}
            type="image"
            remove={() => {
              URL.revokeObjectURL(file.preview!);
              this.setState({ attachment: undefined, attachmentType: undefined });
            }}
          />
        );
      }
      case 'File': {
        const file = attachment as CommentFile;
        return (
          <FilePreview
            file={file}
            key={file?.index}
            onClickDelete={() => {
              this.setState({ attachment: undefined, attachmentType: undefined });
            }}
          />
        );
      }
      case 'LinkInfo': {
        const link = attachment as LinkPreview;
        return (
          <LinkAttachment
            css={S.linkAttachment}
            key={link.url}
            url={link.url}
            title={link.title}
            clickable={false}
            description={link.description}
            image={link.images?.[0]}
            onRemove={() =>
              this.setState({
                attachment: undefined,
                attachmentType: undefined,
                currentLink: '',
              })
            }
            newPost
          />
        );
      }
      case 'LinkEdit': {
        const link = attachment as LinkInfo;
        return (
          <LinkAttachment
            css={S.linkAttachment}
            key={link.url}
            url={link.url}
            title={link.title}
            clickable={false}
            description={link.description}
            image={link.imageUrl}
            onRemove={() =>
              this.setState({
                attachment: undefined,
                attachmentType: undefined,
                currentLink: '',
              })
            }
            newPost
          />
        );
      }
      default:
        return null;
    }
  }

  renderAttachmentPreview() {
    const { attachment, errorFile, isAttachmentLoading } = this.state;

    if (isAttachmentLoading) {
      return (
        <div>
          <FontAwesomeIcon icon={faSpinnerThird} spin />
        </div>
      );
    }

    if (attachment) {
      return this.renderPreview();
    }
    if (errorFile) {
      return (
        <div>
          <span>{errorFile}</span>
        </div>
      );
    }
    return null;
  }

  render() {
    const { className, placeholder, t, isEditComment } = this.props;
    const { MentionSuggestions } = this.mentionPlugin;
    const { EmojiSuggestions } = this.emojiPlugin;
    const plugins = [this.mentionPlugin, this.counterPlugin, this.emojiPlugin];

    const { mentionOpen, suggestions, editorState, sending, postDeleted } = this.state;

    const content = editorState.getCurrentContent().getPlainText();
    const hasText = content.trim().length > 0;
    const isEditing = hasText && !sending;

    const sendCommentTextKey = os.macos ? 'comments.sendCommentMac' : 'comments.sendComment';
    const sendEditCommentTextKey = os.macos
      ? 'comments.sendCommentEditMac'
      : 'comments.sendCommentEdit';

    return (
      <S.EditorCss isEditing={isEditing}>
        <div
          css={S.editorWrapper(sending)}
          role="textbox"
          className={className}
          translate="no"
          onClick={this.focus}
          ref={this.wrapperRef}
        >
          <Editor
            autoCorrect="off"
            editorKey="commentEditor"
            editorState={editorState}
            handleReturn={this.handleSubmit}
            keyBindingFn={this.bindKey}
            onBlur={this.onBlur}
            onChange={this.handleChange}
            placeholder={placeholder}
            plugins={plugins}
            readOnly={sending}
            ref={this.editorRef}
            stripPastedStyles
          />
          <MentionSuggestions
            entryComponent={EntrySuggestions}
            open={mentionOpen}
            suggestions={suggestions}
            onOpenChange={this.handleOpenChange}
            onSearchChange={this.debouncedSearchMentions}
          />
          <EmojiSuggestions />

          {!sending && this.renderButtons()}

          <S.PreviewContainer>{this.renderAttachmentPreview()}</S.PreviewContainer>
        </div>
        <Animated
          css={S.submitHint(postDeleted)}
          animationIn="fadeIn"
          animationOut="fadeOut"
          animationInDuration={300}
          animationInDelay={150}
          animationOutDuration={200}
          isVisible={isEditing}
        >
          <div>
            {postDeleted ? (
              t('comments.sendCommentEdit')
            ) : (
              <>
                <FontAwesomeIcon css={S.submitHintIcon} icon={faLevelUpAlt} rotation={90} />
                <span>{isEditComment ? t(sendEditCommentTextKey) : t(sendCommentTextKey)}</span>
              </>
            )}
          </div>
        </Animated>

        <S.EditorFooter>
          {isEditComment && (
            <Button css={S.btnCancel} size="small" onClick={this.cancelEdit}>
              {t('comments.cancel')}
            </Button>
          )}
          {!postDeleted && (isEditComment || hasText) && (
            <Button
              css={S.btnAction}
              type="primary"
              size="small"
              disabled={!hasText}
              loading={sending}
              onClick={this.submitComment}
            >
              {t('comments.sendCommentsButton')}
            </Button>
          )}
        </S.EditorFooter>
      </S.EditorCss>
    );
  }
}

function makeMapStateToProps() {
  const getPost = makeGetPostSelector();

  function mapStateToProps(state: RootState, ownProps: CommentTextEditorProps) {
    const { postId } = ownProps;

    const postInfo = getPost(state, postId);

    const commentEditorFocused = state.posts.ui[postId]?.commentEditorFocused ?? false;
    const {
      user: { userId },
    } = state.auth.userInfo;
    const { forbiddenExtensions, maxPostFileSize } = state.auth.networkSettings!;

    return {
      userId,
      editorFocused: commentEditorFocused,
      networkFunctions: state.auth.networkFunctions!,
      postAuthor: postInfo?.author as PostAuthor,
      targetUsers: postInfo?.targetUsers as TargetUser[],
      postType: postInfo?.post.type,
      postPrivate: postInfo?.post.private,
      page: postInfo?.page as Page | undefined,
      forbiddenExtensions,
      maxPostFileSize,
    };
  }

  return mapStateToProps;
}

const mapStateToProps = makeMapStateToProps();
const connector = connect(mapStateToProps, {
  createComment,
  updateComment,
  cancelEditComment,
  blurCommentField,
});

export default compose(connector, withTranslation(), withTheme)(CommentTextEditor);

type PropsFromRedux = ConnectedProps<typeof connector>;
