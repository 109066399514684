import styled from '@emotion/styled';

import { Divider as AntDivider } from 'antd';

export const Container = styled.div`
  height: 100vh;
`;

export const Wrapper = styled.div`
  width: 80%;
  max-width: 670px;
  padding: 2.5em 0;
  margin: 0 auto;
  text-align: center;

  .img-wrapper {
    max-width: 356px;
    margin: 0 auto 0.8em auto;
  }

  img {
    display: inline-block;
    width: 100%;
  }

  .spinner {
    color: ${({ theme }) => theme.colors.primary};
  }

  p {
    color: ${({ theme }) => theme.colors.gray6};

    ${({ theme }) => theme.typography.fontxLarge()}
  }
`;

export const Divider = styled(AntDivider)`
  background-color: ${({ theme }) => theme.colors.gray5};
`;
