import React, { Suspense, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { useAsync } from 'react-async-hook';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

import { API_DOMAIN } from 'lib/constants';
import { getSubdomain } from 'lib/helper';
import { featureToogleReceived, networkUpdated } from 'store/actions/authActions';

import NotFound from 'pages/Errors/NotFound';
import Login from 'pages/Login/Login';
import LoginSeniorX from 'pages/SeniorX/LoginSeniorX';
import AuthenticateCas from 'pages/CAS/AuthenticateCas';

const ForgotPassword = React.lazy(() => import('pages/ForgotPassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('pages/ResetPassword/ResetPassword'));
const Welcome = React.lazy(() => import('pages/Welcome/Welcome'));
const Legal = React.lazy(() => import('pages/Legal'));
const EmailResetPassword = React.lazy(() => import('pages/Email/ResetPassword'));
const EmailWelcome = React.lazy(() => import('pages/Email/Welcome'));
const EmailPublicPost = React.lazy(() => import('pages/Email/PublicPost'));
const EmailPostMention = React.lazy(() => import('pages/Email/PostMention'));
const EmailCommentMention = React.lazy(() => import('pages/Email/CommentMention'));
const EmailNewComment = React.lazy(() => import('pages/Email/NewComments'));
const EmailVideoConverted = React.lazy(() => import('pages/Email/VideoConverted'));
const EmailPostPublished = React.lazy(() => import('pages/Email/PostPublished'));
const EmailGroupInvite = React.lazy(() => import('pages/Email/GroupInvite'));

const api = axios.create({ baseURL: API_DOMAIN });

const spinnerStyle: React.CSSProperties = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

function Offline() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isNotFound, setNotFound] = useState(false);

  const asyncGetNetwork = useAsync(async () => {
    let subdomain = getSubdomain(window.location.host);
    if (process.env.NODE_ENV !== 'production') subdomain = 'web-front-praga';

    try {
      const response = await api.get('/api/network/getNetworkByHost', { params: { subdomain } });

      dispatch(networkUpdated(response.data.network));
      dispatch(featureToogleReceived(response.data.featureToggle));
    } catch (_error) {
      if (_error instanceof Error) {
        const error = _error as AxiosError;
        if (error?.isAxiosError) {
          if (error.response?.status === 404 || error.message.toLowerCase() === 'network error') {
            setNotFound(true);
            navigate('/not-found');
          }
        }
      }
    }
  }, []);

  if (asyncGetNetwork.loading) return null;
  if (isNotFound) return <NotFound />;

  return (
    <Suspense
      fallback={<FontAwesomeIcon icon={faSpinnerThird} spin size="3x" style={spinnerStyle} />}
    >
      <Routes>
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/login" element={<Login />}>
          <Route path=":token" element={<Login />} />
        </Route>
        <Route path="/loginSeniorX" element={<LoginSeniorX />} />
        <Route path="/authenticateCas" element={<AuthenticateCas />} />
        <Route path="/email">
          <Route path="reset-password" element={<EmailResetPassword />} />
          <Route path="welcome" element={<EmailWelcome />} />
          <Route path="public-post" element={<EmailPublicPost />} />
          <Route path="post-mention" element={<EmailPostMention />} />
          <Route path="comment-mention" element={<EmailCommentMention />} />
          <Route path="new-comments" element={<EmailNewComment />} />
          <Route path="video-converted" element={<EmailVideoConverted />} />
          <Route path="post-published" element={<EmailPostPublished />} />
          <Route path="group-invite" element={<EmailGroupInvite />} />
        </Route>
        <Route path="/legal/*" element={<Legal />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Suspense>
  );
}

export default Offline;
