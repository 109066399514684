import styled from '@emotion/styled';

export const Fieldset = styled.fieldset`
  margin-top: 1em;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.gray5};
  border-radius: 8px;
`;

export const Container = styled.div`
  padding: 1em;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  h4 {
    margin-bottom: 0;
    font-weight: 600;
  }

  small {
    display: block;
    margin-bottom: 1.5em;
    color: ${({ theme }) => theme.colors.gray6};

    ${({ theme }) => theme.typography.fontxSmall()};
  }
`;
