import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Menu } from 'antd';

export const MenuItem = styled(Menu.Item)<{ selected: boolean }>`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray10};
  ${({ theme }) => theme.typography.fontSmall()};

  ${({ theme, ...props }) =>
    props.selected &&
    css`
      color: ${theme.colors.primary};
    `}

  .avatar {
    margin-right: 12px;
  }
`;
