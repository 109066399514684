import React, { Component } from 'react';
import moment from 'moment';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCircle } from '@fortawesome/pro-solid-svg-icons';

// #region STYLES
const mediaPreview = css`
  position: relative;
  height: inherit;
  max-height: inherit;
  border-radius: 4px;
  object-fit: cover;
`;

const RemoveButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.danger};
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transform: scale(0);

  .svg-inline--fa {
    width: 100%;
    height: 100%;
  }
`;
const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #000;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
`;
const PreviewStyled = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  max-height: 500px;
  border-radius: 4px;

  & + & {
    margin-left: 8px;
  }

  :hover ${Overlay} {
    opacity: 0.4;
  }
  :hover ${RemoveButton} {
    opacity: 1;
    transition: all 0.15s ease-in-out;
    transform: scale(1);
  }
  :hover ${RemoveButton}:hover {
    color: ${({ theme }) => theme.colors.danger5};
    transition: all 0.1s ease;
    transform: scale(1.3);
  }
`;
const Img = styled.img`
  ${mediaPreview};
`;
const Video = styled.video`
  ${mediaPreview};
`;
const TimerPreview = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  margin-top: 90px;
  border-radius: 4px;
`;
const Timer = styled.span`
  font-size: 15px;
  color: #fff;
`;
// #endregion

class Preview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formattedTime: '',
    };
  }

  async componentDidMount() {
    const { type } = this.props;

    if (type === 'video') {
      const video = document.getElementById('videoPreview');
      video.onloadedmetadata = () => {
        const time = moment.utc(video.duration * 1000).format('HH:mm:ss');
        this.setState({ formattedTime: time });
      };
    }
  }

  render() {
    const { fileUrl, remove, className, type, disable } = this.props;
    const { formattedTime } = this.state;

    return (
      <PreviewStyled className={className}>
        {type === 'image' ? (
          <Img src={fileUrl} alt="preview" />
        ) : (
          <Video id="videoPreview" controlsList="nodownload nofullscreen">
            <source src={fileUrl} />
            <track kind="captions" />
          </Video>
        )}

        <Overlay>
          <TimerPreview>
            <Timer>{formattedTime}</Timer>
          </TimerPreview>
        </Overlay>
        <RemoveButton type="button" className="fa-layers fa-fw" onClick={remove} disabled={disable}>
          <FontAwesomeIcon icon={faCircle} />
          <FontAwesomeIcon icon={faTimes} inverse transform="shrink-6" />
        </RemoveButton>
      </PreviewStyled>
    );
  }
}
Preview.defaultProps = {
  className: '',
};

export default Preview;
