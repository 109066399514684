export default {
  editor: {
    createPost: 'Criar publicação',
    editPost: 'Editar publicação',
    linkPlaceholder: 'Cole ou digite um link e pressione enter',
    placeholder: 'No que você está pensando?',
    post: 'Publicar',
    toolbar: {
      bold: 'Negrito',
      italic: 'Itálico',
      underline: 'Sublinhado',
    },
  },
  prompt: {
    discardPost: 'Se você sair nesse momento, sua publicação não será salva.',
    discardTitle: 'Descartar essa publicação',
  },
  toast: {
    successPost: 'Publicado com sucesso',
  },
};
