import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import { fetchMedia } from 'lib/helper';
import { transparentButton } from 'styles/component-styles/buttons';

// #region STYLES
const modalBackground = css`
  & .ant-modal-content {
    background-color: transparent;
    box-shadow: none !important;
  }
`;
const close = css`
  position: fixed;
  top: 80px;
  right: 360px;
  font-size: 24px;
  color: #ffffff;
`;

const ImageAttachmentContainer = styled.div`
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 296px;
    border-radius: 4px;
    object-fit: cover;
  }
`;
const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-height: 700px;
`;
const ModalImg = styled.img`
  max-width: 100%;
  height: auto;
  max-height: inherit;
  object-fit: cover;
`;
const ImageButton = styled.button`
  ${transparentButton}
`;
// #endregion

type ReduxProps = ConnectedProps<typeof connector>;

type CommentImageAttachmentProps = ReduxProps &
  typeof CommentImageAttachment.defaultProps & {
    imageId: number;
    image: string;
  };

type CommentImageAttachmentState = {
  modalOpen: boolean;
};

class CommentImageAttachment extends Component<
  CommentImageAttachmentProps,
  CommentImageAttachmentState
> {
  constructor(props: CommentImageAttachmentProps) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }
  static defaultProps = {
    alt: '',
  };

  toggleImage = () => {
    this.setState(prev => ({ modalOpen: !prev.modalOpen }));
  };

  getSrc = (imageId: number) => {
    const { token } = this.props;
    return fetchMedia(imageId, token!);
  };

  render() {
    const { imageId, alt } = this.props;
    const { modalOpen } = this.state;

    return (
      <ImageAttachmentContainer>
        <ImageButton type="button" onClick={this.toggleImage}>
          <img src={this.getSrc(imageId)} alt={alt} role="presentation" />
        </ImageButton>
        <Modal
          css={modalBackground}
          visible={modalOpen}
          width="60%"
          closable={false}
          onCancel={this.toggleImage}
          footer={null}
        >
          <ImageButton type="button" onClick={this.toggleImage}>
            <FontAwesomeIcon css={close} icon={faTimes} />
          </ImageButton>
          <ModalWrapper>
            <ModalImg src={this.getSrc(imageId)} alt={alt} />
          </ModalWrapper>
        </Modal>
      </ImageAttachmentContainer>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  token: state.auth.token,
});

const connector = connect(mapStateToProps);

export default connector(CommentImageAttachment);
