import { useTranslation } from 'react-i18next';
import {
  faComment,
  faCommentLines,
  faThumbsUp,
  faBirthdayCake,
  faGlobe,
  faUserLock,
  faAt,
  faVideo,
  faClock,
} from '@fortawesome/pro-solid-svg-icons';
import { faUsers } from '@fortawesome/pro-light-svg-icons';

import { ITEM_TYPES } from 'lib/constants';
import { namesList } from 'lib/helper';

export const TypePanel = {
  Creation: 1,
  Mention: 2,
  like: 3,
  comment: 4,
  video: 5,
};

export const RelationType = {
  Owner: 1,
  Mentioned: 2,
  Commented: 3,
  Targeted: 4,
};

function useNotification(notification) {
  const { t } = useTranslation();
  let attributes = {};
  const { emitters, metadata } = notification;
  const { itemType, type, rootElement, relationType, pageName } = notification.notification;
  const { rootElementData } = notification;
  const p = notification.notification.private;
  const names = namesList(emitters, t);
  const hasPermission = true;
  const id =
    rootElement || itemType === ITEM_TYPES.post
      ? notification.notification.itemId
      : rootElementData.itemId;
  const isPostScheduled =
    itemType === ITEM_TYPES.post &&
    type === TypePanel.Creation &&
    relationType === RelationType.Owner &&
    p;

  function getPath(id) {
    if (itemType === ITEM_TYPES.birthday || itemType === ITEM_TYPES.chatChannel) {
      return '';
    }
    if (itemType === ITEM_TYPES.group) {
      return `/groups/${id}`;
    }
    return `/post/${id}`;
  }

  function getCreationIcon() {
    switch (itemType) {
      case ITEM_TYPES.post:
        if (p) {
          if (relationType === RelationType.Owner) return faClock;
          return faUserLock;
        }
        return faGlobe;

      case ITEM_TYPES.comment:
        return faComment;

      case ITEM_TYPES.group:
        return faUsers;
      case ITEM_TYPES.chatChannel:
        return faCommentLines;
      default:
        return '';
    }
  }

  function getCreationTitle() {
    switch (itemType) {
      case ITEM_TYPES.post:
        if (pageName) {
          if (!p) {
            return t('notification.post.publicPostTitleGroup', { name: pageName });
          }
          return t('notification.post.privatePostTitleGroup', { name: pageName });
        }
        if (!p) {
          return t('notification.post.publicPostTitle');
        }
        if (relationType === RelationType.Owner) return t('notification.post.scheduleReadyTitle');

        return t('notification.post.privatePostTitle');

      case ITEM_TYPES.comment:
        return t('notification.comment.commentTitle', { count: emitters.length });
      case ITEM_TYPES.group:
        return t('notification.group.groupAddedTitle');
      case ITEM_TYPES.chatChannel:
        return t('notification.chat.channelCreatedTitle');
      default:
        return '';
    }
  }

  function getActionIcon() {
    switch (type) {
      case TypePanel.comment:
        return faComment;
      case TypePanel.like:
        return faThumbsUp;
      case TypePanel.Mention:
        return faAt;
      case TypePanel.Creation:
        return getCreationIcon();
      case TypePanel.video:
        return faVideo;
      default:
        return '';
    }
  }

  function getTitle() {
    switch (type) {
      case TypePanel.comment:
        return t('notification.comments.commentTitle', { count: emitters.length });
      case TypePanel.like:
        return t('notification.likes.likeTitle', { count: emitters.length });
      case TypePanel.Mention:
        return t('notification.mentions.mentionTitle');
      case TypePanel.Creation:
        return getCreationTitle();
      case TypePanel.video:
        return t('post.videoReadyTitle');
      default:
        return '';
    }
  }

  function getPostBody() {
    switch (type) {
      case TypePanel.comment:
        return t(
          relationType === RelationType.Owner
            ? 'notification.comments.commentMessage'
            : 'notification.comments.commentMessageRelated',
          { name: names, count: emitters.length }
        );
      case TypePanel.like:
        return t(
          relationType === RelationType.Owner
            ? 'notification.likes.likePost'
            : 'notification.likes.likePostRelated',
          { name: names, count: emitters.length }
        );
      case TypePanel.Mention:
        return t('notification.mentions.mentionMessagePost', { name: names });
      case TypePanel.Creation:
        if (!p) {
          return t('notification.post.publicPostBody', { name: names });
        }
        if (relationType === RelationType.Owner) return t('notification.post.scheduleReadyBody');
        return t('notification.post.privatePostBody', { name: names });

      case TypePanel.video:
        return t('post.videoReady');
      default:
        return '';
    }
  }

  function getCommentBody() {
    switch (type) {
      case TypePanel.like:
        return t(
          relationType === RelationType.Owner
            ? 'notification.likes.likeComment'
            : 'notification.likes.likeCommentRelated',
          { name: names, count: emitters.length }
        );
      case TypePanel.Mention:
        return t('notification.mentions.mentionMessageComment', { name: names });
      case TypePanel.Creation:
        return t('notification.comment.commentMessage', { name: names, count: emitters.length });
      default:
        return '';
    }
  }

  function getBirthdayBody() {
    if (emitters.length > 1) {
      return t('notification.birthdays.birthdayMessage', { name: names });
    }
    if (hasPermission) {
      return t('notification.birthdays.birthdayMessageWithPermission', { name: names });
    }
    return t('notification.birthdays.birthdayMessage', { name: names });
  }

  function getPostInfo() {
    return {
      icon: getActionIcon(),
      title: getTitle(),
      body: getPostBody(),
    };
  }

  function getCommentInfo() {
    return {
      icon: getActionIcon(),
      title: getTitle(),
      body: getCommentBody(),
    };
  }

  function getBirthdayInfo() {
    return {
      icon: faBirthdayCake,
      title: t('notification.birthdays.birthdayTitle', { count: emitters.length }),
      body: getBirthdayBody(),
    };
  }

  function getGroupInfo() {
    return {
      icon: getActionIcon(),
      title: getTitle(),
      body: t('notification.group.groupAddedBody', { name: metadata.name }),
    };
  }

  function getChatInfo() {
    return {
      icon: getActionIcon(),
      title: getTitle(),
      body: t('notification.chat.channelCreatedBody', { name: metadata.name }),
    };
  }

  switch (itemType) {
    case ITEM_TYPES.post:
      attributes = getPostInfo();
      break;
    case ITEM_TYPES.comment:
      attributes = getCommentInfo();
      break;
    case ITEM_TYPES.birthday:
      attributes = getBirthdayInfo();
      break;
    case ITEM_TYPES.group:
      attributes = getGroupInfo();
      break;
    case ITEM_TYPES.chatChannel:
      attributes = getChatInfo();
      break;
    default:
      attributes = {};
  }
  attributes.created = emitters[0].created;
  attributes.type = itemType;
  if (
    emitters.length === 1 &&
    type !== TypePanel.video &&
    !isPostScheduled &&
    itemType !== ITEM_TYPES.group
  ) {
    const [user] = emitters;
    attributes.profile = {
      profileId: user.profileId,
      name: user.name,
      profileType: user.type,
    };
  }

  if (emitters.length > 1) {
    const user = emitters[0];
    attributes.profile = {
      profileId: user.profileId,
      name: user.name,
      profileType: user.type,
    };
  }

  if (itemType === ITEM_TYPES.group) {
    attributes.profile = {
      profileId: metadata.pageId,
      name: metadata.name,
      profileType: 2,
    };
  }
  if (itemType === ITEM_TYPES.chatChannel) {
    attributes.profile = metadata;
  }
  attributes.viewed = emitters[0].viewed;
  attributes.read = emitters[0].read;
  attributes.path = getPath(id);

  return { attributes };
}
export default useNotification;
