import React from 'react';
import { Avatar, Tooltip } from 'antd';

import * as S from './ShortcutStyles';

//#region TYPES
export interface ShortcutProps {
  size?: 'small' | 'large';
  name: string;
  iconUrl?: string;
}
//#endregion

const Shortcut = ({ size = 'small', name, iconUrl }: ShortcutProps) => {
  const initials = name.split(' ').slice(0, 2);

  return (
    <Tooltip arrowPointAtCenter placement="bottomLeft" title={name}>
      <S.Wrapper size={size}>
        <Avatar shape="square" size={size === 'small' ? 32 : 40} src={iconUrl}>
          {initials.map(ini => ini.substring(0, 1).toUpperCase())}
        </Avatar>
        <small>{name}</small>
      </S.Wrapper>
    </Tooltip>
  );
};

export default Shortcut;
