import React, { useState, useEffect } from 'react';
import { Modal, DatePicker, Button, TimePicker, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';

import * as S from './PostSchedulingStyles';

//#region TYPES
interface PostSchedulingProps {
  show: boolean;
  loading: boolean;
  onDateSelected: (date: string) => void;
  onClose: () => void;
}
//#endregion

// #region STYLES

// #endregion

function timeRange(time: number) {
  const result = [];
  for (let i = 0; i < time; i += 1) {
    result.push(i);
  }
  return result;
}

export function disabledDate(current: Moment) {
  return current && current < moment().subtract(1, 'day').endOf('day');
}

export function disabledTime(current: Moment | null) {
  if (current && current <= moment().endOf('day')) {
    const now = moment().add(5, 'm');
    return {
      disabledHours: () => {
        return timeRange(now.hour());
      },
      disabledMinutes: () => {
        if (now.hour() === current.hour()) return timeRange(now.minute());
        return [];
      },
    };
  }
  return {
    disabledHours: () => [],
    disabledMinutes: () => [],
  };
}

const PostScheduling: React.VFC<PostSchedulingProps> = ({
  show,
  loading,
  onDateSelected,
  onClose,
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState<Moment | null>(null);
  const [time, setTime] = useState<Moment | null>(null);
  const [dateChosen, setDateChosen] = useState(false);
  const now = moment().add(5, 'm');

  useEffect(() => {
    setDate(null);
    setTime(null);
    setDateChosen(false);
  }, [show]);

  const handleOk = () => {
    const datetime = date!.set({
      hour: time!.hour(),
      minute: time!.minute(),
      second: 0,
      millisecond: 0,
    });

    onDateSelected(datetime!.toISOString(true));
  };

  const handleModalClosed = () => {
    onClose();
  };

  const handleDateChanged = (date: Moment | null) => {
    if (date === null) {
      setDateChosen(false);
    }
    setTime(null);
    setDate(date);
  };

  const handleDateChosen = () => {
    setDateChosen(true);
  };

  const timeChangedHandler = (time: Moment | null) => {
    if (time == null) {
      setDateChosen(false);
    }

    setTime(time);
  };

  return (
    <Modal
      title={t('post.schedule.schedulePost')}
      centered
      visible={show}
      footer={null}
      onCancel={handleModalClosed}
    >
      <Row gutter={8}>
        <Col span={12}>
          <DatePicker
            format={t('formats.date')}
            placeholder={t('common.date')}
            size="large"
            defaultPickerValue={now}
            defaultValue={now}
            disabledDate={disabledDate}
            showToday={false}
            showTime={false}
            onChange={handleDateChanged}
            value={date}
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={12}>
          <TimePicker
            format={'HH:mm'}
            size="large"
            showNow={false}
            showSecond={false}
            value={time}
            disabled={date == null}
            disabledHours={() => disabledTime(date).disabledHours()}
            disabledMinutes={() => disabledTime(date).disabledMinutes()}
            onChange={timeChangedHandler}
            onOk={handleDateChosen}
            style={{ width: '100%' }}
          />
        </Col>
      </Row>
      {/* <S.Invalid invalid={invalid}>{t('errors.invalidDate')}</S.Invalid> */}
      <S.SubmitWrapper>
        <Button
          block
          disabled={!dateChosen}
          loading={loading}
          type="primary"
          size="large"
          onClick={handleOk}
        >
          {t('post.schedule.schedule')}
        </Button>
      </S.SubmitWrapper>
    </Modal>
  );
};

export default PostScheduling;
