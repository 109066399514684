import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { up } from 'styled-breakpoints';

export const menu = css`
  margin: -4px -16px;
  border: 0;

  .ant-menu-item {
    height: 32px;
    margin: 0;
    line-height: 32px;
  }
  .ant-menu-item:not(:last-child) {
    margin-bottom: 0;
  }
`;

export const AvatarButton = styled.button`
  padding: 0;
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;

  ${up('tablet')} {
    margin-left: 0.5em;
  }
`;
