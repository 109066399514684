import { createSelector } from 'reselect';

const bannerSelector = (state: RootState) => state.banner;

export const gallerySelector = createSelector(bannerSelector, banner => banner.banners);

export const publishedBannersSelector = createSelector(bannerSelector, reducer =>
  reducer.banners.filter(b => b.banner.published)
);

export const newBannerSelector = createSelector(bannerSelector, reducer =>
  reducer.banners.filter(b => b.isNew as boolean)
);

export function bannerHidden(state: RootState) {
  return state.banner.hidden;
}
