import React from 'react';
import { Modal } from 'antd';
import type { ModalProps } from 'antd/lib/modal';

const bodyStyle = {
  maxHeight: 500,
  overflow: 'auto',
  paddingTop: 12,
  paddingBottom: 12,
};

const UsersListModal: React.FC<ModalProps> = ({ children, ...props }) => {
  return (
    <Modal {...props} footer={null} bodyStyle={bodyStyle}>
      {children}
    </Modal>
  );
};

export default UsersListModal;
