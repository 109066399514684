import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useImmerReducer } from 'use-immer';
import { toast } from 'react-toastify';
import axios from 'axios';

import { Avatar, Button, Dropdown, Menu, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons';

import { useAuth } from 'hooks';
import { chat as chatApi } from 'lib/api';
import { leaveChat, roomUpdated } from 'store/actions/chatActions';
import { channelPhoto } from 'lib/getProfilePhoto';

import Badge from 'components/layout/Common/Badge/Badge';
import PromptModal from 'components/modal/PromptModal';
import ChatMembersModal from './Modal/ChatMembersModal';
import AddChannelMemberModal from './Modal/AddChannelMemberModal';
import {
  Item,
  ItemButton,
  ItemImageContainer,
  ItemInfoWrapper,
  ItemInfoTitle,
  ItemInfoDescription,
  DividerStyled,
} from './ItemChatStyles';

//#region TYPES
interface ItemChatState {
  membersVisible: boolean;
  addMembersVisible: boolean;
  editRoomVisible: boolean;
}
type ItemChatAction =
  | {
      type: 'showMembers';
    }
  | {
      type: 'hideMembers';
    }
  | {
      type: 'showAddMembers';
    }
  | {
      type: 'hideAddMembers';
    }
  | {
      type: 'toggleEditRoom';
      visible: boolean;
    };

type ChannelItemChatProps = {
  chatRoom: ChatRoomInfo;
  onClick: (chatRoom: ChatRoomInfo) => void;
};
//#endregion

const initialState: Readonly<ItemChatState> = Object.freeze({
  membersVisible: false,
  addMembersVisible: false,
  editRoomVisible: false,
});
function reducer(draft: ItemChatState, action: ItemChatAction) {
  switch (action.type) {
    case 'showMembers':
      draft.membersVisible = true;
      break;
    case 'hideMembers':
      draft.membersVisible = false;
      break;
    case 'showAddMembers':
      draft.addMembersVisible = true;
      break;
    case 'hideAddMembers':
      draft.addMembersVisible = false;
      break;
    case 'toggleEditRoom':
      draft.editRoomVisible = action.visible;
      break;
  }
}

const ChannelItemChat = ({ chatRoom, onClick }: ChannelItemChatProps) => {
  const [state, channelDispatch] = useImmerReducer(reducer, initialState);
  const { userId, token } = useAuth();

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const room = Object.assign({ mediaId: chatRoom.mediaId }, chatRoom.room);
  const hasMessage = room.messages.length > 0;
  const eventType = hasMessage && room.messages[0].eventType;
  const hasMedia = hasMessage && room.messages[0].hasMedia;
  const mediaType = hasMessage && room.messages[0].mediaType;
  const src = channelPhoto(room.roomId, token, chatRoom.mediaId);

  const getLastMessage = () => {
    if (eventType === 1) {
      return t('chat:leftMessage', { name: room.messages[0].descriptionText });
    }

    if (hasMedia) {
      switch (mediaType) {
        case 1:
          return 'enviou uma imagem 📸';
        case 5:
          return 'mensagem de voz 🎤';
        default:
          return room.messages[0].description;
      }
    }

    return hasMessage && room.messages[0].description;
  };

  const handleItemClick = () => {
    onClick(chatRoom);
  };

  const leftChat = () => {
    axios.delete(`/api/chat/leftChat?roomId=${room.roomId}`).then(({ status }) => {
      if (status === 200) {
        dispatch(leaveChat(room.roomId));
      }
    });
  };

  const deleteChannel = () => {
    axios
      .delete(`/api/chat/deleteChatRoom?roomId=${room.roomId}&userId=${userId}`)
      .then(({ status }) => {
        if (status === 200) {
          dispatch(leaveChat(room.roomId));
        }
      });
  };

  //#region HANDLERS
  const handleShowMembers = () => channelDispatch({ type: 'showMembers' });
  const handleHideMembers = () => channelDispatch({ type: 'hideMembers' });
  const handleShowAddMembers = () => channelDispatch({ type: 'showAddMembers' });
  const handleHideAddMembers = () => channelDispatch({ type: 'hideAddMembers' });

  const onClickDelete = () => {
    Modal.confirm({
      title: t('chat:titleDeleteText'),
      content: t('chat:contentDeleteText'),
      okText: t('chat:okDeleteText'),
      okType: 'danger',
      centered: true,
      autoFocusButton: 'cancel',
      onOk: () => deleteChannel(),
    });
  };

  const onClickLeave = () => {
    Modal.confirm({
      title: t('chat:titleLeaveText'),
      content: t('chat:contentLeaveText'),
      okText: t('chat:okLeaveText'),
      okType: 'danger',
      centered: true,
      autoFocusButton: 'cancel',
      onOk: () => leftChat(),
    });
  };

  const changeNameHandler = async (value: string) => {
    const result = await chatApi.updateChatRoomName({ roomId: room.roomId, name: value });

    if (!result.ok) {
      toast.error(t('chat:createChannel.messages.channelNameError'), { position: 'top-right' });
      return;
    }

    const { data } = result;
    const chatRoomClone = structuredClone(chatRoom);

    chatRoomClone.room.name = data.name;
    dispatch(roomUpdated(chatRoomClone));

    toast.success(t('chat:createChannel.messages.channelNameChanged'), { position: 'top-right' });

    channelDispatch({ type: 'toggleEditRoom', visible: false });
  };
  //#endregion

  const menu = (
    <Menu>
      {room.creatorId === userId && (
        <>
          <Menu.Item onClick={handleShowAddMembers}>{t('chat:createChannel.addMembers')}</Menu.Item>
          <Menu.Item onClick={() => channelDispatch({ type: 'toggleEditRoom', visible: true })}>
            {t('chat:createChannel.changeChannelName')}
          </Menu.Item>
        </>
      )}
      <Menu.Item onClick={handleShowMembers}>{t('chat:channelInfo')}</Menu.Item>
      <Menu.Divider />
      {room.creatorId === userId && (
        <Menu.Item onClick={onClickDelete}>{t('groups.deleteChannel')}</Menu.Item>
      )}
      <Menu.Item onClick={onClickLeave}>{t('groups.leaveChannel')}</Menu.Item>
    </Menu>
  );

  const lastMessage = getLastMessage();
  return (
    <>
      <Item className="clearfix">
        <Dropdown trigger={['click']} overlay={menu}>
          <Button className="meatball" type="link">
            <FontAwesomeIcon icon={faEllipsisH} />
          </Button>
        </Dropdown>
        <ItemButton type="button" onClick={handleItemClick}>
          <ItemImageContainer>
            {chatRoom.unreadCount > 0 && <Badge size="sm" count={chatRoom.unreadCount} />}
            <Avatar size="large" src={src}>
              {room.name.substring(0, 1)}
            </Avatar>
          </ItemImageContainer>
          <ItemInfoWrapper>
            <ItemInfoTitle>{room.name}</ItemInfoTitle>
            {lastMessage && <ItemInfoDescription>{lastMessage}</ItemInfoDescription>}
          </ItemInfoWrapper>
        </ItemButton>
        <DividerStyled />
      </Item>
      <ChatMembersModal room={room} visible={state.membersVisible} onClose={handleHideMembers} />
      <AddChannelMemberModal
        chatRoom={room}
        visible={state.addMembersVisible}
        onClose={handleHideAddMembers}
      />
      <PromptModal
        open={state.editRoomVisible}
        title={t('chat:createChannel.changeChannelName')}
        okText={t('common.save')}
        inputProps={{ defaultValue: room.name }}
        onClose={() => channelDispatch({ type: 'toggleEditRoom', visible: false })}
        onSave={changeNameHandler}
      />
    </>
  );
};

export default ChannelItemChat;
