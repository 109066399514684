import React from 'react';

import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

import { PROFILE_TYPES } from 'lib/constants';
import { useSelector } from 'store';
import { isUserOnline } from 'store/selectors/usersSelectors';

import {
  Item,
  ItemButton,
  ItemImageContainer,
  ItemInfoWrapper,
  ItemInfoTitle,
  ItemInfoDescription,
  DividerStyled,
} from './ItemChatStyles';
import ProfileImage from '../Common/ProfileImage/ProfileImage';

//#region TYPES
type PersonItemChatProps = {
  chatRoom: ChatRoomInfo;
  onClick: (chatRoom: ChatRoomInfo) => void;
};
//#endregion

// #region STYLES
const StatusIcon = styled.i<{ userOnline: boolean }>`
  svg {
    width: 10px;
    height: 10px;
    margin-bottom: 5px;
    margin-left: 4px;
    color: ${({ theme, ...props }) =>
      props.userOnline ? theme.colors.success : theme.colors.gray5};
    vertical-align: 0;
  }
`;
// #endregion

const PersonItemChat = (props: PersonItemChatProps) => {
  const { chatRoom, onClick } = props;

  const { room } = chatRoom;
  const chattingWith = room.users[0].user;
  const hasMessage = room.messages.length > 0;
  const eventType = hasMessage && room.messages[0].eventType;
  const hasMedia = hasMessage && room.messages[0].hasMedia;
  const mediaType = hasMessage && room.messages[0].mediaType;

  const userOnline = useSelector(state => isUserOnline(state, chattingWith.userId));

  const lastMessage = () => {
    if (eventType === 1) {
      return `${room.messages[0].description} saiu 😢`;
    }

    if (hasMedia) {
      switch (mediaType) {
        case 1:
          return 'enviou uma imagem 📸';
        case 5:
          return 'mensagem de voz 🎤';
        default:
          return room.messages[0].description;
      }
    }

    return hasMessage && room.messages[0].description;
  };

  const handleItemClick = () => {
    onClick(chatRoom);
  };

  return (
    <Item>
      <ItemButton type="button" onClick={handleItemClick}>
        <ItemImageContainer>
          <ProfileImage
            profile={{
              profileId: chattingWith.userId,
              profileType: PROFILE_TYPES.user,
              name: chattingWith.name,
            }}
            size="sm"
            badge={chatRoom.unreadCount}
          />
        </ItemImageContainer>
        <ItemInfoWrapper>
          <div>
            <ItemInfoTitle>{room.name}</ItemInfoTitle>
            <StatusIcon userOnline={userOnline}>
              <FontAwesomeIcon icon={faCircle} />
            </StatusIcon>
          </div>
          {hasMessage && <ItemInfoDescription>{lastMessage()}</ItemInfoDescription>}
        </ItemInfoWrapper>
      </ItemButton>
      <DividerStyled />
    </Item>
  );
};

export default PersonItemChat;
