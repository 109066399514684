import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import flow from 'lodash/fp/flow';
import { convertFromRaw } from 'draft-js';

import { List, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';

import { useAuth } from 'hooks';
import { mentionReplace, lineBrakeToBr, isJSON, serializeToHtml } from 'lib/helper';
import { getComments, resetComments } from 'store/actions/postsActions';
import { makeGetPostSelector, makeGetPostComments } from 'store/selectors/postSelectors';

import CommentTextEditor from '../../Common/CommentTextEditor';
import Comment from '../Comment/Comment';
import ProfileImage, { profileTypes } from '../../Common/ProfileImage/ProfileImage';

import {
  AvatarWrapper,
  BorderComments,
  CommentsContainer,
  EditorWrapper,
  commentsWrapper,
  editorAvatar,
} from './CommentsStyles';

const getPost = makeGetPostSelector();
const getPostComments = makeGetPostComments();

const Comments = props => {
  const { postId, className = '', commentSinglePost, groupId } = props;
  const { t } = useTranslation();
  const [isCommentLoading, setIsCommentLoading] = useState(false);
  const fromGroup = !!groupId;
  const pageSize = commentSinglePost ? 50 : 2;

  const { userId, userInfo } = useAuth();
  const { name: userName } = userInfo.user;

  // #region REDUX
  const dispatch = useDispatch();
  const comments = useSelector(state => getPostComments(state, postId));
  const count = useSelector(state => getPost(state, postId).commentCount);
  // #endregion

  const page = comments.at(-1)?._pageComment ?? 1;

  const getCommentsHandler = async () => {
    fetchComments(page + 1);
  };

  const fetchComments = useCallback(
    async page => {
      setIsCommentLoading(true);

      try {
        await dispatch(getComments(postId, page, pageSize));
      } finally {
        setIsCommentLoading(false);
      }
    },
    [dispatch, pageSize, postId]
  );

  useEffect(() => {
    dispatch(resetComments(postId));
    fetchComments(1);
  }, [dispatch, fetchComments, postId]);

  const renderNewCommentEditor = () => {
    return (
      <div>
        <AvatarWrapper>
          <ProfileImage
            css={editorAvatar}
            profile={{
              profileId: userId,
              name: userName,
              profileType: profileTypes.user,
            }}
            size="xs"
          />
        </AvatarWrapper>
        <EditorWrapper>
          <CommentTextEditor
            placeholder={t('comments.leaveComment')}
            postId={postId}
            groupId={fromGroup ? groupId : null}
          />
        </EditorWrapper>
      </div>
    );
  };

  return (
    <BorderComments>
      <CommentsContainer className={className}>
        {renderNewCommentEditor()}
        <RenderComments
          comments={comments}
          commentsCount={count}
          isCommentLoading={isCommentLoading}
          userId={userId}
          onGetComments={getCommentsHandler}
        />
      </CommentsContainer>
    </BorderComments>
  );
};

export default Comments;

const RenderComments = ({ comments, commentsCount, isCommentLoading, userId, onGetComments }) => {
  const { t } = useTranslation();

  const shouldNotFetchComments = commentsCount === 0 || comments.length >= commentsCount;

  const isLoading = isCommentLoading && comments.length === 0;
  const loadMore =
    !shouldNotFetchComments && comments.length > 0 ? (
      <Button style={{ marginTop: 12 }} loading={isCommentLoading} onClick={onGetComments} block>
        {t('comments.moreComments')}
      </Button>
    ) : null;

  const loading = isLoading
    ? {
        indicator: <FontAwesomeIcon icon={faSpinnerThird} size="3x" spin />,
      }
    : false;

  const getCommentText = comment => {
    if (isJSON(comment.comment.description)) {
      const contentState = convertFromRaw(JSON.parse(comment.comment.description));
      return serializeToHtml(contentState);
    }
    return flow(mentionReplace, lineBrakeToBr)(comment.mentions, comment.comment.description);
  };

  return (
    <List
      css={commentsWrapper}
      loadMore={loadMore}
      dataSource={comments}
      split={false}
      rowKey={comment => comment.comment.commentId}
      loading={loading}
      renderItem={comment => (
        <List.Item>
          <Comment commentText={getCommentText(comment)} fullComment={comment} userId={userId} />
        </List.Item>
      )}
    />
  );
};
