import axios from 'axios';
import { createCancelToken, statusError, CancelCallback } from '../apiActions';

export interface LikeRequestParams {
  itemId: number;
  itemType: ItemTypes;
  userId: number;
}

export type ChangeReactionParams = LikeRequestParams & {
  reactionId: ReactionType;
};

export async function sendLike(
  params: LikeRequestParams,
  cancelCallback?: CancelCallback
): Promise<number | undefined> {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const { status } = await axios.post('/api/like/sendLike', params, { cancelToken });

    return status;
  } catch (error) {
    return statusError(error);
  }
}

export async function removeLike(
  params: LikeRequestParams,
  cancelCallback?: CancelCallback
): Promise<number | undefined> {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const { status } = await axios.delete('/api/like/removeLike', { params, cancelToken });

    return status;
  } catch (error) {
    return statusError(error);
  }
}

export async function changeReaction(
  params: ChangeReactionParams,
  cancelCallback?: CancelCallback
): Promise<number | undefined> {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const { status } = await axios.patch(`/api/Like/changeReaction`, params, {
      cancelToken,
    });

    return status;
  } catch (error) {
    return statusError(error);
  }
}
