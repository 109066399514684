/* eslint-disable default-case */
import produce from 'immer';
import { UIActionType, UIState } from 'store/types/uiTypes';
import { UIActions } from 'store/types/types';

const initialState: Readonly<UIState> = {
  isLoading: false,
  drawer: {
    isDrawerOpen: false,
    content: {},
  },
  siderCollapsed: false,
  siderPinned: true,
  feedWidgets: {
    calendar: true,
    pinnedPosts: true,
    birthdays: true,
    groups: true,
  },
  feed: {
    resultsTabVisible: false,
    counters: {
      newsCount: 0,
      myFeedCount: 0,
    },
  },
};

function addPendingPost(draft: UIState, post: PostInfo) {
  const { postId } = post.post;
  draft.drawer.content[postId] = {
    ...draft.drawer.content[postId],
    ...{ isVisible: true, enabled: true },
  };
}

function uiReducer(state = initialState, action: UIActionType) {
  return produce(state, draft => {
    switch (action.type) {
      // DRAWER ACTIONS
      case UIActions.DRAWER_OPENED:
        draft.drawer.isDrawerOpen = true;
        break;
      case UIActions.DRAWER_CLOSED:
        draft.drawer.isDrawerOpen = false;
        break;
      case UIActions.DRAWER_CONTENT_LOADING_STATE:
        const { isLoading } = action;
        draft.isLoading = isLoading;
        break;
      case UIActions.DRAWER_PENDING_POSTS_FETCHED: {
        const { payload } = action;
        payload.forEach(addPendingPost.bind(null, draft));
        draft.isLoading = false;
        break;
      }
      case UIActions.DRAWER_PENDING_POST_CREATED: {
        const { payload } = action;
        addPendingPost(draft, payload);
        break;
      }
      case UIActions.DRAWER_PENDING_POST_DELETED: {
        const {
          payload: { postId },
        } = action;

        draft.drawer.content[postId] = { ...draft.drawer.content[postId], ...{ isVisible: false } };
        break;
      }
      case UIActions.DRAWER_PENDING_POST_EDIT: {
        const {
          payload: { postId },
        } = action;
        draft.drawer.content[postId] = { ...draft.drawer.content[postId], ...{ isEditing: true } };
        break;
      }
      case UIActions.DRAWER_PENDING_POST_CANCEL_EDIT: {
        const {
          payload: { postId },
        } = action;
        draft.drawer.content[postId] = { ...draft.drawer.content[postId], ...{ isEditing: false } };
        break;
      }
      case UIActions.DRAWER_PENDING_POST_ENABLED: {
        const {
          payload: { postId },
        } = action;
        draft.drawer.content[postId] = { ...draft.drawer.content[postId], ...{ enabled: true } };
        break;
      }
      case UIActions.DRAWER_PENDING_POST_DISABLED: {
        const {
          payload: { postId },
        } = action;
        draft.drawer.content[postId] = { ...draft.drawer.content[postId], ...{ enabled: false } };

        break;
      }
      case UIActions.DRAWER_PENDING_POST_RESET: {
        draft.drawer.content = {};
        break;
      }

      case UIActions.SIDER_COLLAPSED: {
        draft.siderCollapsed = true;
        break;
      }
      case UIActions.SIDER_EXPANDED: {
        draft.siderCollapsed = false;
        break;
      }
      case UIActions.TOGGLE_SIDER_COLLAPSED: {
        draft.siderCollapsed = !draft.siderCollapsed;
        break;
      }
      case UIActions.TOGGLE_SIDER_PINNED: {
        draft.siderPinned = !draft.siderPinned;
        break;
      }
      case UIActions.WIDGET_CONFIG_LOADED: {
        draft.feedWidgets = action.payload;
        break;
      }
      case UIActions.WIDGET_CONFIG_CHANGED: {
        const { payload } = action;
        draft.feedWidgets = { ...draft.feedWidgets, ...payload };
        break;
      }

      case UIActions.FEED_CONFIG_CHANGED: {
        const { payload } = action;
        draft.feed = { ...draft.feed, ...payload };
        break;
      }

      case UIActions.POSTS_COUNT_FETCHED: {
        const { payload } = action;
        draft.feed.counters = { ...draft.feed.counters, ...payload };
        break;
      }
    }
  });
}

export default uiReducer;
