import React from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useTheme } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/pro-solid-svg-icons';

import { useAuth } from 'hooks';

import { iconUploader, iconDisabled } from '../UploaderStyle';

//#region TYPES
export type { FileRejection };

export interface ImageUploaderProps {
  canSendMultiple?: boolean;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  onImageAdd: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
}
//#endregion

function createArray(forbidden: string[]) {
  const acceptedExtensions = [];
  if (!forbidden.some(value => /\.jpe?g/i.test(value))) {
    acceptedExtensions.push('image/jpeg');
  }

  if (!forbidden.includes('.png')) {
    acceptedExtensions.push('image/png');
  }

  if (!forbidden.includes('.gif')) {
    acceptedExtensions.push('image/gif');
  }

  // if (!forbidden.includes('.webp')) {
  //   acceptedExtensions.push('image/webp');
  // }

  return acceptedExtensions.join(', ');
}

function ImageUploader({
  className = '',
  canSendMultiple = false,
  children,
  disabled = false,
  onImageAdd,
}: ImageUploaderProps) {
  const theme = useTheme();
  const { forbiddenExtensions } = useAuth().networkSettings;

  const { getRootProps, getInputProps } = useDropzone({
    accept: createArray(forbiddenExtensions),
    onDrop: onImageAdd,
    maxSize: 100 * 1e6, // Valor a ser multiplicado deve estar e mb
    multiple: canSendMultiple,
    noDrag: true,
    noKeyboard: true,
    disabled,
  });

  return (
    <div
      {...getRootProps({
        className: `${iconUploader(theme)} ${disabled ? iconDisabled(theme) : ''} ${className}`,
      })}
    >
      <input {...getInputProps()} />
      {children ?? <FontAwesomeIcon icon={faImage} />}
    </div>
  );
}

export default ImageUploader;
