import { css } from '@emotion/react';
import { css as cssClass } from '@emotion/css';
import styled from '@emotion/styled';

export const popoverInnerContent = cssClass`
  & .ant-popover-inner-content {
    max-height: 14.1rem;
    overflow: auto;
  }
`;

export const PopoverTitle = styled.div`
  padding: 4px 0;
  margin-left: -4px;

  span {
    margin-left: 0.5em;
  }
`;

export const ShortcutLink = styled.a`
  ${({ theme }) => css`
    display: inline-block;
    padding: 5px;
    margin-bottom: 0.3em;
    border-radius: 4px;
    color: ${theme.colors.gray10};

    :hover {
      color: ${theme.colors.gray10};
      background-color: hsla(0, 0%, 62%, 0.1);
    }
  `}
`;

export const ShortcutWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
