import React from 'react';

import styled from '@emotion/styled';

// #region STYLES
const Button = styled.button`
  z-index: 10;
  padding: 0;
  cursor: pointer;
  background: none;
  border: 0;

  :focus {
    outline: none;
  }
`;
// #endregion

type LikeCommentProps = {
  likeComment: React.ReactNode;
  unLikeComment: React.ReactNode;
  likeState: boolean;
  click: React.MouseEventHandler<HTMLButtonElement>;
};

function LikeComment(props: LikeCommentProps) {
  const { likeComment, unLikeComment, click, likeState } = props;
  return (
    <Button type="button" onClick={click}>
      {likeState ? likeComment : unLikeComment}
    </Button>
  );
}

export default LikeComment;
