import { css } from '@emotion/react';

export const button = css`
  && {
    padding: 0;
    border: 0;
  }

  &.ant-btn-link:not(:hover) {
    color: inherit;
  }
`;
