import styled from '@emotion/styled';
import { Modal } from 'antd';

export const CropperModal = styled(Modal)`
  .ant-modal-body {
    padding: 20px 24px;
  }
  .ant-modal-footer {
    border-top: 0;
  }
`;

export const CropperContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  margin-bottom: 1em;
  background: #333;
`;
