import { css as cssStyle } from '@emotion/css';
import { css, Theme } from '@emotion/react';

import { reactionColors } from './reactionHelper';

export const button = (liked: boolean) => (theme: Theme) =>
  css`
    &.ant-btn-link {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 24px;
      padding: 0;
      margin: 0;
      color: inherit;
      background: none;
      border: 0;
      text-transform: capitalize;
    }

    ${liked &&
    css`
      .text {
        color: ${theme.colors.primary};
      }
    `}
  `;

export const icon = (liked: boolean, reaction: ReactionLiteral) => (theme: Theme) =>
  css`
    margin-right: 8px;

    ${liked &&
    css`
      color: ${reactionColors[reaction].background};
    `}
  `;

export const popover = () => cssStyle`
  .ant-popover-arrow {
    display: none;
  }
`;
