import produce from 'immer';
import { BirthdayActionType, BirthdayState } from '../types/birthdayTypes';
import { BirthdayActions } from '../types/types';

const initialState: Readonly<BirthdayState> = {
  birthdays: [],
};

const birthdayReducer = (state = initialState, action: BirthdayActionType) =>
  produce(state, draft => {
    switch (action.type) {
      case BirthdayActions.BIRTHDAYS_FETCHED:
        draft.birthdays = action.payload;
        break;
      case BirthdayActions.USER_BIRTHDAY_REMOVED:
        const { userId } = action.payload;
        const filtered = state.birthdays.filter(birthday => birthday.userId !== userId);
        draft.birthdays = filtered;
        break;
    }
  });

export default birthdayReducer;
