import { css } from '@emotion/css';

export const iconUploader = (theme: Themes.DefaultTheme) => css`
  display: inline-block;
  color: ${theme.colors.primary};
  cursor: pointer;
`;

export const iconDisabled = (theme: Themes.DefaultTheme) => css`
  font-weight: 600;
  color: ${theme.colors.gray5};
  cursor: default;
`;
