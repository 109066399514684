import { useEffect, useState, useCallback, useMemo } from 'react';

// Hook
function useMedia(queries, values, defaultValue) {
  // Array containing a media query list for each query
  const mediaQueryLists = useMemo(() => queries.map(q => window.matchMedia(q)), [queries]);
  const singleValues = useMemo(() => values, [values]);
  const singleDefaultValue = useMemo(() => defaultValue, [defaultValue]);

  // Function that gets value based on matching media query
  const getValue = useCallback(() => {
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex(mql => mql.matches);
    // Return related value or defaultValue if none
    return typeof singleValues[index] !== 'undefined' ? singleValues[index] : singleDefaultValue;
  }, [mediaQueryLists, singleDefaultValue, singleValues]);

  // State and setter for matched value
  const [value, setValue] = useState(getValue);

  useEffect(() => {
    // Event listener callback
    // Note: By defining getValue outside of useEffect we ensure that it has ...
    // ... current values of hook args (as this hook callback is created once on mount).
    const handler = () => setValue(getValue);
    // Set a listener for each media query with above handler as callback.
    mediaQueryLists.forEach(mql => mql.addListener(handler));
    // Remove listeners on cleanup
    return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return value;
}

export default useMedia;
