import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

export const editorWrapper = (disable: boolean) => (theme: Theme) =>
  css`
    position: relative;
    display: flex;
    flex-flow: row wrap;
    min-height: 32px;
    padding: 5px 10px;
    color: ${theme.colors.gray10};
    background-color: #fff;
    border: 1px solid ${theme.colors.gray4};
    border-radius: 8px;
    outline: none;

    .DraftEditor-root {
      flex: 1;
      align-items: center;
      padding-right: 16px;
      overflow: auto;

      ${theme.typography.fontSmall('20px')}
    }
    .public-DraftEditor-content {
      max-height: 256px;
    }

    ${disable &&
    css`
      color: ${theme.colors.gray8};
      background-color: ${theme.colors.gray1};
    `}
  `;

export const submitHint = (postDeleted: boolean) => (theme: Theme) =>
  css`
    margin-top: 5px;
    color: ${postDeleted ? theme.colors.danger : theme.colors.gray7};

    ${postDeleted ? theme.typography.fontSmall() : theme.typography.fontxSmall()};
  `;

export const submitHintIcon = css`
  margin-right: 8px;
  margin-left: 4px;
`;

export const btnAction = css`
  display: block;
  margin: 8px 0 0;
  margin-left: auto;
`;

export const btnCancel = css`
  ${btnAction};
  margin-right: 8px;
  border: 0;
`;

export const linkAttachment = css`
  margin-top: 6px;
  margin-bottom: 4px;
`;

type EditorCssProps = { isEditing?: boolean };

export const EditorCss = styled.div<EditorCssProps>`
  transition: padding 0.3s ease-out;
  transition-delay: 0.1s;

  ${props =>
    props.isEditing &&
    css`
      padding-bottom: 16px;
      transition: padding 0.3s ease-in;
      transition-delay: 0s;
    `}
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 20px;
  margin-right: 4px;
  margin-left: auto;

  & > div + div {
    margin-left: 12px;
    color: ${({ theme }) => theme.colors.gray8};
    outline: 0;
  }
  & > div > .svg-inline--fa {
    display: block;
    width: 16px;
    height: 16px;
  }
  & > div:hover {
    color: ${({ theme }) => theme.colors.primary5};
  }
`;

export const PreviewContainer = styled.div`
  flex: 1 0 100%;
  padding-top: 4px;
  margin-top: 4px;
  overflow: hidden;
  border-top: 1px dashed ${({ theme }) => theme.colors.gray2};

  :empty {
    display: none;
  }
  .div-preview {
    margin: 4px 0;
  }
`;

export const EditorFooter = styled.div`
  display: flex;
  float: right;
`;
