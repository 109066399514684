import { AUTH_API } from './lib/constants';

const authenticateBusinessPath = `${AUTH_API}/api/Security/AuthenticateBusiness`;
const authenticatePath = `${AUTH_API}/api/Security/Authenticate`;
const authenticateWithKeyPath = `${AUTH_API}/api/Security/AuthenticateWithKey`;
const authenticateSeniorX = `${AUTH_API}/api/Security/authenticateSeniorX`;
const authenticateCas = `${AUTH_API}/api/Security/authenticateCas`;

const routes = {
  authenticateBusinessPath,
  authenticatePath,
  authenticateWithKeyPath,
  authenticateSeniorX,
  authenticateCas,
};

export default routes;
