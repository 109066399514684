import { useCallback, useRef } from 'react';

export default function useCSV() {
  const mailValidationRef = useRef(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  const nameValidationRef = useRef(/^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/);
  const onlyTextValidationRef = useRef(/^[a-zA-Z ]*$/);
  const onlyDigitsValidationRef = useRef(/@"^-?[0-9][0-9,.]+$"/);
  const minimumCharValidationRef = useRef(/^.{3,}$/);

  const validateCsv = useCallback((csvData: any[], columnValidation: RegExp[]) => {
    const csvHeader = csvData[0];
    csvData.shift();
    const result: any[] = [];
    const invalidItems: string[] = [];

    csvData
      .filter((csvItem: any[]) => csvItem.every(value => value !== ''))
      .forEach((csvItem: any[]) => {
        const columnValue: Record<string, any> = {};
        csvItem.forEach((element: string, index: number) => {
          columnValue[csvHeader[index].toLowerCase()] = {
            value: element,
            valid: columnValidation[index]
              ? columnValidation[index].test(element)
              : minimumCharValidationRef.current.test(element),
          };
          if (!columnValidation[index].test(element)) {
            invalidItems.push(element);
          }
        });

        result.push(columnValue);
      });
    return { csvHeader, result, invalidItems };
  }, []);

  return {
    mailValidation: mailValidationRef.current,
    nameValidation: nameValidationRef.current,
    onlyTextValidation: onlyTextValidationRef.current,
    onlyDigitsValidation: onlyDigitsValidationRef.current,
    minimumCharValidation: minimumCharValidationRef.current,
    validateCsv,
  };
}
