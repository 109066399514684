import styled from '@emotion/styled';
import { up } from 'styled-breakpoints';
import { Button } from 'antd';

export const IconButton = styled(Button)`
  position: relative;
  padding: 0;
  margin: 0;

  svg {
    font-size: 1.6rem;
  }

  ${up('tablet')} {
    display: none;
  }
`;

export const BadgeContainer = styled.div`
  position: absolute;
  top: 0;
  right: 3px;
`;
