import React, {
  useReducer,
  useRef,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
  ElementRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import { useAsyncCallback } from 'react-async-hook';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faTrash } from '@fortawesome/pro-solid-svg-icons';

import { Button, message, Modal } from 'antd';
import AsyncSelect from 'react-select/async';
import { ActionMeta, ControlProps, components } from 'react-select';
import type { FilterOptionOption } from 'react-select/dist/declarations/src/filters';

import { useAuth, useReactSelectStyles } from 'hooks';
import { addUserToRoom, getRoom } from 'lib/apiActions';
import { mention as mentionApi, chat as chatApi } from 'lib/api';
import { PROFILE_TYPES } from 'lib/constants';
import { roomUpdated } from 'store/actions/chatActions';
import UsersList from 'components/layout/Common/UsersList/UsersList';
import type { PersonItemProps } from 'components/layout/Common/Modal/PersonList/PersonItem';
import * as S from './AddChannelMemberModalStyles';

//#region TYPES
interface AddChannelMemberModalState {
  members: Option[];
  optionSelected: boolean;
}
interface SelectMembers {
  type: 'selectMembers';
  membersSelected: Readonly<Option[]>;
}
interface OptionSelected {
  type: 'optionSelected';
  value: boolean;
}
interface ResetState {
  type: 'resetState';
}
type Action = SelectMembers | OptionSelected | ResetState;

type Option = {
  value: number;
  label: string;
};

type AddChannelMemberModalProps = {
  chatRoom: ChatRoom;
  visible: boolean;
  onClose: () => void;
};
//#endregion

// #region REDUCER
const initialState: Readonly<AddChannelMemberModalState> = {
  members: [],
  optionSelected: false,
};

const reducer = (state: AddChannelMemberModalState, action: Action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'selectMembers':
        draft.members = [...action.membersSelected];
        break;
      case 'optionSelected':
        draft.optionSelected = action.value;
        break;
      case 'resetState':
        return initialState;
    }
  });

// #endregion

async function getMembers(name: string) {
  const params = { name };
  const result = await mentionApi.getUserList(params);

  if (!result.ok) return [];

  const mentions = result.data;

  if (!mentions) return [];

  return mentions.map(mention => ({
    value: mention.userId,
    label: mention.name,
  }));
}
const getMembersDebounced = AwesomeDebouncePromise(getMembers, 400);

function AddChannelMemberModal({ chatRoom, visible, onClose }: AddChannelMemberModalProps) {
  const { t } = useTranslation('chat');
  const { userId } = useAuth();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);
  const reduxDispatch = useDispatch();
  const selectRef = useRef<ElementRef<typeof AsyncSelect<Option, true>>>(null);

  const { styles: customSelectStyles } = useReactSelectStyles({ borderRadius: 8 });

  // Scroll members select input to bottom when overflow
  useEffect(() => {
    if (selectRef.current && state.optionSelected) {
      const controlRef = selectRef.current.controlRef;
      if (controlRef) controlRef.scrollTop = controlRef.scrollHeight;
    }
    dispatch({ type: 'optionSelected', value: false });
  }, [state.optionSelected]);

  useLayoutEffect(() => {
    if (selectRef.current && visible) selectRef.current.focus();
  });

  const filterOption = (candidate: FilterOptionOption<any>) => {
    return !chatRoom.users.some(chatUser => chatUser.user.userId === parseInt(candidate.value, 10));
  };

  const usersList = useMemo(
    () =>
      chatRoom.users
        .filter(user => user.user.userId !== userId)
        .map(user => ({
          userId: user.user.userId,
          name: user.user.name,
          profileType: PROFILE_TYPES.user,
          mediaId: user.user.mediaId,
          occupation: user.user.occupation?.description,
          department: user.user.department?.description,
        })),
    [chatRoom.users, userId]
  );

  const getMembersAsync = useAsyncCallback(getMembersDebounced);
  const handleMembersChange = (
    selectedOptions: readonly Option[],
    { action }: ActionMeta<Option>
  ) => {
    dispatch({
      type: 'selectMembers',
      membersSelected: selectedOptions,
    });

    if (action === 'select-option') {
      dispatch({ type: 'optionSelected', value: true });
    }
  };

  const asyncAddUserToRoom = useAsyncCallback(addUserToRoom);
  const addMembersHandler = async () => {
    setLoading(true);
    const { members } = state;
    const result = await asyncAddUserToRoom.execute(
      chatRoom.roomId,
      members.map(opt => opt.value)
    );

    setLoading(false);

    if (result === 200) {
      await updateRoom();
    } else {
      message.error(t('membersModal.addMembersFail'));
    }
  };

  const deleteMemberHandler = (user: PersonItemProps) => () => {
    Modal.confirm({
      title: t('titleRemoveMember', {
        name: user.name,
      }),
      onOk: async () => {
        setLoading(true);
        const result = await chatApi.deleteUserFromChatRoom({
          roomId: chatRoom.roomId,
          userId: user.userId,
        });

        if (!result.ok) {
          setLoading(false);
          return;
        }

        await updateRoom();

        setLoading(false);
      },
    });
  };

  async function updateRoom() {
    const [chatRoomUpdated] = await getRoom({ roomId: chatRoom.roomId });
    reduxDispatch(roomUpdated(chatRoomUpdated));
    dispatch({ type: 'resetState' });
  }

  const handleCancel = () => {
    dispatch({ type: 'resetState' });
    onClose();
  };

  return (
    <>
      <S.Modal
        visible={visible}
        destroyOnClose
        title={<strong style={{ fontWeight: 600 }}>{t('createChannel.addMembers')}</strong>}
        cancelButtonProps={{ disabled: asyncAddUserToRoom.loading }}
        footer={
          <Button type="primary" onClick={handleCancel}>
            {t('default:done')}
          </Button>
        }
        onCancel={handleCancel}
        style={{ top: 20 }}
        width={540}
      >
        <S.InputWrapper>
          <AsyncSelect<Option, true>
            components={{ DropdownIndicator: null, Control }}
            styles={customSelectStyles}
            cacheOptions
            defaultOptions
            inputId="members"
            isLoading={getMembersAsync.loading}
            isMulti
            placeholder={t('createChannel.addMembersPlaceholder')}
            loadOptions={getMembersAsync.execute}
            filterOption={filterOption}
            menuPlacement="auto"
            value={state.members}
            ref={selectRef}
            onChange={handleMembersChange}
          />
          <Button type="primary" size="large" onClick={addMembersHandler}>
            {t('default:common.toAdd')}
          </Button>
        </S.InputWrapper>

        <div>
          <UsersList
            loading={loading}
            users={usersList}
            renderActions={user => [
              <Button
                type="text"
                icon={<FontAwesomeIcon icon={faTrash} />}
                onClick={deleteMemberHandler(user)}
              />,
            ]}
          />
        </div>
      </S.Modal>
    </>
  );
}

export default AddChannelMemberModal;

function Control({ children, ...props }: ControlProps<any, boolean>) {
  return (
    <components.Control {...props}>
      {children}
      <span style={{ padding: '0 8px' }}>
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </span>
    </components.Control>
  );
}
