import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { debounce } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

// ACTIONS

// GLOBAL COMPONENTS
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserLock } from '@fortawesome/pro-solid-svg-icons';
import AsyncSelect from 'react-select/async';

import { addTarget } from 'store/actions/newPostActions';

//#region STYLES
const iconCss = theme => css`
  height: 24px;
  margin-right: 8px;
  font-size: 12px;
  color: ${theme.colors.gray8};
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 16px;
`;
//#endregion

const fetch = (inputValue, callback) => {
  const params = { name: inputValue };
  axios.get(`/api/Mention/getUserList`, { params }).then(({ data }) => {
    if (data) {
      const newSuggestions = data.map(mention => ({
        value: mention.userId,
        label: mention.name,
      }));
      callback(newSuggestions);
    } else {
      callback([]);
    }
  });
};
const debouncedFetch = debounce(300, fetch);

function useCustomStyles() {
  const theme = useTheme();

  const customSelectStyles = {
    clearIndicator: props => ({ ...props, cursor: 'pointer', padding: '2px 4px' }),
    container: props => ({ ...props, margin: '-4px 0', flexGrow: 1 }),
    control: props => ({ ...props, border: 0, boxShadow: 'none', minHeight: '24px' }),
    dropdownIndicator: props => ({ ...props, cursor: 'pointer', padding: '2px 4px' }),
    group: props => ({ ...props, border: '1px solid red' }),
    groupHeading: props => ({ ...props, border: '1px solid red' }),
    indicatorContainer: props => ({ ...props, padding: '2px 4px' }),
    indicatorsContainer: props => ({ ...props }),
    indicatorSeparator: props => ({ ...props }),
    input: props => ({ ...props, margin: '2px 0', padding: '1px 0' }),
    loadingIndicator: props => ({ ...props }),
    loadingMessage: props => ({ ...props, fontSize: '0.79rem' }),
    menu: props => ({ ...props }),
    menuList: props => ({ ...props }),
    menuPortal: props => ({ ...props, border: '1px solid blue' }),
    multiValue: props => ({
      ...props,
      fontSize: '0.79rem',
      margin: '2px 4px 2px 0',
      borderRadius: '4px',
      backgroundColor: theme.colors.gray10,
      color: '#fff',
    }),
    multiValueLabel: props => ({ ...props, lineHeight: '22px', padding: '0 4px', color: '#fff' }),
    multiValueRemove: props => ({
      ...props,
      lineHeight: '22px',
      padding: '0 4px',
      cursor: 'pointer',
    }),
    noOptionsMessage: props => ({ ...props, fontSize: '0.79rem' }),
    option: (props, state) => {
      const { isFocused } = state;
      return {
        ...props,
        fontSize: '0.79rem',
        lineHeight: '22px',
        fontWeight: 600,
        padding: '4px 8px',
        backgroundColor: isFocused ? theme.colors.primary1 : 'none',
      };
    },
    placeholder: props => ({ ...props, fontSize: '0.79rem' }),
    singleValue: props => ({ ...props, border: '1px solid red' }),
    valueContainer: props => ({ ...props, fontSize: '0.79rem' }),
  };

  return customSelectStyles;
}

const PostScopeSelection = ({ storeKey }) => {
  const selectRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customSelectStyles = useCustomStyles();

  useEffect(() => {
    const { current: asyncSelect } = selectRef;
    asyncSelect.focus();
  }, []);

  const onChange = selectedOptions => {
    if (!selectedOptions) return;

    const targetIds = selectedOptions.map(option => option.value);
    dispatch(addTarget(storeKey, targetIds));
  };

  return (
    <Wrapper>
      <FontAwesomeIcon css={iconCss} icon={faUserLock} />
      <AsyncSelect
        styles={customSelectStyles}
        ref={selectRef}
        isMulti
        cacheOptions
        onChange={onChange}
        defaultOptions
        loadOptions={debouncedFetch}
        placeholder={t('new-post.visibility.people-selection-placeholder')}
      />
    </Wrapper>
  );
};

export default PostScopeSelection;
