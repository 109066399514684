const chatPt = {
  channelInfo: 'Informações do canal',
  channelSection: 'Canais',
  contentDeleteText:
    'Você tem certeza que deseja excluir desse canal? A exclusão de um canal é uma ação irreversível, todos os membros perderão acesso a ele. Todos os arquivos e conversas serão perdidos',
  contentLeaveText: 'Você tem certeza que deseja sair desse canal?',
  create: 'Criar',
  emptySearch: 'Nenhum resultado',
  inputPlaceholder: 'Conversar com {{name}}',
  leftMessage: '{{name}} saiu 😢',
  members: 'Participantes',
  messageEmptyChat: 'Para iniciar uma conversa pesquise um usuário ou crie um canal.',
  okDeleteText: 'Sim, excluir',
  okLeaveText: 'Sim, sair',
  searchPlaceholder: 'Busque por pessoas ou canais',
  titleDeleteText: 'Deseja excluir esse canal?',
  titleLeaveText: 'Deseja sair desse canal?',
  titleRemoveMember: 'Deseja remover {{name}} do canal?',
  titleSection: 'Conversas',
  createChannel: {
    addImage: 'Adicionar foto',
    addMembers: 'Adicionar/remover pessoas',
    addMembersPlaceholder: 'Pedro, Mariana, Ana, João...',
    changeChannelName: 'Alterar nome do canal',
    channelImage: 'Foto do canal',
    channelName: 'Nome do canal',
    channelNamePlaceholder: 'Pode ser marketing,comercial,manutenção...',
    create: 'Criar um canal',
    description:
      'Os canais funcionam para conversas em grupo. Conecte-se com seus colegas e inicie uma conversa',
    messages: {
      channelNameChanged: 'Nome do canal alterado com sucesso',
      channelNameError: 'Um problema foi encontrado alterar o nome do canal',
    },
  },
  membersModal: {
    addMembersFail: 'Não foi possível adicionar pessoas a esse canal',
    createdBy: 'Criado por',
    members: '{{count}} Participante',
    members_plural: '{{count}} Participantes',
    adms: '{{count}} Administrador',
    adms_plural: '{{count}} Administradores',
    message: 'Mensagem',
  },
};

export default chatPt;
