import { css } from '@emotion/react';

export const baseButton = css`
  font-weight: bold;
`;

export const transparentButton = css`
  padding: 0;
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;
`;

export const basePrimaryLinkButton = (props: Themes.PropsWithTheme) => css`
  &.ant-btn-link {
    color: ${props.theme.colors.primary};
  }
  &.ant-btn-link :hover {
    color: ${props.theme.colors.primary5};
  }
  &.ant-btn-link :focus {
    color: ${props.theme.colors.primary6};
  }
`;

export const baseNavigationLinkButton = (props: Themes.PropsWithTheme) => css`
  color: ${props.theme.colors.blue};

  :hover,
  :active,
  :focus {
    color: ${props.theme.colors.blue7};
  }

  &.ant-btn-link {
    color: ${props.theme.colors.blue};
  }

  &.ant-btn-link:hover,
  &.ant-btn-link:active,
  &.ant-btn-link:focus {
    color: ${props.theme.colors.blue7};
  }
`;

export const baseNavigationLink = (props: Themes.PropsWithTheme) => css`
  ${baseNavigationLinkButton(props)}
  padding: 0;
`;

export const baseCancelLinkButton = (props: Themes.PropsWithTheme) => css`
  &.ant-btn-link {
    color: ${props.theme.colors.gray10};
  }

  &.ant-btn-link:hover {
    color: ${props.theme.colors.gray9};
  }
  &.ant-btn-link:focus {
    color: ${props.theme.colors.gray10};
  }
`;
