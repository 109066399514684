import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Popover, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'hooks';
import { PROFILE_TYPES } from 'lib/constants';
import { logout } from 'store/actions/authActions';

// COMPONENTS
import ProfileImage from '../../Common/ProfileImage/ProfileImage';

// MODAL
import SettingsModal from '../../../modal/SettingsModal/Settings';

import { AvatarButton, menu } from './ProfileAvatarStyles';

type ProfileMenuProps = {
  userId: number;
  closePopover: () => void;
};

const ProfileMenu = ({ userId, closePopover }: ProfileMenuProps) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);

  const handleCancel = useCallback(() => setModalVisible(false), []);

  return (
    <>
      <Menu css={menu} selectable={false} onClick={closePopover}>
        <Menu.Item key="profile">
          <Link to={`/users/${userId}`}>{t('common.viewProfile')}</Link>
        </Menu.Item>
        <Menu.Item key="settings" onClick={() => setModalVisible(true)}>
          {t('common.settings')}
        </Menu.Item>
        <Menu.Item key="logout" onClick={() => dispatch(logout())}>
          {t('common.logout')}
        </Menu.Item>
      </Menu>
      <SettingsModal visible={modalVisible} onCancel={handleCancel} />
    </>
  );
};
const ProfileAvatar = () => {
  const { userInfo } = useAuth();
  const { user } = userInfo;
  const [visible, setVisible] = useState(false);

  const close = () => setVisible(false);

  if (!user) return null;
  return (
    <Popover
      content={<ProfileMenu userId={user.userId} closePopover={close} />}
      visible={visible}
      onVisibleChange={setVisible}
      title={null}
      placement="bottomRight"
      arrowPointAtCenter
      trigger="click"
    >
      <AvatarButton id="profile-menu" type="button">
        <ProfileImage
          profile={{
            profileId: user.userId,
            name: user.name,
            profileType: PROFILE_TYPES.user,
          }}
          size="xs"
        />
      </AvatarButton>
    </Popover>
  );
};

export default ProfileAvatar;
