export default {
  passwordStep: {
    description: 'You can also access your network via unique code via email.',
    emailWith: 'In the e-mail with the beginning <1>{{value}}</1>',
    title: 'Enter your password',
    keepMeConnected: 'Keep me logged in',
    minChars: 'Enter your password',
  },
  usernameStep: {
    continue: 'Continue',
    description: 'Hello! To start your session, enter your username or email.',
    title: 'Sign In',
    userEmail: 'Username/E-mail',
    errors: {
      404: "We couldn't find an account with this username.",
      '404_description': 'Contact your network administrator if it was not the case.',
      500: "There was a problem, we couldn' complete the process.",
      '500_description': 'Try again later or contact your network administrator.',
    },
  },
  verificationStep: {
    description_email:
      'We sent the token to your e-mail <1>{{value}}</1>. Enter the token to sign in.',
    description_phone:
      'We sent a sms with the token to the phone number <1>{{value}}</1>. Enter the token to sign in.',
    enterCode: 'Enter token',
    resendCode: 'Resend token',
    title: 'Inform the token',
    errors: {
      401: 'The entered code id inválid. Request a new code and try again.',
      500: "There was a problem, we couldn' complete the process.",
    },
  },
};
