import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spin } from 'antd';
import type { Canceler } from 'axios';

import { PROFILE_TYPES } from 'lib/constants';
import { useSelector } from 'store';
import { getLikes } from 'store/actions/postsActions';
import { makeSelectLikes } from 'store/selectors/postSelectors';

import UsersList from '../UsersList/UsersList';
import { PersonItemProps } from '../Modal/PersonList/PersonItem';

type LikesListProps = {
  itemId: number;
  itemType: ItemTypes;
};

let cancel: Canceler;
const selectLikes = makeSelectLikes();

const LikesList = ({ itemId, itemType }: LikesListProps) => {
  const likes = useSelector(state => selectLikes(state, itemId, itemType));
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const likesParams = { itemId, itemType };

    // @ts-expect-error
    dispatch(getLikes(likesParams, c => (cancel = c))).finally(() => setLoading(false));

    return () => {
      cancel?.('getUsersWhoLiked request canceled by LikesList component unmounted');
    };
  }, [dispatch, itemId, itemType]);

  const users: PersonItemProps[] = useMemo(
    () =>
      (likes ?? []).map(({ user }: { user: User }) => ({
        userId: user.userId,
        name: user.name,
        profileType: PROFILE_TYPES.user,
        mediaId: user.mediaId,
        occupation: user.occupation?.description,
        department: user.department?.description,
      })),
    [likes]
  );

  return (
    <Spin spinning={loading}>
      <UsersList users={users} />
    </Spin>
  );
};

export default LikesList;
