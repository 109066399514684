import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// GLOBAL COMPONENTS
import { ClassNames } from '@emotion/react';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { Input, AutoComplete } from 'antd';
import { Link, LinkProps } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faCircleNotch } from '@fortawesome/pro-regular-svg-icons';

// GLOBAL UTILS
import { debounce, isEmpty } from 'lodash/fp';
import escapeStringRegexp from 'escape-string-regexp';
import axios from 'axios';
import { rgba, clearFix } from 'polished';

// UTILS
import { PROFILE_TYPES } from 'lib/constants';
import useAuth from 'hooks/useAuth';

// LOCAL COMPONENTS
import ProfileImage from 'components/layout/Common/ProfileImage/ProfileImage';

//#region TYPES
interface TitleProps {
  seeMore: LinkProps['to'];
  onClickSeeMore?: LinkProps['onClick'];
  count: number;
  children?: React.ReactNode;
  loading?: boolean;
}

interface ProfileOptionProps {
  profileId: number;
  value: string;
  description?: string;
  profileType?: ProfileTypes;
  query?: string;
  phoneExtension?: string;
  mediaId?: number;
}

interface NotFoundOptionProps {
  profileType: ProfileTypes;
}

interface UserSearchResult {
  usersCount: number;
  users: ProfileResult[];
}
interface GroupSearchResult {
  groupsCount: number;
  groups: ProfileResult[];
}
interface ProfileResult {
  description?: string;
  profileId: number;
  to: string;
  profileType: ProfileTypes;
  value: string;
  phoneExtension?: string;
}

interface ProfileData {
  children: ProfileResult[];
  count: number;
  profileType: ProfileTypes;
  seeMorePath: string;
  title: string;
}
//#endregion

// #region STYLES
const Wrapper = styled.div`
  width: 280px;
  margin-right: 20px;
`;
const SearchInput = styled(Input)`
  && {
    height: 33px;
    padding-left: 12px !important;
    background-color: ${({ theme }) => theme.colors.gray3};
    border: 0;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0);
  }

  .ant-input {
    color: ${({ theme }) => theme.colors.gray10};
    background-color: transparent;
    border: 0;
  }
  .ant-input:focus {
    box-shadow: none;
  }

  .ant-input-prefix {
    left: 0;
  }
  .anticon {
    vertical-align: -0.185rem;
  }
  :focus-within {
    border: 1px solid ${({ theme }) => theme.colors.primary5};
    box-shadow: 0 0 0 2px ${({ theme }) => rgba(theme.colors.primary5, 0.2)};
  }
`;

const OptionValue = styled.div`
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray9};

  .highlight {
    padding: 0;
    background-color: ${({ theme }) => theme.colors.primary2};
  }
`;
const OptionOccupation = styled.div`
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.gray7};
`;
// #endregion

const Title: React.VFC<TitleProps> = ({ seeMore, children, onClickSeeMore, count, loading }) => {
  const { t } = useTranslation();

  const renderExtra = () => {
    if (loading) {
      return (
        <div style={{ float: 'right' }}>
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
      );
    }
    if (count > 5) {
      <Link to={seeMore} onClick={onClickSeeMore} style={{ float: 'right' }}>
        {t('common.seeMore', { count })}
      </Link>;
    }
  };

  return (
    <div
      className={css`
        ${clearFix()}
      `}
    >
      {renderExtra()}
      {children}
    </div>
  );
};
const ProfileOption: React.VFC<ProfileOptionProps> = ({
  profileId,
  value,
  description,
  profileType = PROFILE_TYPES.user,
  query,
  phoneExtension,
  mediaId,
}) => (
  <Link
    to={`/${profileType === PROFILE_TYPES.user ? 'users' : 'groups'}/${profileId}`}
    style={{ display: 'flex' }}
  >
    <ProfileImage
      style={{ marginRight: 8 }}
      profile={{ profileId, name: value, profileType, mediaId }}
    />
    <div>
      <OptionValue>
        <Highlighter
          searchWords={[escapeStringRegexp(query ? query : '')]}
          textToHighlight={value}
          highlightClassName="highlight"
        />
      </OptionValue>
      <OptionOccupation>{description}</OptionOccupation>
      {phoneExtension && <OptionOccupation>Ramal: #{phoneExtension}</OptionOccupation>}
    </div>
  </Link>
);
const NotFoundOption: React.VFC<NotFoundOptionProps> = ({ profileType }) => {
  const { t } = useTranslation();
  let message;

  switch (profileType) {
    case PROFILE_TYPES.user:
      message = t('header.searchNoUsers');
      break;
    case PROFILE_TYPES.group:
      message = t('header.searchNoGroups');
      break;
    default:
      message = t('header.searchEmpty');
  }

  return <div>{message}</div>;
};

const SearchBox = () => {
  const { networkId } = useAuth();
  // STATE
  const [dataSet, setData] = useState<Array<ProfileData>>([]);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);

  // HOOKS
  const { t } = useTranslation();

  const searchUsers = useCallback(
    async (term: string): Promise<UserSearchResult> => {
      try {
        const { data, status } = await axios.get<UserFetchData>('api/profile/getUser', {
          params: { name: term, networkId, pageSize: 5 },
        });
        const { users, totalCount } = data;
        if (status === 204 || users.length === 0) return { usersCount: 0, users: [] };

        return {
          usersCount: totalCount || 0,
          users: users.map(({ user, occupation }) => ({
            description: occupation && occupation.description,
            profileId: user.userId,
            to: `/users/${user.userId}`,
            profileType: PROFILE_TYPES.user,
            value: user.name,
            phoneExtension: user.phoneExtension,
          })),
        };
      } catch {
        return {} as UserSearchResult;
      }
    },
    [networkId]
  );

  const searchGroups = useCallback(
    async (term: string): Promise<GroupSearchResult> => {
      try {
        const { data, status } = await axios.get<Pages>('api/profile/getPage', {
          params: { name: term, networkId, pageSize: 5 },
        });
        const { pages, totalCount } = data;
        if (status === 204 || pages.length === 0) return { groupsCount: 0, groups: [] };

        return {
          groupsCount: totalCount,
          groups: pages.map(({ page: { pageId, name, type } }) => ({
            description: type,
            profileId: pageId,
            profileType: PROFILE_TYPES.group,
            to: `/groups/${pageId}`,
            value: name,
          })),
        };
      } catch {
        return {} as GroupSearchResult;
      }
    },
    [networkId]
  );

  const search = useMemo(
    () =>
      debounce(300, async (term: string) => {
        if (isEmpty(term)) {
          setData([]);
          return;
        }
        setLoading(true);

        const [usersResult, groupsResult] = await Promise.all([
          searchUsers(term),
          searchGroups(term),
        ]);
        const { users, usersCount } = usersResult;
        const usersData: ProfileData = {
          children: users,
          count: usersCount,
          profileType: PROFILE_TYPES.user,
          seeMorePath: `/users?q=${term}`,
          title: t('common.people'),
        };

        const { groups, groupsCount } = groupsResult;
        const groupsData: ProfileData = {
          children: groups,
          count: groupsCount,
          profileType: PROFILE_TYPES.group,
          seeMorePath: `/groups?q=${term}`,
          title: t('common.groups'),
        };

        setData([usersData, groupsData]);
        setLoading(false);
      }),
    [searchGroups, searchUsers, t]
  );

  useEffect(() => {
    search(query);
  }, [query, search]);

  //#region RENDERS
  const renderTitle = (data: ProfileData) => (
    <Title
      onClickSeeMore={() => {
        setData([]);
        setQuery('');
      }}
      seeMore={data.seeMorePath}
      count={data.count}
      loading={loading}
    >
      <>
        {data.title}
        {data.count <= 0 && <NotFoundOption {...data} />}
      </>
    </Title>
  );

  const renderOptions = (data: ProfileData) =>
    data.children.map(option => ({
      value: option.profileId,
      label: <ProfileOption {...option} query={query} />,
    }));

  const options = dataSet.map(data => ({
    label: renderTitle(data),
    options: renderOptions(data),
  }));
  //#endregion

  return (
    <Wrapper>
      <ClassNames>
        {({ css }) => (
          <AutoComplete
            allowClear
            options={options}
            dropdownMatchSelectWidth={false}
            onSearch={setQuery}
            onSelect={() => setQuery('')}
            value={query}
            className={css`
              width: 100%;
            `}
            dropdownClassName={css`
              padding-bottom: 6px;

              .ant-select-dropdown-menu {
                max-width: 280px;
                max-height: 480px;
              }
            `}
          >
            <SearchInput
              placeholder={t('header.searchPlaceholder')}
              prefix={
                <FontAwesomeIcon
                  icon={loading ? faCircleNotch : faSearch}
                  style={{ fontSize: 18 }}
                  spin={loading}
                  className="anticon"
                />
              }
            />
          </AutoComplete>
        )}
      </ClassNames>
    </Wrapper>
  );
};

export default SearchBox;
