import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';

import {
  en,
  pt,
  adminEn,
  adminPt,
  chatEn,
  chatPt,
  feedEn,
  feedPt,
  loginEn,
  loginPt,
  onboardingEn,
  onboardingPt,
  settingsEn,
  settingsPt,
  usersEn,
  usersPt,
  widgetEn,
  widgetPt,
} from './translation';

const resources = {
  en: {
    default: en,
    admin: adminEn,
    chat: chatEn,
    feed: feedEn,
    login: loginEn,
    onboarding: onboardingEn,
    settings: settingsEn,
    users: usersEn,
    widget: widgetEn,
  },
  pt: {
    default: pt,
    admin: adminPt,
    chat: chatPt,
    feed: feedPt,
    login: loginPt,
    onboarding: onboardingPt,
    settings: settingsPt,
    users: usersPt,
    widget: widgetPt,
  },
};

i18n
  .use(intervalPlural)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: ['en', 'pt'],
    debug: false,
    defaultNS: 'default',

    interpolation: {
      escapeValue: false,
    },

    react: {
      useSuspense: true,
    },
    detection: {
      order: ['localStorage', 'navigator'],
    },
  });

export default i18n;
