import produce from 'immer';
import { NotificationPanelActions } from '../types/types';

const initialState = {
  notificationList: {},
  isLoading: true,
  counters: 0,
  listIndex: [],
  countGetNotifications: 0,
};

const getKey = notification => {
  const { itemId, itemType, type, relationType } = notification.notification;
  return `${itemId}${itemType}${type}${relationType}`;
};

const addNotification = (draft, notification) => {
  const notificationId = getKey(notification);
  draft.notificationList[notificationId] = notification;
};

const notificationsPanelReducer = (state = initialState, { type, payload }) =>
  produce(state, draft => {
    // eslint-disable-next-line default-case
    switch (type) {
      case NotificationPanelActions.FETCH_NOTIFICATIONS: {
        const { list } = payload;
        list.forEach(addNotification.bind(null, draft));
        // draft.notificationList = payload.list;
        draft.isLoading = false;
        draft.listIndex = payload;
        break;
      }
      case NotificationPanelActions.FETCHING_NOTIFICATIONS:
        draft.isLoading = true;
        break;
      case NotificationPanelActions.FETCH_COMPLETED:
        draft.isLoading = false;
        break;
      case NotificationPanelActions.FETCH_COUNT:
        draft.counters = payload.unreadCount;
        break;
      case NotificationPanelActions.VIEWED:
        draft.counters = 0;
        break;
      case NotificationPanelActions.CLEAR:
        draft.notificationList = {};
        draft.isLoading = true;
        break;
      case NotificationPanelActions.RELOAD_NOTIFICATIONS:
        draft.countGetNotifications = state.countGetNotifications + 1;
        break;
    }
  });

export default notificationsPanelReducer;
