import { Theme, css } from '@emotion/react';
import styled from '@emotion/styled';

export const input =
  (error: boolean = false) =>
  (theme: Theme) =>
    css`
      && {
        ${error ? `border: 1px solid ${theme.colors.danger}` : ''};
      }
    `;

export const Wrapper = styled.div`
  h1,
  label {
    font-weight: 600;
  }

  p {
    color: #575757;
  }

  .ant-alert-message {
    font-weight: 600;
  }
`;

export const Logo = styled.img`
  display: block;
  max-height: 45px;
  margin-block-end: 0.5rem;
  margin: 0 auto;
  margin-bottom: 23px;
`;
