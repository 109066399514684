import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { shade } from 'polished';

export const Nav = styled.nav`
  display: flex;
  justify-content: flex-end;
`;
export const NavButton = styled.button<{ listVisible: boolean }>`
  ${({ theme, ...props }) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 63px;
    padding: 0;
    color: ${props.listVisible ? theme.colors.primary : theme.colors.gray10};
    cursor: pointer;
    background: none;
    border: 0;
    border-radius: 8px;
    outline: none !important;
    transition: all 0.2s ease-out;

    @media screen and (max-width: 1024px) {
      width: 40px;
      font-size: 20px;
    }

    .anticon {
      font-size: 20px;
    }

    &:hover {
      color: ${props.listVisible ? shade(0.1, theme.colors.primary) : theme.colors.gray10};
      transition: all 0.1s ease-in;

      .anticon {
        transition: all 0.1s ease-in;
        transform: scale(1.1);
      }
    }

    &:active .anticon {
      transform: scale(1);
    }

    .ant-badge {
      color: inherit;
    }
  `}
`;
export const NotificationHeader = styled.div`
  padding: 4px 0;
  margin-left: -4px;
`;
