import { css } from '@emotion/react';
import styled from '@emotion/styled';

const displayNone = css`
  display: none;
`;

const imgScale = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const thumbnail = css`
  width: 60px;
  height: 49px;
`;

export const Preview = styled.div<{ visible?: boolean }>`
  display: flex;
  padding: 14px 12px;
  background-color: #fff;
  border-top: solid 1px #e3e3e3;
  ${props => !props.visible && displayNone}
`;

export const ImageWrapper = styled.div`
  position: relative;
  ${thumbnail}

  & + & {
    margin-left: 4px;
  }
  & img {
    ${imgScale}
    border-radius: 4px;
  }
`;

export const PreviewContainer = styled.div`
  ${({ theme }) => css`
    max-width: 60px;
    color: ${theme.colors.gray10};
    ${theme.typography.fontxxSmall()};
  `}
`;

export const IconWrapper = styled.div`
  ${thumbnail};
  ${({ theme }) => css`
    position: relative;
    padding: 0.8em;
    color: ${theme.colors.primary};
    border: 1px solid ${theme.colors.gray4};
    border-radius: 4px;

    svg {
      font-size: 2rem;
    }
  `}
`;

export const Button = styled.button`
  position: absolute;
  top: 4px;
  right: 4px;
  width: 12px;
  height: 12px;
  padding: 0 0 0 0;
  line-height: 0;
  color: #fff;
  text-align: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.danger};
  border: 0;
  border-radius: 50%;
  outline: none;

  :hover {
    background-color: ${({ theme }) => theme.colors.danger7};
  }
`;
