import { css } from '@emotion/react';
import { up } from 'styled-breakpoints';

type BasePostsContentProps = { siderCollapsed?: boolean } & Themes.PropsWithTheme;

export const siderMargin = (props: BasePostsContentProps) =>
  css`
    transition: margin-left 0.2s;

    ${up('tablet')(props)} {
      margin-left: 60px;
    }

    ${up('notebook')(props)} {
      margin-left: ${props.siderCollapsed == null
        ? '120px'
        : props.siderCollapsed
        ? '120px'
        : '320px'};
    }
  `;

export const basePostsContent = (props: BasePostsContentProps) => css`
  display: flex;
  flex: auto;
  justify-content: center;

  ${siderMargin(props)}
`;

export const basePostsInnerContent = (props: Themes.PropsWithTheme) => css`
  position: relative;
  flex: auto;
  width: 100%;
  max-width: 552px;
  margin: 1.25rem;

  ${up('smDesktop')(props)} {
    max-width: 720px;
  }
`;

export const baseDescriptor = ({ theme }: Themes.PropsWithTheme) => css`
  margin-top: 32px;
  font-weight: 600;
  color: ${theme.colors.gray9};

  ${theme.typography.fontNormal()};
`;

export const backTop = (theme: Themes.DefaultTheme) => css`
  right: 20%;

  ${up('lgTablet')({ theme })} {
    right: 30%;
  }
`;
