import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { reactionColors } from './reactionHelper';

export const icon = (reaction: ReactionLiteral) => css`
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  text-align: center;
  line-height: 1.4;
  color: ${reactionColors[reaction].foreground};
  background-color: ${reactionColors[reaction].background};
  border-radius: 50%;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
