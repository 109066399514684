import axios, { CancelToken } from 'axios';
import { createCancelToken, statusError, CancelCallback } from '../apiActions';

export async function getNewPostsCount(
  params: { profileTypes: ProfileTypes[] },
  cancelCallback?: CancelCallback
): Promise<{ count: number } | number | undefined> {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const { data } = await axios.get('/api/notification/getNewPostsCount', { params, cancelToken });

    return { count: data };
  } catch (error) {
    return statusError(error);
  }
}

export async function getPostsCounters(
  cancelCallback?: CancelCallback
): Promise<{ newsCount: number; myFeedCount: number } | number | undefined> {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const { data } = await axios.get('/api/notification/getPostsCounters', { cancelToken });

    return data;
  } catch (error) {
    return statusError(error);
  }
}

export type MarkAsViewedParams = {
  itemType: ItemTypes;
  itemId?: number;
  fromTypes?: ProfileTypes[];
};

export async function markAsViewed(
  userId: number,
  cancelCallback?: CancelCallback
): Promise<number | undefined>;
export async function markAsViewed(
  userId: number,
  reqData: MarkAsViewedParams,
  cancelCallback?: CancelCallback
): Promise<number | undefined>;
export async function markAsViewed(
  userId: number,
  reqData?: MarkAsViewedParams | CancelCallback,
  cancelCallback?: CancelCallback
): Promise<number | undefined> {
  try {
    let cancelToken: CancelToken | undefined;

    if (!reqData || typeof reqData === 'function') {
      cancelToken = createCancelToken(reqData);

      await axios.put(`/api/Notification/markAsViewed`, undefined, {
        params: { userId, cancelToken },
      });
    } else {
      cancelToken = createCancelToken(cancelCallback);

      await axios.patch('/api/Notification/markAsViewed', reqData, {
        params: { userId, cancelToken },
      });
    }
  } catch (error) {
    return statusError(error);
  }
}
