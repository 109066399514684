import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const Invalid = styled.div<{ invalid: boolean }>`
  color: ${({ theme }) => theme.colors.danger};
  ${({ theme }) => theme.typography.fontxSmall()};

  ${props =>
    !props.invalid &&
    css`
      display: none;
    `}
`;

export const SubmitWrapper = styled.div`
  margin-top: 1.5em;
`;
