import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth } from 'hooks';
import { getCount } from 'store/actions/notificationsPanelActions';
import { authenticateBusiness } from 'store/actions/authActions';
import { getSubdomain } from 'lib/helper';
import { network as networkApi } from 'lib/api';
import { loadWidgetConfig } from 'store/actions/uiActions';

export function useOnline() {
  const { userId, networkId, networkSettings } = useAuth();
  const dispatch: AppDispatch = useDispatch();

  const fetchNotificationCount = useCallback(() => dispatch(getCount(userId)), [dispatch, userId]);

  useEffect(() => {
    if (!userId) {
      dispatch(authenticateBusiness());
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && userId) {
      const storageKey = 'session-token';

      (async function () {
        const result = await networkApi.getNetworkByHost({
          subdomain: getSubdomain(window.location.host),
        });

        if (!result || typeof result !== 'object') {
          sessionStorage.removeItem(storageKey);
          localStorage.removeItem(storageKey);
          return;
        }

        if (result.network.networkId !== networkId) {
          const sessionToken =
            sessionStorage.getItem(storageKey) ?? localStorage.getItem(storageKey);

          window.location.href = `https://${networkSettings.subdomain}.4bee.com.br/#/login/${sessionToken}`;

          sessionStorage.removeItem(storageKey);
          localStorage.removeItem(storageKey);
        }
      })();
    }
  }, [networkId, networkSettings.subdomain, userId]);

  useEffect(() => {
    if (userId) {
      fetchNotificationCount();

      // TODO: Usar focus até implementar o background notification
      window.addEventListener('focus', fetchNotificationCount);
    }

    return () => {
      window.removeEventListener('focus', fetchNotificationCount);
    };
  }, [fetchNotificationCount, userId]);

  useEffect(() => {
    dispatch(loadWidgetConfig());
  }, [dispatch]);
}
