import moment from 'moment';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import { convertFromRaw, EditorState } from 'draft-js';
import qs from 'qs';
import type { AxiosError } from 'axios';
import { trim } from 'lodash/fp';

import { API_MEDIA } from './constants';

export function mentionReplace(mentions: Mention[], postText: string) {
  let text = postText;
  if (mentions && mentions.length > 0) {
    mentions.forEach(m => {
      const profileId = m.oldId ?? m.profileId;
      const pattern = new RegExp(`\\(\\{userid:${profileId}\\}\\)`, 'gi');
      text = text.replace(
        pattern,
        `<a href="#/users/${m.profileId}" class="mention-link">@${m.name}</a>`
      );
    });
  }
  return text;
}

export function getSubdomain(domain: string) {
  return domain.split('.')[0];
}

export function escapeHtml(htmlString: string) {
  return htmlString
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/, '&#039;');
}

export function lineBrakeToBr(text: string) {
  if (!text) {
    return '';
  }
  return text.replace(/\r\n|\r|\n/gm, '<br />');
}

export function fileAsBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const fReader = new FileReader();
    fReader.onloadend = () => resolve(fReader.result as string);
    fReader.onerror = reject;
    fReader.readAsDataURL(blob);
  });
}

export function urlDomain(url: string | undefined) {
  if (url) {
    const domain = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/.exec(url)![0];

    return domain.replace(/https?:\/\//, '');
  }
  return url;
}

// #region Draft JS Helpers
export function serializeToHtml(contentState: import('draft-js').ContentState) {
  const config: import('draft-convert').IConvertToHTMLConfig = {
    blockToHTML: block => {
      if (block.type === 'unstyled') {
        return { start: '', end: '<br />' };
      }
    },
    entityToHTML(entity, originalText) {
      switch (entity.type) {
        case 'mention':
          return (
            <a
              href={`/#/users/${(entity.data.mention as MentionSuggestions).userId}`}
              className="mention-link"
            >
              @{entity.data.mention.name}
            </a>
          );
        case 'LINK':
          return (
            <a href={entity.data.url} target="_blank" className="mention-link" rel="noreferrer">
              {originalText}
            </a>
          );
        default:
          return originalText;
      }
    },
  };

  return convertToHTML(config)(contentState);
}

export function deserializeHtml(
  html: string,
  mentions?: Mention[]
): import('draft-js').ContentState {
  if (mentions) {
    const config: import('draft-convert').IConvertFromHTMLConfig = {
      textToEntity: (text, createEntity) => {
        const result: Array<{
          entity: import('draft-convert').EntityKey;
          offset: number;
          length: number;
          result?: string;
        }> = [];
        text.replace(/\({userid:(\d+)}\)/g, (match, user: string, offset: number) => {
          const userId = parseInt(user, 10);
          const mention = mentions.find(m => {
            const profileId = m.oldId ?? m.profileId;
            return profileId === userId;
          })!;
          const entityKey = createEntity('mention', 'IMMUTABLE', {
            mention: {
              userId: mention.profileId,
              name: mention.name,
            },
          });
          result.push({
            entity: entityKey,
            offset,
            length: match.length,
            result: `@${mention.name}`,
          });
          return '';
        });
        return result;
      },
    };
    return convertFromHTML(config)(html);
  }

  return convertFromHTML(html);
}

export function getEntities(editorState: EditorState, entityType: string | null = null) {
  type SelectedEntity = {
    entityKey: string;
    blockKey: string;
    entity: Draft.DraftModel.Entity.DraftEntityInstance;
  };

  const content = editorState.getCurrentContent();
  const entities: Array<SelectedEntity & { start: number; end: number }> = [];
  content.getBlocksAsArray().forEach(block => {
    let selectedEntity: SelectedEntity;
    block.findEntityRanges(
      character => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity());
          if (!entityType || (entityType && entity.getType() === entityType)) {
            selectedEntity = {
              entityKey: character.getEntity(),
              blockKey: block.getKey(),
              entity: content.getEntity(character.getEntity()),
            };
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        entities.push({ ...selectedEntity, start, end });
      }
    );
  });

  return entities;
}

export function transformEditorContent(content: string, mentions?: Mention[]) {
  let editorContent = null;
  if (isJSON(content)) {
    editorContent = convertFromRaw(JSON.parse(content));
  } else {
    editorContent = deserializeHtml(trim(content), mentions);
  }

  return EditorState.createWithContent(editorContent);
}
// #endregion

// TODO: Persons Type
function getNameArray(persons: any) {
  const nameArray: string[] = [];
  persons.forEach((person: any) => {
    nameArray.push(person.name);
  });
  return nameArray;
}

export function namesList(persons: any, t: import('i18next').TFunction) {
  let names = '';
  let quantity = 0;
  const nameArray = getNameArray(persons);
  if (persons.length > 3) {
    quantity = persons.length - 3;
    const personArray = nameArray.slice(0, 3);
    names = personArray.join(', ');
  } else {
    const lastPostion = persons.length - 1;
    for (let i = 0; i < nameArray.length; i += 1) {
      names += nameArray[i];
      if (nameArray.length > 1) {
        if (i + 1 === lastPostion) {
          names += ` ${t('conjunction.and')} `;
        } else if (i !== lastPostion) {
          names += ', ';
        }
      }
    }
  }

  if (quantity > 0) {
    names += ` ${t('notification.prefix.messageMultiple', { count: quantity })}`;
  }
  return names;
}

export function computedSize(fileSize: number) {
  if (fileSize <= 999999) {
    return `${(fileSize / 1000).toFixed(2)}KB`;
  }
  return `${(fileSize / 1e6).toFixed(2)}MB`;
}

export const fetchMedia = (
  mediaId: number,
  token?: string | Nullable,
  download?: boolean
): string => {
  const queryString = qs.stringify({ mediaId, access_token: token, download }, { skipNulls: true });

  return `${API_MEDIA}/api/media/getMedia?${queryString}`;
};

export const fetchVideoPreview = (mediaId: number, token?: string | Nullable) => {
  const queryString = qs.stringify({ mediaId, access_token: token });

  return `${API_MEDIA}/api/media/getVideoPreview?${queryString}`;
};

export function getNetworkLogo({ networkId, logo }: Pick<Network, 'networkId' | 'logo'>) {
  const queryString = qs.stringify({ networkId, mediaId: logo });

  return `${API_MEDIA}/api/media/getNetworkLogo?${queryString}`;
}
export function getNetworkIcon(networkId: number) {
  const queryString = qs.stringify({ networkId });

  return `${API_MEDIA}/api/media/getNetworkIcon?${queryString}`;
}

export function isJSON(str: string | any) {
  let json: any = typeof str !== 'string' ? JSON.stringify(str) : str;

  try {
    json = JSON.parse(json);
  } catch (error) {
    return false;
  }

  return typeof json === 'object' && json !== null;
}

export function setToHappen(callback: Function, date: Date) {
  const t = date.getTime() - new Date().getTime();
  return setTimeout(callback, t);
}

export function isMobileDevice() {
  return (
    typeof window.orientation !== 'undefined' || navigator.userAgent.indexOf('IEMobile') !== -1
  );
}

export function parseBoolean(value: any) {
  return /true/i.test(value);
}

export function setFavicon(networkId: number) {
  const favIconElement = document.getElementById('favicon');

  if (favIconElement) {
    favIconElement.setAttribute(
      'href',
      `${getNetworkIcon(networkId)}&ts=${moment().toISOString(true)}`
    );
  }
}

export function buildPhoneMask(value?: string) {
  return value
    ? value.replace(/(\d{2})(\d{4,5})(\d{4})/, (_, p1, p2, p3) => `(${p1}) ${p2}-${p3}`)
    : '';
}

function phoneLength(value: string) {
  const digits = !value ? '' : value.replace(/[^\d]/g, '');
  if (!digits || digits.length < 10) return value;
  const slice = digits.length === 10 ? 6 : 7;
  const max = digits.length > 11 ? 11 : digits.length;
  return `(${digits.substring(0, 2)}) ${digits.substring(2, slice)}-${digits.substring(
    slice,
    max
  )}`;
}

export function buildInputPhoneMask(value?: string) {
  if (!value || value.length === 0) return '';

  const a = phoneLength(value);
  return a.length >= 6 && a[5] === '9' ? '(99) 99999-9999' : '(99) 9999-9999';
}

export function clearPath(value?: string) {
  if (!value || value.length === 0) return '';

  const slashIndex = value.lastIndexOf('/') + 1;
  return value.substring(slashIndex).replace(/\.\w+$/gi, '');
}

export function removeAccents(value?: string) {
  if (!value || value.length === 0) return '';

  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export function compareTwoStrings(valueA: string, valueB: string) {
  return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
}

export function truncateString(value: string, length: number): string {
  if (value.length <= length) return value;

  const halfLength = length / 2;

  const firstHalf = value.slice(0, halfLength);
  const secondHalf = value.slice(-halfLength);

  return `${firstHalf}...${secondHalf}`;
}

export function isAxiosError<T = any>(error: any): error is AxiosError<T> {
  return (error as AxiosError<T>).isAxiosError;
}

export function isSameLength(arr1: any[], arr2: any[]) {
  return arr1.length === arr2.length;
}
