import React from 'react';
import ContentLoader from 'react-content-loader';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

// #region STYLED
const postSkeleton = css`
  height: 96px;
`;

const Wrapper = styled.div`
  position: static;
  z-index: 1;
  padding: 24px;
  margin-bottom: 24px;
  background-color: ${({ theme }) => theme.colors.white};
  transition: opacity 0.4s ease-in-out;
`;
// #endregion

const PostSkeleton = ({ className = '' }) => (
  <Wrapper className={className}>
    <ContentLoader
      css={postSkeleton}
      height={96}
      width="100%"
      speed={1.4}
      backgroundColor="#f6f6f6"
      foregroundColor="#d2d2d2"
    >
      <rect x="52" y="4" rx="4" ry="4" width="117" height="12" />
      <rect x="52" y="28" rx="4" ry="3" width="85" height="8" />
      <rect x="0" y="56" rx="3" ry="3" width="300" height="8" />
      <rect x="0" y="72" rx="3" ry="3" width="320" height="8" />
      <rect x="0" y="88" rx="3" ry="3" width="201" height="8" />
      <circle cx="20" cy="20" r="20" />
    </ContentLoader>
  </Wrapper>
);

export default PostSkeleton;
