import { hexRegex } from 'lib/constants';
import defaultTheme from './default';
import bwg from './bwg';
import greenShell from './greenShell';
import octoPurple from './octoPurple';
import ladyRed from './ladyRed';
import firefox from './firefox';
import tucanBlue from './tucanBlue';
import generateHexTheme from './hex';

export const rootThemes: Record<string, import('@emotion/react').Theme> = Object.freeze({
  default: defaultTheme,
  bwg,
  greenShell,
  octoPurple,
  ladyRed,
  firefox,
  tucanBlue,
});

export function getTheme(themeName: string | undefined) {
  let theme = defaultTheme;

  if (themeName) {
    if (hexRegex.test(themeName)) {
      theme = generateHexTheme(themeName);
    } else {
      theme = rootThemes[themeName];
    }
  }

  return theme ?? defaultTheme;
}

export default function modifyTheme(themeName: string | undefined) {
  const theme = getTheme(themeName);

  less.modifyVars({
    '@primary-color': theme.colors.primary,
  });

  return theme;
}
