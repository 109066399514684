import {
  NewPostActions,
  NewPostActionType,
  NewPostProfile,
  StoreKey,
  Visibility,
} from 'store/types/newPostTypes';

export const changeVisibility = (storeKey: StoreKey, visibility: Visibility): NewPostActionType => {
  return {
    type: NewPostActions.CHANGE_VISIBILITY,
    payload: { storeKey, visibility },
  };
};
export const changeProfile = (storeKey: StoreKey, profile: NewPostProfile): NewPostActionType => {
  return {
    type: NewPostActions.CHANGE_PROFILE,
    payload: { storeKey, profile },
  };
};
export const addTarget = (storeKey: StoreKey, targetsId: number[]): NewPostActionType => {
  return {
    type: NewPostActions.ADD_TARGET,
    payload: { storeKey, targetsId },
  };
};
export const initNewPostStore = (
  storeKey: StoreKey,
  visibility: Visibility = 'public'
): NewPostActionType => {
  return {
    type: NewPostActions.INIT,
    payload: { storeKey, visibility },
  };
};

export default changeVisibility;
