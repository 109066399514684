import produce from 'immer';
import { NewPostActionType, NewPostState, NewPostActions } from 'store/types/newPostTypes';

const initialState: Readonly<NewPostState> = { posts: {} };

const newPostReducer = (state = initialState, action: NewPostActionType) =>
  produce(state, draft => {
    switch (action.type) {
      case NewPostActions.CHANGE_VISIBILITY:
        draft.posts[action.payload.storeKey]!.visibility = action.payload.visibility;
        break;
      case NewPostActions.CHANGE_PROFILE:
        draft.posts[action.payload.storeKey]!.profile = action.payload.profile;
        break;
      case NewPostActions.ADD_TARGET:
        draft.posts[action.payload.storeKey]!.targets = action.payload.targetsId;
        break;
      case NewPostActions.INIT: {
        draft.posts[action.payload.storeKey] = {
          visibility: action.payload.visibility,
          content: '',
          targets: [],
        };
        break;
      }
    }
  });

export default newPostReducer;
