import React from 'react';
import { Link } from 'react-router-dom';
import { List } from 'antd';

import ReactionIcon from 'components/Reaction/ReactionIcon';
import ProfileImage, { ProfileImageProps } from '../../ProfileImage/ProfileImage';

import * as S from './PersonItemStyles';

export type PersonItemProps = {
  userId: number;
  name: string;
  profileType: ProfileTypes;
  mediaId?: number;
  occupation?: string;
  department?: string;
  reactionName?: ReactionLiteral;
};

const PersonItem: React.FC<PersonItemProps> = ({
  userId,
  name,
  profileType,
  occupation,
  department,
  mediaId,
  reactionName,
}) => {
  return (
    <List.Item.Meta
      avatar={
        <ReactionAvatar
          profile={{ profileId: userId, name, profileType, mediaId }}
          reactionName={reactionName}
        />
      }
      title={<Link to={`/users/${userId}`}>{name}</Link>}
      description={
        occupation && department
          ? `${occupation} - ${department}`
          : occupation
          ? occupation
          : department
          ? department
          : null
      }
    />
  );
};

function ReactionAvatar({
  profile,
  reactionName,
}: {
  profile: ProfileImageProps['profile'];
  reactionName?: ReactionLiteral;
}) {
  return (
    <S.AvatarWrapper>
      <ProfileImage profile={profile} size="sm" />
      {reactionName && <ReactionIcon css={S.icon(reactionName)} reaction={reactionName} />}
    </S.AvatarWrapper>
  );
}

export default PersonItem;
