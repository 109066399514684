import { useEffect } from 'react';

import { NotificationMessageType } from 'lib/constants';
import { makeSubscription } from 'components/application/Messaging';

export interface UseChatNotificationArgs {
  onNewChatMessage?: (data: NewMessageResult) => void;
  onDeletedMessage?: (data: ChatMessage) => void;
  onDeletedChatRoom?: (data: { chatRoomId: number }) => void;
}

export function useChatNotification(args: UseChatNotificationArgs) {
  const { onNewChatMessage, onDeletedMessage, onDeletedChatRoom } = args;

  useEffect(() => {
    let unsubNewChatMessage: Function;
    let unsubDeletedMessage: Function;
    let unsubDeletedChatRoom: Function;

    if (onNewChatMessage) {
      unsubNewChatMessage = makeSubscription<NewMessageResult>(
        NotificationMessageType.NewChatMessage,
        (_, data) => onNewChatMessage(data!)
      );
    }

    if (onDeletedMessage) {
      unsubDeletedMessage = makeSubscription<ChatMessage>(
        NotificationMessageType.DeletedMessage,
        (_, data) => onDeletedMessage(data!)
      );
    }

    if (onDeletedChatRoom) {
      unsubDeletedChatRoom = makeSubscription(NotificationMessageType.DeletedChatRoom, (_, data) =>
        onDeletedChatRoom(data)
      );
    }

    return () => {
      unsubNewChatMessage?.();
      unsubDeletedMessage?.();
      unsubDeletedChatRoom?.();
    };
  }, [onDeletedChatRoom, onDeletedMessage, onNewChatMessage]);
}
