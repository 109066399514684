import { GTAG_ID } from '../constants';

const script = document.createElement('script');
script.async = true;
script.src = `https://www.googletagmanager.com/gtag/js?id=${GTAG_ID}`;

const gtagScript = document.createElement('script');
const initGtag = document.createTextNode(`
  window.dataLayer = window.dataLayer || [];

  function gtag() {
    dataLayer.push(arguments);
  }

  gtag('js', new Date());

  gtag('config', '${GTAG_ID}');
`);
gtagScript.appendChild(initGtag);

document.head.appendChild(script);
document.head.appendChild(gtagScript);
