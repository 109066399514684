import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPosts, OutCanceler, resetPosts } from 'store/actions/postsActions';
import { selectFilters } from 'store/selectors/postSelectors';

export type UsePostsArgs = {
  profileType?: ProfileTypes | ProfileTypes[];
};

export function usePosts({ profileType }: UsePostsArgs) {
  const dispatch: AppDispatch = useDispatch();

  const filters = useSelector(selectFilters);

  const [pages, setPage] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [hasError, setHasError] = useState(false);

  const cancelerRef = useRef({} as OutCanceler);
  const profileTypes = useRef(profileType);

  useLayoutEffect(() => {
    profileTypes.current = profileType;
  });

  const loadPosts = useCallback(
    (page: number) => {
      setLoading(true);
      setHasError(false);

      dispatch(
        getPosts(
          page,
          { profileTypes: profileTypes.current },
          (status: number, needMore: boolean) => {
            if (status === 204 || !needMore) setHasMore(false);

            if (status < 400) {
              setPage(pages => [...pages, page]);
            } else {
              setHasError(true);
            }
            setLoading(false);
          },
          cancelerRef.current
        )
      );
    },
    [dispatch]
  );

  const reloadPosts = useCallback(async () => {
    dispatch(resetPosts());
    setPage([]);
    setHasMore(true);
    loadPosts(1);
  }, [dispatch, loadPosts]);

  useEffect(() => {
    const canceler = cancelerRef.current;
    reloadPosts();

    return () => {
      canceler.cancel?.();
    };
  }, [reloadPosts, filters]);

  return {
    pages,
    loading,
    hasMore,
    hasError,
    loadPosts,
    reloadPosts,
  };
}
