import React, { useCallback, useRef } from 'react';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useFeatureToggle } from 'hooks';
import { useSelector } from 'store';
import { makeGetPostSelector } from 'store/selectors/postSelectors';
import { canUseFunction } from 'store/selectors/authSelectors';

import LikeButton from 'components/layout/Common/Like/Like';
import ReactionButton from 'components/Reaction/ReactionButton';
import CommentButton from 'components/layout/Common/CommentButton/CommentButton';
import PostInteractionCounters from '../PostInteractionCounters';

import * as S from './PostInteractionsStyles';

interface PostInteractionsProps {
  postId: number;
  handleOpen?: () => void;
  handleComments?: () => void;
}

const PostInteractions = (props: PostInteractionsProps) => {
  const { postId, handleOpen, handleComments } = props;

  const { t } = useTranslation();
  const [reactionsFeature] = useFeatureToggle('reactions');
  // #region REDUX
  const getPost = useRef(makeGetPostSelector()).current;
  const postInfo = useSelector(state => getPost(state, postId));
  const commentsFunction = useSelector(state => canUseFunction(state, 'comments'), shallowEqual);
  const likesFunction = useSelector(state => canUseFunction(state, 'likes'), shallowEqual);
  // #endregion

  const onClickComment = useCallback(() => {
    handleOpen?.();
  }, [handleOpen]);

  return (
    <S.InteractionsContainer>
      <PostInteractionCounters postId={postId} handleComments={handleComments} />
      <S.InteractionsButtons hasInteractions>
        {likesFunction &&
          (reactionsFeature ? (
            <ReactionButton postId={postId} reaction={postInfo.reaction} />
          ) : (
            <LikeButton className="like" postId={postId} />
          ))}
        {!postInfo.post.blockCommenting && commentsFunction && (
          <CommentButton onClick={onClickComment} />
        )}
        {/* <ShareButton onClick={() => {}} /> */}
      </S.InteractionsButtons>
      {postInfo.post.blockCommenting && (
        <S.ContainerDisableComments>
          <S.SpanMessage>{t('post.blockCommenting')}</S.SpanMessage>
        </S.ContainerDisableComments>
      )}
    </S.InteractionsContainer>
  );
};

export default React.memo(PostInteractions);
