// GLOBAL
import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { down, up } from 'styled-breakpoints';

import { useTheme, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { rgba } from 'polished';
import { Layout, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAlignLeft, faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import { fadeIn, fadeOut } from 'react-animations';

// REDUX
import { useSelector } from 'store';
import { tooggleSiderCollapsed, collapseSider } from 'store/actions/uiActions';

import useMedia from 'hooks/useMedia';

// #region STYLES
const fadeInAnimation = keyframes`${fadeIn}`;
const fadeOutAnimation = keyframes`${fadeOut}`;

const Container = styled(Layout.Sider)`
  position: fixed;
  top: 64px;
  left: 60px;
  z-index: 2;
  display: none;
  height: calc(100vh - 64px);
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.04);

  ${up('tablet')} {
    display: block;
  }
`;
const Wrapper = styled.div`
  height: 100%;
`;
const CollapseButton = styled.button`
  position: absolute;
  top: 11px;
  right: -14px;
  width: 26px;
  height: 26px;
  padding: 0;
  font-size: 16px;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  background-color: ${({ theme }) => rgba(theme.colors.primary, 0.1)};
  border: 0;
  border-radius: 100%;
  outline: none;

  :active,
  :focus {
    background-color: ${({ theme }) => rgba(theme.colors.primary5, 0.1)};
  }

  svg {
    vertical-align: -0.05rem;
  }
`;

const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;

  @media (max-height: 768px) {
    overflow: auto;
  }

  &.enter-active {
    animation: 600ms ${fadeInAnimation};
  }
  &.exit-active {
    animation: 50ms ${fadeOutAnimation};
  }
`;
// #endregion

const Sider: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const cssMedia = useMemo(
    () => [down('lgTablet', 'portrait')({ theme }).replace('@media', '').trim()],
    [theme]
  );

  const screen = useMedia(cssMedia, ['tablet', 'tablet'], 'desktop');
  // REDUX
  const dispatch = useDispatch();
  const { siderCollapsed } = useSelector(state => state.ui);

  const handleToggleSider = () => {
    dispatch(tooggleSiderCollapsed());
  };

  useEffect(() => {
    if (screen !== 'desktop') {
      dispatch(collapseSider());
    }
  }, [dispatch, screen]);

  return (
    <Container collapsed={siderCollapsed} collapsedWidth={15} width={280}>
      <Wrapper>
        <CSSTransition timeout={{ enter: 1000, exit: 50 }} in={!siderCollapsed} unmountOnExit>
          <AnimationContainer>{children}</AnimationContainer>
        </CSSTransition>
      </Wrapper>
      <Tooltip
        placement="right"
        title={siderCollapsed ? t('common.expand') : t('common.collapse')}
        mouseEnterDelay={0.7}
        mouseLeaveDelay={0}
      >
        <CollapseButton onClick={handleToggleSider}>
          {siderCollapsed ? (
            <FontAwesomeIcon icon={faAlignLeft} />
          ) : (
            <FontAwesomeIcon icon={faChevronLeft} />
          )}
        </CollapseButton>
      </Tooltip>
    </Container>
  );
};

export default Sider;
