import axios from 'axios';
import { PartialDeep } from 'type-fest';
import { AsyncAction } from 'store/actions';
import { UsersActions, UsersActionType } from 'store/types/usersTypes';

//#region TYPES
type Callback = (...value: any) => void;

type FetchAction = (
  userId: number,
  callback?: Callback
) => AsyncAction<Promise<void>, UsersActionType>;
//#endregion

export const fetchUser: FetchAction =
  (userId, callback = () => {}) =>
  async dispatch => {
    const { data, status } = await axios.get('api/profile/getUser', { params: { userId } });
    if (data) {
      dispatch({
        type: UsersActions.FETCH_USER,
        payload: {
          userId,
          user: data.users[0],
        },
      });
    }
    callback(status);
  };

export const fetchUserContacts: FetchAction =
  (userId, callback = () => {}) =>
  async dispatch => {
    const { data, status } = await axios.get('api/profile/getUserContacts', { params: { userId } });
    if (data) {
      dispatch({
        type: UsersActions.FETCH_USER_CONTACTS,
        payload: { userId, contacts: data },
      });
    }
    callback(status);
  };

export function setUserConnected(userId: number): UsersActionType {
  return {
    type: UsersActions.USER_CONNECTED,
    payload: { userId },
  };
}

export function setUserDisconnected(userId: number): UsersActionType {
  return {
    type: UsersActions.USER_DISCONNECTED,
    payload: { userId },
  };
}
export function setUser(userId: number, user: PartialDeep<UserInfo>): UsersActionType {
  return {
    type: UsersActions.USER_UPDATED,
    payload: { userId, user },
  };
}
export function setUserContacts(
  userId: number,
  userContacts: Partial<UserContacts>
): UsersActionType {
  return {
    type: UsersActions.USER_CONTACTS_UPDATED,
    payload: { userId, userContacts },
  };
}

const usersActions = { fetchUser };
export default usersActions;
