import { useAsyncCallback } from 'react-async-hook';

import { getDepartment } from 'lib/apiActions';

function useDepartment() {
  const departmentAsync = useAsyncCallback(getDepartment);

  return departmentAsync;
}

export default useDepartment;
