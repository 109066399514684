import React from 'react';

import logoWork from 'assets/images/work+.svg';
import * as S from './LGPDTermsStyles';

//#region TYPES
export interface LGPDTermsProps {
  className?: string;
}
//#endregion

const LGPDTerms: React.VFC<LGPDTermsProps> = ({ className = '' }) => {
  return (
    <>
      <S.LogoWrapper>
        <img src={logoWork} alt="4bee Work" />
      </S.LogoWrapper>
      <h2>Termo de autorização de utilização de dados pessoais (LGPD)-4bee Work</h2>
      <S.TermsList className={className}>
        <li>
          <p>
            Os dados dos usuários enviados pela contratante para a empresa 4BEE, bem como, os dados
            coletados diretamente pelo usuário, para cadastro e acesso à rede de comunicação social
            4BEE WORK, são revestidos de toda a segurança e garantem a privacidade de seus usuários,
            conforme determina a <em>lei nº 13.709/2018</em>, respeitando sempre os limites já
            indicados na <q>Política de Privacidade</q> e <q>Termos de uso</q> do sistema.
          </p>
        </li>
        <li>
          <p>
            Os dados coletados para a utilização da rede de comunicação 4BEE WORK não serão
            compartilhados em hipótese alguma com terceiros, salvo as hipóteses de autorização
            expressa por parte do usuário, exceto em relação a empresa contratante do serviço, que
            indicou a disponibilização ao usuário, ou uma das hipóteses previstas no item 4 da{' '}
            <q>Política de Privacidade</q>.
          </p>
        </li>
        <li>
          <p>
            As informações pessoais do usuário são fornecidas espontaneamente, e se limitam as
            mínimas necessárias para o adequado funcionamento da rede de comunicação social
            corporativa 4BEE WORK, ou para que a mesma possa atender as legislações, normas e outras
            regulamentações do setor, que a título meramente exemplificativo citamos, a{' '}
            <em>lei nº 12.965/2014</em>, que trata sobre o Marco Civil da Internet e a{' '}
            <em>lei nº 13.709/2018</em>, que trata da Lei Geral de Proteção dos Dados Pessoais
            (LGPD).
          </p>
        </li>
        <li>
          <p>
            As informações que podem ser utilizadas para cadastro e acesso à rede de comunicação
            social 4BEE WORK e, consequentemente, ficarão disponíveis na plataforma, são as
            seguintes:
          </p>
          <dl>
            <dt>Empresa</dt>
            <dt>Cargo</dt>
            <dt>Unidade</dt>
            <dt>Departamento</dt>
            <dt>Ramal</dt>
            <dt>Telefone</dt>
            <dt>Nome completo</dt>
            <dt>CPF</dt>
            <dt>Data de nascimento</dt>
            <dt>Cidade</dt>
            <dt>WhatsApp</dt>
            <dt>E-mail</dt>
            <dt>Foto</dt>
            <dt>Link do Linkedin</dt>
          </dl>
        </li>
        <li>
          <p>
            A título de esclarecimento, o 4BEE WORK é uma rede de comunicação social corporativa,
            com foco específico no uso de atividades empresariais/econômicas, e a liberação para os
            usuários pessoas físicas, só ocorre após a indicação por parte da empresa contratante
            dos serviços, de quais serão as pessoas que terão acesso a rede de comunicação.
          </p>
        </li>
        <li>
          <p>
            Portanto, a disponibilização da utilização da rede social corporativa 4BEE WORK, só
            ocorrerá após a indicação pela empresa contratante de quem serão os usuários.
          </p>
        </li>
        <li>
          <p>
            Não cabe a empresa 4BEE qualquer tipo de avaliação na relação existente entre a empresa
            contratante e os usuários por ela indicados.
          </p>
        </li>
        <li>
          <p>
            A empresa 4BEE utiliza as melhores técnicas e políticas de segurança possíveis e
            disponíveis no mercado, com o objetivo de garantir a segurança e privacidade de seus
            usuários (empresa contratante e usuários pessoas físicas).
          </p>
        </li>
        <li>
          <p>
            É a partir do seu consentimento que tratamos os seus dados pessoais. O consentimento é a
            manifestação livre, informada e inequívoca pela qual você autoriza a 
            <em>4bee Solutions Desenvolvedora de Software Ltda</em> a tratar seus dados.
          </p>
          <p>
            Assim, em consonância com a <em>Lei Geral de Proteção de Dados</em>, seus dados só serão
            coletados, tratados e armazenados mediante prévio e expresso consentimento.
          </p>
          <p>
            O seu consentimento será obtido de forma específica (ao final deste Termo) para as
            finalidades já descritas (cadastro e acesso à rede corporativa social 4BEE WORK),
            evidenciando o compromisso de transparência e boa-fé da 4bee para com seus
            usuários/clientes, seguindo as regulações legislativas pertinentes.
          </p>
          <p>
            Ao utilizar os serviços da (4BEE) e fornecer seus dados pessoais, você está ciente e
            consentindo com as disposições deste Termo, além de conhecer seus direitos e como
            exercê-los.
          </p>
          <p>A qualquer tempo e sem nenhum custo, você poderá revogar seu consentimento.</p>
          <p>
            É importante destacar, que a revogação do consentimento para o tratamento dos dados pode
            implicar a <em>impossibilidade da performance adequada de alguma funcionalidade</em> da
            rede social corporativa 4bee Work, que dependa da operação. Tais consequências serão
            informadas previamente.
          </p>
          <p>
            Para mantermos suas informações pessoais seguras, usamos ferramentas físicas,
            eletrônicas e gerenciais orientadas para a proteção da sua privacidade.
          </p>
          <p>
            Aplicamos essas ferramentas levando em consideração a natureza dos dados pessoais
            coletados, o contexto e a finalidade do tratamento e os riscos que eventuais violações
            gerariam para os direitos e liberdades do titular dos dados coletados e tratados.
          </p>
          <p>Entre as medidas que adotamos, destacamos as seguintes:</p>
          <ul>
            <li>
              <p>Apenas pessoas autorizadas têm acesso a seus dados pessoais</p>
            </li>
            <li>
              <p>
                O acesso a seus dados pessoais é feito somente após o compromisso de
                confidencialidade
              </p>
            </li>
            <li>
              <p>
                Seus dados pessoais são armazenados em ambiente seguro e idôneo. A 4Bee se
                compromete a adotar as melhores posturas para evitar incidentes de segurança.
                Contudo, é necessário destacar que nenhuma página virtual é inteiramente segura e
                livre de riscos. É possível que, apesar de todos os nossos protocolos de segurança,
                problemas de culpa exclusivamente de terceiros ocorram, como ataques cibernéticos
                de hackers, ou também em decorrência da negligência ou imprudência do próprio
                usuário/cliente.
              </p>
              <p>
                Em caso de incidentes de segurança que possa gerar risco ou dano relevante para você
                ou qualquer um de nossos usuários/clientes, comunicaremos aos afetados e a
                Autoridade Nacional de Proteção de Dados sobre o ocorrido, em consonância com as
                disposições da <em>Lei Geral de Proteção de Dados</em>.
              </p>
              <p>
                Reservamos o direito de modificar Termo de utilização a qualquer tempo,
                principalmente em função da adequação a eventuais alterações feitas em nossa rede
                corporativa ou em âmbito legislativo. Recomendamos que você a revise com frequência.
              </p>
              <p>
                Eventuais alterações entrarão em vigor somente a prévia notificação à contratante e
                bem como a seus usuários, e sempre lhe notificaremos acerca das mudanças ocorridas
                através da própria plataforma e solicitando um novo aceite.
              </p>
            </li>
          </ul>
        </li>
        <li>
          <p>
            <strong>
              Declaro para este fim, que li e compreendi o presente de Termo, diante do qual,
              autorizo expressamente que a empresa{' '}
              <em>4bee Solutions Desenvolvedora de Software Ltda</em>, colete e utilize os meus
              dados pessoais, tanto os fornecidos por mim diretamente, quanto aos meus dados
              pessoais informados eventualmente pela contratante, para a finalidade de cadastro e
              acesso a rede social corporativa 4BEE WORK.
            </strong>
          </p>
        </li>
      </S.TermsList>
    </>
  );
};

export default LGPDTerms;
