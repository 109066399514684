import React, { createRef } from 'react';
import linkifyit from 'linkify-it';
import tlds from 'tlds';
import { trimCharsEnd } from 'lodash/fp';

import { withTheme } from '@emotion/react';
import { CSSTransition } from 'react-transition-group';

// DRAFT JS
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import createCounterPlugin from '@draft-js-plugins/counter';
import 'draft-js/dist/Draft.css';
import mentionTheme from 'styles/draftjs/mentionTheme';
import counterTheme from 'styles/draftjs/counterTheme';

import EntrySuggestions from './EntrySuggestions';

const parseLinksContent = editorState => {
  const linkify = linkifyit({}, { fuzzyEmail: false });
  linkify.tlds(tlds);

  const links = [];
  editorState
    .getCurrentContent()
    .getBlockMap()
    .forEach(block => {
      const matches = linkify.match(block.get('text'));

      if (typeof matches !== 'undefined' && matches !== null) {
        matches.forEach(m => links.push(trimCharsEnd('/', m.url)));
      }
    });

  return links;
};

class TextEditor extends React.Component {
  constructor(props) {
    super(props);
    const { theme } = props;

    this.counterTheme = counterTheme(theme);

    this.mentionPlugin = createMentionPlugin({
      entityMutability: 'IMMUTABLE',
      mentionPrefix: '@',
      supportWhitespace: true,
      theme: mentionTheme(theme),
    });
    this.counterPlugin = createCounterPlugin({
      theme: {
        counterOverLimit: this.counterTheme.counterOverLimit,
      },
    });

    this.state = {
      mentionsOpen: true,
      counterVisible: false,
    };

    this.counterLimit = 2048;

    this.editorRef = createRef();
    this.editorWrapper = createRef();
  }
  componentDidMount() {
    const { autoFocus } = this.props;
    if (autoFocus) {
      setTimeout(() => {
        this.editorRef.current.focus();
        if (window.getSelection) {
          const selection = window.getSelection();
          const range = document.createRange();
          range.selectNodeContents(this.editorWrapper.current);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }, 10);
    }
  }

  focus = () => this.editorRef.current.focus();

  handleChange = editorState => {
    const { onChange } = this.props;

    const charCount = editorState
      .getCurrentContent()
      .getPlainText()
      .replace(/\r?\n|\r/g, '').length; // Remove enter characters

    const nearLimit = parseInt(this.counterLimit * 0.9, 10);
    this.setState({ counterVisible: charCount >= nearLimit });
    const isOverLimit = charCount > this.counterLimit;

    onChange(editorState, parseLinksContent(editorState), isOverLimit);
  };

  handleOpenMentionChange = open => {
    const { onSuggestionsClose } = this.props;

    this.setState({ mentionsOpen: open });

    if (!open) onSuggestionsClose?.();
  };

  renderButtons = () => {
    const { buttons } = this.props;
    if (!buttons) {
      return null;
    }

    return buttons();
  };
  render() {
    const {
      className,
      editorState,
      handleReturn,
      onBlur,
      onFocus,
      onSearchChange,
      placeholder,
      suggestions,
      plugins,
    } = this.props;
    const { mentionsOpen, counterVisible } = this.state;
    const pluginsList = [this.mentionPlugin, this.counterPlugin, ...plugins];
    const { MentionSuggestions } = this.mentionPlugin;
    const { CharCounter } = this.counterPlugin;

    return (
      <>
        <div
          role="textbox"
          className={className}
          onClick={this.focus}
          translate="no"
          ref={this.editorWrapper}
        >
          <Editor
            autoCorrect="off"
            editorKey="postEditor"
            editorState={editorState}
            handleReturn={handleReturn}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={this.handleChange}
            placeholder={placeholder}
            plugins={pluginsList}
            stripPastedStyles
            ref={this.editorRef}
          />
          <MentionSuggestions
            open={mentionsOpen}
            onSearchChange={onSearchChange}
            suggestions={suggestions}
            entryComponent={EntrySuggestions}
            onOpenChange={this.handleOpenMentionChange}
          />
          {this.renderButtons()}
        </div>
        <CSSTransition
          in={counterVisible}
          classNames={{
            enter: this.counterTheme.counterWrapperEnter,
            enterActive: this.counterTheme.counterWrapperEnterActive,
            enterDone: this.counterTheme.counterWrapperEnterDone,
            exit: this.counterTheme.counterWrapperExit,
            exitActive: this.counterTheme.counterWrapperExitActive,
          }}
          timeout={400}
        >
          <div className={this.counterTheme.counterWrapper}>
            <CharCounter className={this.counterTheme.counter} limit={this.counterLimit} />
          </div>
        </CSSTransition>
      </>
    );
  }
}

export default withTheme(TextEditor);
