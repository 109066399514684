import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { reactionColors } from './reactionHelper';

type IconProps = {
  size: 'normal' | 'small';
};

export const icon = (reaction: ReactionLiteral, { size }: IconProps) => css`
  position: relative;
  display: inline-block;
  padding: 0.125rem;
  width: ${size === 'normal' ? '1.5rem' : '1.25rem'};
  height: ${size === 'normal' ? '1.5rem' : '1.25rem'};
  vertical-align: middle;
  color: ${reactionColors[reaction].foreground};
  background-color: ${reactionColors[reaction].background};
  border-radius: 50%;
  border: 1px solid #fff;

  & + span {
    margin-left: -10px;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: ${size === 'normal' ? '0.85rem' : '0.7rem'};
    transform: translate(-50%, -50%);
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: inline-block;
  margin-right: 0.5rem;
`;

export const Counter = styled.span`
  display: inline-block;
  font-size: 0.85rem;
`;
