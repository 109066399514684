import produce from 'immer';
import unionBy from 'lodash/fp/unionBy';
import { ChatState, ChatActionType } from 'store/types/chatTypes';
import { ChatActions } from 'store/types/types';

const initialState: Readonly<ChatState> = {
  listOpen: false,
  chatOpen: false,
  chatVisible: false,
  chatCollapsed: true,
  activeRoomId: null,
  chattingWith: {} as ChatRoom,
  chatList: {},
  rooms: {},
  hasMoreMessages: {},
  listIsLoading: false,
  sendingMessage: false,
  messagesIsLoading: false,
  newMessageNotification: false,
  emitter: false,
  defaultMessage: '',
};

const addRoom = (chatList: Record<number, ChatRoomInfo>, chatRoom: ChatRoomInfo) => {
  const { roomId } = chatRoom.room;
  chatList[roomId] = chatRoom;
  return chatList;
};

const chatReducer = (state = initialState, action: ChatActionType) =>
  produce(state, draft => {
    switch (action.type) {
      case ChatActions.OPEN_LIST:
        draft.listOpen = !draft.listOpen;
        break;
      case ChatActions.OPEN_CHAT:
        draft.newMessageNotification = false;
        draft.chatOpen = !draft.chatOpen;
        break;
      case ChatActions.CLOSE_CHAT:
        draft.chattingWith = {} as ChatRoom;
        draft.chatOpen = false;
        draft.chatVisible = false;
        draft.activeRoomId = null;
        delete draft.rooms[action.payload.roomId];
        break;
      case ChatActions.CLEAR_LIST: {
        draft.chatList = {};
        break;
      }
      case ChatActions.FETCH_CHAT_LIST:
        {
          const { list } = action.payload;
          if (list.length > 0) draft.chatList = list.reduce(addRoom, {});
          else draft.chatList = {};
        }
        break;
      case ChatActions.NEW_CHAT_WINDOW:
        {
          const { room } = action.payload.chatRoom;
          const { defaultMessage } = action.payload;
          draft.chattingWith = room;
          draft.activeRoomId = room.roomId;
          draft.chatOpen = true;
          draft.chatVisible = true;
          draft.defaultMessage = defaultMessage;
        }
        break;
      case ChatActions.ROOM_UPDATED: {
        const { roomId } = action.payload.room;
        draft.chatList[roomId] = action.payload;
        break;
      }
      case ChatActions.MESSAGES_FETCHED: {
        const { roomId, messages } = action.payload;
        if (draft.rooms[roomId]) {
          draft.rooms[roomId] = unionBy<ChatMessageInfo>('message.messageId')(
            messages,
            draft.rooms[roomId]
          );
        } else {
          draft.rooms[roomId] = messages;
        }
        draft.hasMoreMessages[roomId] = messages.length === 20;
        break;
      }
      case ChatActions.DELETE_MESSAGE: {
        const { roomId, messageId } = action.payload;
        draft.rooms[roomId] = draft.rooms[roomId].filter(
          message => message.message.messageId !== messageId
        );
        // delete draft.rooms[payload.roomId].messages[payload.messageId];
        break;
      }
      case ChatActions.MESSAGE_SENT: {
        const { roomId, message } = action.payload;
        const roomMessages = draft.rooms[roomId];
        if (roomMessages) draft.rooms[roomId] = [message, ...roomMessages];
        else draft.rooms[roomId] = [message];
        break;
      }
      case ChatActions.NEW_MESSAGE: {
        draft.newMessageNotification = true;
        break;
      }
      case ChatActions.CLEAR_COUNT: {
        if (draft.chatList[action.payload.roomId]) {
          draft.chatList[action.payload.roomId].unreadCount = 0;
        }
        break;
      }
      case ChatActions.LEAVE_ROOM: {
        if (draft.chatList[action.payload.roomId]) {
          delete draft.chatList[action.payload.roomId];
        }
        if (draft.chattingWith.roomId === action.payload.roomId) {
          draft.chattingWith = {} as ChatRoom;
          draft.chatOpen = false;
          draft.chatVisible = false;
        }
        break;
      }
      case ChatActions.FETCH_MESSAGES:
        draft.chatList = action.payload.messages;
        break;
      case ChatActions.LOADING_CHAT:
        draft.listIsLoading = action.payload.loading;
        break;
      case ChatActions.LOADING_MESSAGES:
        draft.messagesIsLoading = action.payload.loading;
        break;
      case ChatActions.SENDING_MESSAGE:
        draft.sendingMessage = action.payload.sending;
        break;
      case ChatActions.CLEAR_MESSAGE_NOTIFICATION:
        draft.newMessageNotification = false;
        break;
      case ChatActions.SET_ACTIVE_ROOM:
        draft.activeRoomId = action.payload.chatRoomId;
        break;
      case ChatActions.TOGGLE_CHAT_COLLAPSED:
        draft.chatCollapsed = !draft.chatCollapsed;
        break;
    }
  });

export default chatReducer;
