const chatEn = {
  channelInfo: 'Channel information',
  channelSection: 'Channels',
  contentDeleteText:
    'Do you really wanna delete this channel? Deleting a channel is a irreversible action, all members will lost access to it. All files and conversations will be lost.',
  contentLeaveText: 'Do you really wanna leave this channel?',
  create: 'Create',
  emptySearch: 'No results',
  inputPlaceholder: 'Chat with {{name}}',
  leftMessage: '{{name}} left 😢',
  members: 'Members',
  messageEmptyChat: 'To start a conversation search for a user or create a channel.',
  okDeleteText: 'Yes, delete.',
  okLeaveText: 'Yes, leave.',
  searchPlaceholder: 'Search for people and channels',
  titleDeleteText: 'Are you deleting this channel?',
  titleLeaveText: 'Are you leaving this channel?',
  titleRemoveMember: 'Will you remove {{name}} from this channel?',
  titleSection: 'Chats',
  createChannel: {
    addImage: 'Add image',
    addMembers: 'Add/remove members',
    addMembersPlaceholder: 'John, Britney, Steve, May...',
    changeChannelName: 'Change channel name',
    channelImage: 'Channel image',
    channelName: 'Channel name',
    channelNamePlaceholder: 'Can be marketing, TI, sales...',
    create: 'Create a channel',
    description:
      'The channels works as group chat. Connect with your colleagues and start a conversation',
    messages: {
      channelNameChanged: 'Channel name updated successfully',
      channelNameError: 'A problem occurred when changing the channel name',
    },
  },
  membersModal: {
    addMembersFail: 'It was not possible to add members to this channel',
    createdBy: 'Created by',
    members: '{{count}} Member',
    members_plural: '{{count}} Members',
    adms: '{{count}} Administrator',
    adms_plural: '{{count}} Administrators',
    message: 'Message',
  },
};

export default chatEn;
