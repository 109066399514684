import React, { useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import isEmpty from 'lodash/fp/isEmpty';

import { Slider } from 'antd';
import Cropper from 'react-easy-crop';
import type { Area, Point, Size } from 'react-easy-crop/types';

import cropImage from 'lib/cropImage';

import * as S from './MediaCropperStyles';

//#region TYPES
interface MediaCropperState {
  crop: Point;
  cropArea: Area;
  zoom: number;
}
interface ChangeCropAction {
  type: 'changeCrop';
  crop: Point;
}
interface CropCompletedAction {
  type: 'cropCompleted';
  cropArea: Area;
}
interface ChangeZoomAction {
  type: 'changeZoom';
  zoom: number;
}
interface ResetStateAction {
  type: 'resetState';
}
type ActionType = ChangeCropAction | CropCompletedAction | ChangeZoomAction | ResetStateAction;

export type CropResult = { area: Area; zoom: number };

type MediaCropperProps = {
  imgSrc: string;
  aspect?: number;
  children?: React.ReactNode;
  cropSize?: Size | undefined;
  cropShape?: 'rect' | 'round' | undefined;
  restrictPosition?: boolean;
  modalWidth?: string | number;
  visible: boolean;
  title?: string;
  okText?: string;
  minZoom?: number;
  onOk: (
    croppedImg: File,
    cropResult: CropResult,
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  onCancel?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};
//#endregion

// #region REDUCER
const initialState: MediaCropperState = {
  crop: { x: 0, y: 0 },
  cropArea: { width: 0, height: 0, x: 0, y: 0 },
  zoom: 1,
};

function reducer(state: MediaCropperState, action: ActionType) {
  return produce(state, draft => {
    switch (action.type) {
      case 'changeCrop':
        draft.crop = action.crop;
        break;
      case 'cropCompleted':
        draft.cropArea = action.cropArea;
        break;
      case 'changeZoom':
        draft.zoom = action.zoom;
        break;
      case 'resetState':
        return initialState;
    }
  });
}
// #endregion

function MediaCropper({
  imgSrc,
  aspect = 1,
  children,
  cropSize,
  cropShape = 'round',
  restrictPosition = true,
  modalWidth,
  visible,
  title,
  okText,
  minZoom = 1,
  onOk,
  onCancel,
}: MediaCropperProps) {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleCropChange = (crop: Point) => {
    dispatch({ type: 'changeCrop', crop });
  };
  const handleCropComplete = (_: Area, croppedAreaPixel: Area) => {
    dispatch({ type: 'cropCompleted', cropArea: croppedAreaPixel });
  };
  const handleZoomChange = (zoom: number) => {
    dispatch({ type: 'changeZoom', zoom });
  };

  const handleCroppedImage = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const croppedImg = await cropImage(imgSrc, state.cropArea);
    onOk(croppedImg, { area: state.cropArea, zoom: state.zoom }, e);
  };

  const handleCancel = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (onCancel) onCancel(e);
  };

  const afterCloseHandler = () => dispatch({ type: 'resetState' });

  return (
    <S.CropperModal
      centered
      closable={false}
      destroyOnClose
      maskClosable={false}
      visible={visible}
      okButtonProps={{ style: { fontWeight: 'bold' } }}
      okText={isEmpty(okText) ? t('common.save') : okText}
      width={modalWidth}
      onOk={handleCroppedImage}
      onCancel={handleCancel}
      afterClose={afterCloseHandler}
    >
      {title && <h4>{title}</h4>}
      {imgSrc && (
        <S.CropperContainer>
          <Cropper
            image={imgSrc}
            crop={state.crop}
            zoom={state.zoom}
            minZoom={minZoom}
            aspect={aspect}
            cropSize={cropSize}
            cropShape={cropShape}
            restrictPosition={restrictPosition}
            onCropChange={handleCropChange}
            onCropComplete={handleCropComplete}
            onZoomChange={handleZoomChange}
          />
        </S.CropperContainer>
      )}

      <div>
        <strong>Zoom</strong>
      </div>
      <Slider min={minZoom} max={3} step={0.1} value={state.zoom} onChange={handleZoomChange} />
      {!!children && React.Children.only(children)}
    </S.CropperModal>
  );
}

export default MediaCropper;
