import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { css } from '@emotion/react';
import { Dropdown, Menu, MenuProps, Popconfirm } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

import useAuth from 'hooks/useAuth';
import { PROFILE_TYPES } from 'lib/constants';
import { useSelector } from 'store';
import { changeProfile } from 'store/actions/newPostActions';
import { NewPostProfile } from 'store/types/newPostTypes';

import { useGroupAdmins, useGroupInfo } from 'components/Groups/GroupStore';
import ProfileImage from 'components/layout/Common/ProfileImage/ProfileImage';

import * as S from './ProfileSwitchStyles';

//#region TYPES
export interface ProfileSwitchProps {
  editorFocused?: boolean;
  storeKey: 'NEW_POST_GROUP';
  onProfileSwitch?: () => void;
}

type ProfileType = 'user' | 'group';
//#endregion

const ProfileSwitch: React.VFC<ProfileSwitchProps> = ({
  editorFocused = false,
  storeKey,
  onProfileSwitch,
}) => {
  const { t } = useTranslation();
  const { userInfo, networkSettings } = useAuth();
  const groupInfo = useGroupInfo();
  const groupAdmins = useGroupAdmins();

  const dispatch = useDispatch();
  const currentProfile = useSelector(state => state.newPost.posts[storeKey]?.profile);

  const { userId, name: userName } = userInfo.user;
  const { pageId, name: pageName, participantsCanPublish } = groupInfo.page;

  const canPublish = participantsCanPublish && networkSettings.allowSendingPosts;

  const isAdmin = useMemo(
    () => userId === groupInfo.owner.userId || groupAdmins?.some(adm => userId === adm.user.userId),
    [groupAdmins, groupInfo.owner.userId, userId]
  );

  const userProfile: NewPostProfile = {
    profileId: userId,
    name: userName,
    profileType: PROFILE_TYPES.user,
    mediaId: userInfo.user.mediaId,
  };

  const groupProfile: NewPostProfile = {
    profileId: pageId,
    name: pageName,
    profileType: PROFILE_TYPES.group,
    mediaId: groupInfo.pageMediaId,
  };

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [isProfileChosen, setProfileChosen] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<ProfileType>('group');

  useEffect(() => {
    if (canPublish && editorFocused && !isProfileChosen) setDialogVisible(true);
  }, [canPublish, editorFocused, isProfileChosen]);

  const handleMenuChange: MenuProps['onClick'] = ({ key }) => {
    const profile = key === 'group' ? groupProfile : userProfile;
    dispatch(changeProfile(storeKey, profile));
    setSelectedProfile(key as ProfileType);
    setDropdownVisible(false);
    setProfileChosen(true);
    onProfileSwitch?.();
  };

  const handleKeepProfile = () => {
    setDropdownVisible(false);
    setDialogVisible(false);
    setProfileChosen(true);
    onProfileSwitch?.();
  };
  const handleChangeProfile = () => {
    setDropdownVisible(true);
    setDialogVisible(false);
  };

  const menu = (
    <Menu onClick={handleMenuChange}>
      <Menu.ItemGroup title={<h5>{t('groups.postAs')}</h5>}>
        <S.MenuItem key="group" selected={selectedProfile === 'group'}>
          <ProfileImage className="avatar" profile={groupProfile} />
          {groupProfile.name}
        </S.MenuItem>
        <S.MenuItem key="user" selected={selectedProfile === 'user'}>
          <ProfileImage className="avatar" profile={userProfile} />
          {userProfile.name}
        </S.MenuItem>
      </Menu.ItemGroup>
    </Menu>
  );
  return (
    <>
      {isAdmin ? (
        <Popconfirm
          cancelText={t('new-post.profileConfirmCancel')}
          okButtonProps={{ style: { fontWeight: 600 } }}
          okText={t('new-post.profileConfirmOk')}
          onCancel={handleKeepProfile}
          onConfirm={handleChangeProfile}
          placement="bottom"
          title={
            <Trans i18nKey="new-post.profileConfirm">
              Você está postando como: <strong>{{ name: currentProfile?.name }}</strong>
              <br />
              isso está certo?
            </Trans>
          }
          visible={dialogVisible}
          disabled={!canPublish}
        >
          <Dropdown
            onVisibleChange={setDropdownVisible}
            overlay={menu}
            placement="bottomLeft"
            trigger={['click']}
            visible={dropdownVisible}
            disabled={!canPublish}
          >
            <div
              css={css`
                float: left;
                margin-right: 12px;
                cursor: pointer;
              `}
            >
              {currentProfile && (
                <ProfileImage
                  css={css`
                    margin-right: 4px;
                  `}
                  profile={
                    currentProfile.profileType === PROFILE_TYPES.group ? groupProfile : userProfile
                  }
                />
              )}
              {canPublish && <FontAwesomeIcon icon={faCaretDown} size="lg" />}
            </div>
          </Dropdown>
        </Popconfirm>
      ) : (
        <ProfileImage
          css={css`
            float: left;
            margin-right: 12px;
          `}
          profile={userProfile}
        />
      )}
    </>
  );
};

export default ProfileSwitch;
