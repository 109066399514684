import { VFC } from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

//#region TYPES
export interface BadgeProps {
  count?: number;
  size?: 'sm' | 'nm';
}
//#endregion

//#region STYLES
const BadgeStyled = styled.div<{ hasCount?: boolean; size: 'sm' | 'nm' }>`
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  font-size: 0.5rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.danger};
  border-radius: 9px;

  ${props => {
    switch (props.size) {
      case 'sm': {
        if (props.hasCount)
          return css`
            right: -10px;
            width: 17px;
            height: 17px;
          `;
        else
          return css`
            right: -6px;
            width: 14px;
            height: 14px;
          `;
      }
      case 'nm': {
        if (props.hasCount)
          return css`
            right: -10px;
            width: 20px;
            height: 20px;
          `;
        else
          return css`
            right: -6px;
            width: 17px;
            height: 17px;
          `;
      }
      default:
        return '';
    }
  }}
`;
//#endregion

const Badge: VFC<BadgeProps> = ({ count, size = 'sm' }) => {
  const getQuantity = (counter?: number) => {
    return counter != null && counter > 99 ? 99 : counter;
  };

  return (
    <BadgeStyled hasCount={count != null && count > 0} size={size}>
      {getQuantity(count)}
    </BadgeStyled>
  );
};

export default Badge;
