import { useState, useEffect, useRef, useCallback, useMemo, useReducer } from 'react';
import { ContentState, EditorState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import { useTranslation } from 'react-i18next';
import { shallowEqual } from 'react-redux';
import { v4 } from 'uuid';
import { isEmpty } from 'lodash/fp';
import produce, { immerable } from 'immer';
import moment from 'moment';
import PubSub from 'pubsub-js';
import Linkify from 'react-linkify';
import axios from 'axios';

// GLOBAL COMPONENTS
import { css, Theme, useTheme } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faCircleNotch,
  faTimes,
  faUser,
  faPaperPlane,
  faExpandAlt,
  faCompressAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { faSmile } from '@fortawesome/pro-regular-svg-icons';
import { faArrowToBottom, faTrash } from '@fortawesome/pro-light-svg-icons';
import { Modal, Dropdown, Button, Menu } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';

import { useAuth } from 'hooks';
import { useDispatch, useSelector } from 'store';
import {
  OpenChat,
  CloseChat,
  newMessage,
  getMessages,
  messageNotification,
  toggleChatCollapsed,
  ClearCount,
  deleteMessage,
} from 'store/actions/chatActions';
import {
  chatSelector,
  makeGetChatMessagesSelector,
  getHasMore,
} from 'store/selectors/chatSelectors';
import { isUserOnline } from 'store/selectors/usersSelectors';
import { fetchMedia } from 'lib/helper';
import { CHAT_ROOM_TYPES, MEDIA_TYPES, PROFILE_TYPES } from 'lib/constants';
import * as storage from 'lib/storageHelper';

import ProfileImage from 'components/layout/Common/ProfileImage/ProfileImage';
import DocViewer, { Document } from 'components/common/DocViewer';

import { emojiThemeChat } from 'styles/draftjs/emojiTheme';

import ChatMembersModal from './Modal/ChatMembersModal';
import ChatMedia from './ChatMedia';
import ChatAttachmentActions, { FileType } from './ChatAttachmentActions';
import ChatMediaPreview from './ChatMediaPreview';
import { useChatNotification } from './useChatNotification';

import * as S from './ChatStyles';

//#region TYPES
export interface ChatProps {
  roomId: number;
  chattingWith: ChatRoom;
  defaultMessage?: string;
}

type ChatState = {
  editorState: EditorState;
  files: ChatFile[];
  showMedia: { media: Media | undefined; show: boolean };
  showMembers: boolean;
};

type ChangeEditorStateAction = {
  type: 'changeEditorState';
  payload: EditorState;
};

type SetFilesAction = {
  type: 'setFiles';
  payload: ChatFile[];
};

type ShowMediaAction = {
  type: 'showMedia';
  payload: ChatState['showMedia'];
};

type ResetEditorAction = {
  type: 'resetEditor';
  payload?: undefined;
};

type ShowMembersAction = {
  type: 'showMembers';
  payload: boolean;
};

type ActionType =
  | ChangeEditorStateAction
  | SetFilesAction
  | ShowMediaAction
  | ResetEditorAction
  | ShowMembersAction;
//#endregion

// #region REDUCER
// @ts-expect-error
EditorState[immerable] = true;

const initialState: ChatState = {
  editorState: EditorState.createEmpty(),
  files: [],
  showMedia: { media: undefined, show: false },
  showMembers: false,
};
const actions = {
  changeEditorState(state: ChatState, editorState: EditorState) {
    state.editorState = editorState;
  },
  setFiles(state: ChatState, files: ChatFile[]) {
    state.files = files;
  },
  showMedia(state: ChatState, media: ChatState['showMedia']) {
    state.showMedia = media;
  },
  resetEditor(state: ChatState) {
    state.editorState = EditorState.moveFocusToEnd(EditorState.createEmpty());
    state.files = [];
  },
  showMembers(state: ChatState, visible: boolean) {
    state.showMembers = visible;
  },
};

const init = (defaultMessage: string) => (initialState: ChatState) => ({
  ...initialState,
  editorState: EditorState.createWithContent(ContentState.createFromText(defaultMessage)),
});

function reducer(state: ChatState, action: ActionType) {
  const fn = actions[action.type];
  if (fn) return produce(state, (draft: ChatState) => fn(draft, action.payload as any));

  return state;
}
// #endregion

function makeEmojiPlugin(theme: Theme) {
  const emojiPlugin = createEmojiPlugin({
    theme: emojiThemeChat(theme),
    selectButtonContent: <FontAwesomeIcon icon={faSmile} />,
    useNativeArt: true,
  });
  return emojiPlugin;
}

const { gtag } = window;

const Chat = ({ roomId, chattingWith, defaultMessage = '' }: ChatProps) => {
  const theme = useTheme();

  const { emojiPlugin, EmojiSuggestions, EmojiSelect } = useMemo(() => {
    const emojiPlugin = makeEmojiPlugin(theme);
    const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

    return { emojiPlugin, EmojiSuggestions, EmojiSelect };
  }, [theme]);

  const [state, chatDispatch] = useReducer(reducer, initialState, init(defaultMessage));
  const [sending, setSendingState] = useState(false);
  const contentRef = useRef<HTMLElement>(null);
  const messagesWrapperRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<Editor>(null);
  const divRef = useRef<HTMLDivElement>(null);
  // INSTANCE REFS
  const mediaType = useRef<FileType>();

  const chattingWithUser = chattingWith.users[0].user;

  const { t } = useTranslation('chat');
  const { token, userId, userInfo } = useAuth();
  const currentUserName = userInfo.user.name;

  // REDUX
  const { current: getChatMessages } = useRef(makeGetChatMessagesSelector());

  const dispatch = useDispatch();
  const { listOpen, chatOpen, newMessageNotification, chatCollapsed } = useSelector(chatSelector);
  const messageList = useSelector(state => getChatMessages(state, roomId));
  const hasMore = useSelector(state => getHasMore(state, roomId), shallowEqual);
  const userOnline = useSelector(
    state => isUserOnline(state, chattingWithUser.userId),
    shallowEqual
  );

  useChatNotification({
    onNewChatMessage(data) {
      if (chatOpen) {
        dispatch(getMessages(roomId)).then(() => {
          scrollToBottom();
          updateMessagesStorage(roomId);
        });
      } else if (roomId === data.room.roomId) {
        dispatch(messageNotification());
        dispatch(ClearCount(roomId));
      }
    },
    onDeletedMessage(data) {
      const { chatRoomId, messageId } = data;
      if (chatRoomId === roomId) {
        dispatch(deleteMessage(chatRoomId, messageId));
      }
    },
    onDeletedChatRoom(data) {
      const { chatRoomId } = data;
      if (chatRoomId === roomId) {
        dispatch(CloseChat(chatRoomId));
      }
    },
  });

  const scrollToBottom = () => {
    const el = messagesWrapperRef.current;
    el?.scrollTo(0, el.scrollHeight - el.clientHeight);
  };

  const focus = () => editorRef.current?.focus();

  const excludeMessage = useCallback(
    id => {
      axios.delete(`/api/chat/deleteMessage?id=${id}`).then(({ status }) => {
        if (status === 200) {
          dispatch(deleteMessage(roomId, id));
          PubSub.publish('RELOAD_CHAT_SINGLE', roomId);
        }
      });
    },
    [roomId, dispatch]
  );

  const onClickDeleteMessage = useCallback(
    id => {
      Modal.confirm({
        title: 'Excluir mensagem',
        content: 'Você realmente quer excluir esta mensagem?',
        okText: 'Sim, excluir',
        okType: 'danger',
        centered: true,
        autoFocusButton: 'cancel',
        onOk: () => excludeMessage(id),
      });
    },
    [excludeMessage]
  );

  const sendMessage = async () => {
    const textMessage = state.editorState.getCurrentContent().getPlainText();

    if (textMessage || state.files.length > 0) {
      let objectUserChat;
      if (roomId) {
        objectUserChat = {
          roomId,
          type: chattingWith.type,
          roomName: chattingWith.name,
          description: textMessage,
          descriptionText: textMessage,
          ...(mediaType.current === 'image' && { images: state.files }),
          ...(mediaType.current === 'file' && { files: state.files }),
        };
      } else {
        objectUserChat = {
          type: chattingWith.type,
          roomName: chattingWith.name,
          roomUserId: chattingWith.users.map(u => u.user.userId),
          description: textMessage,
          descriptionText: textMessage,
          ...(mediaType.current === 'image' && { images: state.files }),
          ...(mediaType.current === 'file' && { files: state.files }),
        };
      }

      setSendingState(true);
      await dispatch(newMessage(objectUserChat));
      setSendingState(false);

      chatDispatch({ type: 'resetEditor' });
      scrollToBottom();
      focus();
      PubSub.publish('RELOAD_CHAT', null);
    }
  };

  const getDocuments = (media: Media | undefined) => {
    if (media) {
      const document: Document = {
        uri: fetchMedia(media.mediaId, token),
        fileType: media.fileName.split('.').pop(),
        fileData: fetchMedia(media.mediaId, token),
        downloadUri: fetchMedia(media.mediaId, token, true),
        fileName: media.originalFileName,
      };

      return [document];
    }

    return [];
  };

  // #region EVENTS

  const handleLoadMoreMessage = useCallback(() => {
    if (messageList.length > 0) {
      dispatch(getMessages(roomId, messageList[0].message.created));
    }
  }, [roomId, dispatch, messageList]);

  const handleSubmit = (e: React.KeyboardEvent<{}>, _: any) => {
    if (e.shiftKey) return 'not-handled';
    divRef.current?.click(); // Workaround to close emoji popover
    sendMessage();
    return 'handled';
  };

  const onChange = (newEditorState: EditorState) =>
    chatDispatch({ type: 'changeEditorState', payload: newEditorState });

  const toggleOpen = () => {
    dispatch(OpenChat());
    if (newMessageNotification) {
      dispatch(getMessages(roomId)).then(() => scrollToBottom());
    }
    focus();
  };

  const closeChat = (e: React.MouseEvent) => {
    e.stopPropagation();

    dispatch(CloseChat(roomId));
  };

  const handleShowMembers = useCallback(e => {
    chatDispatch({ type: 'showMembers', payload: true });
    e.stopPropagation();
  }, []);

  const handleChatCollapsed = useCallback(
    e => {
      dispatch(toggleChatCollapsed());
      setTimeout(() => scrollToBottom(), 350);
      e.stopPropagation();
    },
    [dispatch]
  );

  const handleFileAdded = (acceptedFiles: File[], fileType: FileType) => {
    const files = state.files;
    if (acceptedFiles && acceptedFiles.length > 0) {
      const totalFilesCount = files.length + acceptedFiles.length;
      let tempNewFiles = acceptedFiles;

      if (totalFilesCount >= 4 && fileType === 'image') {
        tempNewFiles = tempNewFiles.slice(0, 4 - files.length);
      }

      const newFiles = tempNewFiles.map(file => ({
        blob: file,
        name: file.name,
        size: file.size,
        preview: URL.createObjectURL(file),
        index: v4(),
        new: true,
      }));

      if (mediaType.current === fileType && fileType === 'image') {
        chatDispatch({
          type: 'setFiles',
          payload: [...files, ...newFiles],
        });
      } else {
        chatDispatch({
          type: 'setFiles',
          payload: newFiles,
        });
        mediaType.current = fileType;
      }
    }
  };

  const handlePastedFiles = (pastedFiles: File[]) => {
    const file = pastedFiles[0];

    if (file.type.includes('image')) {
      handleFileAdded([file], 'image');
    }

    return 'handled' as const;
  };

  const handleFileRemoved = (file: ChatFile) => {
    URL.revokeObjectURL(file.preview);
    chatDispatch({
      type: 'setFiles',
      payload: state.files.filter(f => f.index !== file.index),
    });
  };

  const handleMediaClick = useCallback((media: Media) => {
    chatDispatch({ type: 'showMedia', payload: { media, show: true } });
  }, []);
  // #endregion

  // #region EFFECTS
  useEffect(() => {
    if (chatOpen) gtag('event', 'screen_view', { screen_name: 'Chat' });
  }, [chatOpen]);

  useEffect(() => {
    focus();
  }, []);

  useEffect(() => {
    dispatch(getMessages(roomId)).then(() => {
      scrollToBottom();
      updateMessagesStorage(roomId);
    });

    focus();
  }, [dispatch, roomId]);

  useEffect(
    () => () => state.files.forEach(file => URL.revokeObjectURL(file.preview)),
    [state.files]
  );
  // #endregion

  const renderSendButton = () => {
    return (
      <S.SendButton type="link" onClick={sendMessage}>
        {sending ? (
          <FontAwesomeIcon icon={faCircleNotch} spin />
        ) : (
          <FontAwesomeIcon icon={faPaperPlane} />
        )}
      </S.SendButton>
    );
  };

  const renderUserMessage = (chatMessage: ChatMessageInfo, i: number) => {
    const { message, media } = chatMessage;
    const isUserCreated = message.userId === userId;
    const mediaVisible = media && media.length > 0;
    const isFile = !!media && media[0].mediaType === MEDIA_TYPES.file;

    if (message.eventType === 1) {
      return (
        <S.LeaveChatContainer key={message.messageId}>
          <div className="leaveMessage">{t('leftMessage', { name: message.descriptionText })}</div>
        </S.LeaveChatContainer>
      );
    }

    return (
      <S.ChatContent key={i} userCreated={isUserCreated}>
        {isUserCreated ? (
          <ProfileImage
            className="user-avatar"
            profile={{
              profileId: userId,
              name: currentUserName,
              profileType: PROFILE_TYPES.user,
            }}
            size="xs"
          />
        ) : (
          <ProfileImage
            className="user-avatar"
            profile={{
              profileId: chatMessage.user.userId,
              name: chatMessage.user.name,
              profileType: PROFILE_TYPES.user,
            }}
            size="xs"
            checkOnline
          />
        )}

        <S.ChatContainer>
          <S.ChatUserName userCreated={isUserCreated}>
            <strong>{chatMessage.user.name}</strong>
            {isUserCreated && (
              <Dropdown
                trigger={['click']}
                overlay={() => (
                  <Menu>
                    <Menu.Item
                      onClick={({ domEvent }) => {
                        domEvent.stopPropagation();
                        onClickDeleteMessage(message.messageId);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} /> <span>Excluir mensagem</span>
                    </Menu.Item>
                    {isFile && (
                      <Menu.Item>
                        <FontAwesomeIcon icon={faArrowToBottom} />{' '}
                        <Button
                          type="link"
                          href={fetchMedia(media[0].mediaId, token, true)}
                          download={media[0].originalFileName}
                          style={{ padding: 0 }}
                        >
                          {t('default:viewer.download')}
                        </Button>
                      </Menu.Item>
                    )}
                  </Menu>
                )}
                className="dropdown"
              >
                <Button className="meatball" onClick={e => e.stopPropagation()} type="link">
                  <S.DropdownText>•••</S.DropdownText>
                </Button>
              </Dropdown>
            )}
          </S.ChatUserName>

          <S.ChatWrapper visible={!isEmpty(message.description)} userCreated={isUserCreated}>
            <S.ChatText userCreated={isUserCreated}>
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a
                    target="_blank"
                    href={decoratedHref}
                    key={key}
                    style={{ color: '#3498db' }}
                    rel="noopener noreferrer"
                  >
                    {decoratedText}
                  </a>
                )}
              >
                {message.description}
              </Linkify>
            </S.ChatText>
          </S.ChatWrapper>
        </S.ChatContainer>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <S.MediaWrapper visible={mediaVisible} userCreated={isUserCreated}>
            {mediaVisible && <ChatMedia media={media} onMediaClick={handleMediaClick} />}
          </S.MediaWrapper>
          <S.ChatTime userCreated={isUserCreated}>
            <span>{moment(message.created).format(t('default:formats.datetime'))}</span>
          </S.ChatTime>
        </div>
      </S.ChatContent>
    );
  };

  return (
    <div>
      {messageList && (
        <S.MiniChat collapsed={chatCollapsed} chatOpen={chatOpen} listOpen={listOpen}>
          <S.Header
            role="presentation"
            newMessageNotification={newMessageNotification}
            onClick={toggleOpen}
          >
            <S.RoomName userOnline={userOnline}>
              {chattingWith.name}
              {chattingWith.type === CHAT_ROOM_TYPES.individual && (
                <FontAwesomeIcon icon={faCircle} className="status-icon" />
              )}
            </S.RoomName>
            <S.HeaderActionsContainer>
              {chattingWith.type === CHAT_ROOM_TYPES.group && (
                <S.HeaderButton type="link" onClick={handleShowMembers}>
                  <FontAwesomeIcon icon={faUser} />
                  <span
                    css={theme => css`
                      margin-left: 4px;
                      ${theme.typography.fontxSmall()}
                    `}
                  >
                    {chattingWith.users.length}
                  </span>
                </S.HeaderButton>
              )}
              <S.HeaderButton type="link" onClick={handleChatCollapsed}>
                <FontAwesomeIcon icon={chatCollapsed ? faExpandAlt : faCompressAlt} />
              </S.HeaderButton>
              <S.HeaderButton type="link" onClick={closeChat}>
                <FontAwesomeIcon icon={faTimes} />
              </S.HeaderButton>
            </S.HeaderActionsContainer>
          </S.Header>

          <S.MessagesWrapper ref={messagesWrapperRef}>
            <InfiniteScroll
              initialLoad={false}
              threshold={100}
              isReverse
              getScrollParent={() => contentRef.current}
              loadMore={handleLoadMoreMessage}
              hasMore={hasMore}
              useWindow={false}
            >
              {messageList.map((chatMessage, i) => renderUserMessage(chatMessage, i))}
            </InfiniteScroll>
          </S.MessagesWrapper>

          <ChatMediaPreview
            visible={state.files.length > 0}
            files={state.files}
            onRemoveFile={handleFileRemoved}
          />
          <S.ChatEditor>
            <div
              className="editor-wrapper"
              role="textbox"
              tabIndex={0}
              translate="no"
              onClick={focus}
            >
              <Editor
                autoCorrect="off"
                editorKey="chatEditor"
                editorState={state.editorState}
                handleReturn={handleSubmit}
                onChange={onChange}
                placeholder={t('inputPlaceholder', {
                  name:
                    chattingWith.type === CHAT_ROOM_TYPES.individual
                      ? chattingWithUser.name
                      : chattingWith.name,
                })}
                readOnly={sending}
                stripPastedStyles
                ref={editorRef}
                plugins={[emojiPlugin]}
                handlePastedFiles={handlePastedFiles}
              />
              <div ref={divRef} style={{ display: 'none' }} />
              <EmojiSuggestions />
            </div>
            <S.ChatActions>
              <ChatAttachmentActions
                emojiSelect={<EmojiSelect />}
                filesCount={state.files.length}
                onFileAdded={handleFileAdded}
              />
              {renderSendButton()}
            </S.ChatActions>
          </S.ChatEditor>
        </S.MiniChat>
      )}
      <Modal
        footer={null}
        onCancel={() =>
          chatDispatch({
            type: 'showMedia',
            payload: { ...state.showMedia, show: false },
          })
        }
        visible={state.showMedia.show}
        style={{ top: 50 }}
        width="80vw"
      >
        <S.MediaContainer>
          <DocViewer documents={getDocuments(state.showMedia.media)} />
        </S.MediaContainer>
      </Modal>
      {chattingWith.type === CHAT_ROOM_TYPES.group && (
        <ChatMembersModal
          room={chattingWith}
          visible={state.showMembers}
          onClose={() => chatDispatch({ type: 'showMembers', payload: false })}
        />
      )}
    </div>
  );
};

export default Chat;

function updateMessagesStorage(roomId: number) {
  let sessionData = storage.get<[number, { roomId: number; userId: number; name: string }][]>(
    'newTitleChatMessage',
    'sessionStorage'
  );
  if (sessionData != null) {
    const messages = new Map(sessionData);
    messages.delete(roomId);

    storage.set({ key: 'newTitleChatMessage', value: Array.from(messages) }, 'sessionStorage');

    PubSub.publish('READ_CHAT', messages);
  }
}
