import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { shade, transparentize } from 'polished';

export const previewImageContainer = css`
  position: relative;
  display: flex;
  max-width: 100%;
  padding-bottom: 8px;
  overflow-x: auto;
  overflow-y: auto;

  :empty {
    display: none;
  }
`;
export const mediaPreview = css`
  flex-basis: calc(25% - 6px);
  flex-grow: 0;
  flex-shrink: 0;
  width: calc(25% - 6px);
  height: 96px;
  margin-right: 8px;
`;

export const Wrapper = styled.div`
  margin-top: 16px;

  :empty {
    display: none;
  }
`;
export const AddImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => shade(0.1, theme.colors.primary)};
  border: 1px dashed ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  transition: all 0.2s ease-out;
  ${({ theme }) => theme.typography.fontSmall()};

  :hover {
    color: ${({ theme }) => shade(0.1, theme.colors.primary)};
    cursor: pointer;
    background-color: ${({ theme }) => transparentize(0.97, theme.colors.primary)};
    border-color: ${({ theme }) => shade(0.1, theme.colors.primary)};
    transition: all 0.05s ease-in;
  }
  :active {
    background-color: ${({ theme }) => transparentize(0.95, theme.colors.primary)};
    transform: scale(0.99);
  }

  .image-text {
    margin-top: auto;
    font-weight: 600;
    color: inherit;
    text-align: center;
  }
  .image-count {
    width: 100%;
    margin-bottom: auto;
    color: ${({ theme }) => shade(0.15, theme.colors.primary)};
    text-align: center;

    ${({ theme }) => theme.typography.fontxSmall()};
  }
`;
