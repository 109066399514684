import { Fragment, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, UseFormMethods, useFieldArray } from 'react-hook-form';
import { Button, Checkbox, DatePicker } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/pro-light-svg-icons';

import { FormItem } from 'components/common/Forms/Forms';
import {
  disabledDate,
  disabledTime,
} from 'components/layout/Common/Modal/PostScheduling/PostScheduling';

import {
  CheckAnonymous,
  ContainerWrapper,
  FormBody,
  formItemCss,
  Input,
  optionCss,
  RemoveButton,
} from './SurveyFormStyles';

//#region TYPES
export interface SurveyFormProps extends Pick<UseFormMethods, 'control' | 'errors' | 'register'> {
  editSurvey?: boolean;
  loading?: boolean;
  renderScopeSelector?: () => React.ReactNode;
}
//#endregion

const SurveyForm: VFC<SurveyFormProps> = ({
  control,
  editSurvey = false,
  errors,
  loading,
  register,
  renderScopeSelector,
}) => {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({ control, name: 'options' });

  return (
    <Fragment>
      <FormBody>
        <FormItem
          css={formItemCss}
          errors={errors}
          inputName="title"
          label={t('common.title')}
          labelHelper={t('common.required')}
        >
          <Input
            disabled={loading}
            inputSize="large"
            ref={register({ required: t('validation.fieldRequired').toString() })}
          />
        </FormItem>
        {!editSurvey &&
          fields.map((item, index) => (
            <FormItem
              key={item.id}
              css={optionCss}
              errors={errors}
              inputName={`options[${index}].description`}
              label={'- ' + t('survey.optionCount', { number: index + 1 })}
              labelHelper={
                index > 1 ? (
                  <RemoveButton
                    type="text"
                    icon={<FontAwesomeIcon icon={faTimes} className="anticon" />}
                    onClick={remove.bind(null, index)}
                  />
                ) : (
                  t('common.required')
                )
              }
            >
              <Input
                disabled={loading}
                inputSize="large"
                defaultValue={item.description}
                ref={register({ required: t('validation.fieldRequired').toString() })}
              />
            </FormItem>
          ))}
      </FormBody>
      {!editSurvey && (
        <div>
          <Button
            type="link"
            icon={
              <FontAwesomeIcon icon={faPlus} className="anticon" style={{ fontSize: '1.5rem' }} />
            }
            style={{ width: 'auto' }}
            onClick={() => append({ description: '' })}
          />
        </div>
      )}
      <ContainerWrapper>
        {renderScopeSelector && <div>{renderScopeSelector()}</div>}
        <div>
          <label htmlFor="end-date">{t('survey.selectSurveyEnd')}</label>
          <Controller
            control={control}
            name="endDate"
            rules={{ required: t('validation.fieldRequired').toString() }}
            render={({ name, value, onBlur, onChange, ref }) => (
              <DatePicker
                disabled={loading}
                disabledDate={disabledDate}
                disabledTime={disabledTime}
                format={t('formats.datetime')}
                id="end-date"
                name={name}
                showTime={{ format: 'HH:mm' }}
                showToday={false}
                style={{ width: '100%' }}
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
              />
            )}
          />
        </div>
      </ContainerWrapper>
      {!editSurvey && (
        <CheckAnonymous>
          <Controller
            control={control}
            name="anonymous"
            render={({ name, value, onChange, ref }) => (
              <Checkbox
                checked={value}
                disabled={loading}
                name={name}
                onChange={e => onChange(e.target.checked)}
                ref={ref}
              />
            )}
          />
          <small>{t('survey.checkAnonymous')}</small>
        </CheckAnonymous>
      )}
    </Fragment>
  );
};

export default SurveyForm;
