import { NewPostActions } from './types';

export type Visibility = 'public' | 'private' | 'followers' | 'group' | 'admin';

export interface NewPostProfile {
  profileId: number;
  name: string;
  profileType: ProfileTypes;
  mediaId?: number;
}

export interface NewPost {
  visibility: Visibility;
  content?: string;
  targets: number[];
  profile?: NewPostProfile;
}

interface Posts {
  ['NEW_POST']?: NewPost;
  ['NEW_POST_GROUP']?: NewPost;
}

export type StoreKey = keyof Posts;

export interface NewPostState {
  posts: Posts;
}

interface ChangeVisibility {
  type: typeof NewPostActions.CHANGE_VISIBILITY;
  payload: { storeKey: StoreKey; visibility: Visibility };
}
interface ChangeProfile {
  type: typeof NewPostActions.CHANGE_PROFILE;
  payload: { storeKey: StoreKey; profile: NewPostProfile };
}
interface AddTarget {
  type: typeof NewPostActions.ADD_TARGET;
  payload: { storeKey: StoreKey; targetsId: number[] };
}
interface Init {
  type: typeof NewPostActions.INIT;
  payload: { storeKey: StoreKey; visibility: Visibility };
}

export type NewPostActionType = ChangeVisibility | ChangeProfile | AddTarget | Init;

export { NewPostActions };
