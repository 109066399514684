import { useEffect, useState } from 'react';
import * as storage from 'lib/storageHelper';

export default function useStorage<T>(
  key: string,
  storageType: storage.StorageType = 'localStorage'
) {
  const [value, setValue] = useState(() => storage.get<T>(key, storageType));

  useEffect(() => {
    storage.set({ key, value }, storageType);
  }, [key, storageType, value]);

  return [value, setValue] as const;
}
