import axios from 'axios';
import { Dispatch, ActionCreator, Action } from 'redux';
import { forEach, isEmpty, omit } from 'lodash/fp';

import { MEDIA_TYPES, PROFILE_TYPES } from 'lib/constants';
import WebSocketHelper from 'lib/Websocket';
import DesktopNotifications from 'lib/DesktopNotifications';
import getProfilePhoto from 'lib/getProfilePhoto';
import { getRoom, GetRoomParams, updateChatPhoto } from 'lib/api/chat';
import { isAxiosError, truncateString } from 'lib/helper';

import { AsyncAction } from 'store/actions';
import { ChatActions, ChatActionType } from 'store/types/chatTypes';
import { selectCurrentUser } from 'store/selectors/authSelectors';
import { getActiveRoom } from 'store/selectors/chatSelectors';

import { setUserConnected } from './usersActions';

// #region TYPES
export interface MessageParams {
  roomId?: number;
  type: ChatRoomTypes;
  roomName: string;
  roomUserId?: number[];
  description: string;
  descriptionText: string;
  images?: ChatFile[];
  files?: ChatFile[];
}

export interface SocketChatResult {
  room: { roomId: number; type: ChatRoomTypes; roomName: string };
  message: ChatMessageInfo;
  user: UserInfo;
}

export interface SocketMessageResult {
  chatRoomId: number;
  created: string;
  deleted: boolean;
  deletedDate?: string;
  description?: string;
  descriptionText?: string;
  eventType: number;
  hasMedia: boolean;
  mediaType: number;
  messageId: number;
  userId: number;
}
// #endregion

export function OpenList(): ChatActionType {
  return {
    type: ChatActions.OPEN_LIST,
  };
}

export function fetchChatList(list: Array<ChatRoomInfo>): ChatActionType {
  return { type: ChatActions.FETCH_CHAT_LIST, payload: { list } };
}

export function roomUpdated(chatRoomInfo: ChatRoomInfo): ChatActionType {
  return { type: ChatActions.ROOM_UPDATED, payload: chatRoomInfo };
}

export function leaveChat(roomId: number): ChatActionType {
  return {
    type: ChatActions.LEAVE_ROOM,
    payload: { roomId },
  };
}

export function deleteMessage(roomId: number, messageId: number): ChatActionType {
  return {
    type: ChatActions.DELETE_MESSAGE,
    payload: { messageId, roomId },
  };
}

export function OpenChat(): ChatActionType {
  return {
    type: ChatActions.OPEN_CHAT,
  };
}

export function ClearCount(roomId: number): ChatActionType {
  return {
    type: ChatActions.CLEAR_COUNT,
    payload: { roomId },
  };
}

export function CloseChat(roomId: number): ChatActionType {
  return {
    type: ChatActions.CLOSE_CHAT,
    payload: { roomId },
  };
}

export function toggleChatCollapsed(): ChatActionType {
  return { type: ChatActions.TOGGLE_CHAT_COLLAPSED };
}

export function NewChatWindow(chatRoom: ChatRoomInfo, defaultMessage = ''): ChatActionType {
  return {
    type: ChatActions.NEW_CHAT_WINDOW,
    payload: { chatRoom, defaultMessage },
  };
}

export function clearList(): ChatActionType {
  return {
    type: ChatActions.CLEAR_LIST,
  };
}

export const messageNotification: ActionCreator<ChatActionType> = () => ({
  type: ChatActions.NEW_MESSAGE,
});

export const clearMessageNotification: ActionCreator<ChatActionType> = () => ({
  type: ChatActions.CLEAR_MESSAGE_NOTIFICATION,
});

type GetMessagesAction = (
  roomId: number,
  date?: string
) => AsyncAction<Promise<void>, ChatActionType>;
export const getMessages: GetMessagesAction = (roomId, date) => async dispatch => {
  const params = { roomId, offsetDate: date ?? null };
  const { data, status } = await axios.get<ChatMessageInfo[]>('/api/chat/getMessage', {
    params: { ...params, isWeb: true },
  });
  if (status === 200) {
    dispatch({
      type: ChatActions.MESSAGES_FETCHED,
      payload: {
        messages: data,
        roomId,
      },
    });
    dispatch(ClearCount(roomId));
  }
};

type NewMessageAction = (chatMessage: MessageParams) => AsyncAction<Promise<void>, ChatActionType>;
export const newMessage: NewMessageAction = chatMessage => async dispatch => {
  const formData = new FormData();
  // @ts-expect-error
  const forEachKey = forEach.convert({ cap: false });

  chatMessage.images?.forEach(image => formData.append('images', image.blob, image.name));
  chatMessage.files?.forEach(file => formData.append('files', file.blob, file.name));
  forEachKey((value: any, key: string) => formData.append(key, value))(omit('images', chatMessage));

  const { data, status } = await axios.post<NewMessageResult>('/api/chat/sendMessage', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  if (status === 201) {
    dispatch({
      type: ChatActions.SET_ACTIVE_ROOM,
      payload: {
        chatRoomId: data.room.roomId,
      },
    });
  }
  dispatch({
    type: ChatActions.MESSAGE_SENT,
    payload: { roomId: data.room.roomId, message: data.message },
  });
};

type GetChatListAction = () => AsyncAction<Promise<void>, ChatActionType | Action>;
export const getChatList: GetChatListAction = () => async dispatch => {
  try {
    const rooms = await getRoom();
    const list = isEmpty(rooms) ? [] : rooms;
    dispatch(fetchChatList(rooms));

    list.forEach(item => {
      if (item.room.users[0].online) {
        dispatch(setUserConnected(item.room.users[0].user.userId));
      }
    });
  } catch (error) {
    if (isAxiosError(error)) {
      console.error(`Chat list not fetched with status ${error.response?.status}`);
    }
  }
};

type OpenChatRoomAction = (
  params: GetRoomParams,
  defaultMessage?: string
) => AsyncAction<Promise<void>, ChatActionType>;
export const openChatRoom: OpenChatRoomAction =
  (params, defaultMessage = '') =>
  async dispatch => {
    try {
      const rooms = await getRoom(params);
      dispatch(NewChatWindow(rooms[0], defaultMessage));
    } catch (error) {
      if (isAxiosError(error)) {
        console.error(`Chat user not fetched with status ${error.response?.status}`);
      }
    }
  };

export const updateChannelPhoto =
  (
    roomId: number,
    channelPhoto: File
  ): AsyncAction<Promise<void | number | undefined>, ChatActionType> =>
  async dispatch => {
    const result = await updateChatPhoto(roomId, channelPhoto);

    if (typeof result === 'object') {
      dispatch(roomUpdated(result));
      return;
    }

    return result;
  };

function spawnNotification(
  socketResult: SocketChatResult,
  dispatch: Dispatch<any>,
  state: RootState
) {
  const { room, message: messageInfo, user: sender } = socketResult;
  const { roomId, type, roomName } = room;
  const { message, media } = messageInfo;
  const { userId, name } = sender.user;
  const {
    userInfo: { user: currentUser },
  } = selectCurrentUser(state);
  const chattingWith = getActiveRoom(state);

  if (userId !== currentUser.userId && (roomId !== chattingWith.roomId || !document.hasFocus())) {
    const file =
      !!media && media.length > 0 && media[0].mediaType === MEDIA_TYPES.file ? media[0] : null;
    const notifyMessage = `${message.description}
                          ${!!file ? `\n📎 ${truncateString(file.originalFileName, 20)}` : ''}`;

    const notification = DesktopNotifications.notifyChat(
      `${name}${type === 2 ? ` via ${roomName}` : ''}:`,
      notifyMessage,
      (event: Event) => {
        window.focus();
        event.preventDefault();
        notification!.close();
        dispatch(openChatRoom(type === 2 ? { roomId } : { userId }));
      },
      { roomId, senderId: userId },
      getProfilePhoto(userId, PROFILE_TYPES.user)
    );
  }
}

type ConnectSocketAction = () => AsyncAction<Promise<void>, ChatActionType>;
export const connectSocket: ConnectSocketAction = () => {
  return async (dispatch, getState) => {
    WebSocketHelper.onReceiveMessage('NewChatMessage', async (message: SocketChatResult) => {
      await dispatch(getChatList());
      spawnNotification(message, dispatch, getState());
    });
    WebSocketHelper.onReceiveMessage('DeletedMessage', async (message: SocketMessageResult) => {
      const [chatRoomUpdated] = await getRoom({ roomId: message.chatRoomId });
      dispatch(roomUpdated(chatRoomUpdated));
    });
    dispatch({
      type: ChatActions.CHAT_SOCKET_CONNECTED,
    });
  };
};

export function disconnectSocket(): ChatActionType {
  WebSocketHelper.unsubscribeMessage('NewChatMessage');
  return {
    type: ChatActions.CHAT_SOCKET_DISCONNECTED,
  };
}
