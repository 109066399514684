import axios from 'axios';
import qs from 'qs';

import { isLoggedIn } from '../Auth';
import { getOrRenewApiToken, renewApiToken } from '../BearerRenew';
import { API_DOMAIN } from '../constants';

//#region TYPES
type RequestCallback = (token: string) => void;
type Subscribers = RequestCallback[];
//#endregion

axios.defaults.baseURL = API_DOMAIN;
axios.defaults.paramsSerializer = params => {
  return qs.stringify(params, { arrayFormat: 'indices', allowDots: true, skipNulls: true });
};

let isRetrying = false;
let subscribers: Subscribers = [];
const onTokenFetched = (token: string) => {
  subscribers = subscribers.filter(cb => cb(token));
};
const addSubscriber = (cb: RequestCallback) => {
  subscribers.push(cb);
};

axios.interceptors.request.use(
  async config => {
    if (isLoggedIn()) {
      const token = await getOrRenewApiToken();
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => Promise.reject(error)
);
axios.interceptors.response.use(
  response => response,
  error => {
    const { config: originalRequest, response } = error;

    if (response && response.status === 401 && response.data === 'retry') {
      if (!isRetrying) {
        isRetrying = true;
        renewApiToken().then(newToken => {
          isRetrying = false;
          onTokenFetched(newToken);
        });
      }

      return new Promise(resolve => {
        addSubscriber(token => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(axios(originalRequest));
        });
      });
    }
    return Promise.reject(error);
  }
);
