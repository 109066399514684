import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbsUp,
  faFaceSmileHearts,
  faHands,
  faHandHoldingHeart,
} from '@fortawesome/pro-solid-svg-icons';

export type ReactionIconProps = {
  reaction: ReactionLiteral;
  className?: string;
};

export default function ReactionIcon({ reaction = 'like', className }: ReactionIconProps) {
  return (
    <span className={className}>
      <FontAwesomeIcon icon={reactionIcons[reaction]} />
    </span>
  );
}

const reactionIcons = {
  like: faThumbsUp,
  love: faFaceSmileHearts,
  applause: faHands,
  support: faHandHoldingHeart,
};
