import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from 'antd';

import { baseInput } from 'styles/component-styles/inputs';

export const formItemCss = css`
  margin-bottom: 1.5em;

  label {
    font-weight: 600;
  }
`;
export const optionCss = css`
  label {
    font-size: 0.875rem;
    font-weight: 600;
  }
`;

export const FormBody = styled.div`
  margin-bottom: 1em;
`;

export const ContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block: 0.5em;

  > div:first-of-type {
  }

  label {
    display: block;
    font-weight: 600;
  }
`;

export const CheckAnonymous = styled.div`
  color: ${({ theme }) => theme.colors.gray7};

  .ant-checkbox {
    margin-right: 0.5em;
  }
`;

export const Input = styled.input`
  ${baseInput};
`;

export const RemoveButton = styled(Button)`
  width: auto;
  height: auto;
  && {
    :focus,
    :hover {
      background-color: transparent;
    }
  }

  svg {
    color: ${({ theme }) => theme.colors.gray6};
  }
`;
