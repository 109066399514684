import { useAsyncCallback } from 'react-async-hook';

import { getCompany } from 'lib/apiActions';

function useCompany() {
  const unitAsync = useAsyncCallback(getCompany);

  return unitAsync;
}

export default useCompany;
