import { VFC } from 'react';
import { shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button } from 'antd';

import { useAuth } from 'hooks';
import { useDispatch, useSelector } from 'store';
import { canUseFunction } from 'store/selectors/authSelectors';
import { openChatRoom } from 'store/actions/chatActions';
import { PROFILE_TYPES } from 'lib/constants';

import ProfileImage from 'components/layout/Common/ProfileImage/ProfileImage';

//#region TYPES
export interface MembersListProps {
  userInfoList: Omit<UserInfo, 'online' | 'follow'>[];
  onMessageClick?: () => void;
}
//#endregion

//#region STYLES
const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.gray3};

  :last-of-type {
    border: 0;
  }
`;

const UserContainer = styled.div`
  display: flex;

  .user-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 8px;
    line-height: 1.25;
  }

  .occupation-container {
    ${({ theme }) => theme.typography.fontSmall(1.43)};
  }
`;

const MessageButton = styled(Button)`
  height: 24px;
  padding: 0 0.57em;
  color: ${({ theme }) => theme.colors.gray10};
  border: 1px solid ${({ theme }) => theme.colors.gray10};
`;
//#endregion

const UsersList: VFC<MembersListProps> = ({ userInfoList, onMessageClick }) => {
  const { t } = useTranslation();
  const { userInfo: loggedUser } = useAuth();

  const dispatch = useDispatch();
  const chatFunction = useSelector(state => canUseFunction(state, 'chat'), shallowEqual);

  const handleOpenChat = (userId: number) => {
    dispatch(openChatRoom({ userId }));
    onMessageClick?.();
  };

  return (
    <List>
      {userInfoList.map(userInfo => {
        const { user } = userInfo;

        return (
          <ListItem key={user.userId}>
            <UserContainer>
              <ProfileImage
                profile={{
                  profileId: user.userId,
                  name: user.name,
                  profileType: PROFILE_TYPES.user,
                  mediaId: user.mediaId,
                }}
                size="sm"
                checkOnline
              />
              <div className="user-description">
                <strong>{user.name}</strong>
                {userInfo.occupation && userInfo.department && (
                  <div className="occupation-container ">
                    {userInfo.occupation?.description} • {userInfo.department?.description}
                  </div>
                )}
              </div>
            </UserContainer>
            {chatFunction && user.userId !== loggedUser.user.userId && (
              <MessageButton onClick={handleOpenChat.bind(null, user.userId)}>
                {t('common.message')}
              </MessageButton>
            )}
          </ListItem>
        );
      })}
    </List>
  );
};

export default UsersList;
