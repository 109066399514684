import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Spin, Tooltip } from 'antd';
import { useTheme } from '@emotion/react';
import type { EditorState } from 'draft-js';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';
import createLinkPlugin from '@draft-js-plugins/anchor';
import createMentionPlugin, { MentionData } from '@draft-js-plugins/mention';
import type { MentionSuggestionsPubProps } from '@draft-js-plugins/mention/lib/MentionSuggestions/MentionSuggestions';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import { BoldButton, ItalicButton, UnderlineButton } from '@draft-js-plugins/buttons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile, faSquarePollHorizontal } from '@fortawesome/pro-solid-svg-icons';

import { useAuth, usePermissions } from 'hooks';
import {
  UploadFileButton,
  UploadImageButton,
  UploadVideoButton,
  UploadButtonProps,
} from 'components/common/Attachments/AttachmentsActions';
import TextEditor, { TextEditorProps } from 'components/common/TextEditor/TextEditor';
import AttachmentsPreview from 'components/layout/Main/AttachmentsPreview/AttachmentsPreview';
import EntrySuggestions from 'components/layout/Common/TextEditor/EntrySuggestions';
import mentionTheme from 'styles/draftjs/mentionTheme';
import { emojiThemePost } from 'styles/draftjs/emojiTheme';

import type { AttachmentType } from '../composerContext';
import * as S from './PostEditorPluginStyles';

type PostEditorPluginProps = {
  editorProps: {
    editorState: EditorState;
    editorDisabled?: boolean;
    onTextChange: TextEditorProps['onChange'];
  };
  attachmentProps: {
    attachments: Array<PostFile | LinkInfo | LinkPreview>;
    attachmentType?: AttachmentType;
    attachmentsDisabled?: boolean;
    loading?: boolean;
    onFileUploaded: UploadButtonProps['onFileUploaded'];
    onFileRejected?: () => void;
    onFileRemoved?: (attachment: PostFile) => void;
    resetAttachments?: () => void;
  };
  surveyProps?: {
    surveyDisabled?: boolean;
    onSurveyClick?: React.MouseEventHandler;
  };
  mentionProps: {
    mentionSuggestions: MentionData[];
    mentionsOpen: boolean;
    onMentionSearch: MentionSuggestionsPubProps['onSearchChange'];
    onMentionOpen: (open: boolean) => void;
  };
};

const toolbarPlugin = createToolbarPlugin({
  theme: {
    toolbarStyles: S.toolbarStyles,
    buttonStyles: S.buttonStyles,
  },
});

const { Toolbar } = toolbarPlugin;

export default function PostEditorPlugin(props: PostEditorPluginProps) {
  const { t } = useTranslation('feed');
  const { networkFunctions } = useAuth();
  const [canCreatePoll] = usePermissions('CreatePoll');
  const theme = useTheme();

  const { emojiPlugin, mentionPlugin, EmojiSelect, EmojiSuggestions, MentionSuggestions } =
    useMemo(() => {
      const emojiPlugin = createEmojiPlugin({
        theme: {
          ...emojiThemePost(theme),
          emojiSelect: S.buttonStyles.buttonWrapper,
          emojiSelectButton: S.emojiButton,
          emojiSelectButtonPressed: S.emojiButton,
        },
        selectButtonContent: (
          <FontAwesomeIcon icon={faSmile} style={{ position: 'relative', bottom: 1, height: 19 }} />
        ),
        useNativeArt: true,
      });

      const mentionPlugin = createMentionPlugin({
        entityMutability: 'IMMUTABLE',
        mentionPrefix: '@',
        supportWhitespace: true,
        theme: mentionTheme(theme),
      });

      const { EmojiSelect, EmojiSuggestions } = emojiPlugin;
      const { MentionSuggestions } = mentionPlugin;

      return { emojiPlugin, mentionPlugin, EmojiSelect, EmojiSuggestions, MentionSuggestions };
    }, [theme]);

  const { linkPlugin, LinkButton } = useMemo(() => {
    const linkPlugin = createLinkPlugin({
      placeholder: t('editor.linkPlaceholder'),
    });

    const { LinkButton } = linkPlugin;

    return { linkPlugin, LinkButton };
  }, [t]);

  const { editorState, editorDisabled = false, onTextChange: onTextChanged } = props.editorProps;
  const {
    attachments,
    attachmentType,
    attachmentsDisabled = false,
    loading = false,
    onFileUploaded,
    onFileRejected,
    onFileRemoved,
    resetAttachments,
  } = props.attachmentProps;
  const { surveyDisabled = false, onSurveyClick } = props.surveyProps ?? {};
  const { mentionSuggestions, mentionsOpen, onMentionSearch, onMentionOpen } = props.mentionProps;

  // Necessário pois a toolbar só funciona ao ser colocado após o editor
  return (
    <div style={{ display: 'flex', flexDirection: 'column-reverse' }}>
      <Spin spinning={loading}>
        <AttachmentsPreview
          files={attachments}
          fileType={attachmentType}
          loading={loading}
          handleFileAdded={onFileUploaded}
          handleMediaRemoved={onFileRemoved}
          resetFiles={resetAttachments}
        />
      </Spin>
      <TextEditor
        css={S.editor}
        disabled={editorDisabled}
        editorState={editorState}
        autoFocus={false}
        placeholder={t('editor.placeholder')}
        plugins={[toolbarPlugin, linkPlugin, emojiPlugin, mentionPlugin]}
        onChange={onTextChanged}
      />
      <MentionSuggestions
        open={mentionsOpen}
        onSearchChange={onMentionSearch}
        suggestions={mentionSuggestions}
        entryComponent={EntrySuggestions}
        onOpenChange={onMentionOpen}
      />
      <EmojiSuggestions />
      <S.ToolbarWrapper>
        <Toolbar>
          {externalProps => (
            <>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <LinkButton {...externalProps} />
              <EmojiSelect {...externalProps} />
            </>
          )}
        </Toolbar>
        <S.ControlsToolbar>
          <UploadImageButton
            disabled={attachmentsDisabled}
            fileCount={attachments.length}
            shape="circle"
            size="large"
            onFileUploaded={onFileUploaded}
          />
          <UploadVideoButton
            disabled={attachmentsDisabled}
            shape="circle"
            size="large"
            onFileUploaded={onFileUploaded}
          />
          <UploadFileButton
            disabled={attachmentsDisabled}
            shape="circle"
            size="large"
            onFileUploaded={onFileUploaded}
            onFileRejected={onFileRejected}
          />
          {props.surveyProps && networkFunctions.polls && canCreatePoll && (
            <Tooltip title={t('default:survey.survey')}>
              <Button
                disabled={attachments.length > 0 || surveyDisabled}
                type="text"
                size="large"
                shape="circle"
                icon={<FontAwesomeIcon icon={faSquarePollHorizontal} />}
                onClick={onSurveyClick}
              />
            </Tooltip>
          )}
        </S.ControlsToolbar>
      </S.ToolbarWrapper>
    </div>
  );
}
