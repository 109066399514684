import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import { useAuth } from 'hooks';
import { getScheduledCount } from 'store/actions/postsActions';
import { userOnboarded } from 'store/actions/authActions';
import { fetchFeedCounters, setFeedCounters } from 'store/actions/uiActions';
import { markAsViewed } from 'lib/api/notification';
import { ITEM_TYPES, PROFILE_TYPES } from 'lib/constants';

export function useFeed() {
  const { t } = useTranslation();
  const location = useLocation();
  const { userId } = useAuth();
  const dispatch: AppDispatch = useDispatch();

  const [showWelcomeModal, setShowWelcomeModal] = useState(false);

  const firstLogin = useSelector((state: RootState) => state.auth.firstLogin);
  const activeTab = useSelector((state: RootState) => state.posts.currentTab);
  const { newsCount, myFeedCount } = useSelector((state: RootState) => state.ui.feed.counters);

  const closeWelcomeModal = useCallback(() => {
    setShowWelcomeModal(false);
    dispatch(userOnboarded());
  }, [dispatch]);

  useEffect(() => {
    gtag('event', 'screen_view', { screen_name: 'Home' });
  }, []);

  useEffect(() => {
    if (location.state?.from === 'reset-password') {
      message.config({
        top: 43,
      });
      message.info(t('feed.messages.resetPassword'), 10);
    }
    if (firstLogin) {
      setShowWelcomeModal(true);
    }
  }, [firstLogin, t, location.state?.from]);

  useEffect(() => {
    dispatch(getScheduledCount());
    dispatch(fetchFeedCounters());
  }, [dispatch]);

  useEffect(() => {
    let timeoutId: NodeJS.Timer;

    const fromType = {
      news: [PROFILE_TYPES.group, PROFILE_TYPES.publisher],
      'my-feed': [PROFILE_TYPES.user],
    };

    if (activeTab !== 'search' && (newsCount > 0 || myFeedCount > 0)) {
      timeoutId = setTimeout(() => {
        markAsViewed(userId, { itemType: ITEM_TYPES.post, fromTypes: fromType[activeTab] });

        dispatch(
          setFeedCounters({
            newsCount: activeTab === 'news' ? 0 : newsCount,
            myFeedCount: activeTab === 'my-feed' ? 0 : myFeedCount,
          })
        );
      }, 5000);
    }

    return () => {
      clearInterval(timeoutId);
    };
  }, [activeTab, dispatch, myFeedCount, newsCount, userId]);

  return {
    showWelcomeModal,
    closeWelcomeModal,
  };
}
