import { createWithEqualityFn as create } from 'zustand/traditional';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { EditorState } from 'draft-js';

export type AttachmentType = 'image' | 'file' | 'video' | 'link' | 'survey' | unknown;
type Attachment<T extends AttachmentType> = T extends 'link' ? LinkPreview : PostFile;

type ComposerState = {
  editorState: EditorState;
  attachments: Attachment<unknown>[];
  attachmentType?: AttachmentType;
  scheduleDate?: string;
  pinned: boolean;
  blockCommenting: boolean;
  surveyOpen: boolean;
  isSending: boolean;
  descriptionText?: string;
};

type ComposerActions = {
  setEditorState: (editorState: EditorState) => void;
  setAttachments: <T extends AttachmentType>(
    files: Attachment<T>[],
    attachmentType: T | undefined
  ) => void;
  removeAttachment: (key: string) => void;
  resetAttachments: () => void;
  setScheduleDate: (date: string) => void;
  setModifier: (modifier: { highlight?: boolean; blockCommenting?: boolean }) => void;
  setSurveyOpen: (open: boolean) => void;
  setIsSending: (sending: boolean) => void;
  setDescriptionText: (date: string) => void;

  resetStore: () => void;
};

const initialState: ComposerState = {
  editorState: EditorState.createEmpty(),
  attachments: [],
  attachmentType: undefined,
  scheduleDate: undefined,
  pinned: false,
  blockCommenting: false,
  surveyOpen: false,
  isSending: false,
  descriptionText: undefined,
};

export const useComposerStore = create<ComposerState & ComposerActions>()(
  immer(
    devtools(
      (set, get) => ({
        ...initialState,
        setEditorState(editorState) {
          set(state => void (state.editorState = editorState));
        },
        setAttachments(files, attachmentType) {
          set(state => {
            const { attachmentType: currentType } = get();
            state.attachmentType = attachmentType;

            if (currentType === 'image' && attachmentType === 'image') {
              state.attachments = state.attachments.concat(files);
            } else {
              state.attachments = files;
            }
          });
        },
        removeAttachment(key) {
          set(state => {
            const filteredAttachments = state.attachments.filter(att => att.index !== key);
            state.attachments = filteredAttachments;

            if (filteredAttachments.length === 0) state.attachmentType = undefined;
          });
        },
        resetAttachments() {
          set(state => {
            state.attachmentType = undefined;
            state.attachments = [];
          });
        },
        setScheduleDate(date) {
          set(state => void (state.scheduleDate = date));
        },
        setDescriptionText(text) {
          set(state => void (state.descriptionText = text));
        },
        setModifier({ highlight = false, blockCommenting = false }) {
          set(state => {
            state.pinned = highlight;
            state.blockCommenting = blockCommenting;
          });
        },
        setSurveyOpen(open) {
          set(state => {
            state.surveyOpen = open;
            state.attachmentType = open ? 'survey' : undefined;
          });
        },
        setIsSending(sending) {
          set(state => void (state.isSending = sending));
        },
        resetStore() {
          set(initialState);
        },
      }),
      { name: 'composer-store' }
    )
  )
);
