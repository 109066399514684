import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { ITEM_TYPES } from 'lib/constants';
import ReactionCounter from 'components/Reaction/ReactionCounter';
import UserReactionList from 'components/Reaction/UserReactionList';
import UsersListModal from 'components/layout/Common/Modal/PersonList/PersonListContainer';

import * as S from './CommentReactionCounterStyles';

export type CommentReactionCounterProps = {
  commentId: number;
  reactionList: Reaction[];
};

export default function CommentReactionCounter({
  commentId,
  reactionList,
}: CommentReactionCounterProps) {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <Button css={S.button} type="link" size="small" onClick={() => setModalOpen(true)}>
        <ReactionCounter
          reactionList={reactionList}
          size="small"
          counterStyle={{ paddingTop: 3 }}
        />
      </Button>
      <UsersListModal
        visible={modalOpen}
        destroyOnClose
        title={t('post.viewReacted')}
        onCancel={() => setModalOpen(false)}
      >
        <UserReactionList
          reactionCountList={reactionList}
          itemId={commentId}
          itemType={ITEM_TYPES.comment}
        />
      </UsersListModal>
    </>
  );
}
