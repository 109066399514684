import React from 'react';

import styled from '@emotion/styled';
import { Input } from 'antd';
import { SearchProps } from 'antd/lib/input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

const { Search } = Input;

//#region STYLES
const SearchStyled = styled(Search)`
  .ant-input-affix-wrapper {
    border: 1px solid ${({ theme }) => theme.colors.gray3};
    border-radius: 8px !important;
  }

  .ant-input-affix-wrapper,
  .ant-input {
    background-color: ${({ theme }) => theme.colors.gray2};
  }

  .ant-input-prefix {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.gray7};
  }

  .ant-input-group-addon {
    display: none;
  }
`;
//#endregion

const SearchInput: React.VFC<SearchProps> = ({
  allowClear = false,
  className,
  placeholder,
  onChange,
  onSearch,
  ...props
}) => {
  return (
    <SearchStyled
      addonAfter={null}
      allowClear={allowClear}
      className={className}
      placeholder={placeholder}
      prefix={<FontAwesomeIcon icon={faSearch} className="anticon" />}
      onChange={onChange}
      onSearch={onSearch}
      {...props}
    />
  );
};

export default SearchInput;
