import { Fragment, useCallback, useEffect, useState, VFC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Modal, Button, Avatar } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faUser } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import { channelPhoto } from 'lib/getProfilePhoto';
import { profile } from 'lib/api';
import { useAuth } from 'hooks';
import { openChatRoom, updateChannelPhoto } from 'store/actions/chatActions';

import ProfileImage, { profileTypes } from 'components/layout/Common/ProfileImage/ProfileImage';
import { compareTwoStrings, fileAsBase64 } from 'lib/helper';
import ImageUploader from 'components/layout/Common/Uploader/ImageUploader/ImageUploader';
import MediaCropper from 'components/common/MediaCropper';
import { useImmer } from 'use-immer';

//#region TYPES
export interface ModalChatMembersProps {
  room: ChatRoom & { mediaId?: number };
  visible: boolean;
  onClose: () => void;
}
//#endregion

// #region STYLES
const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const MemberCounter = styled.div`
  ${({ theme }) => theme.typography.fontSmall(2)}

  svg {
    margin-right: 8px;
  }
`;
const CloseButton = styled(Button)`
  && {
    height: auto;
    padding: 0;
    font-size: 14pt;
    color: ${({ theme }) => theme.colors.gray10};
  }
`;
const TitleSection = styled.section`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;

  .avatar-container {
    position: relative;
    width: 70px;
    height: 70px;
    overflow: hidden;
    border-radius: 50%;

    .uploader {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: inherit;
      height: inherit;
      color: ${({ theme }) => theme.colors.gray4};
      cursor: pointer;
      opacity: 0;
      transition: opacity 300ms ease-in-out;

      :focus,
      :hover {
        background-color: rgba(0, 0, 0, 0.3);
        opacity: 1;
      }
    }
  }
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.75rem;

  h1 {
    margin: 0;
    ${({ theme }) => theme.typography.fontxxLarge()}
  }
`;

const ListSection = styled.section`
  max-height: 390px;
  padding-right: 0.5rem;
  overflow-y: auto;
`;
const List = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;
const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0;
  border-bottom: solid 1px ${({ theme }) => theme.colors.gray3};

  :last-of-type {
    border: 0;
  }
`;
const UserContainer = styled.div`
  display: flex;

  .user-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 8px;
    line-height: 1.25;
  }
`;
const UserOccupation = styled.div`
  ${({ theme }) => theme.typography.fontSmall(1.43)};
`;
const MessageButton = styled(Button)`
  height: 24px;
  padding: 0 0.57em;
  color: ${({ theme }) => theme.colors.gray10};
  border: 1px solid ${({ theme }) => theme.colors.gray10};
`;
// #endregion

function userSort(valueA: ChatUser, valueB: ChatUser) {
  return compareTwoStrings(valueA.user.name, valueB.user.name);
}

const ModalChatMembers: VFC<ModalChatMembersProps> = ({ room, visible, onClose }) => {
  const { t } = useTranslation('chat');
  const { token, userInfo } = useAuth();
  const dispatch: AppDispatch = useDispatch();

  const [cropper, setCropper] = useImmer<{
    rawSrc: string;
    croppedSrc: string | null;
    visible: boolean;
  }>({
    rawSrc: '',
    croppedSrc: null,
    visible: false,
  });

  const roomImg = channelPhoto(room.roomId, token, room.mediaId);
  const members = room.users;

  const creator = useRoomCreator({ creatorId: room.creatorId, visible });

  useEffect(
    () => () => {
      if (cropper.croppedSrc) URL.revokeObjectURL(cropper.croppedSrc);
    },
    [cropper.croppedSrc]
  );

  const handleOpenChat = useCallback(
    userId => {
      dispatch(openChatRoom({ userId }));
      onClose();
    },
    [dispatch, onClose]
  );

  const handleCancel = useCallback(() => {
    setCropper(draft => {
      draft.croppedSrc = null;
    });
    onClose();
  }, [onClose, setCropper]);

  const handleImageAdd = async (acceptedFiles: File[]) => {
    const [photo] = acceptedFiles;
    const rawSrc = await fileAsBase64(photo);

    setCropper(draft => {
      draft.rawSrc = rawSrc;
      draft.visible = true;
    });
  };

  const handleImgCropped = (img: File) => {
    setCropper(draft => {
      URL.revokeObjectURL(draft.croppedSrc!);

      draft.croppedSrc = URL.createObjectURL(img);
      draft.visible = false;
    });

    dispatch(updateChannelPhoto(room.roomId, img));
  };

  const handleCancelCrop = () => {
    setCropper(draft => {
      draft.croppedSrc = null;
      draft.visible = false;
    });
  };

  const renderMembers = useCallback(() => {
    return members
      .slice()
      .sort(userSort)
      .map(({ user: usr }) => (
        <ListItem key={usr.userId}>
          <UserContainer>
            <ProfileImage
              profile={{
                profileId: usr.userId,
                name: usr.name,
                profileType: profileTypes.user,
                mediaId: usr.mediaId,
              }}
              size="sm"
              checkOnline
            />
            <div className="user-description">
              <strong>{usr.name}</strong>
              {usr.occupation && usr.department && (
                <UserOccupation>
                  {usr.occupation && usr.occupation.description} •{' '}
                  {usr.department && usr.department.description}
                </UserOccupation>
              )}
            </div>
          </UserContainer>
          {usr.userId !== userInfo.user.userId && (
            <MessageButton onClick={() => handleOpenChat(usr.userId)}>
              {t('membersModal.message')}
            </MessageButton>
          )}
        </ListItem>
      ));
  }, [handleOpenChat, members, t, userInfo.user.userId]);

  return (
    <Fragment>
      <Modal
        css={theme => css`
          && {
            color: ${theme.colors.gray10};
          }
        `}
        closable={false}
        destroyOnClose
        visible={visible}
        footer={null}
      >
        <Header>
          <MemberCounter>
            <FontAwesomeIcon icon={faUser} />
            {t('membersModal.members', { count: members.length })}
          </MemberCounter>
          <CloseButton type="link" onClick={handleCancel}>
            <FontAwesomeIcon icon={faTimes} />
          </CloseButton>
        </Header>
        <TitleSection>
          <div className="avatar-container">
            <Avatar src={cropper.croppedSrc ? cropper.croppedSrc : roomImg} size={70}>
              {room.name.substring(0, 1)}
            </Avatar>
            {userInfo.user.userId === room.creatorId && (
              <ImageUploader onImageAdd={handleImageAdd} className="uploader">
                <FontAwesomeIcon icon={faCamera} size="2x" />
              </ImageUploader>
            )}
          </div>
          <TitleContainer>
            <h1>{room.name}</h1>
            <div>
              {t('membersModal.createdBy')} <strong>{creator ? creator.name : ''}</strong>
            </div>
          </TitleContainer>
        </TitleSection>
        <ListSection>
          <List>{renderMembers()}</List>
        </ListSection>
      </Modal>
      <MediaCropper
        imgSrc={cropper.rawSrc}
        visible={cropper.visible}
        onOk={handleImgCropped}
        onCancel={handleCancelCrop}
      />
    </Fragment>
  );
};

export default ModalChatMembers;

function useRoomCreator({ creatorId, visible }: { creatorId: number; visible: boolean }) {
  const [user, setUser] = useState<User>();

  useEffect(() => {
    if (!user && visible) {
      (async () => {
        const result = await profile.getUser({ userId: creatorId });

        if (result) {
          const userInfo = result.users.users[0];

          setUser(userInfo.user);
        }
      })();
    }
  }, [creatorId, user, visible]);

  return user;
}
