import { createSelector } from 'reselect';

const userSelector = (state: RootState) => state.users;

export const makeUserSelector = () =>
  createSelector(
    userSelector,
    (_: unknown, userId: number) => userId,
    (users, userId) => users.users[userId]
  );

export const selectUser = (state: RootState, userId: number) => {
  return state.users.users[userId] || null;
};

export const makeUserContactsSelector = () =>
  createSelector(
    (state: RootState) => state.users.usersContacts,
    (_: unknown, userId: number) => userId,
    (usersContacts, userdId) => usersContacts[userdId]
  );

export const selectUserContacts = (state: RootState, userId: number) => {
  return state.users.usersContacts[userId] || null;
};

export const makeIsUserOnlineSelector = () =>
  createSelector(
    userSelector,
    (_: unknown, userId: number) => userId,
    (users, userId) => !!users.onlineUsers[userId]
  );

export const isUserOnline = (state: RootState, userId: number): boolean => {
  return !!state.users.onlineUsers[userId];
};
