import { configureStore } from '@reduxjs/toolkit';
import {
  useDispatch as defaultUseDispatch,
  useSelector as defaultUseSelector,
  TypedUseSelectorHook,
} from 'react-redux';

import rootReducer, { RootReducer } from './reducers';
import { apiTokenMiddleware } from './middlewares';

declare global {
  export type RootState = ReturnType<RootReducer>;
  export type AppDispatch = typeof store.dispatch;
}

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiTokenMiddleware),
});

export const useSelector: TypedUseSelectorHook<RootState> = defaultUseSelector;
export const useDispatch: () => AppDispatch = defaultUseDispatch;
export default store;
