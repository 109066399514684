import { createSelector } from 'reselect';
import _ from 'lodash/fp';

export const selectNotifications = createSelector([state => state], state => {
  if (!_.isEmpty(state.notification.notificationList)) {
    const notifications = _.flow(
      _.sortBy(n => n.emitters[0].created),
      _.reverse
    )(state.notification.notificationList);
    return notifications;
  }

  return [];
});
