import { useEffect } from 'react';

import { Result } from 'antd';

const NotFound = () => {
  useEffect(() => {
    gtag('event', 'exception', { description: 'Página não encontrada', fatal: false });
  }, []);

  return (
    <Result status="404" title="404" subTitle="O recurso que você está procurando não existe" />
  );
};

export default NotFound;
