import { FirebaseOptions, initializeApp } from 'firebase/app';
import {
  getMessaging,
  onMessage,
  getToken,
  MessagePayload,
  NextFn,
  isSupported,
} from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';
import { VAPID_KEY } from 'lib/constants';

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyAm1edxyElN8-ECvT0nTLMnlGY4VJvTf6Q',
  authDomain: 'bee-work-plus.firebaseapp.com',
  databaseURL: 'https://bee-work-plus.firebaseio.com',
  projectId: 'bee-work-plus',
  storageBucket: 'bee-work-plus.appspot.com',
  messagingSenderId: '24173777563',
  appId: '1:24173777563:web:3351867c4b6b76dff3ec3e',
  measurementId: 'G-09NRMVKXRH',
};

const firebase = initializeApp(firebaseConfig);
getAnalytics(firebase);

export async function getOrRegisterServiceWorker() {
  if ('serviceWorker' in navigator) {
    const serviceWorker = await window.navigator.serviceWorker.getRegistration(
      '/firebase-push-notification-scope'
    );

    if (serviceWorker) return serviceWorker;

    return window.navigator.serviceWorker.register('/firebase-messaging-sw.js', {
      scope: '/firebase-push-notification-scope',
    });
  }
  throw new Error('The browser doesn`t support service worker.');
}

const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();

    if (isSupportedBrowser) {
      return getMessaging(firebase);
    }

    console.info('Firebase is not supported in this browser');
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
})();

export async function onMessageListener(payloadCallback: NextFn<MessagePayload>) {
  const messagingResolve = await messaging;

  if (messagingResolve) {
    return onMessage(messagingResolve, payload => {
      console.info('Received message: ', { payload });
      payloadCallback(payload);
    });
  }
}

export async function requestToken() {
  try {
    const serviceWorkerRegistration = await getOrRegisterServiceWorker();

    const messagingResolve = await messaging;

    if (messagingResolve) {
      const currentToken = await getToken(messagingResolve, {
        vapidKey: VAPID_KEY,
        serviceWorkerRegistration,
      });
      console.info('Device token is: ', currentToken);
      return currentToken;
    }
  } catch (error) {
    console.error('Could not retrieve FCM token');
  }
}

export { isSupported };
