import { css } from '@emotion/react';

export const likeCounter = (theme: Themes.DefaultTheme) => css`
  &.ant-btn-link {
    padding: 0;
    color: ${theme.colors.blue};
    border: 0;

    ${theme.typography.fontxSmall('24px')};
  }
`;
