import { css } from '@emotion/react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';

export const invalid = (theme: Themes.DefaultTheme) => css`
  color: ${theme.colors.danger};
`;

export const ItemContainer = styled.div`
  flex: 1;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.gray8};

  div.label-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
  }

  label {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.gray10};
  }
  .label-helper {
    ${({ theme }) => theme.typography.fontxSmall()};
  }

  input,
  .ant-select,
  .ant-picker {
    width: 100%;
  }
`;
export const ItemWrapper = styled.div`
  display: flex;

  ${ItemContainer}:not(:last-child) {
    margin-right: 12px;
  }
`;

export type SmallProps = { isInvalid?: boolean };

export const Small = styled('small', {
  shouldForwardProp: prop => isPropValid(prop),
})<SmallProps>`
  ${({ theme, isInvalid }) => css`
    color: ${theme.colors.gray8};
    ${theme.typography.fontxSmall()};

    ${isInvalid && invalid(theme)};
  `}
`;
