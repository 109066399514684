import React from 'react';
import { useTranslation } from 'react-i18next';

import logoWork from 'assets/images/work+.svg';

import * as S from './TermsOfUseStyles';

const TermsOfUse = () => {
  const { t } = useTranslation();

  return (
    <>
      <S.LogoWrapper>
        <img src={logoWork} alt="4bee Work" />
      </S.LogoWrapper>
      <h1>{t('termsOfUse.title')}</h1>
      <S.TermsList dangerouslySetInnerHTML={{ __html: t('termsOfUse.body') }} />
    </>
  );
};

export default TermsOfUse;
