import { useFeatureToggle } from 'hooks';
import React from 'react';
import EditPostModalOld from 'components/layout/Main/EditPostModal/EditPostModal';
import EditPostModal from './EditPost/EditPostModal';

type EditPostWrapperProps = {
  postInfo: PostInfo;
};

export default function EditPostWrapper({ postInfo }: EditPostWrapperProps) {
  const [newComposer] = useFeatureToggle('new_composer');

  if (newComposer) {
    return <EditPostModal postInfo={postInfo} />;
  }

  return <EditPostModalOld postInfo={postInfo} />;
}
