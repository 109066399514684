import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const ComposerPanel = styled.div`
  padding: 0.75em;
  margin-bottom: 1.1em;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(142, 142, 142, 0.08);
`;

export const ComposerContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  gap: 8px;
`;

export const ComposerButton = styled.button`
  ${({ theme }) => css`
    all: unset;
    flex: 1;
    padding: 1em;
    background-color: ${theme.colors.gray2};
    border-radius: 4px;
    cursor: text;
    transition: background-color ease-in 100ms;

    :active {
      background-color: ${theme.colors.gray3};
    }
  `}
`;

export const ControlsWrapper = styled.div`
  ${({ theme }) => css`
    button {
      ${theme.typography.fontSmall()}
    }
  `}
`;
