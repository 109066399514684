import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const imgScale = css`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const thumbnail = css`
  width: 60px;
  height: 49px;
  margin-left: 4px;
`;

export const button = css`
  position: relative;
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: none;

  &:hover {
    &::after {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 4px;
    }
  }
`;

export const SingleMedia = styled.button`
  width: 124px;
  height: 102px;
  ${button};

  & img {
    ${imgScale}
    border-radius: 8px;
  }

  & .wrapper {
    width: 100%;
    height: 100%;
  }
`;
export const TwoThreeMedia = styled.div`
  display: flex;

  & > button {
    ${thumbnail}
    ${button};
  }

  & img {
    ${imgScale}
    border-radius: 4px;
  }

  & .wrapper {
    width: 100%;
    height: 100%;
  }
`;
export const MoreMedia = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 130px;

  & > button {
    ${thumbnail};
    ${button};

    :nth-child(n + 3) {
      margin-top: 4px;
    }
  }

  & img {
    ${imgScale};
    border-radius: 4px;
  }

  & .wrapper {
    width: 100%;
    height: 100%;
  }
`;

export const Player = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  color: #fff;
  background: ${({ theme }) => theme.colors.purple};
  border-radius: 8px;

  .control {
    button {
      padding: 0;
      cursor: pointer;
      background: transparent;
      border: 0;
      outline: 0;
    }
  }
  .progress-track {
    width: 74px;
    margin: 0 6px;
  }
  .spinner {
    color: ${({ theme }) => theme.colors.primary};
  }
  .timer {
    ${({ theme }) => theme.typography.fontxSmall()};
  }
`;
export const Progress = styled.progress`
  display: block;
  cursor: pointer;

  &[value] {
    width: 100%;
    height: 2px;
    color: ${({ theme }) => theme.colors.primary};
    background: #fff;
    appearance: none;
  }
  &[value]::-webkit-progress-bar {
    background: #fff;
  }
  &[value]::-webkit-progress-value {
    background: ${({ theme }) => theme.colors.primary};
  }
  &[value]::-moz-progress-bar {
    background: #fff;
  }
`;

export const FileButton = styled.button`
  display: block;
  padding: 0;
  margin: 0 auto;
  background-color: transparent;
  cursor: pointer;
  border: 0;
  outline: none;

  span {
    display: block;
  }
`;

export const FileWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 100px;
    color: ${theme.colors.blue};
    text-align: center;
    white-space: nowrap;
    ${theme.typography.fontxxSmall()};

    svg {
      color: ${theme.colors.primary};
      font-size: 4rem;
    }
  `}
`;
