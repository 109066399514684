import React from 'react';
import ProfileImage from '../ProfileImage/ProfileImage';

function EntrySuggestions(props) {
  const { mention, theme, searchValue, isFocused, ...parentProps } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...parentProps}>
      <div className={theme.mentionSuggestionsEntryContainer}>
        <div className={theme.mentionSuggestionsEntryContainerLeft}>
          <ProfileImage
            profile={{ profileId: mention.userId, name: mention.name }}
            size="xs"
            role="presentation"
          />
        </div>
        <div className={theme.mentionSuggestionsEntryContainerRight}>
          <div className={theme.mentionSuggestionsEntryText}>{mention.name}</div>
        </div>
      </div>
    </div>
  );
}

export default EntrySuggestions;
