import React from 'react';
import { Result, Button } from 'antd';
import { Navigate } from 'react-router-dom';

import { refreshCacheAndReload } from 'CacheBuster';

//#region TYPES
interface ErrorBoundaryProps {
  children: React.ReactNode;
}
interface ErrorBoundaryState {
  returnHome?: boolean;
  hasError: boolean;
}
//#endregion

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  public state: ErrorBoundaryState = {
    hasError: false,
  };

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Você também pode registrar o erro em um serviço de relatórios de erro
    console.error(error, errorInfo);

    gtag('event', 'exception', { description: error.message, fatal: true });
  }

  public static getDerivedStateFromError(): ErrorBoundaryState {
    // Atualiza o state para que a próxima renderização mostre a UI alternativa.
    return { hasError: true };
  }

  handleRefresh = () => {
    refreshCacheAndReload();
  };

  handleReturnHome = () => {
    this.setState({ hasError: false, returnHome: true });
  };

  render() {
    const { hasError, returnHome } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <Result
          status="500"
          title="Encontramos um problema"
          subTitle="Desculpe, não conseguimos exibir seu conteúdo"
          extra={
            <>
              <Button
                type="primary"
                onClick={this.handleRefresh}
                style={{ display: 'block', margin: '0 auto', marginBottom: 12 }}
              >
                Tentar novamente
              </Button>
              <Button type="link" onClick={this.handleReturnHome}>
                Retornar para o feed
              </Button>
            </>
          }
        />
      );
    }

    return (
      <>
        {returnHome && <Navigate to="/feed" />}
        {children}
      </>
    );
  }
}

export default ErrorBoundary;
