import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Drawer } from 'antd';

import * as storage from 'lib/storageHelper';
import { Wrapper } from './CookieConsentStyles';

const cookieConsentKey = `4bee.cookie-consent`;

const CookieConsent = () => {
  const { t } = useTranslation();

  const [drawerVisible, setDrawerVisible] = useState(() => {
    const cookieConsent = storage.get<string>(cookieConsentKey, 'localStorage');

    if (cookieConsent != null) return false;

    return true;
  });

  const closeDrawerHandler = () => {
    storage.set({ key: cookieConsentKey, value: 'accepted' }, 'localStorage');

    setDrawerVisible(false);
  };

  return (
    <Drawer
      placement="bottom"
      height="5.9rem"
      visible={drawerVisible}
      closable={false}
      mask={false}
    >
      <Wrapper>
        <p>
          {t('cookies.disclaimer')}{' '}
          <Link to="/legal/cookies-policies">{t('cookiesPolicies.menuTitle')}</Link>.
        </p>
        <Button type="primary" onClick={closeDrawerHandler}>
          {t('cookies.continue')}
        </Button>
      </Wrapper>
    </Drawer>
  );
};

export default CookieConsent;
