const settingsEn = {
  language: {
    changeLanguage: 'Change the language',
    chooseLanguage: 'Choose the language you want to use on 4beeWork',
  },
  notifications: {
    emailNotifications: 'Email notifications',
    mentionInComment: 'Any comment in which you are mentioned',
    mentionInPost: 'Any post in which you are mentioned',
    notificationHint: 'You can choose which notifications you wish receive by email.',
    whenAddInGroup: "When you you've been added in a private group",
    whenGroupPostPublic: 'When a group creates a public post',
    whenPostPublished: 'When a scheduled post is published',
    whenVideoConverted: 'When a video conversion is ready',
  },
  security: {
    changePassword: 'Change Password',
    changePasswordDescription: 'You can update your password',
    description:
      'The security area of ​​an account is a section where the user can change their password.',
    descriptionInit: 'Now you can register a ',
    descriptionEnd: ' to quickly log in to your network.',
    descriptionBoldInit: 'personal email',
    descriptionBoldEnd: ' corporate',
    lastUpdate: 'Last update',
    recoveryEmail: 'One-time code login email',
    recoveryEmailDescription: 'Add an e-mail address',
    recoveryTitlePlaceHolder: 'One-time code email',
    tabTitle: 'Security',
    tabUniqueCodeTitle: 'Login by unique code',
    tabUniqueCodeDescription:
      'The security code will be sent to your email to verify your identity when logging in.',
    recoveryEmailPane: {
      description: 'will be used to send the access code to your account. You can use a ',
      boldInit: 'Your email to login ',
      saveSuccess: 'E-mail saved successfuly.',
    },
  },
};

export default settingsEn;
