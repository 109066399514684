import styled from '@emotion/styled';

export const LogoWrapper = styled.div`
  display: table-cell;
  height: 32px;

  > img {
    display: block;
    height: 100%;
  }
`;
