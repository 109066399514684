import React, { useState, ElementRef } from 'react';
import { Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'hooks';
import { getNetworkLogo, getSubdomain } from 'lib/helper';
import { auth, network as networkApi } from 'lib/api';
import { FormItem } from 'components/common/Forms/Forms';

import defaultLogo from 'assets/images/work+.svg';
import { useUserSecurity, useUsername } from '../LoginStore';
import * as S from './UsernameStepStyles';

type UsernameStepProps = {
  onNext: () => void;
};

export default function UsernameStep({ onNext }: UsernameStepProps) {
  const { t } = useTranslation('login');
  const { networkId, network } = useAuth();

  const { username, setUsername } = useUsername();
  const { setUserSecurity } = useUserSecurity();

  const [logoUrl, setLogoUrl] = useState(() =>
    network.logo ? getNetworkLogo(network) : defaultLogo
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const usernameHandler = (e: React.ChangeEvent<ElementRef<'input'>>) => {
    const value = e.target.value;

    setUsername(value);
  };

  const continueHandler = async () => {
    setError(undefined);
    setLoading(true);

    let subdomain = getSubdomain(window.location.host);
    if (process.env.NODE_ENV !== 'production') subdomain = 'web-front-praga';

    const checkSubdomainResult = await networkApi.checkValidSubdomain({ username, subdomain });

    if (checkSubdomainResult.data) {
      const result = await auth.findDomainUser({ username, networkId });

      if (result.ok) {
        setUserSecurity(result.data);
      }
    }
    setLoading(false);
    setUsername(username);

    onNext();
  };

  const keyUpHandler = (e: React.KeyboardEvent<ElementRef<'input'>>) => {
    if (e.key === 'Enter' && username.length > 0) {
      continueHandler();
    }
  };

  return (
    <S.Wrapper>
      <S.Logo src={logoUrl} alt="Network logo" onError={() => setLogoUrl(defaultLogo)} />
      <h1>{t('usernameStep.title')}</h1>
      <p>{t('usernameStep.description')}</p>

      <FormItem
        inputName="username"
        forInput="username"
        label={t('usernameStep.userEmail')}
        errors={
          error
            ? {
                username: {
                  message:
                    t(`usernameStep.errors.${error}`) +
                    ' ' +
                    t(`usernameStep.errors.${error}_description`),
                },
              }
            : undefined
        }
      >
        <Input
          id="username"
          css={S.input(!!error)}
          size="large"
          placeholder={t('usernameStep.userEmail')}
          autoFocus
          value={username}
          onChange={usernameHandler}
          onKeyUp={keyUpHandler}
        />
      </FormItem>

      <Button
        block
        type="primary"
        size="large"
        disabled={!username}
        loading={loading}
        onClick={continueHandler}
      >
        {t('usernameStep.continue')}
      </Button>
    </S.Wrapper>
  );
}
