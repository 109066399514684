import moment from 'moment';
import { getIncomingBirthday, GetIncomingBirthdaysParams } from 'lib/apiActions';

import { AsyncAction } from 'store/actions';
import { BirthdayActions } from '../types/types';
import { BirthdayActionType } from '../types/birthdayTypes';

//#region TYPES
type FetchBirthdaysAction = (
  params: GetIncomingBirthdaysParams
) => AsyncAction<Promise<void>, BirthdayActionType>;
//#endregion

function sortBirthday(a: Birthday, b: Birthday) {
  const aBirthday = moment(a.birthday).format('MM-DD');
  const bBirthday = moment(b.birthday).format('MM-DD');

  if (aBirthday > bBirthday) return 1;
  else if (aBirthday < bBirthday) return -1;
  else return 0;
}

export function setBirthdays(payload: Birthday[]): BirthdayActionType {
  return {
    type: BirthdayActions.BIRTHDAYS_FETCHED,
    payload,
  };
}

export function removeBirthday(userId: number): BirthdayActionType {
  return {
    type: BirthdayActions.USER_BIRTHDAY_REMOVED,
    payload: { userId },
  };
}

export const fetchBirthdays: FetchBirthdaysAction = params => async dispatch => {
  const birthdays = await getIncomingBirthday(params);

  dispatch(setBirthdays(birthdays.sort(sortBirthday)));
};
