import ptBR from 'antd/es/locale/pt_BR';

const antdPtBR = {
  ...ptBR,
  DatePicker: {
    ...ptBR.DatePicker,
    lang: {
      ...ptBR.DatePicker.lang,
      timeSelect: 'Selecionar horário',
    },
  },
};

export default antdPtBR;
