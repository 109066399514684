import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt,
  faFileCsv,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
} from '@fortawesome/pro-solid-svg-icons';

export interface FileIconProps {
  fileExt: string;
}

const FileIcon = ({ fileExt }: FileIconProps) => {
  switch (fileExt) {
    case 'doc':
    case 'docx':
      return <FontAwesomeIcon icon={faFileWord} />;
    case 'pdf':
      return <FontAwesomeIcon icon={faFilePdf} />;
    case 'xls':
    case 'xlsx':
      return <FontAwesomeIcon icon={faFileExcel} />;
    case 'ppt':
    case 'pptx':
      return <FontAwesomeIcon icon={faFilePowerpoint} />;
    case 'csv':
      return <FontAwesomeIcon icon={faFileCsv} />;

    default:
      return <FontAwesomeIcon icon={faFileAlt} />;
  }
};

export default FileIcon;
