import React from 'react';
import ReactionIcon from './ReactionIcon';

import * as S from './ReactionCounterStyles';

export type ReactionCounterProps = {
  reactionList: Reaction[];
  size?: 'normal' | 'small';
  counterStyle?: React.CSSProperties;
};

export default function ReactionCounter({
  reactionList,
  size = 'normal',
  counterStyle,
}: ReactionCounterProps) {
  return (
    <S.Container>
      <S.IconWrapper>
        {reactionList.map(r => (
          <ReactionIcon key={r.reactionId} css={S.icon(r.name, { size })} reaction={r.name} />
        ))}
      </S.IconWrapper>
      <S.Counter style={counterStyle}>
        {reactionList[0].totalCount > 99 ? '+99' : reactionList[0].totalCount}
      </S.Counter>
    </S.Container>
  );
}
