import { createSelector } from 'reselect';
import { flow, sortBy, reverse, map, uniq, find } from 'lodash/fp';
import { CommentMap, PostMap, PostState } from 'store/types/postTypes';

type OrderDate = 'scheduledDate' | 'createdDate';

const selectPosts = (state: RootState) => state.posts;

export const makeGetPostSelector = () =>
  createSelector(
    selectPosts,
    (_: any, postId: number) => postId,
    (posts: PostState, postId): PostInfo => {
      if (!posts) {
        return {} as PostInfo;
      }

      const post =
        posts.posts[postId] ||
        posts.sharedPosts[postId] ||
        posts.pendingPosts[postId] ||
        posts.pinnedPosts[postId];

      return post;
    }
  );

export const getComment = (state: RootState, commentId: number) => {
  const { posts } = state;
  if (!posts.comments) {
    return {};
  }

  return posts.comments[commentId];
};

export const makeGetPostUISelector = () =>
  createSelector(
    (state: RootState) => state,
    (_: any, postId: number) => postId,
    (state: RootState, postId) => state.posts.ui[postId] || state.ui.drawer.content[postId]
  );

export const getPosts = createSelector(selectPosts, posts => {
  const filteredPosts = flow(
    sortBy<PostInfo>(p => p.post.scheduledDate ?? p.post.createdDate),
    reverse
  )(posts.posts);
  return filteredPosts;
});

export const getPendingPosts = createSelector(
  (state: RootState) => state.posts.pendingPosts,
  pendingPosts => {
    const posts = sortBy<PostInfo>(p => new Date(p.post.scheduledDate!))(pendingPosts);

    return posts;
  }
);

export function makeGetPendingPostsSelector() {
  return createSelector(
    (state: RootState) => state.posts.pendingPosts,
    (_: any, orderBy: OrderDate = 'scheduledDate') => orderBy,
    (pendingPosts, orderBy: OrderDate) => {
      const posts = flow(
        sortBy<PostInfo>(p => p.post[orderBy]),
        orderBy === 'createdDate' ? reverse : (array: PostInfo[]) => array
      )(pendingPosts);

      return posts;
    }
  );
}

export const pinnedPostsSelector = createSelector(
  (state: RootState) => state.posts.pinnedPosts,
  pinnedPosts => {
    const posts = flow(
      sortBy<PostInfo>(p => p.post.scheduledDate ?? p.post.createdDate),
      reverse
    )(pinnedPosts);

    return posts;
  }
);
export const getLoadedPages = (state: RootState) =>
  flow(
    map<PostMap[number], number>(p => p._page!),
    uniq
  )(state.posts.posts as []);

export const getLoadedPagesComments = (state: RootState, postId: number) =>
  flow(
    map<CommentMap[number][number], number>(c => c._pageComment!),
    uniq
  )(state.posts.comments[postId] as []);

export const makeGetPostComments = () =>
  createSelector(
    selectPosts,
    (_: any, postId: number) => postId,
    (posts, postId) =>
      flow(
        sortBy<CommentInfo & { _pageComment?: number }>(c => c.comment.createdDate),
        reverse
      )(posts.comments[postId])
  );

export const scheduledCountSelector = createSelector(selectPosts, posts => posts.scheduledCount);

export const getCommentCount = (state: RootState, postId: number) =>
  flow(
    sortBy((c: PostInfo) => c.commentCount),
    reverse
  )(state.posts.posts[postId]);

export const selectFilters = (state: RootState) => state.posts.filters;

export const makeSelectLikes = () =>
  createSelector(
    selectPosts,
    (_: any, itemId: number) => itemId,
    (_: any, __: number, itemType: ItemTypes) => itemType,
    (posts: PostState, itemId: number, itemType: ItemTypes) =>
      sortBy(l => l.user.name, selectLikes(posts, itemId, itemType))
  );

export const selectLikes = (posts: PostState, itemId: number, itemType: ItemTypes) => {
  const typeLikes = posts.likes[itemType];
  return typeLikes ? typeLikes[itemId] : null;
};

export const newPostSelector = createSelector(selectPosts, state =>
  find(post => !!post._new, state.posts)
);
