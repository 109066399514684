import React from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactionIcon from './ReactionIcon';

import * as S from './ReactionOptionsStyles';

export type ReactionOptionsProps = {
  onChosenReaction: (reaction: ReactionLiteral) => void;
};

export default function ReactionOptions({ onChosenReaction }: ReactionOptionsProps) {
  const { t } = useTranslation();

  return (
    <S.Container>
      <Tooltip
        title={t('reactions.like')}
        overlayClassName={S.tooltip}
        overlayInnerStyle={{ borderRadius: 50 }}
      >
        <S.ButtonIcon onClick={() => onChosenReaction('like')}>
          <ReactionIcon css={S.icon('like')} reaction="like" />
        </S.ButtonIcon>
      </Tooltip>
      <Tooltip
        title={t('reactions.love')}
        overlayClassName={S.tooltip}
        overlayInnerStyle={{ borderRadius: 50 }}
      >
        <S.ButtonIcon onClick={() => onChosenReaction('love')}>
          <ReactionIcon css={S.icon('love')} reaction="love" />
        </S.ButtonIcon>
      </Tooltip>
      <Tooltip
        title={t('reactions.applause')}
        overlayClassName={S.tooltip}
        overlayInnerStyle={{ borderRadius: 50 }}
      >
        <S.ButtonIcon onClick={() => onChosenReaction('applause')}>
          <ReactionIcon css={S.icon('applause')} reaction="applause" />
        </S.ButtonIcon>
      </Tooltip>
      <Tooltip
        title={t('reactions.support')}
        overlayClassName={S.tooltip}
        overlayInnerStyle={{ borderRadius: 50 }}
      >
        <S.ButtonIcon onClick={() => onChosenReaction('support')}>
          <ReactionIcon css={S.icon('support')} reaction="support" />
        </S.ButtonIcon>
      </Tooltip>
    </S.Container>
  );
}
