import React, { useMemo } from 'react';
import { useAuth } from 'hooks';
import { fetchMedia, compareTwoStrings } from 'lib/helper';
import { useSelector } from 'store';

import Shortcut from 'components/common/Shortcut';

import { ShortcutLink } from '../ShortcutsStyles';

const NetworkShortcuts = () => {
  const { token } = useAuth();

  const { shortcuts, loading } = useSelector(state => ({
    shortcuts: state.shortcut.networkShortcuts,
    loading: state.shortcut.loading,
  }));

  const sortedShortcuts = useMemo(
    () => [...shortcuts].sort((a, b) => compareTwoStrings(a.name, b.name)),
    [shortcuts]
  );

  return (
    <>
      {sortedShortcuts.map(shortcut => {
        const iconUrl = shortcut.mediaId ? fetchMedia(shortcut.mediaId, token) : undefined;
        return (
          <ShortcutLink
            key={shortcut.id}
            href={shortcut.link}
            target="_blank"
            rel="noopener noreferer"
          >
            <Shortcut name={shortcut.name} iconUrl={iconUrl} />
          </ShortcutLink>
        );
      })}
      {loading === 'pending' && 'Loading...'}
    </>
  );
};

export default NetworkShortcuts;
