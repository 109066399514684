import { Fragment, useCallback, useEffect } from 'react';
import { rgba } from 'polished';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Tooltip } from 'antd';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/opacity.css';

import { useSelector } from 'store';
import { hasPermission } from 'store/selectors/authSelectors';
import { bannerHidden, gallerySelector } from 'store/selectors/bannerSelector';
import { toggleBannerVisible, fetchPublishedBanners } from 'store/reducers/bannerSlice';
import { fetchMedia } from 'lib/helper';
import { useAuth } from 'hooks';

import loading from 'assets/images/blinking-blocks.gif';

// #region STYLES
const bannerButton = ({ theme }: Themes.PropsWithTheme) => css`
  color: ${theme.colors.gray4};
  cursor: pointer;
  background: ${rgba(theme.colors.gray10, 0.5)};
  border: 0;
  border-radius: 100%;
  outline: 0;

  :hover {
    color: ${theme.colors.primary};
  }
`;

const AbsoluteContainer = styled.div`
  position: absolute;
  top: 11px;
  right: 11px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 200ms linear;
`;

const CarouselContainer = styled.div<{ isHidden?: boolean }>`
  position: relative;
  max-height: 300px;
  margin-bottom: 0.5em;
  overflow: hidden;
  border-radius: 8px;
  transition: all 0.7s ease-out;

  & .control-dots {
    .dot {
      width: 10px;
      height: 10px;
      box-shadow: none;
    }
  }

  :hover ${AbsoluteContainer} {
    visibility: visible;
    opacity: 1;
  }

  ${props =>
    props.isHidden &&
    css`
      max-height: 0;
      margin-bottom: 0;
      transition: all 0.7s ease-in;
    `};
`;

const Wrapper = styled.div`
  margin-bottom: 6px;
`;
const BannerItem = styled.div`
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.gray4};
  border-radius: 8px;

  .placeholder {
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
  }
  .placeholder[style] {
    display: block !important;
    background-size: 64px 64px !important;
  }
`;

const BannerImg = styled(LazyLoadImage)`
  width: 100%;
  max-width: 720px;
  max-height: 240px;
  border-radius: 8px;
`;
const Hide = styled.button`
  ${bannerButton}
  width: 28px;
  height: 28px;
  padding: 0;
  font-size: 12px;
`;
const ManageBanner = styled(Link)`
  ${bannerButton}
  padding: 7px 18px;
  margin-right: 12px;
  font-weight: 600;
  border-radius: 4px;
  ${({ theme }) => theme.typography.fontSmall()};
`;
const Show = styled.button<{ isHidden?: boolean }>`
  float: right;
  width: 22px;
  height: 22px;
  padding: 0;
  margin-left: auto;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.gray5};
  cursor: pointer;
  visibility: hidden;
  background: ${({ theme }) => theme.colors.gray3};
  border: 0;
  border-radius: 100%;
  outline: 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
  opacity: 0;
  transition: opacity 0.8s ease-out;

  :hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${props =>
    props.isHidden &&
    css`
      visibility: visible;
      opacity: 1;
      transition: opacity 0.8s ease-in;
    `}
`;
// #endregion

const { gtag } = window;

function Banner() {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const banners = useSelector(gallerySelector);
  const isBannerHidden = useSelector(bannerHidden);
  const createBanner = useSelector(state => hasPermission(state, 'CreateBanner'));
  const { token } = useAuth();

  useEffect(() => {
    dispatch(fetchPublishedBanners());
  }, [dispatch]);

  const handleBannerVisibility = useCallback(() => {
    dispatch(toggleBannerVisible());

    // Use oposite value since "isBannerHidden" is not updated until render
    if (!isBannerHidden) {
      gtag('event', 'banner', { action: 'Banner oculto' });
    } else {
      gtag('event', 'banner', { action: 'Banner visível' });
    }
  }, [dispatch, isBannerHidden]);

  return (
    <Fragment>
      {banners.length > 0 && (
        <Wrapper className="clearfix">
          <CarouselContainer isHidden={isBannerHidden}>
            <Carousel
              autoPlay
              infiniteLoop
              interval={5000}
              showArrows={false}
              showIndicators={banners.length > 1}
              showStatus={false}
              showThumbs={false}
            >
              {banners.map((b, index) => (
                <BannerItem key={b.banner.id}>
                  {b.banner.linkText ? (
                    <a
                      href={`https://${b.banner.linkText.replace(/https?:\/\//, '')}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <BannerImg
                        src={fetchMedia(b.media.mediaId, token)}
                        placeholderSrc={loading}
                        wrapperClassName="placeholder"
                      />
                    </a>
                  ) : (
                    <BannerImg
                      src={fetchMedia(b.media.mediaId, token)}
                      placeholderSrc={loading}
                      wrapperClassName="placeholder"
                    />
                  )}
                </BannerItem>
              ))}
            </Carousel>
            <AbsoluteContainer>
              {createBanner && (
                <ManageBanner to="/admin/banner">{t('feed.manageBanner')}</ManageBanner>
              )}
              <Tooltip title={t('feed.hideBannerTooltip')}>
                <Hide onClick={handleBannerVisibility}>
                  <FontAwesomeIcon icon={faEyeSlash} />
                </Hide>
              </Tooltip>
            </AbsoluteContainer>
          </CarouselContainer>
          <Tooltip title={t('feed.showBannerTooltip')}>
            <Show isHidden={isBannerHidden} onClick={handleBannerVisibility}>
              <FontAwesomeIcon icon={faEye} />
            </Show>
          </Tooltip>
        </Wrapper>
      )}
    </Fragment>
  );
}

export default Banner;
