import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ImageUploader from 'components/layout/Common/Uploader/ImageUploader/ImageUploader';
import FileUploader from 'components/layout/Common/Uploader/FileUploader';

const uploader = (props: Themes.PropsWithTheme<{ disabled?: boolean }>) => css`
  height: 32px;
  margin-right: 8px;
  ${props.theme.typography.fontSmall('32px')}

  ${!props.disabled &&
  css`
    &&:hover {
      color: ${props.theme.colors.primary};
      transition: all 0.08s ease;
      transform: scale(1.2);
    }
    &&:active {
      color: ${props.theme.colors.primary}; /* TODO: Darken main theme */
      transform: scale(1.1);
    }
  `}

  && {
    color: ${props.disabled ? props.theme.colors.gray5 : props.theme.colors.gray9};
    cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
`;

export const ImgUploader = styled(ImageUploader)`
  ${uploader}
`;

export const FlUploader = styled(FileUploader)`
  ${uploader}
`;
