import { css, Theme } from '@emotion/react';
import { generate } from '@ant-design/colors';

const [primary1, primary2, primary3, primary4, primary5, primary6, primary7] = generate('#26A9E0');
const [danger1, danger2, danger3, danger4, danger5, danger6, danger7] = generate('#DE2A2F');
const [success1, success2, success3, success4, success5, success6, success7] = generate('#0CCE6B');
const [cyan1, cyan2, cyan3, cyan4, cyan5, cyan6, cyan7] = generate('#24A4AF');
const [purple1, purple2, purple3, purple4, purple5, purple6, purple7] = generate('#6610F2');

export const primaryColor = primary6;
const danger = danger6;
const success = success6;
const cyan = cyan6;
const purple = purple6;

const theme: Theme = {
  breakpoints: {
    tablet: '600px',
    lgTablet: '1024px',
    notebook: '1280px',
    smDesktop: '1440px',
    desktop: '1600px',
    lgDesktop: '1820px',
  },
  colors: {
    black: '#000',
    white: '#fff',
    blue: '#3498db',
    blue7: '#2980b9',
    cyan,
    cyan1,
    cyan2,
    cyan3,
    cyan4,
    cyan5,
    cyan6,
    cyan7,
    danger,
    danger1,
    danger2,
    danger3,
    danger4,
    danger5,
    danger6,
    danger7,
    gray1: '#fafafa',
    gray2: '#f5f5f5',
    gray3: '#eee',
    gray4: '#e0e0e0',
    gray5: '#bdbdbd',
    gray6: '#9e9e9e',
    gray7: '#757575',
    gray8: '#616161',
    gray9: '#424242',
    gray10: '#212121',
    primary: primaryColor,
    primary1,
    primary2,
    primary3,
    primary4,
    primary5,
    primary6,
    primary7,
    purple,
    purple1,
    purple2,
    purple3,
    purple4,
    purple5,
    purple6,
    purple7,
    success,
    success1,
    success2,
    success3,
    success4,
    success5,
    success6,
    success7,
  },
  typography: {
    fontxxSmall: (lineHeight = 1.5) => css`
      font-size: 0.625rem; /* 10px */
      line-height: ${lineHeight};
    `,
    fontxSmall: (lineHeight = 1.5) => css`
      font-size: 0.75rem; /* 12px */
      line-height: ${lineHeight};
    `,
    fontSmall: (lineHeight = 1.5) => css`
      font-size: 0.875rem; /* 14px */
      line-height: ${lineHeight};
    `,
    fontNormal: (lineHeight = 1.5) => css`
      font-size: 1rem; /* 16px */
      line-height: ${lineHeight};
    `,
    fontLarge: (lineHeight = 1.5) => css`
      font-size: 1.125rem; /* 18px */
      line-height: ${lineHeight};
    `,
    fontxLarge: (lineHeight = 1.5) => css`
      font-size: 1.25rem; /* 20px */
      line-height: ${lineHeight};
    `,
    fontxxLarge: (lineHeight = 1.5) => css`
      font-size: 1.375rem; /* 22px */
      line-height: ${lineHeight};
    `,
  },
};

export default theme;
