import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as linkifyjs from 'linkifyjs';
import Editor, { EditorPlugin } from '@draft-js-plugins/editor';
import { EditorProps, EditorState } from 'draft-js';
import createCounterPlugin from '@draft-js-plugins/counter';
import { CSSTransition } from 'react-transition-group';
import { useTheme } from '@emotion/react';

import createCounterTheme from 'styles/draftjs/counterTheme';

export interface TextEditorProps {
  disabled?: boolean;
  editorState: EditorState;
  autoFocus: boolean;
  className?: string;
  placeholder?: string;
  plugins?: EditorPlugin[];
  handleReturn?: EditorProps['handleReturn'];
  onChange: (editorState: EditorState, linksList: string[], isOverLimit: boolean) => void;
  onBlur?: EditorProps['onBlur'];
  onFocus?: EditorProps['onFocus'];
}

const counterLimit = 2048;
export default function TextEditor({
  disabled = false,
  editorState,
  autoFocus,
  className = '',
  placeholder,
  plugins: pluginsList = [],
  handleReturn,
  onChange,
  onBlur,
  onFocus,
}: TextEditorProps) {
  const theme = useTheme();

  const [counterVisible, setCounterVisible] = useState(false);

  const editorWrapperRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<Editor>(null);

  const counterTheme = createCounterTheme(theme);

  const { counterPlugin, CharCounter } = useMemo(() => {
    const counterPlugin = createCounterPlugin({
      theme: {
        counterOverLimit: counterTheme.counterOverLimit,
      },
    });

    const { CharCounter } = counterPlugin;

    return { counterPlugin, CharCounter };
  }, [counterTheme.counterOverLimit]);

  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        editorRef.current!.focus();
        if (window.getSelection) {
          const selection = window.getSelection();
          const range = document.createRange();
          range.selectNodeContents(editorWrapperRef.current!);
          selection!.removeAllRanges();
          selection!.addRange(range);
        }
      }, 10);
    }
  }, [autoFocus]);

  const changeHandler: EditorProps['onChange'] = editorState => {
    const charCount = editorState
      .getCurrentContent()
      .getPlainText()
      .replace(/\r?\n|\r/g, '').length; // Remove enter characters

    const nearLimit = counterLimit * 0.9;
    setCounterVisible(charCount > nearLimit);
    const isOverLimit = charCount > counterLimit;

    onChange(editorState, parseLinksContent(editorState), isOverLimit);
  };

  return (
    <>
      <div
        role="textbox"
        className={className}
        onClick={() => editorRef.current?.focus()}
        translate="no"
        ref={editorWrapperRef}
      >
        <Editor
          readOnly={disabled}
          stripPastedStyles
          autoCorrect="off"
          editorKey="postEditor"
          editorState={editorState}
          handleReturn={handleReturn}
          onChange={changeHandler}
          onBlur={onBlur}
          onFocus={onFocus}
          placeholder={placeholder}
          plugins={[counterPlugin, ...pluginsList]}
          ref={editorRef}
        />
      </div>
      <CSSTransition
        in={counterVisible}
        classNames={{
          enter: counterTheme.counterWrapperEnter,
          enterActive: counterTheme.counterWrapperEnterActive,
          enterDone: counterTheme.counterWrapperEnterDone,
          exit: counterTheme.counterWrapperExit,
          exitActive: counterTheme.counterWrapperExitActive,
        }}
        timeout={400}
      >
        <div className={counterTheme.counterWrapper}>
          <CharCounter className={counterTheme.counter} limit={counterLimit} />
        </div>
      </CSSTransition>
    </>
  );
}

const parseLinksContent = (editorState: EditorState) => {
  const links: string[] = [];
  editorState
    .getCurrentContent()
    .getBlockMap()
    .forEach(block => {
      const matches = linkifyjs.find(block!.get('text'), 'url');

      if (matches != null && matches.length > 0) {
        matches.forEach(m => links.push(m.href));
      }
    });

  return links;
};
