import styled from '@emotion/styled';

export const SubmitWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 1em;
`;

export const ErrorMessage = styled.span`
  display: block;
  margin-block: 0.5em;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.danger};

  ${({ theme }) => theme.typography.fontxSmall('20px')}
`;
