import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Space, Switch, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { shallow } from 'zustand/shallow';
import { toast } from 'react-toastify';

import { useAuth, useFeatureToggle, usePermissions } from 'hooks';
import { PROFILE_TYPES } from 'lib/constants';
import { useDispatch, useSelector } from 'store';
import { getScheduledCount, submitPost } from 'store/actions/postsActions';
import type { StoreKey } from 'store/types/newPostTypes';
import changeVisibility, {
  addTarget,
  changeProfile,
  initNewPostStore,
} from 'store/actions/newPostActions';
import PostModifierMenu, {
  PostModifierMenuRef,
} from 'components/layout/Common/PostModifierMenu/PostModifierMenu';
import * as customNotification from 'components/layout/Common/CustomNotification';
import PostFilterModal, {
  PostFilterModalRef,
} from 'components/modal/PostFilterModal/PostFilterModal';
import { useGroupInfo } from 'components/Groups/GroupStore';

import PostEditor, { PostEditorProps } from '../PostEditor/PostEditor';
import ScopeSelector from '../ScopeSelectorWrapper';
import ComposerProfileWrapper from '../ComposerProfileWrapper';
import * as S from './ComposerModalStyles';
import { useComposerStore } from '../composerContext';

export interface ComposerModalProps {
  storeKey: 'NEW_POST' | 'NEW_POST_GROUP';
}

export type ComposerModalRef = {
  open: () => void;
};

const ComposerModal = forwardRef<ComposerModalRef, ComposerModalProps>(({ storeKey }, ref) => {
  const { t } = useTranslation('feed');
  const [canCreatePublicPost, canCreatePublicPostForPage] = usePermissions(
    'CreatePublicPost',
    'createPublicPostForPage'
  );

  const dispatch = useDispatch();
  const visibility = useSelector(state => state.newPost.posts[storeKey]?.visibility);
  const profile = useSelector(
    state => state.newPost.posts[storeKey]?.profile,
    (profileA, profileB) => profileA?.profileId === profileB?.profileId
  );

  const groupInfo = useGroupInfo();

  const [open, setOpen] = useState(false);

  const profileRef = useRef<ProfileRef>(null);

  const { attachments, attachmentType, editorState, scheduledDate, pinned, blockCommenting } =
    useComposerStore(
      state => ({
        attachments: state.attachments,
        attachmentType: state.attachmentType,
        editorState: state.editorState,
        scheduledDate: state.scheduleDate,
        pinned: state.pinned,
        blockCommenting: state.blockCommenting,
        descriptionText: state.descriptionText,
      }),
      shallow
    );
  const setIsSending = useComposerStore(state => state.setIsSending);
  const resetStore = useComposerStore(state => state.resetStore);

  useImperativeHandle(ref, () => ({
    open: () => setOpen(true),
  }));

  const closeHandler = () => {
    const content = editorState.getCurrentContent().getPlainText().trim();

    if (content.length > 0) {
      Modal.confirm({
        content: t('prompt.discardPost'),
        title: t('prompt.discardTitle'),
        onOk: () => {
          setOpen(false);
          reset();
        },
      });
    } else {
      setOpen(false);
      reset();
    }
  };

  const submitPostHandler: PostEditorProps['onSubmitPost'] = surveyInfo => {
    const contentState = editorState.getCurrentContent();

    const postAttributes = {
      _extra: {
        contentState,
        files: [...attachments].reverse(),
        fileType: attachmentType,
        pinned,
        scheduledDate,
        blockCommenting,
        surveyInfo,
      },
      type: visibility ? typePost[visibility] : 1,
      sendToFollowers: visibility === 'followers',
    };

    setIsSending(true);

    dispatch(
      submitPost(
        storeKey,
        postAttributes,
        status => postResult(scheduledDate, status),
        s => {
          // this.cancelToken = s;
        }
      )
    );
  };

  const postResult = (date: string | undefined, status: number) => {
    switch (status) {
      case 200:
        reset();
        toast(t('toast.successPost'), {
          className: 'bee-toast primary',
          position: toast.POSITION.TOP_RIGHT,
          toastId: 'new-post',
        });
        setOpen(false);
        break;
      case 202: {
        if (date) {
          customNotification.renderToast({
            title: t(
              `default:post.schedule.${
                profile?.profileType === PROFILE_TYPES.user && storeKey === 'NEW_POST_GROUP'
                  ? 'successByMember'
                  : 'success'
              }`
            ),
            type: customNotification.TYPE_POST_SCHEDULED,
          });
          dispatch(
            getScheduledCount(
              profile?.profileType === PROFILE_TYPES.group ? groupInfo.page.pageId : undefined
            )
          );
        } else {
          customNotification.renderToast({
            type: customNotification.TYPE_VIDEO_PROCESSING,
            title: t('default:post.processingVideoTitle'),
            body: t('default:post.processingVideo'),
          });
        }
        reset();
        setOpen(false);

        break;
      }
      default:
        setIsSending(false);
        break;
    }
  };

  const reset = () => {
    resetStore();

    dispatch(initNewPostStore(storeKey, canCreatePublicPost ? 'public' : 'followers'));
    profileRef.current?.resetModifiers();

    if (storeKey === 'NEW_POST_GROUP') {
      dispatch(changeProfile(storeKey, profile!));

      if (profile?.profileType === PROFILE_TYPES.group && groupInfo.page.public) {
        dispatch(changeVisibility(storeKey, canCreatePublicPostForPage ? 'public' : 'group'));
        dispatch(addTarget(storeKey, []));
      } else {
        dispatch(changeVisibility(storeKey, 'group'));
        dispatch(addTarget(storeKey, [groupInfo.page.pageId]));
      }
    }
  };

  return (
    <Modal
      css={S.modal}
      destroyOnClose
      centered
      visible={open}
      title={t('editor.createPost')}
      footer={null}
      onCancel={closeHandler}
      width={744}
    >
      <Profile storeKey={storeKey} ref={profileRef} />
      <PostEditor onSubmitPost={submitPostHandler} />
    </Modal>
  );
});

export default ComposerModal;

type ProfileRef = {
  resetModifiers: () => void;
};

const Profile = forwardRef<ProfileRef, { storeKey: StoreKey }>(({ storeKey }, ref) => {
  const { t } = useTranslation();
  const { userInfo } = useAuth();
  const [createNews] = usePermissions('CreateNews');
  const [newFeed] = useFeatureToggle('new_feed');

  const dispatch = useDispatch();
  const newPostProfile = useSelector(state => state.newPost.posts[storeKey]?.profile);
  const visibility = useSelector(state => state.newPost.posts[storeKey]?.visibility);

  const [postAsNews, setPostAsNews] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [targetUsers, setTargetUsers] = useState<UserInfo[]>([]);

  const setModifier = useComposerStore(state => state.setModifier);
  const isSending = useComposerStore(state => state.isSending);

  const postModifierMenuRef = useRef<PostModifierMenuRef>(null);
  const filterRef = useRef<PostFilterModalRef>(null);

  function targetTooltip() {
    const firstThree = targetUsers.slice(0, 3).map(target => target.user.name);

    return t('filter.tooltipCount_interval', {
      postProcess: 'interval',
      targets: firstThree.join(', '),
      count: targetUsers.length,
      restCount: targetUsers.length - 3,
    });
  }

  useImperativeHandle(ref, () => ({
    resetModifiers: () => {
      postModifierMenuRef.current?.resetModifiers();
      setPostAsNews(false);
    },
  }));

  useEffect(() => {
    if (visibility === 'private') {
      setFilterOpen(true);
      filterRef.current?.clearAll();
    }
  }, [visibility]);

  const changeProfileHandler = (checked: boolean) => {
    setPostAsNews(checked);

    dispatch(
      changeProfile(storeKey, {
        ...newPostProfile!,
        profileType: checked ? PROFILE_TYPES.publisher : PROFILE_TYPES.user,
      })
    );
  };

  const selectedUsersHandler = (selectedUsers: UserInfo[]) => {
    setTargetUsers(selectedUsers);
  };

  return (
    <>
      <S.ProfileContainer>
        <ComposerProfileWrapper storeKey={storeKey} />
        <Space direction="vertical" className="name-wrapper">
          <span>{storeKey === 'NEW_POST' ? userInfo.user.name : newPostProfile?.name}</span>
          <S.ScopeWrapper>
            <ScopeSelector storeKey={storeKey} />
            {visibility === 'private' && (
              <Tooltip title={targetTooltip()}>
                <PostFilterModal.EditFilterButton
                  count={targetUsers.length}
                  onClick={() => setFilterOpen(true)}
                />
              </Tooltip>
            )}
            {createNews && newFeed && storeKey === 'NEW_POST' && (
              <S.PostAsNewsContainer>
                <Switch
                  disabled={isSending}
                  checked={newPostProfile?.profileType === PROFILE_TYPES.publisher}
                  size="small"
                  onChange={changeProfileHandler}
                />{' '}
                <label role="button" onClick={() => changeProfileHandler(!postAsNews)}>
                  {t('post.postAsNews')}
                </label>
              </S.PostAsNewsContainer>
            )}
          </S.ScopeWrapper>
        </Space>
        <PostModifierMenu
          storeKey={storeKey}
          renderButton={() => (
            <Button shape="circle" icon={<FontAwesomeIcon icon={faEllipsis} />} />
          )}
          onChange={setModifier}
          ref={postModifierMenuRef}
        />
      </S.ProfileContainer>
      <PostFilterModal
        storeKey={storeKey}
        visible={filterOpen}
        selectedUsers={selectedUsersHandler}
        onClose={() => setFilterOpen(false)}
        ref={filterRef}
      />
    </>
  );
});

const typePost: Record<string, number> = {
  public: 1,
  private: 2,
  followers: 3,
  group: 4,
  admin: 4,
};
