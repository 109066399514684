import { useLayoutEffect, useState, VFC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, message, Modal, Space, Steps } from 'antd';

import { useAuth } from 'hooks';
import { updateUser, setTermsRefused, setLgpdTermsRefused } from 'lib/apiActions';
import { userUpdated, logout } from 'store/actions/authActions';

import TermsOfUse from 'components/application/TermsOfUse/TermsOfUse';
import LGPDTerms from 'components/application/LGPDTerms/LGPDTerms';

import { Container, Content } from './AcceptTermsStyles';

const termsSteps = [
  {
    step: <TermsOfUse />,
  },
  {
    step: <LGPDTerms />,
  },
];

const { Step } = Steps;
const AcceptTerms: VFC = () => {
  const { t } = useTranslation();
  const { userId, userInfo } = useAuth();
  const dispatch: AppDispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(0);

  useLayoutEffect(() => {
    if (currentStep > 0) {
      document.querySelector('.RMM__body')?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [currentStep]);

  const updateTerms = async (termsAccepted: boolean) => {
    const result = await updateUser(userId, {
      termsAccepted: termsAccepted,
      termsAcceptedDate: new Date().toISOString(),
      lgpdTermsAccepted: termsAccepted,
      lgpdTermsAcceptedDate: new Date().toISOString(),
    });

    if (!result || typeof result === 'number') {
      message.error(t('errors.generic'));
      return;
    }

    if (result)
      dispatch(
        userUpdated({
          ...userInfo,
          user: { ...userInfo.user, termsAccepted, lgpdTermsAccepted: termsAccepted },
        })
      );
  };

  const acceptTermsHandler = async () => {
    if (currentStep === 0) {
      setCurrentStep(currentStep + 1);
      return;
    }

    await updateTerms(true);
  };

  const confirmRefuseHandler = async () => {
    const [termsResult, lgpdTermsResult] = await Promise.all([
      setTermsRefused(),
      setLgpdTermsRefused(),
    ]);

    if (termsResult !== 200 && lgpdTermsResult !== 200) {
      message.error(t('errors.generic'));
      return;
    }

    dispatch(logout());
  };

  const refuseTermsHandler = async () => {
    Modal.confirm({
      content: t('termsOfUse.confirmRefuse'),
      cancelText: t('common.doNotRefuse'),
      cancelButtonProps: { type: 'default' },
      okText: t('common.refuse'),
      type: 'confirm',
      onOk: confirmRefuseHandler,
    });
  };

  return (
    <Container>
      <Steps current={currentStep} className="steps">
        <Step key={0} title={t('termsOfUse.menuTitle')} />
        <Step key={1} title={t('lgpdTerms.menuTitle')} />
      </Steps>
      <Content>
        {termsSteps[currentStep].step}
        <Space className="button-container">
          <Button type="link" onClick={refuseTermsHandler}>
            {t('common.refuse')}
          </Button>
          <Button type="primary" onClick={acceptTermsHandler}>
            {t('common.accept')}
          </Button>
        </Space>
      </Content>
    </Container>
  );
};

export default AcceptTerms;
