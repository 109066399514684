import produce from 'immer';
import { forEach } from 'lodash/fp';
import { UsersActionType, UsersState } from 'store/types/usersTypes';
import { UsersActions } from '../types/types';

const initialState: UsersState = {
  users: {} as UsersState['users'],
  usersContacts: {} as UsersState['usersContacts'],
  onlineUsers: {} as UsersState['onlineUsers'],
};

const usersReducer = (state = initialState, action: UsersActionType) =>
  produce(state, draft => {
    switch (action.type) {
      case UsersActions.FETCH_USER: {
        const { userId, user } = action.payload;
        draft.users[userId] = user;
        break;
      }
      case UsersActions.FETCH_USER_CONTACTS: {
        const { userId, contacts } = action.payload;
        draft.usersContacts[userId] = contacts;
        break;
      }
      case UsersActions.USER_CONNECTED: {
        const { userId } = action.payload;
        draft.onlineUsers[userId] = true;
        break;
      }
      case UsersActions.USER_DISCONNECTED: {
        const { userId } = action.payload;
        delete draft.onlineUsers[userId];
        break;
      }
      case UsersActions.USER_UPDATED: {
        // @ts-expect-error
        const forEachKey = forEach.convert({ cap: false });
        const { userId, user } = action.payload;

        if (!draft.users[userId]) {
          draft.users[userId] = user as UserInfo;
        } else {
          forEachKey(
            (value: any, key: keyof Omit<UserInfo, 'online' | 'follow' | 'profilePhoto'>) => {
              const current = draft.users[userId][key];
              draft.users[userId][key] = { ...current, ...value };
            },
            user
          );
        }
        break;
      }
      case UsersActions.USER_CONTACTS_UPDATED: {
        // @ts-expect-error
        const forEachKey = forEach.convert({ cap: false });
        const { userId, userContacts } = action.payload;

        if (!draft.usersContacts[userId]) {
          draft.usersContacts[userId] = userContacts;
        } else {
          forEachKey((value: string, key: keyof UserContacts) => {
            draft.usersContacts[userId][key] = value;
          }, userContacts);
        }
        break;
      }
    }
  });

export default usersReducer;
