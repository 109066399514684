import React from 'react';

// GLOBAL COMPONENTS
import { css } from '@emotion/react';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

// #region STYLES
const mainMenu = css`
  display: flex;
  flex: 1;
  flex-direction: column;

  &.ant-menu {
    max-width: 60px;
    background-color: transparent;
  }
`;
const bottomMenu = css`
  margin-top: auto;
`;

export const menuItem = (theme: Themes.DefaultTheme) => css`
  &.ant-menu-item {
    height: 32px;
    padding: 0 20px !important;
    margin-top: 20px;
    line-height: 32px;

    :hover {
      background-color: rgba(0, 0, 0, 0.03);
    }
    ::after {
      border-right-color: transparent;
    }
    :not(:last-child) {
      margin-bottom: 0;
    }

    .anticon {
      width: 1em;
      font-size: 21px !important;
    }
    a {
      color: ${theme.colors.gray7};
    }
    a:hover {
      color: ${theme.colors.gray9};
    }
    a.active {
      font-weight: 600;
      color: ${theme.colors.primary};
      background-color: transparent;
    }
    &.ant-menu-item-selected {
      background-color: transparent;
    }
  }
`;

// #endregion

type NavMenuItemProps = {
  to: string;
  label: string;
  icon: IconProp;
};

const NavMenuItem = (props: NavMenuItemProps) => {
  const { to, label, icon } = props;
  return (
    <NavLink to={to} className={({ isActive }) => (isActive ? 'active' : '')}>
      <FontAwesomeIcon className="anticon" icon={icon} /> <span>{label}</span>
    </NavLink>
  );
};

type VerticalMenuProps = {
  menuItems: Array<[string, React.ReactNode]>;
  bottomMenuItems: Array<[string, React.ReactNode]>;
};

const VerticalMenu = (props: VerticalMenuProps) => {
  const { menuItems, bottomMenuItems } = props;

  return (
    <Menu css={mainMenu} mode="inline" style={{ borderRight: 0 }} inlineCollapsed>
      {menuItems.map(([key, item]) => (
        <Menu.Item key={key} css={menuItem}>
          {item}
        </Menu.Item>
      ))}
      <Menu.ItemGroup css={bottomMenu}>
        {bottomMenuItems.map(([key, item]) => (
          <Menu.Item key={key} css={menuItem}>
            {item}
          </Menu.Item>
        ))}
      </Menu.ItemGroup>
    </Menu>
  );
};

export default Object.assign(React.memo(VerticalMenu), { NavMenuItem });
