import axios from 'axios';
import { serialize } from 'object-to-formdata';
import { createCancelToken, statusError, CancelCallback } from '../apiActions';

export interface CreateUserParams {
  name: string;
  email: string;
  companyId: number;
  companyUnitId: number;
  departmentId: number;
  occupationId: number;
  password: string;
  sex: string;
  birthday: string;
  currentCity: string;
  termsAccepted: boolean;
  profileImage?: File;
  phone?: string;
  phoneExtension?: string;
  whatsapp?: string;
}
export async function createUser(createUserParams: CreateUserParams, token: string) {
  const userFormData = serialize(createUserParams);

  try {
    const { data, status } = await axios.post('/api/account/createUser', userFormData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    return { data, status };
  } catch (error) {
    return statusError(error);
  }
}

export async function updateProfilePhoto(
  userId: number,
  profileImage: File
): Promise<{ media: Media; status: number } | number | undefined> {
  try {
    const formData = new FormData();
    formData.append('profileImage', profileImage);
    const { data, status } = await axios.put('/api/account/updateProfilePhoto', formData, {
      params: { userId },
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return { media: data, status };
  } catch (error) {
    return statusError(error);
  }
}

export async function updateProfileCover(
  userId: number,
  coverImage: File
): Promise<{ media: Media; status: number } | number | undefined> {
  try {
    const formData = new FormData();
    formData.append('coverImage', coverImage);

    const { data, status } = await axios.put('/api/account/updateProfileCover', formData, {
      params: { userId },
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return { media: { itemId: data.userId, mediaId: data.coverMediaId, ...data }, status };
  } catch (error) {
    return statusError(error);
  }
}

export interface UpdateUserParams {
  profileImage?: File;
  userId?: number;
  companyId?: number;
  oldId?: number;
  companyUnitId?: number;
  departmentId?: number;
  occupationId?: number;
  name?: string;
  userName?: string;
  email?: string;
  password?: string;
  sex?: string;
  birthday?: string;
  currentCity?: string;
  hometown?: string;
  bio?: string;
  mediaId?: number;
  relationship?: string;
  address?: string;
  zip?: string;
  createdDate?: string;
  deletedDate?: string;
  networkId?: number;
  cpf?: string;
  workphone?: string;
  phoneExtension?: string;
  whatsapp?: string;
  networkOwner?: boolean;
  department?: KeyValue;
  occupation?: KeyValue;
  deleted?: boolean;
  termsAccepted?: boolean;
  termsAcceptedDate?: string;
  isSystemAdmin?: boolean;
  lgpdTermsAccepted?: boolean;
  lgpdTermsAcceptedDate?: string;
  coverImage?: File;
  removePhoto?: boolean;
}
export async function updateUser(
  userId: number,
  updateUserParams: UpdateUserParams
): Promise<{ status: number; userInfo: UserInfo } | number | undefined> {
  const userFormData = serialize(updateUserParams);

  try {
    const { data, status } = await axios.put('/api/account/updateUser', userFormData, {
      params: { userId },
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return { userInfo: data, status: status };
  } catch (error) {
    return statusError(error);
  }
}

export async function setTermsRefused() {
  try {
    const response = await axios.post('/api/account/setTermsRefused');

    return response.status;
  } catch (error) {
    return statusError(error);
  }
}

export async function setLgpdTermsRefused() {
  try {
    const response = await axios.post('/api/account/setLgpdTermsRefused');

    return response.status;
  } catch (error) {
    return statusError(error);
  }
}

export type UserDeviceBody = Required<UserDevice>;
export async function checkDevice(params: UserDeviceBody, cancelCallback?: CancelCallback) {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const response = await axios.post('/api/account/checkDevice', params, {
      cancelToken,
    });

    return response.status;
  } catch (error) {
    return statusError(error);
  }
}

export type UserConnectionParams = {
  token: string;
};
export async function userConnected(params: UserConnectionParams, cancelCallback?: CancelCallback) {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const response = await axios.post('/api/account/userConnected', params, { cancelToken });

    return response.status;
  } catch (error) {
    return statusError(error);
  }
}

export async function userDisconnected(
  params: UserConnectionParams,
  cancelCallback?: CancelCallback
) {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const response = await axios.post('/api/account/userDisconnected', params, { cancelToken });

    return response.status;
  } catch (error) {
    return statusError(error);
  }
}

export type SaveUserSecurityParams = {
  email?: string;
  phone?: string;
};
export async function saveUserSecurity(
  params: SaveUserSecurityParams,
  cancelCallback?: CancelCallback
): Promise<number | undefined> {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const response = await axios.post('/api/account/saveUserSecurity', params, { cancelToken });

    return response.status;
  } catch (error) {
    return statusError(error);
  }
}

export async function getUserSecurity(
  cancelCallback?: CancelCallback
): Promise<UserSecurity | number | undefined> {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const { data } = await axios.get('/api/account/getUserSecurity', { cancelToken });

    return data;
  } catch (error) {
    return statusError(error);
  }
}

export async function getAccountInfo(
  cancelCallback?: CancelCallback
): Promise<AccountInfo | number | undefined> {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const { data } = await axios.get('/api/account/getAccountInfo', { cancelToken });

    return data;
  } catch (error) {
    return statusError(error);
  }
}

export type SendVerificationCodeParams = { value: string };
export async function sendVerificationCode(
  params: SendVerificationCodeParams,
  cancelCallback?: CancelCallback
): Promise<number | undefined> {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const { data } = await axios.post('/api/account/sendVerificationCode', params, { cancelToken });

    return data;
  } catch (error) {
    return statusError(error);
  }
}

export async function verifyCode(
  verificationCode: string,
  cancelCallback?: CancelCallback
): Promise<boolean | number | undefined> {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const { data } = await axios.post('/api/account/verifyCode', JSON.stringify(verificationCode), {
      cancelToken,
      headers: { 'Content-Type': 'text/plain' },
    });

    return data;
  } catch (error) {
    return statusError(error);
  }
}
