import { useMemo } from 'react';

import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { readableColor } from 'polished';
import { components } from 'react-select';

import ProfileImage from 'components/layout/Common/ProfileImage/ProfileImage';

const { Option } = components;

// #region STYLES
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;
const FlexImg = styled.div`
  align-self: flex-start;
  margin-right: 8px;
`;
const FlexLabel = styled.div`
  flex: 1;
`;
const FlexManagerContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const FlexManagerLabel = styled.div`
  flex: 1;
  font-size: ${props => (props.isName ? 14 : 12)}px;
  font-weight: ${props => (props.isName ? 800 : 400)};
`;
// #endregion

const AvatarOption = ({ children, value = '', data, ...props }) => (
  <Option {...props}>
    <FlexContainer>
      <FlexImg>
        <ProfileImage profile={{ profileId: value, name: children }} size="xs" />
      </FlexImg>
      <FlexLabel>{children}</FlexLabel>
    </FlexContainer>
  </Option>
);

const AvatarManagerOption = ({ children, value = '', data, ...props }) => (
  <Option {...props}>
    <FlexContainer>
      <FlexImg>
        <ProfileImage profile={{ profileId: value, name: children }} size="xs" />
      </FlexImg>
      <FlexManagerContainer>
        <FlexManagerLabel isName={true}>{children}</FlexManagerLabel>
        <FlexManagerLabel>{`${data.data.occupation?.description ?? ''} - ${
          data.data.department?.description ?? ''
        }`}</FlexManagerLabel>
      </FlexManagerContainer>
    </FlexContainer>
  </Option>
);

function useReactSelectStyles(options = {}, invalid) {
  const { width, height, maxHeight, backgroundColor, border, borderRadius, tagColor, tagWeight } =
    options;
  const theme = useTheme();
  const { colors } = theme;

  const styles = useMemo(
    () => ({
      clearIndicator: props => ({ ...props, cursor: 'pointer', padding: '2px 4px' }),
      control: (props, { isFocused }) => {
        let color = isFocused ? colors.primary : colors.gray5;
        if (invalid) color = colors.danger;

        return {
          ...props,
          width: width || '100%',
          height,
          maxHeight: maxHeight || 94,
          padding: '5px 2px',
          backgroundColor: backgroundColor || props.backgroundColor,
          border: border || `1px solid ${color}`,
          borderRadius,
          overflow: 'auto',
          boxShadow: isFocused && `0 0 0 2px ${colors.primary5}`,
          ':hover': { border: `1px solid ${colors.primary}` },
        };
      },
      dropdownIndicator: props => ({ ...props, cursor: 'pointer', padding: '2px 4px' }),
      group: props => ({ ...props, border: '1px solid red' }),
      groupHeading: props => ({ ...props, border: '1px solid red' }),
      indicatorsContainer: props => ({
        ...props,
        '& > div': {
          position: 'sticky',
          top: '2em',
          bottom: '2em',
        },
      }),
      input: props => ({ ...props, margin: '2px 0', padding: '1px 0' }),
      loadingMessage: props => ({ ...props, fontSize: '0.79rem' }),
      menuPortal: props => ({ ...props, border: '1px solid blue' }),
      multiValue: props => {
        const color = tagColor || colors.gray9;
        return {
          ...props,
          margin: '2px 4px 2px 0',
          borderRadius: '4px',
          backgroundColor: color,
          color: readableColor(color, colors.gray10, '#fff'),
          fontSize: '0.79rem',
          fontWeight: tagWeight || 'normal',
        };
      },
      multiValueLabel: props => ({ ...props, lineHeight: '22px', padding: '0 4px', color: '#fff' }),
      multiValueRemove: props => ({
        ...props,
        lineHeight: '22px',
        padding: '0 4px',
        cursor: 'pointer',
      }),
      noOptionsMessage: props => ({ ...props, fontSize: '0.79rem' }),
      option: (props, state) => {
        const { isFocused } = state;
        return {
          ...props,
          fontSize: '0.79rem',
          lineHeight: '22px',
          fontWeight: 600,
          padding: '4px 8px',
          backgroundColor: isFocused ? colors.primary1 : 'none',
        };
      },
      placeholder: props => ({ ...props, color: colors.gray6, fontSize: '14px' }),
      singleValue: props => ({ ...props, border: '1px solid red' }),
      valueContainer: props => ({ ...props, fontSize: '0.79rem' }),
    }),
    [
      backgroundColor,
      border,
      borderRadius,
      colors,
      height,
      invalid,
      maxHeight,
      tagColor,
      tagWeight,
      width,
    ]
  );

  const selectTheme = thm => ({
    ...thm,
    colors: {
      ...thm.colors,
      danger: colors.danger,
      neutral5: colors.gray1,
      neutral10: colors.gray2,
      neutral20: colors.gray3,
      neutral30: colors.gray4,
      neutral40: colors.gray5,
      neutral50: colors.gray6,
      neutral60: colors.gray7,
      neutral70: colors.gray8,
      neutral80: colors.gray9,
      neutral90: colors.gray10,
      primary: colors.primary,
      primary75: colors.primary4,
      primary50: colors.primary3,
      primary25: colors.primary2,
    },
  });

  return { styles, selectTheme, appTheme: theme };
}

export { AvatarOption, AvatarManagerOption };
export default useReactSelectStyles;
