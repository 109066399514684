import { useDropzone, FileRejection } from 'react-dropzone';

import { useTheme } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons';

import { iconUploader, iconDisabled } from '../UploaderStyle';
import useAuth from '../../../../../hooks/useAuth';

//#region TYPES
type FileRejectedHandler = (rejectedFiles: File[], fileRejections: FileRejection[]) => void;
export type { FileRejection };

export interface FileUploaderProps {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  onFileAdded: (acceptedFiles: File[], fileRejections: FileRejection[]) => void;
  onFileRejected?: FileRejectedHandler;
}
//#endregion

function extensionValidator(file: File, forbiddenExtensions: string[]) {
  const extension = file.name.split('.').pop()!;
  const isForbidden = forbiddenExtensions.some(ext => ext.includes(extension));

  if (isForbidden)
    return { message: `Extension '.${extension}' is forbidden`, code: 'file-extension-forbidden' };

  return null;
}

const FileUploader = ({
  children,
  className,
  disabled,
  onFileAdded,
  onFileRejected,
}: FileUploaderProps) => {
  const theme = useTheme();
  const { forbiddenExtensions, maxPostFileSize } = useAuth().networkSettings;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onFileAdded,
    onDropRejected: fileRejections =>
      onFileRejected?.(
        fileRejections.map(({ file }) => file),
        fileRejections
      ),
    validator: file => extensionValidator(file, forbiddenExtensions),
    maxSize: maxPostFileSize * 1e6,
    multiple: false,
    noDrag: true,
    noKeyboard: true,
    disabled,
  });

  return (
    <div
      {...getRootProps({
        className: `${iconUploader(theme)} ${disabled ? iconDisabled(theme) : ''} ${className}`,
      })}
    >
      <input {...getInputProps()} />
      {children ?? <FontAwesomeIcon icon={faPaperclip} />}
    </div>
  );
};
FileUploader.defaultProps = {
  className: '',
};

export default FileUploader;
