import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Spin, Tabs, Tooltip } from 'antd';
import type { Canceler } from 'axios';
import { useTranslation } from 'react-i18next';

import { PROFILE_TYPES, REACTION_TYPE } from 'lib/constants';
import { useDispatch, useSelector } from 'store';
import { getLikes } from 'store/actions/postsActions';
import { makeSelectLikes } from 'store/selectors/postSelectors';

import UsersList from 'components/layout/Common/UsersList/UsersList';
import ReactionIcon from './ReactionIcon';

import * as S from './UserReactionListStyles';

export type UserReactionListProps = {
  reactionCountList: Reaction[];
  itemId: number;
  itemType: ItemTypes;
};

const selectLikes = makeSelectLikes();

export default function UserReactionList({
  itemId,
  itemType,
  reactionCountList,
}: UserReactionListProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const likes = useSelector(state => selectLikes(state, itemId, itemType));
  const cancelRef = useRef<Canceler>();

  const dispatch = useDispatch();

  const totalCount = reactionCountList[0].totalCount;

  const users = useMemo(
    () =>
      (likes ?? []).map(({ user, reaction }) => ({
        userId: user.userId,
        name: user.name,
        profileType: PROFILE_TYPES.user,
        mediaId: user.mediaId,
        occupation: user.occupation?.description,
        department: user.department?.description,
        reactionName: reaction?.name,
      })),
    [likes]
  );

  const fetchReactions = useCallback(
    (reactionId?: number) => {
      const likesParams = { itemId, itemType, reactionId };

      setIsLoading(true);

      dispatch(getLikes(likesParams, c => (cancelRef.current = c))).finally(() =>
        setIsLoading(false)
      );
    },
    [dispatch, itemId, itemType]
  );

  useEffect(() => {
    fetchReactions();

    return () => {
      cancelRef.current?.(
        'getUsersWhoLiked request canceled by UserReactionList component unmounted'
      );
    };
  }, [fetchReactions]);

  const tabClickHandler = (activeKey: string) => {
    const reactionId = REACTION_TYPE[activeKey as ReactionLiteral];

    fetchReactions(reactionId);
  };

  return (
    <Tabs onTabClick={tabClickHandler}>
      <Tabs.TabPane key="all" tab={`${t('reactions.all')} ${totalCount} `}>
        <Spin spinning={isLoading}>
          <UsersList users={users} />
        </Spin>
      </Tabs.TabPane>
      {reactionCountList.map(r => (
        <Tabs.TabPane key={r.name} tab={<TabTitle reaction={r.name} count={r.reactionCount} />}>
          <Spin spinning={isLoading}>
            <UsersList users={users} />
          </Spin>
        </Tabs.TabPane>
      ))}
    </Tabs>
  );
}

function TabTitle({ reaction, count }: { reaction: ReactionLiteral; count: number }) {
  const { t } = useTranslation();

  return (
    <Tooltip title={t(`reactions.${reaction}`)}>
      <S.Container>
        <ReactionIcon css={S.icon(reaction)} reaction={reaction} />
        <span>{count}</span>
      </S.Container>
    </Tooltip>
  );
}
