import qs from 'qs';
import { API_MEDIA } from './constants';

export function getProfilePhoto(profileId: number): string;
export function getProfilePhoto(profileId: number, profileType: number): string;
export function getProfilePhoto(profileId: number, profileType: number, mediaId?: number): string;
export function getProfilePhoto(profileId: number, profileType = 1, mediaId?: number): string {
  const queryString = qs.stringify({ profileId, profileType, mediaId }, { skipNulls: true });

  return `${API_MEDIA}/api/media/getProfilePhoto?${queryString}`;
}

export function channelPhoto(roomId: number, token?: string | Nullable, mediaId?: number) {
  const queryString = qs.stringify({ roomId, access_token: token, mediaId }, { skipNulls: true });

  return `${API_MEDIA}/api/media/getGroupChatPhoto?${queryString}`;
}

export default getProfilePhoto;
