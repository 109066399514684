/* eslint-disable no-bitwise */
import * as signalR from '@microsoft/signalr';

import { API_DOMAIN } from './constants';
import { getOrRenewApiToken } from './BearerRenew';

type SubscriberCallback = (...args: any[]) => void;

class WebSocketHelper {
  static connection: signalR.HubConnection | null = null;

  static initSocketConnection() {
    if (!this.connection) {
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(`${API_DOMAIN}/api/notifier`, {
          transport: signalR.HttpTransportType.WebSockets | signalR.HttpTransportType.LongPolling,
          accessTokenFactory: async () => getOrRenewApiToken(),
        })
        .withHubProtocol(new signalR.JsonHubProtocol())
        .configureLogging(signalR.LogLevel.Information)
        .withAutomaticReconnect()
        .build();
    }
    return this.connection;
  }

  static stopConnection() {
    if (this.connection) return this.connection.stop();
    return Promise.resolve();
  }

  static startConnection() {
    if (this.connection) return this.connection.start();
    return Promise.resolve();
  }

  static onReceiveMessage(name: string, callback: SubscriberCallback) {
    if (this.connection) this.connection.on(name, callback);
  }

  static unsubscribeMessage(name: string): void;
  static unsubscribeMessage(name: string, callback: SubscriberCallback): void;

  static unsubscribeMessage(name: string, callback?: SubscriberCallback) {
    if (this.connection) {
      if (callback) this.connection.off(name, callback);
      else this.connection.off(name);
    }
  }

  static get hubConnectionState() {
    return this.connection ? this.connection.state : signalR.HubConnectionState.Disconnected;
  }
}

export default WebSocketHelper;
