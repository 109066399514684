import React, { useState } from 'react';
import { Input, InputProps, Modal } from 'antd';

export type PromptModalProps = {
  open?: boolean;
  title?: string;
  okText?: string;
  inputProps?: Omit<InputProps, 'size'>;
  onSave: (value: string) => void;
  onClose: () => void;
};

export default function PromptModal({
  open = false,
  title,
  okText,
  inputProps,
  onSave,
  onClose,
}: PromptModalProps) {
  const [value, setValue] = useState('');

  const saveHandler = () => {
    onSave(value);
  };

  const inputChangedHandler: React.ChangeEventHandler<HTMLInputElement> = e => {
    const { value } = e.target;

    setValue(value);
  };

  const keyUpHandler: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      onSave(value);
    }
  };

  return (
    <Modal
      closable={false}
      title={<strong style={{ fontWeight: 600 }}>{title}</strong>}
      okText={okText}
      visible={open}
      onOk={saveHandler}
      onCancel={onClose}
    >
      <Input {...inputProps} size="large" onChange={inputChangedHandler} onKeyUp={keyUpHandler} />
    </Modal>
  );
}
