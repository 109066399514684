import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { pascalCase } from 'change-case';

const permissionsSelector = createSelector(
  (state: RootState) => state.auth,
  auth => auth.userPermissions
);

function usePermissions(...permissionNames: string[]) {
  const userPermissions = useSelector(permissionsSelector);

  const permissions = permissionNames.map(
    permissionName => !!userPermissions[pascalCase(permissionName)]
  );

  return permissions;
}

export default usePermissions;
