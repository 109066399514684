import axios from 'axios';
import { createCancelToken, statusError, CancelCallback, Result, errorResult } from '../apiActions';

export interface GetRoomParams {
  name?: string;
  userId?: number;
  roomId?: number;
}

export async function getChatMessagesCount(
  userId: number,
  cancelCallback?: CancelCallback
): Promise<number | undefined> {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const { data } = await axios.get('/api/chat/getChatMessagesCount', {
      params: { userId },
      cancelToken,
    });

    return data;
  } catch (error) {
    return statusError(error);
  }
}

export async function getRoom(): Promise<ChatRoomInfo[]>;
export async function getRoom(params: GetRoomParams): Promise<ChatRoomInfo[]>;
export async function getRoom(params?: GetRoomParams): Promise<ChatRoomInfo[]> {
  const { data } = await axios.get<ChatRoomInfo[]>('api/chat/getRoom', { params });

  return data;
}

export async function addUserToRoom(
  roomId: number,
  userIds: number[]
): Promise<number | undefined> {
  try {
    const response = await axios.post('/api/chat/addUserToRoom', userIds, { params: { roomId } });

    return response.status;
  } catch (error) {
    return statusError(error);
  }
}

export type UpdateChatRoomNameParams = {
  roomId: number;
  name: string;
};
export async function updateChatRoomName(
  { roomId, name }: UpdateChatRoomNameParams,
  cancelCallback?: CancelCallback
): Promise<Result<{ roomId: number; name: string }>> {
  try {
    const { status, data } = await axios.patch(
      '/api/chat/updateChatRoomName',
      { name },
      {
        params: { roomId },
      }
    );

    return { ok: true, status, data };
  } catch (error) {
    return errorResult(error);
  }
}

export async function updateChatPhoto(
  roomId: number,
  groupPhoto: File
): Promise<ChatRoomInfo | number | undefined> {
  try {
    const formData = new FormData();
    formData.append('groupPhoto', groupPhoto);

    const { data } = await axios.put('/api/chat/updateChatPhoto', formData, {
      params: { roomId },
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return data[0];
  } catch (error) {
    return statusError(error);
  }
}

export type DeleteUserFromChatRoomParams = {
  roomId: number;
  userId: number;
};

export async function deleteUserFromChatRoom(
  params: DeleteUserFromChatRoomParams,
  abortSignal?: AbortSignal
): Promise<Result<void>> {
  try {
    const { status } = await axios.delete('/api/chat/deleteUserFromChatRoom', {
      params,
      signal: abortSignal,
    });

    return { ok: true, status };
  } catch (error) {
    return errorResult(error);
  }
}
