import styled from '@emotion/styled';

export const TermsList = styled.div`
  text-align: justify;
`;

export const LogoWrapper = styled.div`
  width: 30vw;
  height: 50px;
  margin-bottom: 1.5rem;

  img {
    display: block;
    height: 100%;
  }
`;
