import axios from 'axios';
import { serialize } from 'object-to-formdata';
import { createCancelToken, serializeOptions, statusError, CancelCallback } from '../apiActions';

export interface GetUserListParams {
  name?: string;
  pageId?: number;
  isPublic?: boolean;
}

export interface GetFollowersParams
  extends Omit<GetUserListParams, 'deleted' | 'userCollaboratorsFilter' | 'userName'> {}

export interface GetPageParams {
  networkId?: number;
  pageId?: number;
  name?: string | Nullable;
  pageNumber?: number;
  pageSize?: number;
  isPublic?: boolean;
  participating?: boolean;
  pinned?: boolean;
}
export type GetPageMembersParams = Omit<GetPageParams, 'isPublic' | 'participating'>;

export interface CreatePageRequest {
  name?: string;
  description?: string;
  linkBio?: string;
  administrators?: number[];
  users?: number[];
  groupPhoto?: File | null;
  public?: boolean;
  participantsCanPublish?: boolean;
  coverImage?: File | null;
  segmented?: boolean;
  segmentationFilters?: Omit<SegmentationFilter, 'idPageInfo'>[];
}

export interface UpdatePageRequest extends Partial<CreatePageRequest> {
  networkId: number;
  pageId: number;
  ownerId?: number;
}
export interface UpdatePageResponse {
  updatedPage: {
    networkId: number;
    pageId: number;
    ownerId: number;
    type?: string;
    name: string;
    description?: string;
    public: boolean;
    createdDate: Date;
    deletedDate?: Date;
    linkBio?: string;
    deleted?: boolean;
  };
  mediaId: number;
}

export interface GetUserParams {
  networkId?: number;
  userId?: number;
  deleted?: boolean;
  name?: string;
  pageNumber?: number;
  pageSize?: number;
  departmentId?: number | null;
  occupationId?: number | null;
  companyUnitId?: number | null;
  roleId?: number;
  inRole?: boolean;
  userCollaboratorsFilter?: 1 | 2 | 3;
  userName?: string;
  companyId?: number | null;
  segmentationFilters?: Omit<SegmentationFilter, 'idPageInfo'>[];
}

export interface GetUsersWhoLikedParams {
  itemId: number;
  itemType: ItemTypes;
  reactionId?: number;
}

export interface GetPostViewersParams {
  postId: number;
  pageNumber?: number;
  pageSize?: number;
}

export interface PinPageParams {
  pageId: number;
  pinned: boolean;
}

export async function getFollowers(
  params: GetFollowersParams,
  cancelCallback?: CancelCallback
): Promise<{ status: number; users: UserFetchData }> {
  const cancelToken = createCancelToken(cancelCallback);
  const { status, data } = await axios.get('/api/profile/getFollowers', { params, cancelToken });

  return { status, users: data };
}

export async function getFollowInfo(userId: number, following = false): Promise<FollowInfo[]> {
  const { data } = await axios.get('/api/profile/getFollowInfo', { params: { userId, following } });

  return data;
}
export async function followUser(userId: number): Promise<number | undefined> {
  try {
    const { status } = await axios.post('/api/Profile/followUser', undefined, {
      params: { userToFollow: userId },
    });

    return status;
  } catch (error) {
    return statusError(error);
  }
}
export async function unfollowUser(userId: number) {
  try {
    const { status } = await axios.delete('/api/Profile/unfollowUser', {
      params: { userToUnfollow: userId },
    });

    return status;
  } catch (error) {
    return statusError(error);
  }
}

export async function getManagerUser(userId: number): Promise<UserInfo | undefined> {
  const { data } = await axios.get('/api/Profile/getManagerUser', { params: { userId } });

  return data;
}
export async function deleteManagerUser(userId: number): Promise<number | undefined> {
  try {
    const { status } = await axios.delete('/api/Profile/deleteManagerUser', { params: { userId } });

    return status;
  } catch (error) {
    return statusError(error);
  }
}
export async function updateManagerUser(manager: {
  userId: number;
  managerId: number;
}): Promise<number | undefined> {
  try {
    const { status } = await axios.post('/api/Profile/updateManagerUser', manager);

    return status;
  } catch (error) {
    return statusError(error);
  }
}

export async function getPage(
  params: GetPageParams,
  cancelCallback?: CancelCallback
): Promise<Pages | undefined> {
  const cancelToken = createCancelToken(cancelCallback);
  const { data } = await axios.get('/api/Profile/getPage', { params, cancelToken });

  return data;
}

export async function getPageV2(
  params: GetPageParams,
  cancelCallback?: CancelCallback
): Promise<Paginated<PageInfo> | number | undefined> {
  try {
    const cancelToken = createCancelToken(cancelCallback);
    const { data } = await axios.get('/api/v2/profile/getPage', { params, cancelToken });

    return data;
  } catch (error) {
    console.error('Error caught in getPageV2', error);

    return statusError(error);
  }
}

export async function createPage(
  groupRequest: CreatePageRequest
): Promise<{ pageId: number; status: number } | number | undefined> {
  try {
    const formData = serialize(groupRequest, serializeOptions);

    const { data, status } = await axios.post('/api/profile/createPage', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return { pageId: data, status };
  } catch (error) {
    return statusError(error);
  }
}
export async function updatePage(
  groupRequest: UpdatePageRequest
): Promise<{ page: UpdatePageResponse; status: number } | number | undefined> {
  try {
    const formData = serialize(groupRequest, serializeOptions);

    const { data, status } = await axios.put('/api/profile/updatePage', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return { page: data, status };
  } catch (error) {
    return statusError(error);
  }
}
export async function getPageAdministrator(
  params: GetPageMembersParams,
  cancelCallback?: CancelCallback
): Promise<UserFetchData | undefined> {
  const cancelToken = createCancelToken(cancelCallback);
  const { data } = await axios.get('/api/profile/getPageAdministrator', { params, cancelToken });

  return data;
}
export async function getPageUser(
  params: GetPageMembersParams,
  cancelCallback?: CancelCallback
): Promise<UserFetchData | undefined> {
  const cancelToken = createCancelToken(cancelCallback);
  const { data } = await axios.get('/api/profile/getPageUser', { params, cancelToken });

  return data;
}
export async function addUserToPage(
  pageId: number,
  userIds: number[]
): Promise<number | undefined> {
  try {
    const { status } = await axios.post('/api/Profile/addUserToPage', userIds, {
      params: { pageId },
    });

    return status;
  } catch (error) {
    return statusError(error);
  }
}
export async function leftPage(pageId: number): Promise<number | undefined> {
  try {
    const { status } = await axios.delete('/api/Profile/leftPage', { params: { pageId } });

    return status;
  } catch (error) {
    return statusError(error);
  }
}
export async function updateGroupOwner(
  pageId: number,
  ownerId: number
): Promise<number | undefined> {
  try {
    const { status } = await axios.post('/api/profile/updateGroupOwner', ownerId.toString(), {
      params: { pageId },
      headers: { 'Content-Type': 'text/plain' },
    });

    return status;
  } catch (error) {
    return statusError(error);
  }
}

export async function getUser(
  params: GetUserParams,
  cancelCallback?: CancelCallback
): Promise<{ status: number; users: UserFetchData }> {
  const cancelToken = createCancelToken(cancelCallback);
  const { status, data } = await axios.get('/api/profile/getUser', { params, cancelToken });

  return { status, users: data };
}
export async function getUserSummary(userId: number): Promise<UserSummary> {
  const { data } = await axios.get('/api/profile/getUserSummary', { params: { userId } });

  return data;
}
export async function updateUserContacts(
  userId: number,
  contacts: UserContacts
): Promise<{ userContacts: UserContacts; status: number } | number | undefined> {
  try {
    const { data, status } = await axios.put('/api/profile/updateUserContacts', contacts, {
      params: { userId },
    });

    return { userContacts: data, status };
  } catch (error) {
    return statusError(error);
  }
}

export async function getPageSegments(pageId: number): Promise<PageSegment[] | number | undefined> {
  try {
    const { data } = await axios.get('/api/profile/getPageSegments', {
      params: { pageId },
    });

    return data;
  } catch (error) {
    return statusError(error);
  }
}

export async function getUsersWhoLiked(
  params: GetUsersWhoLikedParams,
  cancelCallback?: CancelCallback
): Promise<UserReactionResponse[] | number | undefined> {
  const cancelToken = createCancelToken(cancelCallback);

  try {
    const { data: likes } = await axios.get('api/Profile/getUsersWhoLiked', {
      params,
      cancelToken,
    });

    return likes;
  } catch (error) {
    return statusError(error);
  }
}

export async function getPostViewers(
  params: GetPostViewersParams,
  cancelCallback?: CancelCallback
): Promise<Paginated<User> | number | undefined> {
  const cancelToken = createCancelToken(cancelCallback);

  try {
    const { data: views } = await axios.get('/api/profile/getPostViewers', { params, cancelToken });

    return views;
  } catch (error) {
    return statusError(error);
  }
}

export async function pinPage(
  params: PinPageParams,
  cancelCallback?: CancelCallback
): Promise<number | undefined> {
  const cancelToken = createCancelToken(cancelCallback);

  try {
    const { status } = await axios.put('/api/profile/pinPage', undefined, {
      params,
      cancelToken,
    });

    return status;
  } catch (error) {
    return statusError(error);
  }
}
