import { VFC } from 'react';
import { useTranslation } from 'react-i18next';

import SurveyForm from './SurveyForm';
import { Container, Fieldset } from './Styles';

//#region STYLES
export interface EditSurveyProps {
  control: import('react-hook-form').UseFormMethods['control'];
  errors: import('react-hook-form').UseFormMethods['errors'];
  loading?: boolean;
  register: import('react-hook-form').UseFormMethods['register'];
}
//#endregion

const EditSurvey: VFC<EditSurveyProps> = ({ control, errors, loading = false, register }) => {
  const { t } = useTranslation();

  return (
    <Fieldset>
      <Container>
        <h4>{t('common.edit')}</h4>
        <small>{t('survey.editSurvey')}</small>
        <SurveyForm
          editSurvey
          loading={loading}
          control={control}
          errors={errors}
          register={register}
        />
      </Container>
    </Fieldset>
  );
};

export default EditSurvey;
