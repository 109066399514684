import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { reactionColors } from 'components/Reaction/reactionHelper';

export const icon = (reaction: ReactionLiteral) => css`
  position: absolute;
  right: -3px;
  bottom: -3px;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  font-size: 0.65rem;
  line-height: 1.3;
  text-align: center;
  color: ${reactionColors[reaction].foreground};
  background-color: ${reactionColors[reaction].background};
  border: 1px solid #fff;
  border-radius: 50%;
`;

export const AvatarWrapper = styled.div`
  position: relative;
`;
