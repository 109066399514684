import styled from '@emotion/styled';

import type { SizeType } from './ProfileImage';

export const Container = styled.div`
  position: relative;
  display: inline-block;
  border-radius: 50%;

  .ant-avatar > img {
    object-fit: cover;
  }
`;

export const OnlineCheck = styled.i<{ size: SizeType; online: boolean }>`
  position: absolute;
  right: ${props => (props.size === 'xs' ? '3px' : '4px')};
  bottom: ${props => (props.size === 'xs' ? '-5px' : '-2px')};
  font-size: 8px;

  .check-online {
    color: ${({ theme, ...props }) => (props.online ? theme.colors.success : theme.colors.gray5)};
  }
`;
