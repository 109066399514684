import styled from '@emotion/styled';

export const TermsList = styled.ol`
  margin: 0 1em;
  text-align: justify;

  ul {
    margin: 0 1em;
    list-style: disc;
  }
`;

export const LogoWrapper = styled.div`
  width: 30vw;
  height: 50px;
  margin-bottom: 1.5rem;

  img {
    display: block;
    height: 100%;
  }
`;
