import type { AnyAction } from '@reduxjs/toolkit';
import produce from 'immer';
import { POST_TYPES } from 'lib/constants';
import { PostsActions, PostUIActions, FilterTypes } from '../types/types';
import type { LikePayload, PostState } from '../types/postTypes';

const initialState: PostState = {
  posts: {},
  pinnedPosts: {},
  pinnedCount: 0,
  filters: {
    postTypes: POST_TYPES.all,
    mediaType: undefined,
    query: '',
  },
  likes: {},
  sharedPosts: {},
  ui: {},
  comments: {},
  isLoading: false,
  error: false,
  isCommentEdit: false,
  pendingPosts: {},
  scheduledCount: 0,
  currentTab: 'news',
  errorDescription: '',
};

const addPost = (draft: PostState, post: PostInfo) => {
  const { postId } = post.post;
  draft.posts[postId] = post;
  draft.ui[postId] = { ...draft.ui[postId], ...{ isVisible: true } };
};
const addPinnedPost = (draft: PostState, post: PostInfo) => {
  const { postId } = post.post;
  draft.pinnedPosts[postId] = post;
  draft.ui[postId] = { ...draft.ui[postId], ...{ isVisible: true } };
};

const postsReducer = (state = initialState, { type, payload }: AnyAction) =>
  produce(state, (draft: PostState) => {
    switch (type) {
      case PostsActions.POST_CREATED: {
        const { post } = payload;
        addPost(draft, post);
        if (post.post.pinned) {
          addPinnedPost(draft, post);
          draft.pinnedCount += 1;
        }
        draft.error = false;
        draft.errorDescription = '';
        draft.isLoading = false;
        break;
      }
      case PostsActions.POSTS_FETCHING:
        draft.isLoading = true;
        draft.error = false;
        draft.errorDescription = '';
        break;
      case PostsActions.POSTS_FETCHED: {
        const { posts } = payload;
        posts.forEach(addPost.bind(null, draft));
        draft.isLoading = false;
        draft.error = false;
        draft.errorDescription = '';
        break;
      }
      case PostsActions.POST_INSERTED:
        draft.isLoading = false;
        draft.error = false;
        draft.errorDescription = '';
        break;
      case PostsActions.POST_FETCHED:
        payload.forEach(addPost.bind(null, draft));
        draft.isLoading = false;
        draft.error = false;
        draft.errorDescription = '';
        break;
      case PostsActions.POST_SHARED_FETCHED: {
        const { post, parentPostId } = payload;

        if (!post.post) break;

        draft.sharedPosts[post.post.postId] = post;
        draft.posts[parentPostId]._sharedPostId = post.post.postId;
        break;
      }
      case PostsActions.POST_ERROR:
        const { errorDescription } = payload;

        draft.errorDescription = errorDescription;
        draft.isLoading = false;
        draft.error = true;
        break;
      case PostsActions.RESET_ERROR:
        draft.isLoading = false;
        draft.error = false;
        draft.errorDescription = '';
        break;
      case PostsActions.POST_UPDATED: {
        const { post } = payload;
        const draftPost = draft.posts[post.postId];
        const draftPinnedPost = draft.pinnedPosts[post.postId];

        if (draftPost) {
          draft.posts[post.postId] = {
            ...draftPost,
            ...payload,
            post: { ...draftPost.post, ...post },
          };
        }
        if (draftPinnedPost) {
          draft.pinnedPosts[post.postId] = {
            ...draftPinnedPost,
            ...payload,
            post: { ...draftPinnedPost.post, ...post },
          };
        }

        draft.isLoading = false;
        draft.error = false;
        draft.errorDescription = '';
        break;
      }
      case PostsActions.CREATE_COMMENTS: {
        const { comment } = payload;
        const postComments = { ...draft.comments[comment.comment.itemId] };
        postComments[comment.comment.commentId] = comment;
        draft.comments[comment.comment.itemId] = postComments;
        break;
      }
      case PostsActions.RESET_COMMENTS: {
        const { postId } = payload;
        draft.comments[postId] = {};
        break;
      }
      case PostsActions.GET_COMMENTS: {
        const { comments, postId } = payload;

        if (comments && comments.length > 0) {
          draft.comments[postId] ??= {};

          comments.forEach((c: CommentInfo) => {
            draft.comments[postId][c.comment.commentId] = c;
          });
        }
        break;
      }
      case PostsActions.GET_LIKES: {
        const { itemId, itemType, likes }: LikePayload = payload;

        if (!draft.likes[itemType]) draft.likes[itemType] = {};
        draft.likes[itemType]![itemId] = likes;

        break;
      }
      case PostsActions.COMMENT_DELETED: {
        const { comment } = payload;
        const { commentId, itemId } = comment.comment;
        delete draft.comments[itemId][commentId];
        break;
      }
      case PostsActions.COMMENT_UPDATED: {
        const { data } = payload;
        const { commentId, itemId } = data.comment;
        draft.comments[itemId][commentId] = data;
        break;
      }
      case PostsActions.COMMENT_UPDATED_LIKE: {
        const { like, likeCount, reaction, reactionCount, commentId, itemId } = payload;
        draft.comments[itemId][commentId].liked = like;
        draft.comments[itemId][commentId].likeCount = likeCount;
        draft.comments[itemId][commentId].reaction = reaction;
        draft.comments[itemId][commentId].reactionCount = reactionCount;

        break;
      }
      case PostsActions.COMMENT_EDITING: {
        const { commentId, itemId } = payload.comment;
        draft.comments[itemId][commentId] = payload;
        break;
      }
      case PostsActions.COMMENT_EDIT_CANCEL: {
        const { commentId, itemId } = payload.comment;
        draft.comments[itemId][commentId] = payload;
        break;
      }
      case PostsActions.POST_DELETED: {
        const { postId } = payload;
        delete draft.posts[postId];
        delete draft.pinnedPosts[postId];
        draft.pinnedCount -= 1;
        break;
      }

      case PostsActions.PINNED_POSTS_FETCHED: {
        const { posts } = payload;
        posts.forEach(addPinnedPost.bind(null, draft));
        draft.pinnedCount = posts.length;
        draft.isLoading = false;
        draft.error = false;
        draft.errorDescription = '';
        break;
      }
      case PostsActions.PINNED_POST_SET: {
        const { post } = payload;
        const draftPost = draft.posts[post.post.postId];

        if (draftPost) draftPost.post.pinned = true;
        addPinnedPost(draft, post);
        draft.pinnedCount += 1;

        break;
      }
      case PostsActions.PINNED_POST_REMOVED: {
        const { postId } = payload;
        const draftPost = draft.posts[postId];

        delete draft.pinnedPosts[postId];
        draft.pinnedCount -= 1;
        if (draftPost) draftPost.post.pinned = false;

        break;
      }

      case PostsActions.PENDING_POSTS_FETCHED: {
        const { posts } = payload;
        posts.forEach((post: PostInfo) => {
          const { postId } = post.post;
          draft.pendingPosts[postId] = post;
        });

        break;
      }
      case PostsActions.PENDING_POST_SENT:
      case PostsActions.PENDING_POST_DELETED: {
        const { postId } = payload;
        delete draft.pendingPosts[postId];
        draft.scheduledCount -= 1;
        break;
      }
      case PostsActions.PENDING_POST_COUNT: {
        draft.scheduledCount = payload.count;
        break;
      }
      case PostsActions.PENDING_POST_UPDATED: {
        const draftPost = draft.pendingPosts[payload.post.postId];
        draft.pendingPosts[payload.post.postId] = {
          ...draftPost,
          ...payload,
          post: { ...draftPost.post, ...payload.post },
        };
        break;
      }
      case PostsActions.PENDING_POST_RESET: {
        draft.pendingPosts = {};
        break;
      }

      case PostsActions.POST_BOOKMARKED: {
        const pinnedPost = draft.pinnedPosts[payload.postId];
        const draftPost = draft.posts[payload.postId];

        if (pinnedPost) pinnedPost.post.saved = payload.saved;
        else draftPost.post.saved = payload.saved;

        break;
      }

      case PostsActions.TOGGLE_COMMENTING: {
        const draftPost = draft.posts[payload.postId];
        const draftPinnedPost = draft.pinnedPosts[payload.postId];

        if (draftPost)
          draft.posts[payload.postId].post.blockCommenting =
            !draft.posts[payload.postId].post.blockCommenting;

        if (draftPinnedPost)
          draft.pinnedPosts[payload.postId].post.blockCommenting =
            !draft.pinnedPosts[payload.postId].post.blockCommenting;

        break;
      }

      case PostsActions.TAB_CHANGED: {
        draft.currentTab = payload;
        break;
      }

      // UI
      case PostUIActions.FOCUS_COMMENT_FIELD: {
        const { postId } = payload;
        draft.ui[postId] = { ...draft.ui[postId], ...{ commentEditorFocused: true } };
        break;
      }
      case PostUIActions.BLUR_COMMENT_FIELD: {
        const { postId } = payload;
        draft.ui[postId] = { ...draft.ui[postId], ...{ commentEditorFocused: false } };
        break;
      }
      case PostUIActions.HIDE: {
        const { postId } = payload;
        draft.ui[postId] = { ...draft.ui[postId], ...{ isVisible: false } };
        break;
      }
      case PostUIActions.EDIT: {
        const { postId } = payload;
        draft.ui[postId] = { ...draft.ui[postId], ...{ isEditing: true } };
        break;
      }
      case PostUIActions.CANCEL_EDIT: {
        const { postId } = payload;
        draft.ui[postId] = { ...draft.ui[postId], ...{ isEditing: false } };
        break;
      }
      case PostUIActions.OPEN_GALLERY_MODAL: {
        const { postId, galleryActiveIndex } = payload;
        draft.ui[postId] = {
          ...draft.ui[postId],
          ...{ isGalleryModalOpen: true, galleryActiveIndex },
        };
        break;
      }
      case PostUIActions.CLOSE_GALLERY_MODAL: {
        const { postId } = payload;
        draft.ui[postId] = { ...draft.ui[postId], isGalleryModalOpen: false };
        break;
      }

      // UI PINNED POSTS
      case PostUIActions.PINNED_DRAWER_OPENED: {
        const { postId } = payload;
        draft.ui[postId] = { ...draft.ui[postId], isPinnedDrawerVisible: true };
        break;
      }
      case PostUIActions.PINNED_DRAWER_CLOSED: {
        const { postId } = payload;
        draft.ui[postId] = { ...draft.ui[postId], isPinnedDrawerVisible: false };
        break;
      }

      // UI COMMENTS
      case PostUIActions.HIDE_COMMENT: {
        const { commentId } = payload;
        draft.ui[commentId] = { ...draft.ui[commentId], ...{ isVisible: false } };
        break;
      }
      case PostsActions.POST_RESET: {
        draft.posts = {};
        draft.isLoading = true;
        draft.errorDescription = '';
        draft.error = false;
        break;
      }
      case PostsActions.POST_REDUCER_RESET: {
        return initialState;
      }

      // FILTERS
      case FilterTypes.UPDATE: {
        const { postTypes, mediaType, query } = payload;

        if (postTypes) draft.filters.postTypes = postTypes;
        if (typeof query !== 'undefined') {
          draft.filters.query = query === '' ? null : query;
        }
        if (typeof mediaType !== 'undefined') {
          draft.filters.mediaType = mediaType === 0 ? null : mediaType;
        }
        break;
      }
      default:
        return draft;
    }
  });

export default postsReducer;
