import React from 'react';
import styled from '@emotion/styled';

//#region TYPES
type TagProps = {
  children: React.ReactNode;
};
//#endregion

//#region STYLES
const TagStyled = styled.div`
  padding: 0 8px;
  font-weight: 600;
  color: #fff;
  text-transform: lowercase;
  background-color: ${({ theme }) => theme.colors.gray5};
  border-radius: 4px;

  ${({ theme }) => theme.typography.fontxSmall('24px')};

  & + & {
    margin-left: 4px;
  }
`;

//#endregion

const Tag = (props: TagProps) => {
  const { children } = props;
  return <TagStyled>{children}</TagStyled>;
};

export default Tag;
