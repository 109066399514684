import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

// UTILS
import { useSelector } from 'store';
import { fetchUser, fetchUserContacts } from 'store/actions/usersActions';
import { makeUserSelector, makeUserContactsSelector } from 'store/selectors/usersSelectors';

//#region TYPES
type GetUserCallback = (responses: [number, number]) => void;

export interface UseUserReturn {
  user?: UserInfo;
  userContacts?: UserContacts;
  responseStatus?: number;
  loading: boolean;
}
//#endregion

const useUser = (userId: number): UseUserReturn => {
  //#region SELECTORS
  const selectUser = useMemo(makeUserSelector, []);
  const selectUserContacts = useMemo(makeUserContactsSelector, []);
  //#endregion

  // REDUX
  const dispatch: AppDispatch = useDispatch();
  const rootUser = useSelector(state => selectUser(state, userId));
  const rootUserContacts = useSelector(state => selectUserContacts(state, userId));

  const [userResponseStatus, setUserResponseStatus] = useState<number | null>(null);
  const [contactsResponseStatus, setContactsResponseStatus] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function getUser(cb: GetUserCallback) {
      dispatch(
        fetchUser(userId, userResponse => {
          dispatch(
            fetchUserContacts(userId, contactsResponse => cb([userResponse, contactsResponse]))
          );
        })
      );
    }

    if (userId) {
      setLoading(true);
      getUser(([userResponse, contactsResponse]) => {
        setLoading(false);
        setUserResponseStatus(userResponse);
        setContactsResponseStatus(contactsResponse);
      });
    }
  }, [dispatch, userId]);

  const getResponseStatus = () => {
    if (userResponseStatus !== 200) return userResponseStatus!;
    else if (contactsResponseStatus !== 200) return contactsResponseStatus!;

    return 200;
  };

  if (!rootUser && !rootUserContacts) return { loading };

  return {
    user: rootUser,
    userContacts: rootUserContacts,
    responseStatus: getResponseStatus(),
    loading,
  };
};

export default useUser;
