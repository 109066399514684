import { VFC } from 'react';
import styled from '@emotion/styled';
import { rgba } from 'polished';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';

//#region TYPES
export interface SurveyOptionProps {
  anonymous: boolean;
  hasEnded: boolean;
  option: SurveyOption;
  selected: boolean;
  selectedOption: number;
  onOptionClick: (optionId: number) => void;
}
//#endregion

//#region STYLES
const OptionContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8em;
  cursor: pointer;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px;

  :hover {
    border-color: ${({ theme }) => theme.colors.primary4};
  }
  :active,
  :focus {
    border-color: ${({ theme }) => theme.colors.primary6};
  }

  & + & {
    margin-top: 1em;
  }

  .content {
    display: inline-flex;
    z-index: 1;
  }

  .content span {
    margin: 0 0 0 3px;
    width: 50px;
  }

  .descOption {
    line-height: 1.2em;
    text-align: justify;
    padding: 2px 12px 2px 3px;
  }
`;

const PercentageBar = styled.div<{ percentage: number; optionSelected: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: ${({ percentage }) => percentage}%;
  height: 100%;
  content: '';
  background-color: ${({ optionSelected, theme }) =>
    rgba(optionSelected ? theme.colors.primary : theme.colors.gray6, 0.2)};
  transition: width 500ms ease;
`;

const SelectedIcon = styled(FontAwesomeIcon)`
  margin-right: 0.8em;
  color: ${({ theme }) => theme.colors.success};
`;
//#endregion

const SurveyOption: VFC<SurveyOptionProps> = ({
  anonymous,
  hasEnded,
  option,
  selected,
  selectedOption,
  onOptionClick,
}) => {
  const { description, id, percentage } = option;

  const isOptionSelected = selectedOption === id;

  const cursor = (selected || hasEnded) && anonymous ? 'default' : 'pointer';

  const optionClickHandler = () => {
    onOptionClick(id);
  };

  return (
    <OptionContainer onClick={optionClickHandler} style={{ cursor }}>
      <div className="content descOption">{description}</div>
      {(selected || hasEnded) && (
        <div className="content">
          {isOptionSelected && <SelectedIcon icon={faCheckCircle} size="lg" />}
          <span>
            <strong>{percentage}%</strong>
          </span>
        </div>
      )}
      <PercentageBar
        optionSelected={isOptionSelected}
        percentage={selected || hasEnded ? percentage : 0}
      />
    </OptionContainer>
  );
};

export default SurveyOption;
