import { Fragment, ComponentType, PureComponent } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { compose } from 'redux';
import axios, { AxiosError } from 'axios';

import { API_DOMAIN } from 'lib/constants';
import SeniorService from 'lib/SeniorService';
import { logout } from 'store/actions/authActions';
import { getCurrentUserIds } from 'store/selectors/authSelectors';

import TransitionScreen from 'components/layout/TransitionScreen/TransitionScreen';

import Routes from '../../routes';

const api = axios.create({ baseURL: API_DOMAIN });

//#region TYPES
type ReduxProps = ConnectedProps<typeof connector>;
type LoginSeniorXProps = ReduxProps;
type LoginSeniorXState = {
  redirect?: boolean;
  error: string | null;
  retry: number;
};
//#endregion

class LoginSeniorX extends PureComponent<LoginSeniorXProps, LoginSeniorXState> {
  constructor(props: LoginSeniorXProps) {
    super(props);

    this.state = {
      error: null,
      retry: 0,
    };
  }

  async componentDidMount() {
    const { logout } = this.props;

    const sessionToken = localStorage.getItem('session-token');

    if (sessionToken) {
      logout();
      return;
    }

    const token = await SeniorService.instance.token;
    this.authenticate(token);
  }

  messageListenerHandler(event: MessageEvent<any>) {
    console.info(event.origin);
    if (event.origin.includes('senior.com.br')) {
      if (event.data) this.authenticate(event.data.token);
    }
  }

  async authenticate(token: any) {
    const { networkId } = this.props;

    try {
      const userData = Object.assign(token, { networkId });

      const authResponse = await api.post(Routes.authenticateSeniorX, userData);

      if (authResponse.data) {
        localStorage.setItem('session-token', authResponse.data);

        this.setState({ redirect: true });
      }
    } catch (error) {
      console.error({ error });
      const axiosError = error as AxiosError;

      if (!axiosError.isAxiosError) {
        this.setState({ error: 'generic' });
        return;
      }

      if (axiosError.response!.status === 401) {
        this.setState({ error: '401' });
      } else {
        this.setState({ error: 'generic' });
      }
    }
  }

  render() {
    const { redirect, error } = this.state;

    return (
      <Fragment>
        {redirect && <Navigate to="/feed" />}
        <TransitionScreen errorType={error} />
      </Fragment>
    );
  }
}

function makeMapStateToProps() {
  function mapStateToProps(state: RootState) {
    const userIds = getCurrentUserIds(state);

    return {
      networkId: userIds.networkId,
    };
  }

  return mapStateToProps;
}

const connector = connect(makeMapStateToProps(), { logout });

export default compose<ComponentType<any>>(connector)(LoginSeniorX);
