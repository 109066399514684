import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from 'antd';

export const icon = css`
  margin-right: 8px;
`;

export const InteractionCounters = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  text-transform: capitalize;

  ${({ theme }) => theme.typography.fontxSmall('20px')};
`;

export const ViewCommentWrapper = styled.div`
  margin-left: auto;

  button + button {
    margin-left: 1em;
  }
`;

export const CounterButton = styled(Button)`
  padding: 0;
  font-size: 0.85rem;
  font-weight: inherit;
  line-height: inherit;

  &.ant-btn-link:not(:hover) {
    color: inherit;
  }
`;
