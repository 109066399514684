import _ from 'lodash/fp';
import axios from 'axios';
import { POST_TYPES, PROFILE_TYPES } from 'lib/constants';
import { makeGetPostSelector } from 'store/selectors/postSelectors';

declare global {
  interface Post {
    postId: number;
    fromId: number;
    fromType: ProfileTypes;
    description: string;
    descriptionText: string;
    type: PostTypes;
    deletedDate?: string;
    createdDate: string;
    updatedDate?: string;
    scheduledDate?: string;
    pending: boolean;
    pendingType: PendingType;
    hasMedia: boolean;
    private: boolean;
    pinned: boolean;
    editors: string;
    hidden: boolean;
    saved: boolean;
    blockCommenting: boolean;
    pinnedDate?: string;
    survey: number;
    bulletin?: boolean;
  }

  interface PostAuthor {
    profileId: number;
    name: string;
    type: ProfileTypes;
    publicGroup: boolean;
    mediaId: number;
  }

  interface PostInfo {
    post: Post;
    commentCount: number;
    likeCount: number;
    reactionCount: Reaction[];
    reaction?: LikeReaction;
    shareCount: number;
    viewsCount: number;
    author: PostAuthor;
    tags?: []; // TODO tags;
    mentions?: Mention[];
    media?: Media[];
    linkInfo?: LinkInfo;
    liked: boolean;
    targetUsers: TargetUser[];
    publushing?: boolean;
    page?: Page;
    survey: Survey;
  }

  interface PostRequestParams {
    postId?: number;
    pending?: boolean;
    types?: PostTypes[];
    profileTypes?: ProfileTypes | ProfileTypes[];
    orderDirection?: string;
    pageNumber?: number;
    pageSize?: number;
    mediaType?: MediaTypes[];
    postText?: string;
    profileId?: number;
    pinned?: boolean;
  }

  interface PostView {
    postId: number;
    userId: number;
    deviceType: DevicePlatforms;
    viewType: PostViewType;
    datetimeView: string;
  }

  interface PostTarget extends Mention {
    departmentId: number;
    occupationId: number;
    targetCount: number;
  }

  interface TargetUser {
    postTarget: PostTarget;
    department?: KeyValue;
    occupation?: KeyValue;
    targetCount: number;
  }

  interface PostFile {
    blob?: File;
    name: string;
    size: number;
    preview: string;
    index: string | number;
    new: boolean;
  }

  type PendingType = (typeof PendingType)[keyof typeof PendingType];
}

export const PendingType = {
  none: 0,
  video: 1,
  schedule: 2,
  videoSchedule: 3,
} as const;

export interface Attributes {
  postId?: number;
  fromId?: number;
  fromType?: ProfileTypes;
  type?: PostTypes;
  descriptionText?: string;
  description?: string;
  pending?: boolean;
  pinned?: boolean;
  blockCommenting?: boolean;
  _extra?: Record<string, any>;
}

interface ModelAttributes extends Partial<Attributes> {
  [key: string]: any;
}

export class PostModel {
  private _defaults = {
    descriptionText: '',
    description: '',
    pending: false,
    _extra: {},
  };
  private formData: FormData;
  private CancelToken = axios.CancelToken;
  cancelSource = this.CancelToken.source();
  attributes: ModelAttributes = {};

  constructor(attrs: Attributes, state?: RootState) {
    this.formData = new FormData();
    const getPost = makeGetPostSelector();
    let { _defaults } = this;
    if (state) {
      const _post = getPost(state, attrs.postId!);
      _defaults = { ..._defaults, ..._post.post };
    }

    this.set({ ..._defaults, ...attrs });
  }

  set(attrs: ModelAttributes) {
    const result = _.flow(
      _.entries,
      _.forEach(([key, value]: [keyof Attributes, any]) => {
        this.attributes[key] = value;
      })
    )(attrs);

    return result;
  }

  get(attr: keyof Attributes) {
    return this.attributes[attr];
  }

  getFormData() {
    this._setVisibility();
    this._setFilesFormData();
    this._setDeletedFilesFormData();
    this._buildFormData();
    return this.formData;
  }

  // API
  save() {
    return this.get('postId') ? this._update() : this._create();
  }
  destroy() {
    return axios.delete(`/api/Post/deletePost?postId=${this.get('postId')}`);
  }
  cancel() {
    this.cancelSource.cancel('Request canceled');
  }

  // PRIVATE
  // API
  private async _create() {
    const response = await axios.post<PostInfo[]>('/api/post/createPost', this.getFormData(), {
      cancelToken: this.cancelSource.token,
    });
    return { status: response.status, postInfo: response.data[0] };
  }
  private async _update() {
    const response = await axios.put<PostInfo[]>('/api/post/updatePost', this.getFormData(), {
      cancelToken: this.cancelSource.token,
    });
    return { status: response.status, postInfo: response.data[0] };
  }

  private _getAuthorId() {
    return this.get('fromId');
  }
  private _setFilesFormData() {
    const { files, fileType } = this.attributes._extra!;

    const add: Record<string, Function> = {
      image: () => {
        files.forEach((file: any) => {
          this._addFile('Images', file.blob, file.name);
        });
      },
      file: () => {
        files.forEach((file: any) => {
          this._addFile('File', file.blob, file.name);
        });
      },
      video: () => {
        files.forEach((file: any) => {
          this._addFile('Video', file.blob, file.name);
        });
      },
      link: () => {
        const linkInfo = files[0];
        this.set({
          'linkInfo.url': linkInfo.url,
          'linkInfo.imageUrl': linkInfo.images?.[0],
          'linkInfo.title': linkInfo.title,
          'linkInfo.description': linkInfo.description,
          'linkInfo.itemType': 2, // TODO: ser dinamico quando houver os outros tipos de posts
        });
      },
      survey: () => {
        const { surveyInfo } = this.attributes._extra!;
        this.set({
          'surveyInfo.companyNetworkId': surveyInfo.companyNetworkId,
          'surveyInfo.title': surveyInfo.title,
          'surveyInfo.anonymous': surveyInfo.anonymous,
          'surveyInfo.endDate': surveyInfo.endDate,
        });
        if (surveyInfo.id) this.set({ 'surveyInfo.id': surveyInfo.id });

        surveyInfo.options.forEach((option: SurveyOption, index: number) =>
          this.set({
            [`surveyInfo.options[${index}].description`]: option.description,
          })
        );
      },
    };

    if (add[fileType]) {
      add[fileType]();
    }
  }
  private _setDeletedFilesFormData() {
    const { toRemove } = this.attributes._extra!;

    if (toRemove) {
      this.formData.append('remove', JSON.stringify(toRemove));
    }
  }
  private _buildTargetUsers() {
    const { targetProfileIds, visibility } = this.attributes._extra!;

    if (!_.isArray(targetProfileIds) || _.isEmpty(targetProfileIds)) return [];

    const profileType =
      visibility === 'group' || visibility === 'admin' ? PROFILE_TYPES.group : PROFILE_TYPES.user;
    return targetProfileIds.map(uid => ({
      profileId: uid,
      profileType,
    }));
  }
  private _buildFormData() {
    const result = _.flow(
      _.entries,
      _.forEach(([key, value]: [string, any]) => {
        this.formData.append(key, value);
      })
    )(_.omit('_extra', this.attributes));

    return result;
  }
  private _addFile(keyName: string, fileBlob: Blob, fileName: string) {
    this.formData.append(keyName, fileBlob, fileName);
  }
  private _setVisibility() {
    const { targetProfileIds, sendToFollowers, visibility } = this.attributes._extra!;

    if (targetProfileIds && targetProfileIds.length > 0) {
      const type =
        visibility === 'group' || visibility === 'admin' ? POST_TYPES.group : POST_TYPES.private;
      const isPrivate = visibility === 'admin' || visibility === 'private';

      this.set({
        type,
        targetUsers: JSON.stringify(this._buildTargetUsers()),
        private: isPrivate,
      });
    }
    if (sendToFollowers) {
      this.set({
        type: POST_TYPES.following,
      });
    }
  }
}

export default PostModel;
