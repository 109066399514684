import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentLines } from '@fortawesome/pro-regular-svg-icons';

import { useDispatch, useSelector } from 'store';
import { OpenList } from 'store/actions/chatActions';
import { getIndividualRooms, getGroupRooms } from 'store/selectors/chatSelectors';

import Badge from 'components/layout/Common/Badge/Badge';

import { BadgeContainer, IconButton } from './ChatButtonStyles';

const ChatButton = () => {
  const dispatch = useDispatch();
  const individualRooms = useSelector(getIndividualRooms);
  const groupRooms = useSelector(getGroupRooms);

  const badgeCount =
    individualRooms.reduce((count, currRoom) => count + currRoom.unreadCount, 0) +
    groupRooms.reduce((count, currRoom) => count + currRoom.unreadCount, 0);

  const toggleChatListHandler = () => dispatch(OpenList());

  return (
    <IconButton
      id="chat-list"
      type="text"
      shape="circle"
      icon={<FontAwesomeIcon icon={faCommentLines} className="anticon" />}
      onClick={toggleChatListHandler}
    >
      {badgeCount > 0 && (
        <BadgeContainer>
          <Badge count={badgeCount} />
        </BadgeContainer>
      )}
    </IconButton>
  );
};

export default ChatButton;
