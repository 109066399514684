import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// COMPONENTS
import styled from '@emotion/styled';
import { Modal } from 'antd';

import { cancelEdit as cancelPostEdit, updatePost } from 'store/actions/postsActions';
import { cancelEditPendingPost } from 'store/actions/uiActions';
import { makeGetPostUISelector } from 'store/selectors/postSelectors';

import * as customNotification from '../../Common/CustomNotification';
import EditPost from '../EditPost/EditPost';

//#region STYLES
const ErrorMessage = styled.strong`
  display: block;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.danger};

  ${({ theme }) => theme.typography.fontxSmall()};
`;
//#endregion

const EditPostModal = props => {
  const { isPendingPost = false, postInfo } = props;
  const { postId } = postInfo.post;

  const { t } = useTranslation();
  const cancelRef = useRef();

  // #region REDUX
  const getPostUI = useRef(makeGetPostUISelector()).current;
  // const post = useSelector(state => getPost(state, postId));
  const dispatch = useDispatch();
  const { isEditing } = useSelector(state => getPostUI(state, postId));
  // #endregion

  const [sending, setSending] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const cancelEdit = postId =>
    isPendingPost ? cancelEditPendingPost(postId) : cancelPostEdit(postId);

  const handleSendPost = attributes => {
    // this.setState({ errorMessage: '', sending: true });

    setSending(true);
    setErrorMessage('');

    dispatch(
      updatePost(
        attributes,
        status => {
          setSending(false);

          switch (status) {
            case 200:
              dispatch(cancelEdit(postId));
              break;
            case 202: {
              const attributes = {
                type: customNotification.TYPE_VIDEO_PROCESSING,
                title: t('post.processingVideoTitle'),
                body: t('post.processingVideo'),
              };
              customNotification.renderToast(attributes);
              dispatch(cancelEdit(postId));
              break;
            }
            default:
              setErrorMessage(t('post.errorSend'));
              break;
          }
        },
        source => {
          cancelRef.current = source;
        }
      )
    );
  };

  const cancelHandler = () => {
    dispatch(cancelEdit(postId));
    if (cancelRef.current) cancelRef.current.cancel();
  };

  return (
    <Modal
      footer={null}
      visible={isEditing}
      centered
      closable={false}
      confirmLoading
      destroyOnClose
      title={null}
    >
      <EditPost
        loading={sending}
        postId={postId}
        postInfo={postInfo}
        onCancel={cancelHandler}
        onSave={handleSendPost}
      />
      <ErrorMessage>{errorMessage}</ErrorMessage>
    </Modal>
  );
};

export default EditPostModal;
