import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { getNetworkShortcuts } from 'lib/apiActions';
import { ShortcutState } from '../types/shortcutTypes';

const initialState: ShortcutState = {
  networkShortcuts: [],
  userShortcuts: [],
  loading: 'idle',
};

const fetchShortcuts = createAsyncThunk<NetworkShortcut[]>(
  'shortcut/fetchShortcuts',
  async (_, thunkApi) => {
    const shortcuts = await getNetworkShortcuts();
    // TODO: Change to get all shorcuts

    if (shortcuts == null || typeof shortcuts === 'number') return thunkApi.rejectWithValue(true);

    return shortcuts;
  }
);

const shortcutSlice = createSlice({
  name: 'shortcut',
  initialState,
  reducers: {
    setShortcut(state, action: PayloadAction<NetworkShortcut>) {
      const index = state.networkShortcuts.findIndex(shortcut => shortcut.id === action.payload.id);

      if (index > -1) {
        state.networkShortcuts[index] = action.payload;
        return;
      }

      state.networkShortcuts.push(action.payload);
    },
    removeShortcut(state, action: PayloadAction<number>) {
      state.networkShortcuts = state.networkShortcuts.filter(
        shortcut => shortcut.id !== action.payload
      );
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchShortcuts.pending, state => {
      state.loading = 'pending';
    });

    builder.addCase(fetchShortcuts.fulfilled, (state, { payload }) => {
      state.networkShortcuts = payload;
      state.loading = 'succeeded';
    });

    builder.addCase(fetchShortcuts.rejected, state => {
      state.loading = 'failed';
    });
  },
});

export { fetchShortcuts };
export const { setShortcut, removeShortcut } = shortcutSlice.actions;

export default shortcutSlice.reducer;
