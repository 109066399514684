import { useAsyncCallback } from 'react-async-hook';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { getUser } from 'lib/apiActions';

const debouncedGetUser = AwesomeDebouncePromise(getUser, 400);

function useGetUsers() {
  const getUserAsync = useAsyncCallback(debouncedGetUser);

  return getUserAsync;
}

export default useGetUsers;
