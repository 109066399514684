import { ReactNode, VFC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import styled from '@emotion/styled';
import { Button, Space } from 'antd';
import moment from 'moment';

import EditSurvey from 'components/Feed/Survey/EditSurvey';

//#region TYPES
export interface EditPostFormProps {
  attachmentActions?: ReactNode;
  disabled?: boolean;
  isSurvey?: boolean;
  loading?: boolean;
  survey: Survey;
  onCancel: () => void;
  onSave: (survey?: Survey) => void;
}

interface SurveyForm extends Pick<Survey, 'title' | 'anonymous'> {
  endDate: moment.Moment;
}
//#endregion

//#region STYLES
const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 16px;
  margin-top: auto;
  line-height: 24px;
`;
//#endregion

const EditPostForm: VFC<EditPostFormProps> = ({
  attachmentActions,
  disabled = false,
  isSurvey = false,
  loading = false,
  survey,
  onCancel,
  onSave,
}) => {
  const { t } = useTranslation();

  const { control, errors, handleSubmit, register } = useForm<SurveyForm>({
    mode: 'all',
    defaultValues: {
      title: survey.title,
      endDate: moment(survey.endDate),
      anonymous: survey.anonymous,
    },
  });

  function onSubmit(data: SurveyForm) {
    const surveyForm = data.title
      ? { ...survey, ...data, endDate: data.endDate.format('YYYY-MM-DDTHH:mm:ss') }
      : undefined;

    onSave(surveyForm);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {isSurvey && (
        <EditSurvey control={control} errors={errors} loading={loading} register={register} />
      )}
      <ActionsWrapper>
        <div>{attachmentActions}</div>
        <Space>
          <Button block disabled={loading} onClick={onCancel}>
            {t('common.cancel')}
          </Button>
          <Button block disabled={disabled} htmlType="submit" loading={loading} type="primary">
            {t('common.save')}
          </Button>
        </Space>
      </ActionsWrapper>
    </form>
  );
};

export default EditPostForm;
