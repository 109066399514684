import { useAsyncCallback } from 'react-async-hook';

import { getOccupation } from 'lib/apiActions';

function useOccupation() {
  const occupationAsync = useAsyncCallback((networkId: number, any: boolean = false) =>
    getOccupation(networkId, any)
  );

  return occupationAsync;
}

export default useOccupation;
