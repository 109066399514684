import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEqual, isEmpty, last } from 'lodash/fp';
import InfiniteScroll from 'react-infinite-scroller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';

import styled from '@emotion/styled';

import { useAuth } from 'hooks';
import { useSelector } from 'store';
import { selectNotifications } from 'store/selectors/notificationSelector';
import { getNotifications, clearNotifications } from 'store/actions/notificationsPanelActions';

import { RenderPanelItem } from '../../Common/CustomNotification';

// @ts-expect-error
const getKey = notification => {
  const { itemId, itemType, type, relationType } = notification.notification;
  return `${itemId}${itemType}${type}${relationType}`;
};

// #region STYLES
const NotificationList = styled.div`
  width: 440px;
  max-height: 280px;
  overflow-x: hidden;
  overflow-y: 0 auto;
`;
const EmptyMessage = styled.h6`
  padding: 8px 0;
  margin-bottom: 0;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray8};
  text-align: center;
`;

const Spinner = styled.div`
  margin-top: 8px;
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
`;
// #endregion

type MenuNotificationProps = {
  hide: () => void;
  isVisible: boolean;
};

const { gtag } = window;

const MenuNotification = (props: MenuNotificationProps) => {
  const { hide, isVisible } = props;
  const { t } = useTranslation();
  const { userId } = useAuth();

  const dispatch = useDispatch();
  const notification = useSelector(state => state.notification, isEqual);
  const notificationList = useSelector(selectNotifications);

  const [pageNumber, setPageNumber] = useState<number[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const contentRef = useRef<HTMLDivElement>(null);

  const loadPage = useCallback(
    page => {
      dispatch(
        // @ts-expect-error
        getNotifications(userId, page, (status: number) => {
          if (status === 200) {
            setPageNumber(pages => [...pages, page]);
          } else {
            setHasMore(false);
          }
        })
      );
    },
    [dispatch, userId]
  );

  useEffect(() => {
    if (isVisible) {
      loadPage(1);
      gtag('event', 'screen_view', { screen_name: 'Menu notificação' });
    } else {
      setPageNumber([]);
      setHasMore(true);
      dispatch(clearNotifications());
    }
  }, [dispatch, isVisible, loadPage]);

  const { isLoading } = notification;
  const hasNotifications = !isEmpty(notificationList);

  const handleLoadMore = () => {
    const nextPage = isEmpty(pageNumber) ? 1 : last(pageNumber)! + 1;
    if (!isLoading) loadPage(nextPage);
  };

  const renderLoading = () => (
    <Spinner>
      <FontAwesomeIcon icon={faSpinnerThird} size="1x" spin />
    </Spinner>
  );
  const renderNotifications = () => {
    if (hasNotifications || isLoading) {
      return (
        <>
          <InfiniteScroll
            initialLoad={false}
            threshold={1024}
            getScrollParent={() => contentRef.current}
            loadMore={handleLoadMore}
            hasMore={!isLoading && hasMore}
            useWindow={false}
          >
            {notificationList.map(notif => (
              <RenderPanelItem key={getKey(notif)} notification={notif} hide={hide} />
            ))}
          </InfiniteScroll>
          {isLoading && renderLoading()}
        </>
      );
    }
    return <EmptyMessage>{t('header.emptyNotifications')}</EmptyMessage>;
  };

  return <NotificationList ref={contentRef}>{renderNotifications()}</NotificationList>;
};

export default MenuNotification;
