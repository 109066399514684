import React, { useState } from 'react';
import { isEqual } from 'lodash/fp';

// GLOBAL COMPONENTS
import { ClassNames } from '@emotion/react';
import { Popover, Badge } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { useFeatureToggle } from 'hooks';
import { useSelector } from 'store';

// COMPONENTS
import MenuNotification from './MenuNotification/MenuNotification';
import Shortcuts from './Shortcuts';
import * as S from './NavbarStyles';

// #region STYLES

// #endregion

const Navbar = () => {
  const { t } = useTranslation();

  const [networkShortcuts] = useFeatureToggle('network_shortcuts');
  const notifReducer = useSelector(state => state.notification, isEqual);
  const [isNotificationMenuVisible, setIsNotificationMenuVisible] = useState(false);

  return (
    <S.Nav>
      <ClassNames>
        {({ css }) => (
          <Popover
            visible={isNotificationMenuVisible}
            onVisibleChange={setIsNotificationMenuVisible}
            trigger="click"
            placement="bottomRight"
            arrowPointAtCenter
            overlayStyle={{ position: 'fixed' }}
            overlayClassName={css`
              .ant-popover-inner-content {
                padding: 0;
              }
            `}
            content={
              <MenuNotification
                isVisible={isNotificationMenuVisible}
                hide={() => setIsNotificationMenuVisible(false)}
              />
            }
            title={
              <S.NotificationHeader>
                <FontAwesomeIcon className="anticon" style={{ marginRight: 8 }} icon={faBell} />
                <span>{t('notification.title')}</span>
              </S.NotificationHeader>
            }
          >
            <S.NavButton
              id="notifications-menu"
              type="button"
              listVisible={isNotificationMenuVisible}
            >
              <Badge count={notifReducer.counters}>
                <FontAwesomeIcon className="anticon" icon={faBell} />
              </Badge>
            </S.NavButton>
          </Popover>
        )}
      </ClassNames>
      {networkShortcuts && <Shortcuts />}
    </S.Nav>
  );
};

export default Navbar;
