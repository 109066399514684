import React from 'react';
import { compose } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import createEmojiPlugin from '@draft-js-plugins/emoji';
import { withTranslation } from 'react-i18next';
import _ from 'lodash/fp';
import { v4 } from 'uuid';

// GLOBAL COMPONENTS
import { css, withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile } from '@fortawesome/pro-regular-svg-icons';
import { Animated } from 'react-animated-css';

import http from 'lib/httpClient';
import { fetchMedia, isJSON, deserializeHtml } from 'lib/helper';
import { POST_TYPES, PROFILE_TYPES } from 'lib/constants';
// ACTIONS
import { initNewPostStore } from 'store/actions/newPostActions';
import { cancelEdit, updatePost, resetPostError } from 'store/actions/postsActions';
import { makeGetPostUISelector } from 'store/selectors/postSelectors';

// COMPONENTS
import TextEditor from 'components/layout/Common/TextEditor/TextEditor';
import PostScopeSelection from 'components/layout/Common/PostScopeSelection/PostScopeSelection';
import PostHeader from 'components/layout/Main/Posts/PostHeader/PostHeader';
import AttachmentsPreview from 'components/layout/Main/AttachmentsPreview/AttachmentsPreview';
import AttachmentsActions from 'components/layout/Main/AttachmentsActions/AttachmentsActions';

import { emojiThemePost } from 'styles/draftjs/emojiTheme';

import EditPostForm from './EditPostForm';

// #region STYLES
const header = css`
  margin-bottom: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .editor {
    overflow-x: hidden;
    overflow-y: auto;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray9};

    ${({ theme }) => theme.typography.fontSmall()};
  }
  .editor .public-DraftEditor-content {
    max-height: 300px;
  }
  .editor .public-DraftEditorPlaceholder-root {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.gray6};
  }
`;

const ContainerPreview = styled.div`
  margin-top: 17px;
  margin-left: 0.5rem;
`;
const ErrorMessage = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.danger};

  ${({ theme }) => theme.typography.fontSmall()};
`;
// #endregion

class EditPost extends React.Component {
  constructor(props) {
    super(props);
    const { postInfo, token, theme } = props;

    let postFiles = [];
    let fileType = null;

    const mediaTypes = {
      1: 'image',
      2: 'video',
      4: 'file',
    };
    if (postInfo.media) {
      fileType = mediaTypes[postInfo.media[0].mediaType];
      postFiles = postInfo.media.map(media => ({
        index: media.mediaId,
        Id: media.mediaId,
        name: media.originalFileName,
        size: media.fileSize,
        preview: fetchMedia(media.mediaId, token),
        new: false,
      }));
    } else if (postInfo.linkInfo) {
      fileType = 'link';
      const link = postInfo.linkInfo;

      postFiles = [
        {
          linkId: link.id,
          title: link.title,
          description: link.description,
          images: [link.imageUrl],
          url: link.url,
          authorId: link.userId,
        },
      ];
    } else if (postInfo.post.survey) {
      fileType = 'survey';
    }

    this.emojiPlugin = createEmojiPlugin({
      theme: emojiThemePost(theme),
      useNativeArt: true,
      selectButtonContent: <FontAwesomeIcon icon={faSmile} />,
    });

    const content = postInfo.post.description;
    let editorContent = null;
    if (isJSON(content)) {
      editorContent = convertFromRaw(JSON.parse(content));
    } else {
      editorContent = deserializeHtml(_.trim(content), postInfo.mentions); // ContentState.createFromText(_.trim(content));
    }

    this.state = {
      editorState: EditorState.createWithContent(editorContent),
      suggestions: [],
      currentLink: '',
      errorMessage: '',
      deletedFiles: [],
      files: postFiles,
      fileType,
    };

    this.debouncedPreviewLink = _.debounce(500, this.previewLink);
    this.throttledChange = _.throttle(10, this.handleChange);
    this.debouncedSearchMentions = _.debounce(250, this.handleSearchMentions);

    this.saveSurveyHandler = this.saveSurveyHandler.bind(this);
  }

  static defaultProps = {
    className: '',
  };

  componentDidMount() {
    const { initNewPostStore, postId, postInfo } = this.props;
    if (!(postInfo.post.pending && postInfo.post.scheduledDate)) initNewPostStore(postId);
  }
  componentWillUnmount() {
    const { files } = this.state;
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }

  getMessage(forbiddenExtensions, maxFileSize) {
    const { t } = this.props;

    return t('files.forbiddenFiles', {
      extensions: forbiddenExtensions.join(', '),
      size: maxFileSize,
    });
  }
  getAttributes = () => {
    const { editorState, files, fileType, deletedFiles } = this.state;
    const { postInfo, postId } = this.props;
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);

    this.setState({ errorMessage: '' });

    const newFiles = files.filter(file => file.new);
    const attributes = {
      description: JSON.stringify(rawContent),
      descriptionText: contentState.getPlainText(),
      postId,
      type: postInfo.post.type,
      pending: postInfo.post.pending,
      pinned: postInfo.post.pinned,
      _extra: {},
    };

    attributes.originalPost = JSON.stringify(postInfo);

    if (!_.isEmpty(newFiles)) {
      attributes._extra.files = newFiles.reverse();
      attributes._extra.fileType = fileType;
    }
    if (!_.isEmpty(deletedFiles)) {
      attributes._extra.toRemove = deletedFiles;
    }

    if (postInfo.post.scheduledDate) {
      Object.assign(attributes, { scheduledDate: postInfo.post.scheduledDate });
    }

    if (postInfo.post.pendingType || postInfo.post.pendingType > 0) {
      Object.assign(attributes, { pendingType: postInfo.post.pendingType });
    }

    const mentions = _.filter(em => em.type === 'mention', rawContent.entityMap);

    if (postInfo.post.type === POST_TYPES.private) {
      attributes._extra.targetProfileIds = postInfo.targetUsers.map(
        tgu => tgu.postTarget.profileId
      );

      if (mentions.length > 0) {
        const newTargets = _.differenceWith(
          (m, tgu) => m.data.mention.userId === tgu.postTarget.profileId,
          mentions,
          postInfo.targetUsers
        );

        attributes._extra.targetProfileIds = [
          ...newTargets.map(t => t.data.mention.userId),
          ...postInfo.targetUsers.map(tgu => tgu.postTarget.profileId),
        ];
      }
    }

    return attributes;
  };

  previewLink = link => {
    http.previewLink(link).then(preview => {
      if (preview) {
        const { postInfo } = this.props;

        preview.authorId = postInfo.author.userId;
        preview.new = true;
        this.resetFiles();
        this.setState({
          currentLink: link,
          files: [preview],
          fileType: 'link',
        });
      }
    });
  };
  resetPost = result => {
    if (result) {
      const { initNewPostStore, postId } = this.props;
      this.setState({ editorState: EditorState.createEmpty() });
      this.resetFiles();
      initNewPostStore(postId);
    }
  };

  handleChange = (newEditorState, linksContent) => {
    const { onTextChange } = this.props;
    const { editorState, currentLink, fileType } = this.state;

    if (!fileType) {
      if (newEditorState.getCurrentContent() !== editorState.getCurrentContent()) {
        if (linksContent.length > 0) {
          const link = linksContent.pop();
          if (link !== currentLink) {
            this.debouncedPreviewLink(link);
          }
        } else if (fileType === 'link') {
          this.debouncedPreviewLink.cancel();
          if (fileType === 'link') {
            this.resetFiles();
            this.setState({ currentLink: '' });
          }
        }
      }
    }

    this.setState({ editorState: newEditorState });
    onTextChange?.(newEditorState.getCurrentContent().getPlainText());
  };
  handleSearchMentions = async ({ value }) => {
    const { postInfo } = this.props;
    if (value.length > 0) {
      const { author, page } = postInfo;
      const params = { name: value };
      if (page && !page.public) {
        params.pageId = page.pageId;
      }

      const forAdmin =
        postInfo.post.type === POST_TYPES.group &&
        postInfo.post.private &&
        author.type === PROFILE_TYPES.user &&
        (!page?.public ?? false);
      if (!forAdmin) {
        const { data } = await http.get(`/api/Mention/getUserList`, { params });
        if (data) {
          const suggestions = data.map(mention => ({
            userId: mention.userId,
            name: mention.name,
          }));

          this.setState({ suggestions });
        }
      }
    }
  };

  saveSurveyHandler(data) {
    const { onSave } = this.props;
    const attributes = this.getAttributes();

    Object.assign(attributes._extra, { surveyInfo: data });

    onSave(attributes);
  }

  // FILES
  handleFileAdded = (acceptedFiles, type) => {
    const {
      resetPostError,
      networkSettings: { maxPhotosPermittedInPost },
      t,
    } = this.props;
    const { fileType, files } = this.state;

    if (acceptedFiles && acceptedFiles.length > 0) {
      resetPostError();
      this.setState({ errorMessage: '' });

      const totalFileCount = acceptedFiles.length + files.length;
      let newFiles = acceptedFiles;

      if (totalFileCount >= maxPhotosPermittedInPost && type === 'image') {
        newFiles = newFiles.slice(0, maxPhotosPermittedInPost - files.length);
      }

      newFiles = acceptedFiles.map(file => ({
        blob: file,
        name: file.name,
        preview: URL.createObjectURL(file),
        index: v4(),
        new: true,
        size: file.size,
      }));

      if (fileType === type && type === 'image') {
        this.setState({
          files: [...files, ...newFiles],
        });
      } else {
        this.resetFiles();
        this.setState({
          files: newFiles,
          fileType: type,
        });
      }
    } else {
      this.resetFiles();
      if (type === 'image') {
        this.setState({
          errorMessage: t('files.forbiddenAttachment'),
        });
      }
    }
  };
  handleMediaRemoved = removedFile => {
    const { files, fileType, deletedFiles } = this.state;
    const newFiles = files.filter(file => file.index !== removedFile.index);

    URL.revokeObjectURL(removedFile.preview);
    this.setState({
      files: newFiles,
      fileType: newFiles.length > 0 ? fileType : null,
    });
    if (removedFile.Id) {
      const description = fileType !== 'link' ? 'media' : 'link';
      this.setState({
        deletedFiles: [...deletedFiles, ...[{ id: removedFile.Id, description }]],
      });
    }
  };
  handleRejectedFile = () => {
    const { networkSettings, resetPostError } = this.props;

    resetPostError();
    this.resetFiles();

    this.setState({
      errorMessage: this.getMessage(
        networkSettings.forbiddenExtensions,
        networkSettings.maxPostFileSize
      ),
    });
  };
  resetFiles = () => {
    const { files, fileType, deletedFiles } = this.state;

    let newDeletedFiles = [];
    if (files) {
      const description = fileType !== 'link' ? 'media' : 'link';
      newDeletedFiles = _.flow(
        _.filter(file => file.Id),
        _.map(({ Id }) => ({ id: Id, Description: description }))
      )(files);
    }

    this.setState({
      fileType: null,
      files: [],
      deletedFiles: [...deletedFiles, ...newDeletedFiles],
    });
  };

  renderErrorMessage() {
    const { errorMessage } = this.state;
    if (!errorMessage) {
      return null;
    }
    return (
      <ContainerPreview>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </ContainerPreview>
    );
  }
  renderSendError() {
    const { t, sendError } = this.props;
    if (!sendError) {
      return null;
    }
    return (
      <ContainerPreview>
        <ErrorMessage>{t('post.errorSend')}</ErrorMessage>
      </ContainerPreview>
    );
  }

  render() {
    const { className, forwardedRef, loading, postId, postInfo } = this.props;
    const { t, user, visibility, isEditing, onCancel } = this.props;
    const { editorState, suggestions, files, fileType } = this.state;
    const { EmojiSelect, EmojiSuggestions } = this.emojiPlugin;

    const contentState = editorState.getCurrentContent();

    return (
      <Wrapper ref={forwardedRef} className={className}>
        <PostHeader css={header} postId={postId} hideDropdown />
        <TextEditor
          editorState={editorState}
          autoFocus
          suggestions={suggestions}
          onChange={this.throttledChange}
          onSearchChange={this.debouncedSearchMentions}
          onSuggestionsClose={() => this.setState({ suggestions: [] })}
          className="editor"
          placeholder={`${t('post.tellUsSomething')} ${user.name.substr(
            0,
            user.name.indexOf(' ')
          )}...`}
          plugins={[this.emojiPlugin]}
        />
        <EmojiSuggestions />
        <Animated
          animationInDuration={300}
          animationInDelay={300}
          animationOutDuration={300}
          isVisible={isEditing}
        >
          <AttachmentsPreview
            resetFiles={this.resetFiles}
            files={files}
            fileType={fileType}
            handleFileAdded={this.handleFileAdded}
            handleMediaRemoved={this.handleMediaRemoved}
          />
          {visibility === 'private' && <PostScopeSelection storeKey={postId} />}
          {this.renderErrorMessage()}
          {this.renderSendError()}
        </Animated>
        <EditPostForm
          disabled={contentState.getPlainText().trim() === 0}
          isSurvey={!!postInfo.post.survey}
          loading={loading}
          survey={postInfo.survey}
          onCancel={onCancel}
          onSave={this.saveSurveyHandler}
          attachmentActions={
            <>
              {!postInfo.post.survey && (
                <AttachmentsActions
                  disabled={loading}
                  handleFileAdded={this.handleFileAdded}
                  handleRejectedFile={this.handleRejectedFile}
                  files={files}
                />
              )}
              <EmojiSelect />
            </>
          }
        />
      </Wrapper>
    );
  }
}

function makeMapStateToProps() {
  const getPostUI = makeGetPostUISelector();

  const mapStateToProps = (state, ownProps) => {
    const { storeKey, postId } = ownProps;
    const newPostStore = state.newPost.posts[storeKey];
    const { isEditing } = getPostUI(state, postId);

    return {
      isEditing,
      networkSettings: state.auth.networkSettings,
      sendError: state.posts.error,
      token: state.auth.token,
      user: state.auth.userInfo.user,
      visibility: newPostStore?.visibility,
    };
  };

  return mapStateToProps;
}

const mapActionsToProps = {
  cancelEdit,
  initNewPostStore,
  resetPostError,
  updatePost,
};

export default compose(
  withTranslation('default', { withRef: true }),
  withTheme,
  connect(makeMapStateToProps, mapActionsToProps, null, { forwardRef: true })
)(EditPost);
