import React, { Fragment, useRef, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/pro-solid-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import useAuth from 'hooks/useAuth';
import { MEDIA_TYPES } from 'lib/constants';
import { fetchMedia, truncateString } from 'lib/helper';

import loadingGif from 'assets/images/placeholder-image.png';
import FileIcon from '../FileIcon';
import * as S from './ChatMediaStyles';

//#region TYPES
export interface ChatMediaProps {
  media: Media[];
  onMediaClick: (media: Media) => void;
}
//#endregion

const ChatMedia = ({ media, onMediaClick }: ChatMediaProps) => {
  const { t } = useTranslation();
  const { token } = useAuth();
  const [isAudioPlaying, setAudioPlaying] = useState(false);
  const [audioLoading, setAudioLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const playerRef = useRef<HTMLAudioElement>(null);
  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const handleAudioControl = () => {
    const player = playerRef.current;
    if (player == null) return;

    if (isAudioPlaying) {
      setAudioPlaying(false);
      player.pause();
    } else {
      if (player.error) player.load();
      setAudioPlaying(true);
      player.play();
    }
  };
  const handleAudioSeek = (e: React.MouseEvent<HTMLProgressElement>) => {
    const player = playerRef.current;
    if (player) {
      const rect = e.currentTarget.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const percent = offsetX / e.currentTarget.offsetWidth;
      player.currentTime = percent * player.duration;
      setProgress(percent * 100);
    }
  };
  const handleTimeUpdate = () => {
    const player = playerRef.current;
    if (player) {
      setProgress((player.currentTime / player.duration) * 100);
      setCurrentTime(player.currentTime);
    }
  };

  const renderImgs = (m: Media, imgCount: number) => {
    const mediaClickHandler = () => onMediaClick(m);

    switch (imgCount) {
      case 1:
        return (
          <S.SingleMedia key={m.mediaId} type="button" onClick={mediaClickHandler}>
            <LazyLoadImage
              src={fetchMedia(m.mediaId, token)}
              alt={m.originalFileName}
              effect="blur"
              wrapperClassName="wrapper"
              placeholderSrc={loadingGif}
            />
          </S.SingleMedia>
        );
      case 2:
      case 3:
        return (
          <S.TwoThreeMedia key={m.mediaId}>
            <button type="button" onClick={mediaClickHandler}>
              <LazyLoadImage
                src={fetchMedia(m.mediaId, token)}
                alt={m.originalFileName}
                effect="blur"
                wrapperClassName="wrapper"
                placeholderSrc={loadingGif}
              />
            </button>
          </S.TwoThreeMedia>
        );
      case 4:
        return (
          <S.MoreMedia key={m.mediaId}>
            <button type="button" onClick={mediaClickHandler}>
              <LazyLoadImage
                src={fetchMedia(m.mediaId, token)}
                alt={m.originalFileName}
                effect="blur"
                wrapperClassName="wrapper"
                placeholderSrc={loadingGif}
              />
            </button>
          </S.MoreMedia>
        );
      default:
        return null;
    }
  };

  const renderAudio = (m: Media) => {
    return (
      <Fragment key={m.mediaId}>
        <audio
          onEnded={() => setAudioPlaying(false)}
          onPlaying={() => {
            clearTimeout(timeout.current!);
            setAudioLoading(false);
          }}
          onTimeUpdate={handleTimeUpdate}
          onWaiting={() => {
            setAudioLoading(true);

            timeout.current = setTimeout(() => {
              setAudioPlaying(false);
              setAudioLoading(false);
            }, 30000);
          }}
          preload="none"
          ref={playerRef}
        >
          <track kind="captions" />
          <source src={fetchMedia(m.mediaId, token)} type="audio/mpeg" />
          {t('unsuportedAudio')}
        </audio>
        <S.Player>
          <div className="control">
            {audioLoading ? (
              <FontAwesomeIcon className="spinner" icon={faSpinnerThird} spin />
            ) : (
              <button type="button" onClick={handleAudioControl}>
                <FontAwesomeIcon icon={isAudioPlaying ? faPause : faPlay} />
              </button>
            )}
          </div>
          <div className="progress-track">
            <S.Progress max={100} onClick={handleAudioSeek} value={progress} />
          </div>
          <div className="timer">
            {moment({
              minutes: Math.trunc(currentTime / 60),
              seconds: Math.trunc(currentTime % 60),
            }).format('mm:ss')}
          </div>
        </S.Player>
      </Fragment>
    );
  };

  const renderFile = (m: Media) => {
    const fileExt = m.originalFileName.split('.').pop()!;
    const mediaClickHandler = () => onMediaClick(m);

    return (
      <S.FileWrapper>
        <S.FileButton onClick={mediaClickHandler}>
          <FileIcon fileExt={fileExt} />
          <span>{truncateString(m.originalFileName, 17)}</span>
        </S.FileButton>
      </S.FileWrapper>
    );
  };

  const renderMedia = () => {
    return media.map(m => {
      switch (m.mediaType) {
        case MEDIA_TYPES.image: {
          return renderImgs(m, media.length);
        }
        case MEDIA_TYPES.audio: {
          return renderAudio(m);
        }
        case MEDIA_TYPES.file: {
          return renderFile(m);
        }
        default:
          return null;
      }
    });
  };

  return <>{renderMedia()}</>;
};

export default React.memo(ChatMedia);
