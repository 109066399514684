import styled from '@emotion/styled';
import { up } from 'styled-breakpoints';

export const Wrapper = styled.div`
  position: relative;
  z-index: 998;
`;
export const ChatListPanel = styled.div<{ listOpen: boolean }>`
  position: fixed;
  top: 64px;
  right: ${props => (props.listOpen ? 0 : '-345px')};
  bottom: 0;
  width: 344px;
  padding: 24px 8px 24px 24px;
  color: #fff;
  background-color: ${({ theme }) => theme.colors.gray10};
  border-left: 8px solid ${({ theme }) => theme.colors.primary};
  transition: all 0.3s ease-in-out;

  .section {
    margin-top: 24px;
  }

  ${up('tablet')} {
    right: ${props => (props.listOpen ? 0 : '-336px')};
  }
`;
export const ChatIcon = styled.button`
  position: absolute;
  top: 32px;
  left: -41px;
  display: none;
  padding: 8px;
  margin: 0;
  color: #fff;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.primary};
  border: 0;
  border-radius: 4px 0 0 4px;

  &:focus {
    outline: 0;
  }
  .icon {
    font-size: 21px;
  }

  ${up('tablet')} {
    display: unset;
  }
`;
export const BadgeContainer = styled.div`
  position: absolute;
  top: 1px;
  right: 8px;
`;
export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 10px;
  margin-right: 24px;
  margin-bottom: 24px;
  color: ${({ theme }) => theme.colors.gray5};
  background: ${({ theme }) => theme.colors.gray9};
  border: 1px solid ${({ theme }) => theme.colors.gray8};
  border-radius: 4px;

  .icon {
    font-size: 14px;
  }
`;
export const SearchField = styled.input`
  width: 100%;
  margin-left: 6px;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.gray5};
  background: transparent;
  border: 0;
  outline: none;

  ${({ theme }) => theme.typography.fontSmall('20px')};
`;
export const ClearSearch = styled.button`
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
`;

export const Spinner = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
`;
