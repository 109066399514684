import axios, { Canceler, AxiosError, CancelToken } from 'axios';
import { serialize } from 'object-to-formdata';
import { SetOptional } from 'type-fest';
import { isAxiosError } from './helper';
import { LINK_PREVIEW_API } from './constants';

//#region TYPES
export type OkResult<T> = T extends void
  ? { ok: true; status: number }
  : { ok: true; status: number; data: T };
export type ErrorResult = { ok: false; status: number; data?: undefined; error?: ProblemDetails };

export type Result<T> = OkResult<T> | ErrorResult;

export interface CreateUserParams {
  name: string;
  email: string;
  companyId: number;
  companyUnitId: number;
  departmentId: number;
  occupationId: number;
  password: string;
  sex: string;
  birthday: string;
  currentCity: string;
  termsAccepted: boolean;
  profileImage?: File;
  phone?: string;
  phoneExtension?: string;
  whatsapp?: string;
}
export interface UpdateUserParams {
  profileImage?: File;
  userId?: number;
  companyId?: number;
  oldId?: number;
  companyUnitId?: number;
  departmentId?: number;
  occupationId?: number;
  name?: string;
  userName?: string;
  email?: string;
  password?: string;
  sex?: string;
  birthday?: string;
  currentCity?: string;
  hometown?: string;
  bio?: string;
  mediaId?: number;
  relationship?: string;
  address?: string;
  zip?: string;
  createdDate?: string;
  deletedDate?: string;
  networkId?: number;
  cpf?: string;
  workphone?: string;
  phoneExtension?: string;
  whatsapp?: string;
  networkOwner?: boolean;
  department?: KeyValue;
  occupation?: KeyValue;
  deleted?: boolean;
  termsAccepted?: boolean;
  termsAcceptedDate?: string;
  isSystemAdmin?: boolean;
  lgpdTermsAccepted?: boolean;
  lgpdTermsAcceptedDate?: string;
  coverImage?: File;
  removePhoto?: boolean;
}
export interface GetRoomParams {
  name?: string;
  userId?: number;
  roomId?: number;
}
export interface GetUserListParams {
  name?: string;
  pageId?: number;
  isPublic?: boolean;
}

export interface CreateKeyValueParams {
  networkId: number;
  name: string;
}

export interface GetPostParams {
  postId?: number;
  pending?: boolean;
  types?: PostTypes;
  profileTypes?: ProfileTypes[];
  orderDirection?: 'asc' | 'desc';
  pageNumber?: number;
  pageSize?: number;
  mediaType?: MediaTypes;
  postText?: string;
  profileId?: number;
  pinned?: boolean;
}

export interface GetPostFilesParams extends Omit<GetPostParams, 'profileId'> {
  pageId?: number;
  searchText?: string;
}

export interface GetIncomingBirthdaysParams {
  currentMonth: number;
  pageNumber?: number;
  pageSize?: number;
}

export interface GetPageParams {
  networkId?: number;
  pageId?: number;
  name?: string | Nullable;
  pageNumber?: number;
  pageSize?: number;
  isPublic?: boolean;
  participating?: boolean;
  pinned?: boolean;
}
export type GetPageMembersParams = Omit<GetPageParams, 'isPublic' | 'participating'>;

export interface CreatePageRequest {
  name?: string;
  description?: string;
  linkBio?: string;
  administrators?: number[];
  users?: number[];
  groupPhoto?: File | null;
  public?: boolean;
  participantsCanPublish?: boolean;
  coverImage?: File | null;
  segmented?: boolean;
  segmentationFilters?: Omit<SegmentationFilter, 'idPageInfo'>[];
}

export interface UpdatePageRequest extends Partial<CreatePageRequest> {
  networkId: number;
  pageId: number;
  ownerId?: number;
}
export interface UpdatePageResponse {
  updatedPage: {
    networkId: number;
    pageId: number;
    ownerId: number;
    type?: string;
    name: string;
    description?: string;
    public: boolean;
    createdDate: Date;
    deletedDate?: Date;
    linkBio?: string;
    deleted?: boolean;
  };
  mediaId: number;
  coverMediaId: number;
}

export interface GetRoleParams {
  networkId: number;
  roleId?: number;
  defaultRole?: boolean;
}

export interface GetUserParams {
  networkId?: number;
  userId?: number;
  deleted?: boolean;
  name?: string;
  pageNumber?: number;
  pageSize?: number;
  departmentId?: number | null;
  occupationId?: number | null;
  companyUnitId?: number | null;
  roleId?: number;
  inRole?: boolean;
  userCollaboratorsFilter?: 1 | 2 | 3;
  userName?: string;
  companyId?: number | null;
  segmentationFilters?: Omit<SegmentationFilter, 'idPageInfo'>[];
}

export interface GetFollowersParams
  extends Omit<GetUserListParams, 'deleted' | 'userCollaboratorsFilter' | 'userName'> {}

export interface SetSurveyOptionParams {
  userId: number;
  surveyId: number;
  surveyOptionId: number;
}

export interface GetUsersWhoLikedParams {
  itemId: number;
  itemType: ItemTypes;
}

export interface GetPostViewersParams {
  postId: number;
  pageNumber?: number;
  pageSize?: number;
}

export interface IncreasePostViewParams {
  postId: number;
  userId: number;
  deviceType: DevicePlatforms;
  viewType: PostViewType;
}

export interface CreateBanerParams {
  image: File;
  linkText?: string;
  width: number;
  height: number;
  x: number;
  y: number;
  zoom: number;
}

export interface UpdateBannerParams {
  id: number;
  linkText: string;
}

export interface GetBannerParams {
  onlyPublished?: boolean;
  pageNumber?: number;
  pageSize?: number;
}

type ShortcutParams = {
  icon?: File;
};

export type NetworkShortcutParams = Partial<NetworkShortcut> & ShortcutParams;
export type UserShortcutParams = Partial<UserShortcut> & ShortcutParams;

export type CancelCallback = (canceler: Canceler) => void;
export type { Canceler };
//#endregion

export function statusError(error: unknown) {
  if (isAxiosError(error)) return error.response?.status;
}

export function errorResponse(error: unknown) {
  if (isAxiosError(error)) return error.response;
}

export function errorResult(error: unknown): ErrorResult {
  const response = errorResponse(error);
  return { ok: false, status: response!.status, error: response?.data };
}

export function createCancelToken(cancelCallback: CancelCallback | undefined) {
  let cancelToken: CancelToken | undefined;
  if (cancelCallback) {
    cancelToken = new axios.CancelToken(cancelCallback);
  }

  return cancelToken;
}

export const serializeOptions = { indices: true, dotsForObjectNotation: true };

//#region ACCOUNT
export async function createUser(createUserParams: CreateUserParams, token: string) {
  const userFormData = serialize(createUserParams);

  try {
    const { data, status } = await axios.post('/api/account/createUser', userFormData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    });

    return { data, status };
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function updateProfilePhoto(
  userId: number,
  profileImage: File
): Promise<{ media: Media; status: number } | number | undefined> {
  try {
    const formData = new FormData();
    formData.append('profileImage', profileImage);
    const { data, status } = await axios.put('/api/account/updateProfilePhoto', formData, {
      params: { userId },
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return { media: data, status };
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function updateProfileCover(
  userId: number,
  coverImage: File
): Promise<{ media: Media; status: number } | number | undefined> {
  try {
    const formData = new FormData();
    formData.append('coverImage', coverImage);

    const { data, status } = await axios.put('/api/account/updateProfileCover', formData, {
      params: { userId },
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return { media: { itemId: data.userId, mediaId: data.coverMediaId, ...data }, status };
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function updateUser(
  userId: number,
  updateUserParams: UpdateUserParams
): Promise<{ status: number; userInfo: UserInfo } | number | undefined> {
  const userFormData = serialize(updateUserParams);

  try {
    const { data, status } = await axios.put('/api/account/updateUser', userFormData, {
      params: { userId },
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return { userInfo: data, status: status };
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function setTermsRefused() {
  try {
    const response = await axios.post('/api/account/setTermsRefused');

    return response.status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function setLgpdTermsRefused() {
  try {
    const response = await axios.post('/api/account/setLgpdTermsRefused');

    return response.status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export const Account = {
  createUser,
  updateProfilePhoto,
  updateUser,
  setTermsRefused,
  setLgpdTermsRefused,
};
//#endregion

//#region BANNER
export async function createBanner(
  bannerData: CreateBanerParams,
  cancelCallback?: CancelCallback
): Promise<BannerInfo | number | undefined> {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const formData = serialize(bannerData);
    const { data } = await axios.post<BannerInfo[]>('/api/banner/createBanner', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      cancelToken,
    });

    return data[0];
  } catch (error) {
    return statusError(error);
  }
}

export async function updateBanner(params: UpdateBannerParams): Promise<number | undefined> {
  try {
    const { status } = await axios.put('/api/banner/updateBanner', params);

    return status;
  } catch (error) {
    return statusError(error);
  }
}

export async function publishBanner(bannerIds: number[]): Promise<number | undefined> {
  try {
    const { status } = await axios.patch('/api/banner/publishBanner', bannerIds);

    return status;
  } catch (error) {
    return statusError(error);
  }
}

export async function deleteBanner(bannerId: number): Promise<number | undefined> {
  try {
    const response = await axios.delete('/api/banner/deleteBanner', { params: { bannerId } });

    return response.status;
  } catch (error) {
    return statusError(error);
  }
}

export async function getBanner(
  params?: GetBannerParams,
  cancelCallback?: CancelCallback
): Promise<number | BannerDataset | undefined> {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const { data } = await axios.get<BannerDataset>('/api/banner/getBanner', {
      params,
      cancelToken,
    });

    return data;
  } catch (error) {
    return statusError(error);
  }
}

export const Banner = {
  createBanner,
  updateBanner,
  publishBanner,
  deleteBanner,
  getBanner,
};
//#endregion

//#region COMPANY
export async function createCompany(params: CreateKeyValueParams): Promise<number | undefined> {
  try {
    const response = await axios.post('/api/company/createCompany', {
      name: params.name,
      companyNetworkId: params.networkId,
    });

    return response.status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function createManyCompanies(
  params: CreateKeyValueParams[]
): Promise<number | undefined> {
  try {
    const args = params.map(param => ({ name: param.name, companyNetworkId: param.networkId }));
    const response = await axios.post('/api/company/createManyCompanies', args);

    return response.status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function deleteCompany(companyId: number): Promise<number | undefined> {
  try {
    const response = await axios.delete('/api/company/deleteCompany', {
      params: { id: companyId },
    });

    return response.status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function getCompany(networkId: number): Promise<KeyValue[]> {
  const { data } = await axios.get<KeyValue[]>('/api/company/getCompany', {
    params: { networkId },
  });

  return data;
}

export const Company = {
  createCompany,
  createManyCompanies,
  deleteCompany,
  getCompany,
};
//#endregion

//#region DEPARTMENT
export async function createDepartment(params: CreateKeyValueParams): Promise<number | undefined> {
  try {
    const response = await axios.post('/api/Department/createDepartment', {
      name: params.name,
      companyNetworkId: params.networkId,
    });

    return response.status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function createManyDepartments(
  params: CreateKeyValueParams[]
): Promise<number | undefined> {
  try {
    const args = params.map(param => ({ name: param.name, companyNetworkId: param.networkId }));
    const response = await axios.post('/api/department/createManyDepartments', args);

    return response.status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function deleteDepartment(departmentId: number): Promise<number | undefined> {
  try {
    const response = await axios.delete('/api/Department/deleteDepartment', {
      params: { id: departmentId },
    });

    return response.status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function getDepartment(networkId: number): Promise<KeyValue[]> {
  const { data } = await axios.get<KeyValue[]>('/api/Department/getDepartment', {
    params: { networkId },
  });

  return data;
}

export const Department = {
  createDepartment,
  createManyDepartments,
  deleteDepartment,
  getDepartment,
};
//#endregion

//#region CHAT
export async function getRoom(): Promise<ChatRoomInfo[]>;
export async function getRoom(params: GetRoomParams): Promise<ChatRoomInfo[]>;
export async function getRoom(params?: GetRoomParams): Promise<ChatRoomInfo[]> {
  const { data } = await axios.get<ChatRoomInfo[]>('api/chat/getRoom', { params });

  return data;
}

export async function addUserToRoom(roomId: number, userIds: number[]): Promise<number> {
  const response = await axios.post('/api/chat/addUserToRoom', userIds, { params: { roomId } });

  return response.status;
}

export async function updateChatPhoto(
  roomId: number,
  groupPhoto: File
): Promise<ChatRoomInfo | number | undefined> {
  try {
    const formData = new FormData();
    formData.append('groupPhoto', groupPhoto);

    const { data } = await axios.put('/api/chat/updateChatPhoto', formData, {
      params: { roomId },
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return data[0];
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export const Chat = {
  getRoom,
  addUserToRoom,
};
//#endregion

//#region MENTION
export async function getUserList(params: GetUserListParams): Promise<MentionFetchData[]> {
  const { data } = await axios.get<MentionFetchData[]>('/api/Mention/getUserList', { params });

  return data;
}

export const Mention = {
  getUserList,
};
//#endregion

//#region NETWORK
export async function getFunctions(networkId: number): Promise<NetworkFunctions> {
  const { data } = await axios.get('/api/Network/getFunctions', { params: { networkId } });

  return data;
}

export async function updateFunctions(
  networkId: number,
  functions: Partial<NetworkFunctions>
): Promise<number | undefined> {
  try {
    const { status } = await axios.put(`/api/Network/updateFunctions`, functions, {
      params: { networkId },
    });

    return status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function getNetwork(): Promise<NetworkList>;
export async function getNetwork(networkId: number): Promise<Network | undefined>;
export async function getNetwork(networkId?: number): Promise<Network | NetworkList | undefined> {
  const { data } = await axios.get<NetworkList>('/api/network/getNetwork', {
    params: { networkId },
  });

  if (networkId) return data.networks.length > 0 ? data.networks[0] : undefined;
  return data;
}

export async function updateNetwork(
  networkId: number,
  networkInfo: Partial<NetworkInfo>
): Promise<number | undefined> {
  const formData = serialize(networkInfo, { indices: true });
  try {
    const response = await axios.put('/api/network/updateNetwork', formData, {
      params: { networkId },
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return response.status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function getActiveDirectorySettings(): Promise<ActiveDirectorySettings | undefined> {
  const response = await axios.get('/api/network/getActiveDirectorySettings');

  return response.data;
}

export async function updateActiveDirectorySettings(
  adSettings: SetOptional<ActiveDirectorySettings, 'id'>
) {
  try {
    const response = await axios.put('/api/network/updateActiveDirectorySettings', adSettings);

    return response.status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export const Network = {
  getActiveDirectorySettings,
  updateActiveDirectorySettings,
  getFunctions,
  updateFunctions,
  getNetwork,
  updateNetwork,
};
//#endregion

//#region OCCUPATION
export async function createOccupation(params: CreateKeyValueParams): Promise<number | undefined> {
  try {
    const response = await axios.post('/api/Occupation/createOccupation', {
      name: params.name,
      companyNetworkId: params.networkId,
    });

    return response.status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function createManyOccupations(
  params: CreateKeyValueParams[]
): Promise<number | undefined> {
  try {
    const args = params.map(param => ({ name: param.name, companyNetworkId: param.networkId }));
    const response = await axios.post('/api/occupation/createManyOccupations', args);

    return response.status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function deleteOccupation(occupationId: number): Promise<number | undefined> {
  try {
    const response = await axios.delete('/api/Occupation/deleteOccupation', {
      params: { id: occupationId },
    });

    return response.status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function getOccupation(networkId: number): Promise<KeyValue[]>;
export async function getOccupation(networkId: number, any: boolean): Promise<KeyValue[]>;
export async function getOccupation(networkId: number, any?: boolean): Promise<KeyValue[]> {
  const { data } = await axios.get<KeyValue[]>('/api/Occupation/getOccupation', {
    params: { networkId, any },
  });

  return data;
}

export const Occupation = {
  createOccupation,
  createManyOccupations,
  deleteOccupation,
  getOccupation,
};
//#endregion

//#region POST
export async function getPost(params: GetPostParams) {
  const { status, data } = await axios.get<PostInfo[]>('/api/post/getPost', { params });

  return { status, posts: data };
}

export async function getPostFiles(params: GetPostFilesParams) {
  const { status, data } = await axios.get<PostInfo[]>('/api/post/getPostFiles', { params });

  return { status, posts: data };
}

export async function savePostToSeeLater(postId: number) {
  const { data } = await axios.post<boolean>('/api/post/savePostToSeeLater', postId, {
    headers: { 'Content-Type': 'application/json' },
  });

  return data;
}

export async function deleteSavedPost(postId: number) {
  const { data } = await axios.delete<boolean>('/api/post/deleteSavedPost', { params: { postId } });

  return data;
}

export async function increasePostView(
  params: IncreasePostViewParams,
  cancelCallback: CancelCallback
): Promise<{ viewsCount: number } | number | undefined> {
  const cancelToken = createCancelToken(cancelCallback);

  try {
    const response = await axios.post('/api/post/increasePostView', params, {
      cancelToken,
    });

    return response.data;
  } catch (error) {
    return statusError(error);
  }
}

export const Post = {
  getPost,
  getPostFiles,
  savePostToSeeLater,
  deleteSavedPost,
  increasePostView,
};
//#endregion

//#region PROFILE
export async function getFollowers(
  params: GetFollowersParams,
  cancelCallback?: CancelCallback
): Promise<{ status: number; users: UserFetchData }> {
  const cancelToken = createCancelToken(cancelCallback);
  const { status, data } = await axios.get('/api/profile/getFollowers', { params, cancelToken });

  return { status, users: data };
}

export async function getFollowInfo(userId: number, following = false): Promise<FollowInfo[]> {
  const { data } = await axios.get('/api/profile/getFollowInfo', { params: { userId, following } });

  return data;
}
export async function followUser(userId: number): Promise<number | undefined> {
  try {
    const { status } = await axios.post('/api/Profile/followUser', undefined, {
      params: { userToFollow: userId },
    });

    return status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}
export async function unfollowUser(userId: number) {
  try {
    const { status } = await axios.delete('/api/Profile/unfollowUser', {
      params: { userToUnfollow: userId },
    });

    return status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function getManagerUser(userId: number): Promise<UserInfo | undefined> {
  const { data } = await axios.get('/api/Profile/getManagerUser', { params: { userId } });

  return data;
}
export async function deleteManagerUser(userId: number): Promise<number | undefined> {
  try {
    const { status } = await axios.delete('/api/Profile/deleteManagerUser', { params: { userId } });

    return status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}
export async function updateManagerUser(manager: {
  userId: number;
  managerId: number;
}): Promise<number | undefined> {
  try {
    const { status } = await axios.post('/api/Profile/updateManagerUser', manager);

    return status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function getPage(
  params: GetPageParams,
  cancelCallback?: CancelCallback
): Promise<Pages | undefined> {
  const cancelToken = createCancelToken(cancelCallback);
  const { data } = await axios.get('/api/Profile/getPage', { params, cancelToken });

  return data;
}
export async function createPage(
  groupRequest: CreatePageRequest
): Promise<{ pageId: number; status: number } | number | undefined> {
  try {
    const formData = serialize(groupRequest, serializeOptions);

    const { data, status } = await axios.post('/api/profile/createPage', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return { pageId: data, status };
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}
export async function updatePage(
  groupRequest: UpdatePageRequest
): Promise<{ page: UpdatePageResponse; status: number } | number | undefined> {
  try {
    const formData = serialize(groupRequest, serializeOptions);

    const { data, status } = await axios.put('/api/profile/updatePage', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    return { page: data, status };
  } catch (error) {
    return statusError(error);
  }
}
export async function getPageAdministrator(
  params: GetPageMembersParams,
  cancelCallback?: CancelCallback
): Promise<UserFetchData | undefined> {
  const cancelToken = createCancelToken(cancelCallback);
  const { data } = await axios.get('/api/profile/getPageAdministrator', { params, cancelToken });

  return data;
}
export async function getPageUser(
  params: GetPageMembersParams,
  cancelCallback?: CancelCallback
): Promise<UserFetchData | undefined> {
  const cancelToken = createCancelToken(cancelCallback);
  const { data } = await axios.get('/api/profile/getPageUser', { params, cancelToken });

  return data;
}
export async function addUserToPage(
  pageId: number,
  userIds: number[]
): Promise<number | undefined> {
  try {
    const { status } = await axios.post('/api/Profile/addUserToPage', userIds, {
      params: { pageId },
    });

    return status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}
export async function leftPage(pageId: number): Promise<number | undefined> {
  try {
    const { status } = await axios.delete('/api/Profile/leftPage', { params: { pageId } });

    return status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}
export async function updateGroupOwner(
  pageId: number,
  ownerId: number
): Promise<number | undefined> {
  try {
    const { status } = await axios.post('/api/profile/updateGroupOwner', ownerId.toString(), {
      params: { pageId },
      headers: { 'Content-Type': 'text/plain' },
    });

    return status;
  } catch (error) {
    return statusError(error);
  }
}

export async function getUser(
  params: GetUserParams,
  cancelCallback?: CancelCallback
): Promise<{ status: number; users: UserFetchData }> {
  const cancelToken = createCancelToken(cancelCallback);
  const { status, data } = await axios.get('/api/profile/getUser', { params, cancelToken });

  return { status, users: data };
}
export async function getUserSummary(userId: number): Promise<UserSummary> {
  const { data } = await axios.get('/api/profile/getUserSummary', { params: { userId } });

  return data;
}
export async function updateUserContacts(
  userId: number,
  contacts: UserContacts
): Promise<{ userContacts: UserContacts; status: number } | number | undefined> {
  try {
    const { data, status } = await axios.put('/api/profile/updateUserContacts', contacts, {
      params: { userId },
    });

    return { userContacts: data, status };
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function getPageSegments(pageId: number): Promise<PageSegment[] | number | undefined> {
  try {
    const { data } = await axios.get('/api/profile/getPageSegments', {
      params: { pageId },
    });

    return data;
  } catch (error) {
    return statusError(error as AxiosError);
  }
}

export async function getUsersWhoLiked(
  params: GetUsersWhoLikedParams,
  cancelCallback?: CancelCallback
): Promise<{ user: User }[] | number | undefined> {
  const cancelToken = createCancelToken(cancelCallback);

  try {
    const { data: likes } = await axios.get('api/Profile/getUsersWhoLiked', {
      params,
      cancelToken,
    });

    return likes;
  } catch (error) {
    return statusError(error);
  }
}

export async function getPostViewers(
  params: GetPostViewersParams,
  cancelCallback?: CancelCallback
): Promise<Paginated<User> | number | undefined> {
  const cancelToken = createCancelToken(cancelCallback);

  try {
    const { data: views } = await axios.get('/api/profile/getPostViewers', { params, cancelToken });

    return views;
  } catch (error) {
    return statusError(error);
  }
}

export const Profile = {
  getFollowers,
  getFollowInfo,
  followUser,
  unfollowUser,
  getManagerUser,
  deleteManagerUser,
  updateManagerUser,
  getPage,
  createPage,
  updatePage,
  getPageAdministrator,
  getPageUser,
  addUserToPage,
  leftPage,
  updateGroupOwner,
  getUser,
  getUserSummary,
  updateUserContacts,
  getPageSegments,
  getUsersWhoLiked,
  getPostViewers,
};
//#endregion

//#region ROLE
export async function getRole(
  params: GetRoleParams,
  cancelCallback?: CancelCallback
): Promise<{ status: number; roles: RoleInfo[] }> {
  const cancelToken = createCancelToken(cancelCallback);
  const { data, status } = await axios.get<RoleInfo[]>('api/role/getRole', { params, cancelToken });

  return { status, roles: data };
}

export const Role = {
  getRole,
};
//#endregion

//#region SHORTCUT
export async function createNetworkShortcut(
  params: NetworkShortcutParams,
  cancelCallback?: CancelCallback
): Promise<NetworkShortcut | number | undefined> {
  try {
    const formData = serialize(params, serializeOptions);
    const cancelToken = createCancelToken(cancelCallback);

    const { data } = await axios.post('/api/shortcut/createNetworkShortcut', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      cancelToken,
    });

    return data;
  } catch (error) {
    return statusError(error);
  }
}

export async function createUserShortcut(
  params: UserShortcutParams,
  cancelCallback?: CancelCallback
): Promise<UserShortcut | number | undefined> {
  try {
    const formData = serialize(params, serializeOptions);
    const cancelToken = createCancelToken(cancelCallback);

    const { data } = await axios.post('/api/shortcut/createUserShortcut', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      cancelToken,
    });

    return data;
  } catch (error) {
    return statusError(error);
  }
}

export async function updateNetworkShortcut(
  params: NetworkShortcutParams,
  cancelCallback?: CancelCallback
): Promise<NetworkShortcut | number | undefined> {
  try {
    const formData = serialize(params, serializeOptions);
    const cancelToken = createCancelToken(cancelCallback);

    const { data } = await axios.put('/api/shortcut/updateNetworkShortcut', formData, {
      params: { shortcutId: params.id },
      headers: { 'Content-Type': 'multipart/form-data' },
      cancelToken,
    });

    return data;
  } catch (error) {
    return statusError(error);
  }
}

export async function deleteNetworkShortcut(shortcutId: number, cancelCallback?: CancelCallback) {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const { status } = await axios.delete('/api/shortcut/deleteNetworkShortcut', {
      params: { shortcutId },
      cancelToken,
    });

    return status;
  } catch (error) {
    return statusError(error);
  }
}

export async function getNetworkShortcuts(
  shortcutId?: number,
  cancelCallback?: CancelCallback
): Promise<NetworkShortcut[] | number | undefined> {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const { data } = await axios.get('/api/shortcut/getNetworkShortcuts', {
      params: { shortcutId },
      cancelToken,
    });

    return data;
  } catch (error) {
    return statusError(error);
  }
}
//#endregion

//#region SURVEY
export async function setUserOption(
  surveyParams: SetSurveyOptionParams
): Promise<Survey | number | undefined> {
  try {
    const response = await axios.post('/api/survey/setUserOption', surveyParams);

    return response.data;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function getUserBySurveyOption(surveyOptionId: number): Promise<User[]> {
  const { data } = await axios.get('/api/survey/getUserBySurveyOption', {
    params: { surveyOptionId },
  });

  return data;
}

export async function updateSurvey(
  surveyId: number,
  surveyInfo: Pick<SurveyInfo, 'title' | 'anonymous' | 'endDate'>
): Promise<Survey | number | undefined> {
  try {
    const response = await axios.put('/api/survey/update', surveyInfo, { params: { surveyId } });

    return response.data;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export const Survey = {
  setUserOption,
  updateSurvey,
};
//#endregion

//#region UNIT
export async function createUnit(params: CreateKeyValueParams): Promise<number | undefined> {
  try {
    const response = await axios.post('/api/unit/createUnit', {
      name: params.name,
      companyNetworkId: params.networkId,
    });

    return response.status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function createManyUnits(params: CreateKeyValueParams[]): Promise<number | undefined> {
  try {
    const args = params.map(param => ({ name: param.name, companyNetworkId: param.networkId }));
    const response = await axios.post('/api/unit/createManyUnits', args);

    return response.status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function deleteUnit(unitId: number): Promise<number | undefined> {
  try {
    const response = await axios.delete('/api/unit/deleteUnit', {
      params: { id: unitId },
    });

    return response.status;
  } catch (_error) {
    const error = _error as AxiosError;
    return statusError(error);
  }
}

export async function getUnit(networkId: number): Promise<KeyValue[]> {
  const { data } = await axios.get<KeyValue[]>('/api/unit/getUnit', { params: { networkId } });

  return data;
}

export const Unit = {
  createUnit,
  createManyUnits,
  deleteUnit,
  getUnit,
};
//#endregion

//#region WIDGET
export async function getIncomingBirthday(params: GetIncomingBirthdaysParams): Promise<Birthday[]> {
  const { data } = await axios.get(`/api/Widget/getIncomingBirthday`, {
    params,
  });

  return data;
}

export const Widget = {
  getIncomingBirthday,
};
//#endregion

//#region LINK PREVIEW
export async function previewLink(url: string): Promise<LinkPreview | number | undefined> {
  try {
    const { data } = await axios.post(LINK_PREVIEW_API!, JSON.stringify(url), {
      headers: { 'Content-Type': 'text/plain' },
    });

    return data;
  } catch (error) {
    return statusError(error);
  }
}
//#endregion
