import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const commentContainer = (theme: Themes.DefaultTheme) => css`
  width: 100%;
  clear: both;
  color: ${theme.colors.gray9};

  ${theme.typography.fontNormal()};
`;
export const userAvatar = css`
  float: left;
`;
export const meatballMenu = (theme: Themes.DefaultTheme) => css`
  &.ant-btn-link {
    height: auto;
    padding: 0;
    margin-left: auto;
    color: inherit;

    ${theme.typography.fontLarge('16px')};
  }
`;
export const likeDivider = (theme: Themes.DefaultTheme) => css`
  margin: 0 8px;
  font-size: 4px;
  color: ${theme.colors.gray8};
`;

export const ContentWrapper = styled.div`
  float: left;
  min-width: 240px;
  max-width: calc(100% - 40px);
  padding: 8px 12px;
  margin-left: 8px;
  word-break: break-all;
  background: ${({ theme }) => theme.colors.gray3};
  border-radius: 8px;
`;
export const CommentHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;
export const UserName = styled.div`
  margin-right: 24px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.gray9};

  ${({ theme }) => theme.typography.fontSmall()};
`;
export const CommentText = styled.div`
  word-break: break-word;

  ${({ theme }) => theme.typography.fontSmall()};

  p {
    margin-bottom: 0;
  }
  p + p {
    margin-top: 1em;
  }
`;
export const CommentInteractions = styled.div`
  display: flex;
  align-items: center;
  height: 24px;
  padding-top: 8px;
  padding-left: 52px;
  clear: left;

  ${({ theme }) => theme.typography.fontxSmall('24px')};
`;
export const CommentTime = styled.div`
  bottom: 0;
  padding-right: 12px;
  margin-left: auto;
`;
export const CommentWhen = styled.span`
  font-weight: normal;
  color: ${({ theme }) => theme.colors.gray7};

  ${({ theme }) => theme.typography.fontxSmall('16px')};
`;
export const Liked = styled.span`
  color: ${({ theme }) => theme.colors.primary};

  :hover {
    color: ${({ theme }) => theme.colors.primary5};
    text-decoration: underline;
  }
`;
export const Unliked = styled.span`
  :hover {
    color: ${({ theme }) => theme.colors.blue7};
    text-decoration: underline;
  }
`;
