import { useAsyncCallback } from 'react-async-hook';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import { getUserList } from 'lib/apiActions';

const getMembersDebounced = AwesomeDebouncePromise(getUserList, 400);

function useMentions() {
  const mentions = useAsyncCallback(getMembersDebounced);

  return mentions;
}

export default useMentions;
