import React, { FunctionComponent } from 'react';
import { shallowEqual } from 'react-redux';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { up } from 'styled-breakpoints';
import { Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';

import useMedia from 'hooks/useMedia';
import { useSelector } from 'store';
import { canUseFunction } from 'store/selectors/authSelectors';

import ImageGallery from '../ImageGallery';
import PostHeader from '../../Main/Posts/PostHeader/PostHeader';
import PostInteractions from '../../Main/Posts/PostInteractions';
import PostContent from '../../Main/Posts/Post/PostContent';
import Comments from '../../Main/Comments/Comments';

// #region STYLES
const modal = (theme: Themes.DefaultTheme) => css`
  width: 100%;
  max-width: none;
  height: 100%;
  padding: 0;
  margin: 0 auto;

  ${up('desktop')({ theme })} {
    padding: 64px;
  }

  .ant-modal-content {
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
  }
  .ant-modal-body {
    height: 100%;
    padding: 0;
  }

  /* // POST
.modal :global(.post-header) {
  margin-bottom: 24px;
}
.modal :global(.comments-container) {
  flex-grow: 1;
  overflow: auto;
} */
`;
const close = (theme: Themes.DefaultTheme) => css`
  position: fixed;
  top: 12px;
  right: 12px;
  color: #fff;
  cursor: pointer;

  ${up('desktop')({ theme })} {
    top: 24px;
    right: 24px;
  }
`;
const postHeader = css`
  margin-bottom: 24px;
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
`;
const LeftColumn = styled.div`
  flex-basis: 70%;
  flex-grow: 1;
`;
const RightColumn = styled.div`
  display: flex;
  flex-basis: 30%;
  flex-direction: column;
  max-width: 360px;
  overflow-y: scroll;

  ${up('desktop')} {
    max-width: 420px;
  }
`;
const RightHeader = styled.div`
  padding: 24px;
  padding-bottom: 16px;
`;
// #endregion

interface ImageGalleryModalProps {
  postInfo: PostInfo;
  postId: number;
  isModalOpen: boolean;
  onClose: () => void;
  canComment?: boolean;
}

const localStyle = {
  height: '90%',
  width: '90%',
  top: '5%',
};
const ImageGalleryModal: FunctionComponent<ImageGalleryModalProps> = props => {
  const { postInfo, postId, onClose, isModalOpen, canComment = true } = props;
  const commentsFunction = useSelector(state => canUseFunction(state, 'comments'), shallowEqual);

  const screen = useMedia(
    ['(max-width: 1300px)'],
    [
      {
        width: '90%',
        height: '90%',
        top: '5%',
      },
    ],
    localStyle
  );

  return (
    <Modal
      css={modal}
      visible={isModalOpen}
      width={screen.width}
      closable={false}
      style={screen}
      onCancel={onClose}
      destroyOnClose
    >
      <FontAwesomeIcon css={close} onClick={onClose} icon={faTimes} />
      <Wrapper>
        <LeftColumn>
          <ImageGallery forceGallery isModal postId={postId} />
        </LeftColumn>
        <RightColumn>
          <RightHeader>
            <PostHeader css={postHeader} postId={postId} onEdit={onClose} />
            <PostContent postInfo={postInfo} charactersCount={240} showAttachments={false} />
            <PostInteractions postId={postId} />
          </RightHeader>
          {canComment && commentsFunction && !postInfo.post.blockCommenting && (
            <Comments postId={postId} />
          )}
        </RightColumn>
      </Wrapper>
    </Modal>
  );
};

export default ImageGalleryModal;
