import { useEffect, VFC } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { useSelector } from 'store';
import { getPinnedPosts } from 'store/actions/postsActions';
import { pinnedPostsSelector } from 'store/selectors/postSelectors';
import Post from 'components/layout/Main/Posts/Post/Post';

const Title = styled.h1`
  padding-bottom: 0.2em;
  margin-bottom: 0.7em;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray3};
`;

const HighlightedPosts: VFC = () => {
  const { t } = useTranslation();

  const dispatch: AppDispatch = useDispatch();
  const pinnedPosts = useSelector(pinnedPostsSelector);

  useEffect(() => {
    dispatch(getPinnedPosts({ pinned: true }));
  }, [dispatch]);

  if (pinnedPosts.length === 0) return null;

  const highlightedPosts = pinnedPosts.filter(postInfo => postInfo.post.pinned);
  if (highlightedPosts.length === 0) return null;

  const [highlightedPost] = highlightedPosts;
  return (
    <div>
      <Title>{t('post.highlight')}</Title>
      {/* @ts-expect-error */}
      <Post key={highlightedPost.post.postId} postId={highlightedPost.post.postId} />
    </div>
  );
};

export default HighlightedPosts;
