import React, { useCallback, useReducer, useRef, useEffect, ElementRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import produce from 'immer';
import { useAsyncCallback } from 'react-async-hook';
import axios from 'axios';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import isEmpty from 'lodash/fp/isEmpty';
import { useDispatch } from 'react-redux';

import { Input } from 'antd';
import AsyncSelect from 'react-select/async';
import type { ActionMeta } from 'react-select';

import useReactSelectStyles from 'hooks/useReactSelectStyles';
import { fileAsBase64 } from 'lib/helper';
import { getChatList, NewChatWindow } from 'store/actions/chatActions';

import MediaCropper from 'components/common/MediaCropper';

import { ReactComponent as UploadCloud } from 'assets/images/upload_icon.svg';

import * as S from './CreateChannelModalStyles';

// #region TYPES
export interface CreateChannelModalProps {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ChannelState {
  channelName: string;
  croppedImg?: File;
  croppedImgSrc: string;
  cropperVisible: boolean;
  droppedImgSrc: string;
  members: number[];
  optionSelected: boolean;
}

type OptionType = { value: number; label: string };

type ShowCropperAction = { type: 'showCropper'; payload: string };
type HideCropperAction = { type: 'hideCropper'; payload?: undefined };
type SetCroppedImageAction = { type: 'setCroppedImage'; payload: File };
type ChangeChannelNameAction = { type: 'changeChannelName'; payload: string };
type SelectMembersAction = { type: 'selectMembers'; payload: number[] };
type OptionSelectedAction = { type: 'optionSelected'; payload: boolean };
type ResetStateAction = { type: 'resetState'; payload?: undefined };

type ActionType =
  | ShowCropperAction
  | HideCropperAction
  | SetCroppedImageAction
  | ChangeChannelNameAction
  | SelectMembersAction
  | OptionSelectedAction
  | ResetStateAction;
// #endregion

// #region REDUCER
const initialState: ChannelState = {
  channelName: '',
  croppedImg: undefined,
  croppedImgSrc: '',
  cropperVisible: false,
  droppedImgSrc: '',
  members: [],
  optionSelected: false,
};
const actions = {
  showCropper(state: ChannelState, img: string) {
    state.cropperVisible = true;
    state.droppedImgSrc = img;
    URL.revokeObjectURL(state.croppedImgSrc);
  },
  hideCropper(state: ChannelState) {
    state.cropperVisible = false;
  },
  setCroppedImage(state: ChannelState, img: File) {
    state.croppedImg = img;
    state.croppedImgSrc = URL.createObjectURL(img);
  },
  changeChannelName(state: ChannelState, value: string) {
    state.channelName = value;
  },
  selectMembers(state: ChannelState, membersSelected: number[]) {
    state.members = membersSelected;
  },
  optionSelected(state: ChannelState, value: boolean) {
    state.optionSelected = value;
  },
  resetState(state: ChannelState) {
    URL.revokeObjectURL(state.croppedImgSrc);
    return initialState;
  },
};

function reducer(state: ChannelState, action: ActionType) {
  const fn = actions[action.type];
  if (fn) return produce(state, draft => fn(draft, action.payload as never));

  return state;
}
// #endregion

async function getMembers(name: string): Promise<OptionType[]> {
  const params = { name };

  const { data: mentions } = await axios.get<MentionFetchData[]>('/api/Mention/getUserList', {
    params,
  });

  if (mentions)
    return mentions.map(mention => ({
      value: mention.userId,
      label: mention.name,
    }));

  return [];
}
const getMembersDebounced = AwesomeDebouncePromise(getMembers, 400);

async function createChannel(channelName: string, members: number[], img?: File) {
  const formData = new FormData();
  formData.append('roomName', channelName);
  members.forEach((memberId, index) =>
    formData.append(`roomUserId[${index}]`, memberId.toString())
  );
  if (img) formData.append('groupPhoto', img, channelName);

  const resp = await axios.post('/api/chat/createRoom', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return { room: resp.data[0], status: resp.status };
}

function CreateChannelModal({ visible, setVisible }: CreateChannelModalProps) {
  const { t } = useTranslation('chat');
  const [state, dispatch] = useReducer(reducer, initialState);
  const appDispatch: AppDispatch = useDispatch();
  const selectRef = useRef<ElementRef<typeof AsyncSelect<OptionType, true>>>(null);
  const { open, getRootProps, getInputProps } = useDropzone({
    accept: 'image/png, image/jpeg, image/webp',
    multiple: false,
    onDropAccepted: async files => {
      const imgUrl = await fileAsBase64(files[0]);
      dispatch({ type: 'showCropper', payload: imgUrl });
    },
  });
  const { styles: customSelectStyles } = useReactSelectStyles();

  useEffect(() => {
    if (!visible) {
      URL.revokeObjectURL(state.croppedImgSrc);
    }
  }, [state.croppedImgSrc, visible]);

  // Scroll members select input to bottom when overflow
  useEffect(() => {
    if (selectRef.current && state.optionSelected) {
      const controlRef = selectRef.current.controlRef;
      if (controlRef) controlRef.scrollTop = controlRef.scrollHeight;
    }
    dispatch({ type: 'optionSelected', payload: false });
  }, [state.optionSelected]);

  const getMembersAsync = useAsyncCallback(getMembersDebounced);
  const handleMembersChange = useCallback(
    (selectedOptions: readonly OptionType[], { action }: ActionMeta<any>) => {
      dispatch({
        type: 'selectMembers',
        payload: selectedOptions?.map(opt => opt.value) ?? [],
      });

      if (action === 'select-option') dispatch({ type: 'optionSelected', payload: true });
    },
    []
  );

  const handleSubmit = useAsyncCallback(async () => {
    const { channelName, croppedImg, members } = state;
    const result = await createChannel(channelName.trim(), members, croppedImg);

    if (result.status === 200) {
      dispatch({ type: 'resetState' });
      appDispatch(getChatList());
      appDispatch(NewChatWindow(result.room));
      setVisible(false);
    }
  });

  return (
    <>
      <S.Modal
        visible={visible}
        okText={t('create')}
        closable={false}
        destroyOnClose
        maskClosable={false}
        okButtonProps={{
          disabled: isEmpty(state.channelName.trim()) || state.members.length === 0,
          loading: handleSubmit.loading,
          style: { fontWeight: 'bold' },
        }}
        onOk={handleSubmit.execute}
        cancelButtonProps={{ disabled: handleSubmit.loading }}
        onCancel={() => {
          dispatch({ type: 'resetState' });
          setVisible(false);
        }}
        style={{ top: 20 }}
        width={408}
      >
        <S.Wrapper>
          <h4>{t('createChannel.create')}</h4>
          <S.Description>{t('createChannel.description')}</S.Description>
          <div {...getRootProps({ style: { cursor: 'pointer', outline: 0 } })}>
            <input {...getInputProps()} />
            {state.croppedImgSrc ? (
              <S.ChannelImage src={state.croppedImgSrc} alt={state.channelName} />
            ) : (
              <S.UploadIconWrapper>
                <UploadCloud />
              </S.UploadIconWrapper>
            )}
          </div>
          <S.AddImageButton onClick={open}>{t('createChannel.addImage')}</S.AddImageButton>
        </S.Wrapper>
        <div>
          <S.InputWrapper>
            <S.Label htmlFor="channel-name">{t('createChannel.channelName')}</S.Label>
            <Input
              id="channel-name"
              placeholder={t('createChannel.channelNamePlaceholder')}
              size="large"
              style={{ fontSize: 14 }}
              value={state.channelName}
              onChange={e => dispatch({ type: 'changeChannelName', payload: e.target.value })}
              maxLength={100}
            />
          </S.InputWrapper>
          <S.InputWrapper>
            <S.Label htmlFor="members">{t('createChannel.addMembers')}</S.Label>
            <AsyncSelect
              components={{ DropdownIndicator: null }}
              styles={customSelectStyles}
              cacheOptions
              defaultOptions
              inputId="members"
              isLoading={getMembersAsync.loading}
              isMulti
              placeholder={t('createChannel.addMembersPlaceholder')}
              loadOptions={getMembersAsync.execute}
              menuPlacement="auto"
              ref={selectRef}
              onChange={handleMembersChange}
            />
          </S.InputWrapper>
        </div>
      </S.Modal>
      <MediaCropper
        imgSrc={state.droppedImgSrc}
        visible={state.cropperVisible}
        title={t('createChannel.channelImage')}
        onOk={croppedImg => {
          dispatch({ type: 'setCroppedImage', payload: croppedImg });
          dispatch({ type: 'hideCropper' });
        }}
        onCancel={() => {
          dispatch({ type: 'hideCropper' });
        }}
      />
    </>
  );
}

export default CreateChannelModal;
