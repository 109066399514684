import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import styled from '@emotion/styled';

interface ShowMoreProps {
  textContent: string;
  charactersCount: number;
  onShowMore?: () => void;
}

// #region STYLES
const Button = styled.button`
  padding-left: inherit;
  cursor: pointer;
  background: initial;
  border: 0;
  outline: none !important;
`;

const ReadMore = styled.div`
  margin-top: 8px;
  font-size: 0.889rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.blue};
  cursor: pointer;
`;
// #endregion

function ShowMore({ textContent, charactersCount, onShowMore }: ShowMoreProps) {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);

  const toggle = () => {
    if (onShowMore) onShowMore();
    else setOpen(isOpen => !isOpen);
  };

  const renderViewMore = () => {
    if (textContent.length > charactersCount) {
      return (
        <Button type="button" onClick={toggle}>
          {isOpen ? (
            <ReadMore>{t('post.viewLess')}</ReadMore>
          ) : (
            <ReadMore>{t('post.viewMore')}</ReadMore>
          )}
        </Button>
      );
    }
    return null;
  };

  const getText = () => {
    if (isOpen) return textContent;

    var chars = Array.from(textContent);
    return chars.slice(0, charactersCount).join('');
  };

  return (
    <div>
      {parse(getText())}
      {renderViewMore()}
    </div>
  );
}

export default React.memo(ShowMore);
