import React, { useLayoutEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popover } from 'antd';
import { useDispatch } from 'store';
import { dislike, changeReaction } from 'store/actions/postsActions';

import * as S from './ReactionButtonStyles';
import ReactionIcon from './ReactionIcon';
import ReactionOptions from './ReactionOptions';

export type ReactionButtonProps = {
  postId: number;
  reaction: LikeReaction | undefined;
};

export default function ReactionButton({ postId, reaction }: ReactionButtonProps) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const btnLikeRef = useRef<HTMLButtonElement>(null);

  const reactHandler = (rl: ReactionLiteral = 'like') => {
    if (reaction?.name !== rl) {
      dispatch(changeReaction({ postId, reaction: rl }));
    }
  };

  const likeHandler = () => {
    if (!reaction) {
      dispatch(changeReaction({ postId }));
    } else {
      dispatch(dislike(postId));
    }
  };

  useLayoutEffect(() => {
    btnLikeRef.current?.blur();
  }, [reaction]);

  return (
    <Popover
      content={<ReactionOptions onChosenReaction={reactHandler} />}
      arrowPointAtCenter
      overlayClassName={S.popover()}
      align={{ offset: [0, 5] }}
      overlayInnerStyle={{ borderRadius: 50 }}
    >
      <Button
        css={S.button(!!reaction)}
        type="link"
        size="small"
        onClick={likeHandler}
        ref={btnLikeRef}
      >
        <ReactionIcon
          css={S.icon(!!reaction, reaction?.name ?? 'like')}
          reaction={reaction?.name ?? 'like'}
        />
        <span className="text">{t(`reactions.${reaction?.name ?? 'like'}`)}</span>
      </Button>
    </Popover>
  );
}
