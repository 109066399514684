import { css, cx } from '@emotion/css';

// #region STYLES
const mentionSuggestionsEntryFocused = (theme: Themes.DefaultTheme) => css`
  color: ${theme.colors.gray10};
  background-color: ${theme.colors.primary1};
`;
const mentionSuggestionsEntryText = css`
  text-align: left;
`;
const mentionSuggestionsEntry = (theme: Themes.DefaultTheme) => css`
  padding: 2px 8px;
  font-weight: 600;
  color: ${theme.colors.gray9};
  cursor: pointer;

  ${theme.typography.fontxSmall('24px')};
`;
const mentionSuggestionsEntryContainerRight = css`
  display: table-cell;
  vertical-align: middle;
`;
const mentionSuggestionsEntryContainerLeft = css`
  padding-right: 8px;

  [class^='ProfileImage_xsImg'],
  [class^='ProfileImage_xsProfilePlaceholder'] {
    width: 16px;
    height: 16px;
  }
  [class^='ProfileImage_xsProfilePlaceholder'] {
    font-size: 10px;
    line-height: 16px;
  }
`;
const mentionSuggestionsEntryContainer = css`
  width: 100%;
`;
const mentionSuggestions = css`
  position: absolute;
  z-index: 40;
  box-sizing: border-box;
  min-width: 192px;
  max-height: 336px;
  padding: 4px 0;
  overflow-y: auto;
  background-color: #fff;
  border: solid 1px #fafafa;
  border-radius: 6px;
  box-shadow: -5px 5px 19px 1px rgba(226, 226, 226, 0.25);
  transform: scale(0);
`;
const mention = (theme: Themes.DefaultTheme) => css`
  font-weight: 600;
  color: ${theme.colors.primary};
  text-decoration: none;
`;
// #endregion

const mentionTheme = (theme: Themes.DefaultTheme): Record<string, string> => ({
  mention: mention(theme),
  mentionSuggestions,
  mentionSuggestionsEntryContainer,
  mentionSuggestionsEntryContainerLeft: cx(
    mentionSuggestionsEntryContainerLeft,
    mentionSuggestionsEntryContainerRight
  ),
  mentionSuggestionsEntryContainerRight,
  mentionSuggestionsEntry: mentionSuggestionsEntry(theme),
  mentionSuggestionsEntryText,
  mentionSuggestionsEntryFocused: cx(
    mentionSuggestionsEntry(theme),
    mentionSuggestionsEntryFocused(theme)
  ),
});

export default mentionTheme;
