import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGrid } from '@fortawesome/pro-solid-svg-icons';

import NetworkShortcuts from './NetworkShortcuts';
import * as S from './ShortcutsStyles';
import { NavButton } from '../NavbarStyles';

const gridIcon = <FontAwesomeIcon icon={faGrid} className="anticon" />;

const Shortcuts = () => {
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      placement="bottomRight"
      trigger="click"
      visible={visible}
      content={
        <S.ShortcutWrapper>
          <NetworkShortcuts />
        </S.ShortcutWrapper>
      }
      title={<Title />}
      overlayStyle={{ position: 'fixed' }}
      overlayClassName={S.popoverInnerContent}
      overlayInnerStyle={{ width: 340 }}
      onVisibleChange={setVisible}
    >
      <NavButton id="shortcuts-menu" type="button" listVisible={visible}>
        {gridIcon}
      </NavButton>
    </Popover>
  );
};

const Title = () => {
  const { t } = useTranslation();

  return (
    <S.PopoverTitle>
      {gridIcon}
      <span>{t('shortcuts.shortcuts')}</span>
    </S.PopoverTitle>
  );
};

export default Shortcuts;
