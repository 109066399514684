import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { css } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faPaperclip, faVideo } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from 'antd';

import FileUploader from '../../Common/Uploader/FileUploader/FileUploader';
import ImageUploader from '../../Common/Uploader/ImageUploader/ImageUploader';
import VideoUploader from '../../Common/Uploader/VideoUploader/VideoUploader';

// #region STYLES
const actionButton = theme => css`
  margin-right: 24px;
  transition: all 0.2s ease;

  ${theme.typography.fontNormal('24px')};

  .svg-inline--fa {
    width: 1em;
  }
  :hover {
    transition: all 0.08s ease;
    transform: scale(1.2);
  }
  :active {
    transform: scale(1.1);
  }
`;

// #endregion

const AttachmentsPreview = props => {
  const { disabled, handleFileAdded, files, handleRejectedFile } = props;
  const { t } = useTranslation();

  const networkSettings = useSelector(state => state.auth.networkSettings);

  const renderImageUploader = () => (
    <ImageUploader
      css={actionButton}
      onImageAdd={acceptedFiles => handleFileAdded(acceptedFiles, 'image')}
      disabled={files.length >= networkSettings.maxPhotosPermittedInPost || disabled}
      canSendMultiple
    >
      <Tooltip placement="bottom" title={t('common.photos')}>
        <FontAwesomeIcon icon={faImage} />
      </Tooltip>
    </ImageUploader>
  );
  const renderFileUploader = () => (
    <FileUploader
      css={actionButton}
      disabled={disabled}
      onFileAdded={(acceptedFiles, rejectedFiles) =>
        handleFileAdded(acceptedFiles, 'file', rejectedFiles)
      }
      onFileRejected={handleRejectedFile}
    >
      <Tooltip placement="bottom" title={t('common.attachment')}>
        <FontAwesomeIcon icon={faPaperclip} />
      </Tooltip>
    </FileUploader>
  );
  const renderVideoUploader = () => (
    <VideoUploader
      css={actionButton}
      disabled={disabled}
      onVideoAdd={(acceptedFiles, rejectedFiles) =>
        handleFileAdded(acceptedFiles, 'video', rejectedFiles)
      }
      onFileRejected={handleRejectedFile}
    >
      <Tooltip placement="bottom" title={t('common.video')}>
        <FontAwesomeIcon icon={faVideo} />
      </Tooltip>
    </VideoUploader>
  );

  return (
    <Fragment>
      {renderImageUploader()}
      {renderVideoUploader()}
      {renderFileUploader()}
    </Fragment>
  );
};

export default AttachmentsPreview;
