import type { StoreKey } from 'store/types/newPostTypes';
import PostScopeSelector from 'components/layout/Common/PostScopeSelector/PostScopeSelector';
import GroupScopeSelector from 'components/Groups/NewPost/GroupScopeSelector';

type ScopeSelectorWrapperProps = {
  disabled?: boolean;
  storeKey: StoreKey;
};

export default function ScopeSelectorWrapper({ disabled, storeKey }: ScopeSelectorWrapperProps) {
  if (storeKey === 'NEW_POST') {
    return <PostScopeSelector disabled={disabled} storeKey={storeKey} />;
  }

  return <GroupScopeSelector disabled={disabled} storeKey={storeKey} />;
}
