import { css } from '@emotion/react';

export const arrowMenuItem = (checked: boolean) => (theme: Themes.DefaultTheme) =>
  css`
    display: flex;
    align-items: center;
    background-color: ${checked ? theme.colors.gray2 : 'transparent'};

    span {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      ${theme.typography.fontSmall()};
    }

    .check-icon {
      margin-right: 0 !important;
      margin-left: 8px;
      visibility: ${checked ? 'visible' : 'hidden'};
    }
  `;
