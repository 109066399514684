import axios from 'axios';
import { createCancelToken, errorResult, statusError, CancelCallback, Result } from '../apiActions';

export type MentionReqParams = {
  name: string;
  postId?: number;
  pageId?: number;
};

export type UserListReqParams = {
  name: string;
  pageId?: number;
  isPublic?: boolean;
};

export async function getMentionsList(
  params: MentionReqParams,
  cancelCallback?: CancelCallback
): Promise<MentionFetchData[] | number | undefined> {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const { data } = await axios.get<MentionFetchData[]>('/api/mention/getMentionsList', {
      params,
      cancelToken,
    });

    return data;
  } catch (error) {
    return statusError(error);
  }
}

export async function getUserList(
  params: UserListReqParams,
  cancelCallback?: CancelCallback
): Promise<Result<MentionFetchData[]>> {
  try {
    const cancelToken = createCancelToken(cancelCallback);

    const { status, data } = await axios.get('/api/Mention/getUserList', { params, cancelToken });

    return { ok: true, status, data };
  } catch (error) {
    return errorResult(error);
  }
}
