import { useEffect, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios, { AxiosError } from 'axios';
import qs from 'qs';

import { useAuth } from 'hooks';
import { API_DOMAIN } from 'lib/constants';
import SeniorService from 'lib/SeniorService';
import { useDispatch } from 'store';
import { logout } from 'store/actions/authActions';

import TransitionScreen from 'components/layout/TransitionScreen/TransitionScreen';

import Routes from '../../routes';

const api = axios.create({ baseURL: API_DOMAIN });

const AuthenticateCas = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { networkId } = useAuth();

  const dispatch = useDispatch();

  const [error, setError] = useState<string | null>(null);

  const qsRef = useRef(qs.parse(location.search, { ignoreQueryPrefix: true }));

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);

  useEffect(() => {
    const { access_token } = qsRef.current;

    async function authenticate() {
      try {
        const userData = { token: access_token, networkId };

        const authResponse = await api.post(Routes.authenticateCas, userData);

        if (authResponse.data) {
          localStorage.setItem('session-token', authResponse.data);

          navigate('/feed');
        }
      } catch (error) {
        console.error(error);
        const axiosError = error as AxiosError;

        if (!axiosError.isAxiosError) {
          setError('generic');
          return;
        }

        if (axiosError.response!.status === 401) {
          setError('401');
        } else {
          setError('generic');
        }
      }
    }

    if (!access_token) {
      setError('generic');
      alert(t('login.casErrors.tokenNotFound'));
      return;
    }

    if (networkId) {
      authenticate();
    }

    return () => {
      SeniorService.instance.destroy();
    };
  }, [navigate, networkId, t]);

  return <TransitionScreen errorType={error} />;
};

export default AuthenticateCas;
