import { useAsyncCallback } from 'react-async-hook';

import { getUnit } from 'lib/apiActions';

function useUnit() {
  const unitAsync = useAsyncCallback(getUnit);

  return unitAsync;
}

export default useUnit;
