import produce from 'immer';
import { AuthState, AuthActionType, AccountState } from 'store/types/authTypes';
import { AuthActions } from 'store/types/types';

const initialState: Readonly<AuthState> = {
  token: null,
  networkId: 0,
  userId: 0,
  firstLogin: false,
  userInfo: {} as UserInfo,
  userPermissions: {},
  network: {} as Network,
  networkSettings: {} as NetworkSettings,
  networkFunctions: {} as NetworkFunctions,
  intervalId: 0,
  isSystemMasterAdmin: false,
  favicon: null,
  seniorXToken: null,
  featureToogle: {} as FeatureToogle,
  deviceToken: undefined,
  account: {
    userSecurity: {},
  } as AccountState,
};

const addPermission = (userPermission: UserPermissions, permission: KeyValue) => {
  const { description } = permission;
  userPermission[description] = permission;
  return userPermission;
};

const authReducer = (state = initialState, action: AuthActionType) =>
  produce(state, draft => {
    switch (action.type) {
      case AuthActions.FAVICON_UPDATED: {
        draft.favicon = action.payload;
        break;
      }
      case AuthActions.NETWORK_UPDATED: {
        draft.networkId = action.network.networkId;
        draft.network = action.network;
        break;
      }
      case AuthActions.THEME_UPDATED: {
        draft.network.theme = action.theme;
        break;
      }
      case AuthActions.USER_AUTHENTICATED: {
        const { payload } = action;
        draft.token = payload.token;
        draft.deviceToken = payload.deviceToken;
        draft.firstLogin = payload.firstLogin;
        draft.intervalId = payload.intervalId;
        break;
      }
      case AuthActions.USER_INFO_RECEIVED: {
        const { payload } = action;

        draft.networkId = payload.networkId;
        draft.userId = payload.userId;
        draft.userInfo = payload.userInfo;
        draft.userPermissions = payload.userPermissions.reduce(addPermission, {});
        draft.network = payload.network;
        draft.networkSettings = payload.networkSettings;
        draft.networkFunctions = payload.networkFunctions;
        draft.isSystemMasterAdmin = payload.isSystemMasterAdmin;
        break;
      }
      case AuthActions.USER_ONBOARDED: {
        draft.firstLogin = false;
        break;
      }
      case AuthActions.AUTH_USER_UPDATED: {
        draft.userInfo = action.payload;
        break;
      }
      case AuthActions.SENIORX_TOKEN_RECEIVED: {
        draft.seniorXToken = action.seniorXToken;
        break;
      }
      case AuthActions.FEATURE_TOOGLE_RECEIVED: {
        draft.featureToogle = action.payload;
        break;
      }
      case AuthActions.DEVICE_TOKEN_RECEIVED: {
        draft.deviceToken = action.deviceToken;
        break;
      }
      case AuthActions.ACCOUNT_RECEIVED: {
        const { userSecurity } = action.payload;
        draft.account.userSecurity = userSecurity ?? {};
        break;
      }
      case AuthActions.USER_SECURITY_UPDATED: {
        const userSecurity = action.payload;

        draft.account.userSecurity = { ...draft.account.userSecurity, ...userSecurity };
        break;
      }
    }
  });

export default authReducer;
