import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faPaperclip } from '@fortawesome/pro-solid-svg-icons';

import { useFeatureToggle } from 'hooks';
import type { FileRejection } from 'components/layout/Common/Uploader/FileUploader';
import * as S from './ChatAttachmentActionsStyles';

//#region TYPES
export type FileType = 'image' | 'audio' | 'file';

export interface ChatAttachmentActionsProps {
  emojiSelect: React.ReactNode;
  filesCount: number;
  onFileAdded: (acceptedFiles: File[], fileType: FileType) => void;
}
//#endregion

const ChatAttachmentActions: React.VFC<ChatAttachmentActionsProps> = ({
  emojiSelect,
  filesCount,
  onFileAdded,
}) => {
  const { t } = useTranslation();
  const [chatFilesAttachments] = useFeatureToggle('chat_files_attachments');

  const fileAddedHandler = (fileType: FileType) => {
    return (acceptedFiles: File[]) => onFileAdded(acceptedFiles, fileType);
  };

  const fileRejectedHandler = (_: any, fileRejections: FileRejection[]) => {
    const [fileRejection] = fileRejections;
    const extension = '.' + fileRejection.file.name.split('.').pop()!;

    fileRejection.errors.forEach(error =>
      toast.error(t(`files.errors.${error.code}`, { extension }), {
        position: 'top-right',
      })
    );
  };

  return (
    <S.ActionsWrapper>
      <S.ImgUploader
        canSendMultiple
        onImageAdd={fileAddedHandler('image')}
        disabled={filesCount >= 4}
      >
        <FontAwesomeIcon icon={faImage} />
      </S.ImgUploader>
      {chatFilesAttachments && (
        <S.FlUploader
          onFileAdded={fileAddedHandler('file')}
          disabled={filesCount >= 1}
          onFileRejected={fileRejectedHandler}
        >
          <FontAwesomeIcon icon={faPaperclip} />
        </S.FlUploader>
      )}
      {emojiSelect}
    </S.ActionsWrapper>
  );
};

export default ChatAttachmentActions;
