import { css } from '@emotion/react';
import { rgba } from 'polished';

const lgSize = css`
  height: 40px;
  padding: 6px 11px;
  font-size: 16px;
`;
export const invalid = (theme: Themes.DefaultTheme) => css`
  border-color: ${theme.colors.danger} !important;
  :focus,
  :hover {
    border-color: ${theme.colors.danger};
  }
`;

export interface BaseInputProps {
  colorMode?: 'dark' | 'light';
  inputSize?: 'default' | 'large';
  invalid?: boolean;
}
export const baseInput = ({ theme, ...props }: Themes.PropsWithTheme<BaseInputProps>) => css`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  margin: 0;
  font-size: 14px;
  color: ${theme.colors.gray9};
  background-color: ${props.colorMode === 'dark' ? theme.colors.gray4 : theme.colors.white};
  border: 1px solid ${props.colorMode === 'dark' ? theme.colors.gray4 : theme.colors.gray5};
  border-radius: 4px;
  outline: 0;
  transition: all 0.3s;

  ${props.inputSize === 'large' && lgSize};

  :disabled {
    cursor: not-allowed;
    background: ${theme.colors.gray4};
  }
  :focus {
    box-shadow: 0 0 0 2px ${rgba(theme.colors.primary5, 0.2)};
  }
  :focus,
  :hover:not(:disabled) {
    border-color: ${theme.colors.primary5};
  }

  ${props.invalid && invalid(theme)};
`;
