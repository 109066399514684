const widgetsPt = {
  information: 'Informações',
  birthday: {
    birthdays: 'Aniversariantes',
    congratulate: 'Parabenizar',
    happyBirthday: 'Parabéns {{user}}. Feliz aniversário!',
    incomingBirthdays: 'Não há aniversariantes para os próximos dias',
    otherBirthdays: 'Ver mais',
    noBirthdays: 'Que pena, não há aniversariantes no mês',
    widgetTitle: 'Aniversariantes de {{month}}',
  },
  calendar: {
    accepted: 'Aceito',
    add: 'Adicionar',
    calendar: 'Agenda',
    connect: 'Conecte seu Google Calendar',
    declined: 'Rejeitado',
    empty: 'Nada por hoje',
    emptyHint: 'Seus eventos agendados aparecerão aqui.',
    myCalendar: 'Meu calendário',
    needsAction: 'Não visto',
    tentative: 'Talvez',
    today: '[Hoje], DD [de] MMMM',
  },
  config: {
    configTitle: 'Organize seus widgets',
    configSubtitle: 'Ative quais widgets você deseja ver na página inicial',
  },
  group: {
    about: 'Sobre o grupo',
    addDescription: 'Adicionar descrição',
    addLink: 'Adicionar link',
    administrator: 'Administrador',
    administrator_plural: 'Administradores',
    emptyFiles: 'Os arquivos que estiverem em posts do grupo, serão guardados aqui',
    manager: 'Gestor do grupo',
    members: 'Participantes do grupo',
    membersCount: 'Ver todos ({{count}})',
    noDescription: 'Sem descrição',
  },
  groupList: {
    joinedGroups: 'Meus Grupos',
    seeOtherGroupsIJoined: 'Ver mais',
  },
  manager: {
    addManager: 'Adicione um gestor',
    addManagerDescription: 'Adicione uma pessoa responsável pela liderança da sua equipe.',
    delete: 'Excluir',
    deleteManagerModalContent: 'Você realmente quer excluir seu gestor?',
    deleteManagerModalTitle: 'Excluir gestor',
    organizationalChart: 'Organograma',
  },
  post: {
    pinnedPosts: 'Posts Fixados',
    post: 'Post',
  },
  user: {
    addExtension: 'Adicione um ramal',
    contactNumber: 'Número para contato',
    change: 'Alterar',
    emptyField: 'Campo em branco',
    lessInformation: 'Ver menos informações',
    moreInformation: 'Ver mais informações',
    personal: 'Pessoal',
    yourBirthday: 'Data do seu aniversário',
    yourCompany: 'Sua empresa',
    yourDepartment: 'Seu departamento',
    yourEmail: 'Seu email',
    yourLinkedIn: 'Seu linkedin',
    yourName: 'Seu nome',
    yourOccupation: 'Sua função na empresa',
    yourUnit: 'Qual unidade você trabalha?',
    yourWhatsapp: 'Seu whatsapp',
  },
};

export default widgetsPt;
