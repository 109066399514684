import { css as cssStyle } from '@emotion/css';
import { css, Theme } from '@emotion/react';

export const button = (liked: boolean) => (theme: Theme) =>
  css`
    &.ant-btn-link {
      padding: 0;
      margin: 0;
      color: ${liked ? theme.colors.primary : 'inherit'};
      border: 0;
      transition: color 200ms ease;
      ${theme.typography.fontxSmall('24px')};

      :hover {
        color: ${theme.colors.primary5};

        > span {
          text-decoration: underline;
        }
      }

      > span {
        transition: text-decoration 200ms ease;
      }
    }
  `;

export const popover = cssStyle`
  .ant-popover-arrow {
    display: none;
  }
`;
