import { css, Theme } from '@emotion/react';
import 'animate.css/animate.compat.css';
import { up } from 'styled-breakpoints';

const scaleSize = 0.9;

const animateOverrides = css`
  @keyframes fadeInDownSmall {
    from {
      opacity: 0;
      transform: translate3d(0, -16px, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .fadeInDownSmall {
    animation-name: fadeInDownSmall;
  }

  @keyframes fadeInDownXSmall {
    from {
      opacity: 0;
      transform: translate3d(0, -8px, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .fadeInDownXSmall {
    animation-name: fadeInDownXSmall;
  }

  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale3d(${scaleSize}, ${scaleSize}, ${scaleSize});
    }
    50% {
      opacity: 1;
    }
  }
  @keyframes zoomOut {
    from {
      opacity: 1;
    }
    50% {
      opacity: 0;
      transform: scale3d(${scaleSize}, ${scaleSize}, ${scaleSize});
    }
    to {
      opacity: 0;
    }
  }
`;
const scrollbar = (theme: Theme) => css`
  /* Scrollbar */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border: 1px solid ${theme.colors.gray4};
    border-width: 0 0 0 1px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: ${theme.colors.gray5};
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background: ${theme.colors.gray6};
  }
`;

const globalStyle = (theme: Theme) => css`
  ${animateOverrides};
  ${scrollbar(theme)};

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  :root {
    --gray2: ${theme.colors.gray2};
    --gray8: ${theme.colors.gray8};
    --gray10: ${theme.colors.gray10};
  }

  html,
  body {
    height: 100%;

    ${up('lgTablet')({ theme })} {
      height: calc(100vh - 64px);
    }
  }

  body {
    padding: 0;
    margin: 0;
    font-family: 'Open Sans', 'Apple Color Emoji', 'Noto Color Emoji', 'Twemoji Mozilla',
      'Segoe UI Emoji', -apple-system, BlinkMacSystemFont, 'Segoe-UI', Roboto, 'Helvetica Neue',
      'Noto Sans', Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    color: ${theme.colors.gray10};
    background-color: ${theme.colors.gray1};
  }

  #root {
    height: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.75rem;
    font-weight: bold;
    color: ${theme.colors.gray10};
  }
  h1 {
    ${theme.typography.fontxxLarge()};
  }
  h2 {
    ${theme.typography.fontxLarge()};
  }
  h3 {
    ${theme.typography.fontLarge()};
  }
  h4 {
    ${theme.typography.fontNormal()};
  }
  h5 {
    ${theme.typography.fontSmall()};
  }
  h6 {
    ${theme.typography.fontxSmall()};
  }
  hr {
    margin: 1.5em 0;
    border-top-color: ${theme.colors.gray5};
  }

  .mention-link {
    font-weight: 600;
    color: ${theme.colors.primary};

    :hover {
      color: ${theme.colors.primary5};
    }
    :focus,
    :visited {
      color: ${theme.colors.primary7};
    }
  }
  .url-link {
    color: ${theme.colors.blue};
    text-decoration: none;

    &:hover {
      color: ${theme.colors.blue7};
    }
  }

  /* CSS TRANSITIONS ANIMATION */

  /* #region FADE */

  .fade_animation-enter {
    opacity: 0;
  }
  .fade_animation-enter-active {
    opacity: 1;
  }
  .fade_animation-exit {
    opacity: 1;
  }
  .fade_animation-exit-active {
    opacity: 0;
  }
  .fade_animation-enter-active,
  .fade_animation-exit-active {
    transition: opacity 250ms ease-in-out;
  }

  /* #endregion */

  /* #region TOAST */
  .bee-toast {
    padding: 0.625em;
    border-radius: 4px;
  }
  .bee-toast.primary {
    color: #fff;
    background-color: ${theme.colors.primary};
  }
  .bee-toast.gray {
    color: #fff;
    background-color: ${theme.colors.gray9};

    .Toastify__close-button {
      color: #fff;
    }
  }
  .bee-toast.danger {
    color: #fff;
    background-color: ${theme.colors.danger};
  }
  .bee-toast-body {
    font-weight: bold;
  }
  .bee-toast-progress {
    background: ${theme.colors.primary};
  }

  /* #endregion */

  /* RECAPTCHA BADGE */
  .grecaptcha-badge {
    visibility: collapse !important;
  }

  /* PURE CAROUSEL */
  div.carousel__slide-focus-ring {
    outline: 0;
  }

  /* Router NavLink */
  .active {
    color: ${theme.colors.primary};
  }

  .ant-tooltip-inner a {
    color: #fff !important;
  }
`;

export default globalStyle;
