import { css, cx } from '@emotion/css';
import { EmojiPluginTheme } from '@draft-js-plugins/emoji';

// #region STYLES BASE
const emojiSuggestions = css`
  position: absolute;
  z-index: 2;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 220px;
  max-width: 440px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 1.75em;
  cursor: pointer;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 2px;
  box-shadow: 0 4px 30px 0 rgba(220, 220, 220, 1);
  transform: scale(0);

  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-orient: vertical;

  /* stylelint-disable-next-line property-no-vendor-prefix */
  -webkit-box-direction: normal;
`;
const emojiSuggestionsEntryIcon = css`
  display: inline-block;
  width: 1em;
  height: 1em;
  margin-right: 0.25em;
  margin-left: 0.25em;
`;
const emojiSuggestionsEntryText = css`
  display: inline-block;
  max-width: 368px;
  margin-left: 8px;
  overflow: hidden;
  font-size: 0.9em;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const emojiSuggestionsEntryFocused = css`
  background-color: #e6f3ff;
`;
const emojiSuggestionsEntry = css`
  padding: 5px 10px 1px 10px;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);

  :active {
    background-color: #cce7ff;
  }
`;
const emojiSelectPopoverScrollbarThumb = css`
  cursor: pointer;
  background-color: #000;
  border-radius: 0.125em;
`;
const emojiSelectPopoverScrollbar = css`
  position: absolute;
  top: 0.3em;
  right: 0;
  bottom: 0.3em;
  width: 0.25em;
  background-color: #e0e0e0;
  border-radius: 0.125em;
  opacity: 0.1;
  transition: opacity 0.4s;
`;
const emojiSelectPopoverNavEntryActive = css`
  color: #3498db;
`;
const emojiSelectPopoverNavEntry = css`
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 1.2em;
  color: #bdbdbd;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
`;
const emojiSelectPopoverNavItem = css`
  width: 2.5em;
  height: 2.5em;
`;
const emojiSelectPopoverNav = css`
  display: flex;
  width: 20em;
  padding: 0 0.5em;
  margin: 0;
  list-style: none;
`;
const emojiSelectPopoverEntryIcon = css`
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
`;
const emojiSelectPopoverEntryFocused = css`
  background-color: #efefef;
`;
const emojiSelectPopoverEntry = css`
  width: 100%;
  height: 100%;
  padding: 0;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  font-size: 20px;
`;
const emojiSelectPopoverToneSelectItem = css`
  width: 2.5em;
  height: 2.5em;

  :first-child {
    border-right: 1px solid #e0e0e0;
  }
`;
const emojiSelectPopoverToneSelectList = css`
  position: absolute;
  display: flex;
  padding: 0.3em;
  margin: 0.3em;
  list-style: none;
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 0.5em;
  box-shadow: 0 0 0.3em rgba(0, 0, 0, 0.1);
`;
const emojiSelectPopoverToneSelect = css`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
`;
const emojiSelectPopoverGroupItem = css`
  width: 2.5em;
  height: 2.5em;
`;
const emojiSelectPopoverGroupList = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
`;
const emojiSelectPopoverGroupTitle = css`
  padding-left: 0.5em;
  margin: 1em 0;
  font-size: 1em;
  font-weight: normal;
  color: #9e9e9e;
`;
const emojiSelectPopoverGroup = css`
  padding: 0 0.5em;

  :first-child > .hideEmojiSelectPopoverGroupTitle {
    display: none;
  }
`;
const emojiSelectPopoverGroups = css`
  position: relative;
  z-index: 0;
  width: 21em;
  height: 20em;
  margin: 0 0 0.3em;

  :hover.interactEmojiSelectPopoverScrollbar {
    opacity: 0.3;
  }
  .interactEmojiSelectPopoverScrollbar:active,
  .interactEmojiSelectPopoverScrollbar:hover {
    opacity: 0.6;
  }
`;
const emojiSelectPopoverTitle = css`
  height: 2.5em;
  padding-left: 1em;
  margin: 0 0 0.3em;
  font-size: 1em;
  font-weight: normal;
  line-height: 2.5em;
  color: #9e9e9e;
`;
const emojiSelectPopoverClosed = css`
  display: none;
`;
const emojiSelectPopover = css`
  position: absolute;
  z-index: 1000;
  box-sizing: content-box;
  padding: 0 0.3em;
  margin-top: 10px;
  background: #fff;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 30px 0 gainsboro;
`;
const emojiSelect = css`
  position: relative;
  display: inline-block;
`;
const emoji = css`
  /*
    We need to limit the emoji width because it can be multiple characters
    long if it is a 32bit unicode. Since the proper width depends on the font and
    it's relationship between 0 and other characters it's not ideal. 1.95ch is not
    the best value, but hopefully a good enough approximation for most fonts.
    */
  display: inline-block;

  /*
  Some SVG files (say 2764 for :heart:) don't have default width/height, thus
  may not be rendered properly on some platforms/browsers (e.g., Windows 10 +
  Chrome 61).
  */
  min-width: 1em;
  max-width: 1.95ch;

  /*
  Needed for iOS rendering to avoid some icons using a lot of height without
  actually needing it.
  */
  max-height: 1em;
  margin: -0.2ex 0 0.2ex;
  overflow: hidden;
  line-height: inherit;

  /*
  In the past we used opacity: 0 to hide the original Emoji icon no matter what
  system it is. Recently we switched to color: transparent since it started to
  work in recent iOS version.
  */
  color: transparent;

  /* move it a bit further down to align it nicer with the text */
  vertical-align: middle;

  /* make sure the background the image is only shown once */
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;
// #endregion

// #region STYLES POST
const emojiSelectButtonPost = (theme: Themes.DefaultTheme) => css`
  padding: 0;
  margin-right: 24px;
  color: ${theme.colors.primary};
  cursor: pointer;
  background: none;
  border-width: 0;

  ${theme.typography.fontNormal('24px')};

  :focus {
    outline: 0;
  }
  :hover {
    transition: all 0.08s ease;
    transform: scale(1.2);
  }
  :active {
    transform: scale(1.1);
  }
`;
// #endregion

// #region STYLES COMMENT
const emojiSelectButtonComment = (theme: Themes.DefaultTheme) => css`
  padding: 0;
  color: ${theme.colors.gray8};
  cursor: pointer;
  background: none;
  border-width: 0;

  :hover {
    color: ${theme.colors.primary5};
  }
  :focus {
    outline: 0;
  }
`;
const emojiSelectPopoverComment = css`
  right: 0;
  position: absolute !important;
  transform: none !important;
  inset: 30px auto auto -290px !important;
  overflow: overlay !important;
`;
// #endregion

//#region STYLES CHAT
const emojiSelectButtonChat = (theme: Themes.DefaultTheme) => css`
  height: 32px;
  padding: 0;
  font-size: 14px;
  line-height: 1.5;
  color: ${theme.colors.gray9};
  cursor: pointer;
  background: none;
  border-width: 0;

  :active {
    transform: scale(1.1);
  }
  :hover {
    color: ${theme.colors.primary};
    transition: all 0.08s ease;
    transform: scale(1.2);
  }
  :focus {
    outline: 0;
  }
`;
const emojiSelectPopoverChat = css`
  right: -4px;
  bottom: 20px;
  position: absolute !important;
  transform: none !important;
  inset: -430px auto auto -145px !important;
  overflow: overlay !important;
`;
//#endregion

const baseEmojiTheme: EmojiPluginTheme = {
  emoji,
  emojiSelect,
  emojiSelectPopover,
  emojiSelectPopoverClosed,
  emojiSelectPopoverTitle,
  emojiSelectPopoverGroups,
  emojiSelectPopoverGroup,
  emojiSelectPopoverGroupTitle: cx(
    'hideEmojiSelectPopoverGroupTitle',
    emojiSelectPopoverGroupTitle
  ),
  emojiSelectPopoverGroupList,
  emojiSelectPopoverGroupItem,
  emojiSelectPopoverToneSelect,
  emojiSelectPopoverToneSelectList,
  emojiSelectPopoverToneSelectItem,
  emojiSelectPopoverEntry,
  emojiSelectPopoverEntryFocused: cx(emojiSelectPopoverEntry, emojiSelectPopoverEntryFocused),
  emojiSelectPopoverEntryIcon,
  emojiSelectPopoverNav,
  emojiSelectPopoverNavItem,
  emojiSelectPopoverNavEntry,
  emojiSelectPopoverNavEntryActive: cx(
    emojiSelectPopoverNavEntry,
    emojiSelectPopoverNavEntryActive
  ),
  emojiSelectPopoverScrollbar: cx(
    'interactEmojiSelectPopoverScrollbar',
    emojiSelectPopoverScrollbar
  ),
  emojiSelectPopoverScrollbarThumb,
  emojiSuggestionsEntry,
  emojiSuggestionsEntryFocused,
  emojiSuggestionsEntryText,
  emojiSuggestionsEntryIcon,
  emojiSuggestions,
};

export const emojiThemePost = (theme: Themes.DefaultTheme): EmojiPluginTheme => ({
  ...baseEmojiTheme,
  emojiSelectButton: emojiSelectButtonPost(theme),
  emojiSelectButtonPressed: emojiSelectButtonPost(theme),
});

export const emojiThemeComment = (theme: Themes.DefaultTheme): EmojiPluginTheme => ({
  ...baseEmojiTheme,
  emojiSelectButton: emojiSelectButtonComment(theme),
  emojiSelectButtonPressed: emojiSelectButtonComment(theme),
  emojiSelectPopover: cx(emojiSelectPopover, emojiSelectPopoverComment),
});

export const emojiThemeChat = (theme: Themes.DefaultTheme): EmojiPluginTheme => ({
  ...baseEmojiTheme,
  emojiSelectButton: emojiSelectButtonChat(theme),
  emojiSelectButtonPressed: emojiSelectButtonChat(theme),
  emojiSelectPopover: cx(emojiSelectPopover, emojiSelectPopoverChat),
});

export const TabsFeed = css`
  overflow: unset;
`;
