import { css, Theme } from '@emotion/react';

const antStyles = (theme: Theme) => css`
  /* ANT BUTTON */
  .ant-btn-primary {
    font-weight: bold;
  }

  /* LINK BUTTON */
  .bee-cancel-btn-link {
    color: ${theme.colors.gray10};
  }
  .bee-nav-link {
    padding: 0;
    color: ${theme.colors.blue};

    :focus,
    :hover,
    :active,
    :visited {
      color: ${theme.colors.blue7};
    }
  }

  /* ANT INPUT, PICKER */
  .ant-input,
  .ant-picker {
    border: 1px solid ${theme.colors.gray5};
  }

  /* ANT SELECT */
  .ant-select-multiple .ant-select-selector,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid ${theme.colors.gray5};
  }

  /* ANT CHECKBOX */
  .ant-checkbox-wrapper {
    line-height: 24px;
  }
`;

export default antStyles;
