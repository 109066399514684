const usersPt = {
  briefDescription: 'Uma breve descrição do usuário',
  coworkers: 'Colaboradores',
  coworkersFollow: 'Colaboradores que eu sigo',
  currentPassword: 'Senha atual',
  editProfile: 'Editar perfil',
  fullName: 'Nome completo',
  linkDescription: 'O usuário pode inserir um link de um site pessoal,portfólio etc.',
  newCoworker: 'Novo colaborador',
  newPassword: 'Nova senha',
  notFoundTitle: 'Não encontramos quem você procura.',
  notFoundSubtitle: 'Essa pessoa está na empresa?',
  peopleFound: 'Encontramos {{count}} pessoa',
  peopleFound_plural: 'Encontramos {{count}} pessoas',
  repeatPassword: 'Repetir nova senha',
  searchPeople: 'Buscar pessoas',
  userUpdated: 'As informações do usuário foram atualizadas',
  errors: {
    couldNotSaveContactInfo: 'Não foi possível salvar as informações de contato',
    uq_userinfo_email: 'O e-mail já está sendo utilizado nesta rede',
    uq_userinfo_username: 'O username já está sendo utilizado nesta rede',
  },
};

export default usersPt;
