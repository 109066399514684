import { createSelector } from 'reselect';

const auth = (state: RootState) => state.auth;

export const getToken = createSelector(auth, ({ token }) => token);

export const getCurrentUserIds = createSelector(auth, ({ networkId, userId }) => ({
  networkId,
  userId,
}));

export const selectCurrentUser = createSelector(
  auth,
  ({ userInfo, userPermissions, network, networkSettings, networkFunctions }) => ({
    userInfo,
    userPermissions,
    network,
    networkSettings,
    networkFunctions,
  })
);

export const selectMasterAdmin = createSelector(auth, ({ isSystemMasterAdmin }) => ({
  isSystemMasterAdmin,
}));

export const currentThemeSelector = createSelector(auth, ({ network }) => network.theme);

export const hasPermission = (state: RootState, name: string) => !!state.auth.userPermissions[name];
export const canUseFunction = (state: RootState, name: keyof NetworkFunctions) =>
  state.auth.networkFunctions![name];

export default selectCurrentUser;
