import styled from '@emotion/styled';
import { Button as AntButton, Modal as AntModal } from 'antd';

export const Modal = styled(AntModal)`
  .ant-modal-body {
    padding: 20px 24px;
  }
  .ant-modal-footer {
    border-top: 0;
  }
`;
export const Wrapper = styled.section`
  margin-bottom: 20px;
  text-align: center;
`;
export const Description = styled.h4`
  font-weight: normal;
`;
export const ChannelImage = styled.img`
  width: 90px;
  height: 90px;
  margin-bottom: 12px;
  border-radius: 50%;
  object-fit: cover;
`;
export const UploadIconWrapper = styled.div`
  display: inline-block;
  width: 90px;
  height: 90px;
  padding: 22px 15px;
  margin-bottom: 12px;
  border: dashed 1px ${({ theme }) => theme.colors.primary};
  border-radius: 50%;

  svg {
    width: 60px;
    height: 38px;
    color: ${({ theme }) => theme.colors.primary};
  }
`;
export const AddImageButton = styled(AntButton)`
  && {
    ${({ theme }) => theme.typography.fontxSmall()};
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: 14px;
`;
export const Label = styled.label`
  display: inline-block;
  margin-bottom: 8px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.gray10};
`;
