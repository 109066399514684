import { css } from '@emotion/react';
import styled from '@emotion/styled';

type WrapperProps = {
  size: 'small' | 'large';
};

export const Wrapper = styled.div<WrapperProps>`
  ${({ theme, size }) => css`
    width: ${size === 'small' ? '65' : '70'}px;
    max-height: 65px;
    text-align: center;

    > small {
      display: block;
      margin-top: 0.625em;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      max-width: 20ch;

      ${theme.typography.fontxxSmall()}
    }
  `}
`;
