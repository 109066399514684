import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button as AntButton } from 'antd';
import { faPaperclip } from '@fortawesome/pro-solid-svg-icons';
import { faArrowToBottom } from '@fortawesome/pro-regular-svg-icons';

import { useAuth } from 'hooks';
import { computedSize, fetchMedia, truncateString } from 'lib/helper';

import { Button, FileName, LinkContainer, icon } from './FileAttachmentStyles';

//#region TYPES
type FileAttachmentProps = {
  fileName: string;
  fileSize: number;
  mediaId: number;
};
//#endregion

const FileAttachment = ({ fileName, fileSize, mediaId }: FileAttachmentProps) => {
  const { token } = useAuth();

  function getExtension(name: string) {
    const splitedName = name.split('.');

    return splitedName[splitedName.length - 1];
  }
  function getName(name: string) {
    const splitedName = name.split('.');
    splitedName.pop();

    return splitedName.join('.');
  }

  return (
    <LinkContainer>
      <Button
        href={fetchMedia(mediaId, token)}
        target="_blank"
        rel="noopener noreferrer"
        download={fileName}
      >
        <FontAwesomeIcon css={icon} icon={faPaperclip} />
        <FileName>{truncateString(getName(fileName), 44)}</FileName>.{getExtension(fileName)} (
        {computedSize(fileSize)})
      </Button>
      <AntButton
        download={fileName}
        href={fetchMedia(mediaId, token, true)}
        icon={<FontAwesomeIcon icon={faArrowToBottom} />}
        shape="circle"
        type="text"
      />
    </LinkContainer>
  );
};

export default FileAttachment;
