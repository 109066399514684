import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

import { ITEM_TYPES } from 'lib/constants';
import UsersListModal from 'components/layout/Common/Modal/PersonList/PersonListContainer';
import LikesList from 'components/layout/Common/LikesList/LikesList';

import { likeCounter } from './CommentLikeCountStyles';

export interface CommentLikeCountProps {
  likeCount: number;
  commentId: number;
}

const CommentLikeCount = ({ commentId, likeCount }: CommentLikeCountProps) => {
  const { t } = useTranslation();

  const [likesModalVisible, setLikesModalVisible] = useState(false);

  const handleOpenModal = () => {
    setLikesModalVisible(true);
  };

  const handleCloseModal = () => {
    setLikesModalVisible(false);
  };

  return (
    <>
      <Button css={likeCounter} type="link" size="small" onClick={handleOpenModal}>
        {t('comments.likeCount', { count: likeCount })}
      </Button>
      <UsersListModal
        visible={likesModalVisible}
        title={t('comment.liked')}
        onCancel={handleCloseModal}
      >
        <LikesList itemId={commentId} itemType={ITEM_TYPES.comment} />
      </UsersListModal>
    </>
  );
};

export default CommentLikeCount;
