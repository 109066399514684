import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

export const interactions = (theme: Theme) => css`
  padding-top: 8px;
  margin-top: 8px;
  border-top: 1px solid ${theme.colors.gray4};
`;

export const InteractionsContainer = styled.div`
  color: ${({ theme }) => theme.colors.gray8};
`;
export const InteractionsButtons = styled.div<{ hasInteractions: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;

  ${props => props.hasInteractions && interactions(props.theme)}

  button {
    height: 24px;
    padding: 0;
    font-weight: 600;
    color: inherit;
    text-decoration: none;
    border-radius: 0;
    transition: none;

    ${({ theme }) => theme.typography.fontxSmall('24px')};
  }
`;

export const ContainerDisableComments = styled.div`
  margin-top: 15px;
  border-top: 1px solid ${({ theme }) => theme.colors.gray4};
`;

export const SpanMessage = styled.span`
  display: inline-block;
  margin-top: 10px;
  font-size: 14px;
`;
