import React from 'react';
import packageJson from '../package.json';

type GlobalVersion = typeof global & { appVersion: string };
const globalApp = global as GlobalVersion;
globalApp.appVersion = packageJson.version;

// version from `meta.json` - first param
// version in bundle file - second param
const semverGreaterThan = (versionA: string, versionB: string) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

export const refreshCacheAndReload = () => {
  console.log('Clearing cache and hard reloading...');
  if (window.caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then(names => names.forEach(name => caches.delete(name)));
  }
  // delete browser cache and hard reload
  window.location.reload();
};

type CacheBusterProps = {
  children: (
    isLoading: boolean,
    isLatestVersion: boolean,
    refreshCacheAndReload: Function
  ) => React.ReactNode;
};
type CacheBusterState = {
  isLoading: boolean;
  isLatestVersion: boolean;
};

class CacheBuster extends React.PureComponent<CacheBusterProps, CacheBusterState> {
  constructor(props: CacheBusterProps) {
    super(props);

    this.state = {
      isLoading: true,
      isLatestVersion: false,
    };
  }

  componentDidMount() {
    this.checkVersion();
  }

  async checkVersion() {
    const response = await fetch('/meta.json');
    const meta: Record<'version', string> = await response.json();

    const latestVersion = meta.version;
    const currentVersion = globalApp.appVersion;

    const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);

    if (shouldForceRefresh) {
      console.log(`New version - ${latestVersion}. Should force refresh`);
      this.setState({ isLoading: false, isLatestVersion: false });
    } else {
      console.log(`App is in latest version - ${latestVersion}`);
      this.setState({ isLoading: false, isLatestVersion: true });
    }
  }

  render() {
    const { children } = this.props;
    const { isLoading, isLatestVersion } = this.state;

    return children(isLoading, isLatestVersion, refreshCacheAndReload);
  }
}

export default CacheBuster;
