import { css } from '@emotion/css';
import type { Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { up } from 'styled-breakpoints';

export const removeLink = (theme: Theme) => css`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;

  :hover {
    color: ${theme.colors.danger};
  }
`;

export const titleDescription = ({ theme }: { theme?: Theme }) => css`
  overflow: hidden;
  text-overflow: ellipsis;

  ${theme?.typography.fontxSmall('20px')}
`;

export const imageAttachment = (theme: Theme) => css`
  img {
    min-height: 100%;
  }

  ${up('tablet')({ theme })} {
    position: absolute;
    inset: 0;
    border: 0;
  }
`;

export const AttachmentContainer = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.gray3};
  border-radius: 8px;

  ${up('tablet')} {
    height: 106px;
  }
`;
export const A = styled.a`
  display: flex;
  flex-direction: column-reverse;
  height: inherit;

  ${up('tablet')} {
    flex-direction: row;
  }
`;
export const ImageWrapper = styled.div`
  position: relative;

  ${up('tablet')} {
    width: 171px;
    height: 100%;
  }
`;
export const LinkInfo = styled.div`
  padding: 12px 16px;
  overflow: hidden;
  color: ${({ theme }) => theme.colors.gray10};
  background-color: ${({ theme }) => theme.colors.gray1};

  ${up('tablet')} {
    flex: 1;
  }
`;
export const LinkSource = styled.div`
  color: ${({ theme }) => theme.colors.gray8};

  ${({ theme }) => theme.typography.fontxSmall('20px')};
`;
export const LinkTitle = styled.div`
  ${titleDescription};
  font-weight: 600;

  ${up('tablet')} {
    white-space: nowrap;
  }
`;
export const LinkDescription = styled.div`
  ${titleDescription}
  ${({ theme }) => theme.typography.fontxSmall()};

  ${up('tablet')} {
    max-height: 40px;
  }
`;
