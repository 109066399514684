import React, { useEffect, useCallback, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios, { Canceler } from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { debounce, isEqual, isEmpty } from 'lodash/fp';
import produce from 'immer';

// GLOBAL COMPONENTS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentLines, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import PubSub from 'pubsub-js';

import useSingle from 'hooks/useSingle';

import { CHAT_ROOM_TYPES, APP_TITLE, NotificationMessageType } from 'lib/constants';

import { useAuth } from 'hooks';
import { useSelector } from 'store';
import {
  OpenList,
  NewChatWindow,
  getChatList,
  roomUpdated,
  openChatRoom,
} from 'store/actions/chatActions';
import {
  chatSelector,
  getActiveRoom,
  getGroupRooms,
  getIndividualRooms,
} from 'store/selectors/chatSelectors';
import { getChatMessagesCount, getRoom } from 'lib/api/chat';
import * as storage from 'lib/storageHelper';

import Badge from '../../Common/Badge/Badge';
import Chat from '../Chat';
import ChatSection, { ChatListContainer } from '../ChatSection';
import PersonItemChat from '../PersonItemChat';
import ChannelItemChat from '../ChannelItemChat';
import * as S from './ChatListStyles';
import { useChatNotification } from '../useChatNotification';

//#region TYPES
type UniqChatRoomInfo = ChatRoomInfo & { uniq: string };

type ChatListState = {
  channels: UniqChatRoomInfo[];
  individuals: UniqChatRoomInfo[];
  loading: boolean;
  searchQuery: string;
  messagesCount: number;
};

type ActionsTypes =
  | {
      type: 'getRoomsSearched';
      searchedRooms: UniqChatRoomInfo[];
    }
  | {
      type: 'clearSearched';
    }
  | {
      type: 'setSearchQuery';
      searchQuery: string;
    }
  | {
      type: 'setLoading';
      loading: boolean;
    }
  | {
      type: 'setMessagesCount';
      count: number;
    };
//#endregion

// #region REDUCER
const initialState: Readonly<ChatListState> = {
  channels: [],
  individuals: [],
  loading: false,
  searchQuery: '',
  messagesCount: 0,
};

function reducer(state: ChatListState, action: ActionsTypes) {
  return produce(state, draft => {
    switch (action.type) {
      case 'getRoomsSearched': {
        const { searchedRooms } = action;
        draft.individuals = searchedRooms.filter(r => r.room.type === CHAT_ROOM_TYPES.individual);
        draft.channels = searchedRooms.filter(r => r.room.type === CHAT_ROOM_TYPES.group);
        break;
      }
      case 'clearSearched': {
        draft.individuals = [];
        draft.channels = [];
        draft.searchQuery = '';
        break;
      }
      case 'setSearchQuery':
        draft.searchQuery = action.searchQuery;
        break;
      case 'setLoading':
        draft.loading = action.loading;
        break;
      case 'setMessagesCount':
        draft.messagesCount = action.count;
        break;
    }
  });
}
// #endregion

let cancelGetUsers: Canceler;

const ChatList = () => {
  const { userId } = useAuth();
  const { t } = useTranslation('chat');
  const dispatch: AppDispatch = useDispatch();
  const [state, chatListDispatch] = useReducer(reducer, initialState);

  const chat = useSelector(chatSelector, isEqual);
  const individualRooms = useSelector(getIndividualRooms);
  const groupRooms = useSelector(getGroupRooms);
  const chattingWith = useSelector(getActiveRoom, isEqual);

  const getList = useCallback(() => dispatch(getChatList()), [dispatch]);

  const getMessagesCount = useCallback(async () => {
    const count = await getChatMessagesCount(userId);

    chatListDispatch({ type: 'setMessagesCount', count: count ?? 0 });
  }, [chatListDispatch, userId]);

  const getUsers = async (value: string) => {
    const params = { name: value };
    try {
      const { data } = await axios.get<ChatRoomInfo[]>(`/api/chat/getRoom`, {
        params,
        cancelToken: new axios.CancelToken(canceler => {
          cancelGetUsers = canceler;
        }),
      });
      if (data) {
        const usersChatList = data.map(cl => ({ ...cl, uniq: uuidv4() }));

        chatListDispatch({ type: 'getRoomsSearched', searchedRooms: usersChatList });
      }
    } finally {
      chatListDispatch({ type: 'setLoading', loading: false });
    }
  };
  const debouncedGetUsers = useSingle(() => debounce(400, getUsers));

  const getSingleItem = useCallback(
    async (type, id) => {
      const [chatRoomUpdated] = await getRoom({ roomId: id });
      dispatch(roomUpdated(chatRoomUpdated));
    },
    [dispatch]
  );

  const { setStoreMessages } = useChatList({ getMessagesCount });

  useChatNotification({
    onNewChatMessage(messageResult) {
      getMessagesCount();

      setStoreMessages(setMessageNotification(messageResult));
    },
  });

  //#region EFFECTS
  useEffect(() => {
    async function focusEvent() {
      if ('serviceWorker' in navigator) {
        const serviceWorker = await navigator.serviceWorker.getRegistration(
          '/firebase-push-notification-scope'
        );
        const notifications = await serviceWorker?.getNotifications({ tag: 'new-chat-message' });

        if (!notifications || notifications.length === 0) return;

        if (chat.chatVisible) {
          const notification = notifications.find(
            noti => noti.data.room.roomId === chat.activeRoomId
          );

          if (!notification) return;

          PubSub.publish(
            NotificationMessageType[NotificationMessageType.NewChatMessage],
            notification.data
          );

          notification.close();
        }
      }
    }

    window.addEventListener('focus', focusEvent);

    return () => {
      window.removeEventListener('focus', focusEvent);
    };
  }, [chat.activeRoomId, chat.chatVisible]);

  useEffect(() => {
    const reloadChatSub = PubSub.subscribe('RELOAD_CHAT', getList);
    const reloadSingleSub = PubSub.subscribe('RELOAD_CHAT_SINGLE', getSingleItem);

    return () => {
      PubSub.unsubscribe(reloadChatSub);
      PubSub.unsubscribe(reloadSingleSub);
    };
  }, [getList, getSingleItem]);

  useEffect(() => {
    getMessagesCount();
  }, [getMessagesCount]);

  //#endregion

  const handleSearchUsers = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;

    chatListDispatch({ type: 'setSearchQuery', searchQuery: query });

    chatListDispatch({ type: 'setLoading', loading: true });
    if (query) {
      await debouncedGetUsers(query);
    } else {
      cancelGetUsers?.('Get Users cancelled');
      debouncedGetUsers.cancel();
      await getList();
      chatListDispatch({ type: 'setLoading', loading: false });
    }
  };

  const clearSearchHandler = () => {
    chatListDispatch({ type: 'clearSearched' });
    getList();
  };

  const toggleOpenHandler = async () => {
    dispatch(OpenList());

    if (!chat.listOpen) {
      chatListDispatch({ type: 'setLoading', loading: true });
      await getList();
      chatListDispatch({ type: 'setLoading', loading: false });
    }
  };

  const newChat = (room: ChatRoomInfo) => {
    dispatch(NewChatWindow(room));
    dispatch(OpenList());
  };

  const openNewChat = (room: ChatRoomInfo) => {
    newChat(room);
    chatListDispatch({ type: 'clearSearched' });
  };

  const renderChannelSection = () => {
    if (state.loading)
      return (
        <S.Spinner>
          <FontAwesomeIcon icon={faSpinnerThird} size="2x" spin />
        </S.Spinner>
      );
    if (groupRooms.length > 0 && isEmpty(state.searchQuery.trim()))
      return groupRooms.map(cRoom => (
        <ChannelItemChat key={cRoom.room.roomId} chatRoom={cRoom} onClick={newChat} />
      ));
    if (state.channels.length > 0)
      return state.channels.map(cRoom => (
        <ChannelItemChat key={cRoom.uniq} chatRoom={cRoom} onClick={openNewChat} />
      ));
    if (state.channels.length === 0 && !isEmpty(state.searchQuery.trim()))
      return <div>{t('emptySearch')}</div>;
    return <div>{t('messageEmptyChat')}</div>;
  };
  const renderIndividualSection = () => {
    if (state.loading)
      return (
        <S.Spinner>
          <FontAwesomeIcon icon={faSpinnerThird} size="2x" spin />
        </S.Spinner>
      );
    if (individualRooms.length > 0 && isEmpty(state.searchQuery.trim()))
      return individualRooms.map(cRoom => (
        <PersonItemChat key={cRoom.room.roomId} chatRoom={cRoom} onClick={newChat} />
      ));
    if (state.individuals.length > 0)
      return state.individuals.map(cRoom => (
        <PersonItemChat key={cRoom.uniq} chatRoom={cRoom} onClick={openNewChat} />
      ));
    if (state.individuals.length === 0 && !isEmpty(state.searchQuery.trim()))
      return <div>{t('emptySearch')}</div>;
    return <div>{t('messageEmptyChat')}</div>;
  };

  return (
    <S.Wrapper>
      <S.ChatListPanel listOpen={chat.listOpen}>
        <S.ChatIcon id="chat-list" type="button" onClick={toggleOpenHandler}>
          {state.messagesCount > 0 && (
            <S.BadgeContainer>
              <Badge count={state.messagesCount} size="sm" />
            </S.BadgeContainer>
          )}
          <FontAwesomeIcon icon={faCommentLines} className="icon" />
        </S.ChatIcon>
        <S.SearchWrapper>
          <FontAwesomeIcon icon={faSearch} className="icon" />
          <S.SearchField
            type="text"
            placeholder={t('searchPlaceholder')}
            value={state.searchQuery}
            onChange={handleSearchUsers}
          />
          {!isEmpty(state.searchQuery.trim()) && (
            <S.ClearSearch type="button" onClick={clearSearchHandler}>
              <FontAwesomeIcon icon={faTimes} />
            </S.ClearSearch>
          )}
        </S.SearchWrapper>
        <ChatListContainer>
          <ChatSection title={t('channelSection')} createChannel>
            {renderChannelSection()}
          </ChatSection>
          <ChatSection title={t('titleSection')} className="section">
            {renderIndividualSection()}
          </ChatSection>
        </ChatListContainer>
      </S.ChatListPanel>
      {chat.chatVisible && chattingWith && (
        <Chat
          roomId={chat.activeRoomId!}
          chattingWith={chattingWith}
          defaultMessage={chat.defaultMessage}
        />
      )}
    </S.Wrapper>
  );
};

export default ChatList;

type StoreChatMessage = {
  roomId: number;
  userId: number;
  name: string;
  roomName: string;
  roomType: ChatRoomTypes;
};

function useChatList({ getMessagesCount }: { getMessagesCount: () => Promise<void> }) {
  const { network } = useAuth();
  const dispatch: AppDispatch = useDispatch();

  const [storeMessages, setStoreMessages] = useState<Map<number, StoreChatMessage> | undefined>(
    () => {
      let sessionMessages = storage.get<[number, StoreChatMessage][]>(
        'newTitleChatMessage',
        'sessionStorage'
      );

      if (sessionMessages) return new Map(sessionMessages);

      return undefined;
    }
  );

  useEffect(() => {
    const channel = new BroadcastChannel('sw-notification');

    function messageReceived(e: MessageEvent<{ room: ChatRoom }>) {
      dispatch(openChatRoom({ roomId: e.data.room.roomId }));
      PubSub.publish(NotificationMessageType[NotificationMessageType.NewChatMessage], e.data);
    }

    channel.addEventListener('message', messageReceived);

    return () => {
      channel.removeEventListener('message', messageReceived);
    };
  }, [dispatch]);

  useEffect(() => {
    const bc = new BroadcastChannel('new-message');

    function messageReceived(e: any) {
      getMessagesCount();
      setStoreMessages(setMessageNotification(e.data));
    }

    bc.addEventListener('message', messageReceived);

    return () => {
      bc.removeEventListener('message', messageReceived);
    };
  }, [getMessagesCount]);

  useEffect(() => {
    let interval: NodeJS.Timer;

    const title = `${network.name} | ${APP_TITLE}`;

    if (storeMessages) {
      const chatMessages = storeMessages;

      if (chatMessages.size > 0) {
        let count = chatMessages.size;

        let keys = chatMessages.keys();

        interval = setInterval(() => {
          const nextKey = keys.next();

          if (nextKey.done) {
            document.title = title;
            keys = chatMessages.keys();
          } else {
            const message = chatMessages.get(nextKey.value)!;

            document.title = `(${count}) ${
              message.roomName && message.roomType === 2 ? message.roomName + ' |' : ''
            } ${
              message.name === APP_TITLE
                ? network.name + ' | ' + APP_TITLE
                : message.name + ' enviou uma mensagem | ' + APP_TITLE
            }`;
          }
        }, 2200);
      } else {
        document.title = title;
      }
    }

    return () => {
      clearInterval(interval);
    };
  }, [network.name, storeMessages]);

  useEffect(() => {
    const subscription = PubSub.subscribe('READ_CHAT', (_, value) => {
      setStoreMessages(value);
      getMessagesCount();
    });

    return () => {
      PubSub.unsubscribe(subscription);
    };
  }, [getMessagesCount]);

  return { setStoreMessages };
}

function setMessageNotification(messageResult: NewMessageResult) {
  let messageMap = new Map<number, StoreChatMessage>([
    [
      messageResult.room.roomId,
      {
        roomId: messageResult.room.roomId,
        userId: messageResult.message.message.userId,
        name: messageResult.message.user.name,
        roomName: messageResult.room.roomName ?? '',
        roomType: messageResult.room.type,
      },
    ],
  ]);

  const storeMessages = storage.get<[number, StoreChatMessage][]>(
    'newTitleChatMessage',
    'sessionStorage'
  );

  if (storeMessages) {
    const messageList = new Map(storeMessages);
    messageMap = new Map([...messageList, ...messageMap]);
  }

  const messages = Array.from(messageMap);
  storage.set(
    {
      key: 'newTitleChatMessage',
      value: messages,
    },
    'sessionStorage'
  );

  return messageMap;
}
