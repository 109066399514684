import { Profile } from 'lib/apiActions';
import { useMemo } from 'react';
import { AsyncStateStatus, useAsync } from 'react-async-hook';

//#region TYPES
export interface FetchProfileParams {
  networkId: number;
  userId?: number;
  pageId?: number;
  name?: string;
  pageNumber?: number;
  pageSize?: number;
}

export type ProfileType = 'user' | 'page';

export type ReturnType<T> = {
  loading: boolean;
  result:
    | (T extends 'user'
        ? { status: number; users: UserFetchData }
        : T extends 'page'
        ? Pages
        : never)
    | undefined;
  execute: (
    ...args: any[]
  ) => Promise<
    | (T extends 'user'
        ? { status: number; users: UserFetchData }
        : T extends 'page'
        ? Pages
        : never)
    | undefined
  >;
  status: AsyncStateStatus;
};

//#endregion

function useFetchProfile<T extends ProfileType>(
  params: FetchProfileParams,
  type: T,
  args: any[] = []
): ReturnType<T> {
  const profileAsync = useAsync(async () => {
    switch (type) {
      case 'user':
        return Profile.getUser(params);
      case 'page':
        return Profile.getPage(params);
      default:
        throw new Error('Type is neither "user" or "page"');
    }
  }, args);

  return useMemo(
    () => ({
      loading: profileAsync.loading,
      result: profileAsync.result as ReturnType<T>['result'],
      execute: profileAsync.execute as ReturnType<T>['execute'],
      status: profileAsync.status,
    }),
    [profileAsync.execute, profileAsync.loading, profileAsync.result, profileAsync.status]
  );
}

export default useFetchProfile;
