import { useMemo } from 'react';
import { useSelector } from 'store';
import { parseBoolean } from 'lib/helper';
import { createSelector } from 'reselect';

const togglesSelector = createSelector(
  (state: RootState) => state.auth,
  auth => auth.featureToogle
);

export default function useFeatureToggle(...featureNames: string[]) {
  const features = useSelector(togglesSelector);

  const toggles = useMemo(
    () => featureNames.map(name => parseBoolean(features[name])),
    [featureNames, features]
  );

  return toggles;
}
