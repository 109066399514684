import { useAsyncCallback } from 'react-async-hook';
import axios from 'axios';
import { serialize } from 'object-to-formdata';

//#region TYPES
type CreateBanerParams = {
  image: File;
  linkText: string;
  width: number;
  height: number;
  x: number;
  y: number;
  zoom: number;
};
type GetBannerParams = {
  onlyPublished?: boolean;
  pageSize?: number;
};
//#endregion

async function createBanner(bannerData: CreateBanerParams) {
  const formData = serialize(bannerData);
  const { data, status } = await axios.post<BannerInfo[]>('/api/banner/createBanner', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  if (status === 200) return data[0];
  return null;
}

async function publishBanner(bannerIds: number[]) {
  const response = await axios.patch('/api/banner/publishBanner', bannerIds);

  return response.status;
}

async function getBanner(params?: GetBannerParams) {
  const { data } = await axios.get<BannerDataset>('/api/banner/getBanner', { params });

  return data.banners;
}

async function deleteBanner(bannerId: number) {
  const response = await axios.delete('/api/banner/deleteBanner', { params: { bannerId } });

  return response.status;
}

function useBanner() {
  const createBannerAsync = useAsyncCallback(createBanner);
  const publishBannerAsync = useAsyncCallback(publishBanner);
  const getBannerAsync = useAsyncCallback(getBanner);
  const deleteBannerAsync = useAsyncCallback(deleteBanner);

  return { createBannerAsync, publishBannerAsync, getBannerAsync, deleteBannerAsync };
}

export default useBanner;
