import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Menu, Dropdown } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookmark,
  faHome,
  faUserFriends,
  faUsers,
  faUserShield,
  faQuestion,
} from '@fortawesome/pro-solid-svg-icons';
import { useAuth, usePermissions } from 'hooks';

import VerticalMenu from 'components/common/VerticalMenu';

import packageJson from '../../../../../package.json';

type GlobalVersion = typeof global & { appVersion: string };
const globalApp = global as GlobalVersion;
globalApp.appVersion = packageJson.version;

// #region STYLES
const legalItemGroup = css`
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled.div`
  margin: 4px;

  a {
    color: ${({ theme }) => theme.colors.gray10};
  }
`;
const Button = styled.button`
  padding: 0;
  cursor: pointer;
  background: none;
  border: 0;
  outline: 0;
`;
const SupportLink = styled.a`
  color: inherit;
  :hover {
    color: inherit;
  }
`;
const Label = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.gray9};
`;
// #endregion

const NavigationMenu = () => {
  const { t } = useTranslation();
  const { networkSettings, networkFunctions } = useAuth();

  const [createBanner, manageRoles, manageNetwork, manageUsers, manageGroups] = usePermissions(
    'CreateBanner',
    'ManageRoles',
    'ManageNetwork',
    'ManageUsers',
    'ManageGroups'
  );

  const currentVersion = globalApp.appVersion;

  const checkManagement =
    createBanner || manageRoles || manageNetwork || manageUsers || manageGroups;

  const { current: menuItems } = useRef<Array<[string, React.ReactNode]>>(
    (() => {
      const menu: Array<[string, React.ReactNode]> = [
        ['feed', <VerticalMenu.NavMenuItem to="/feed" label={t('menu.feed')} icon={faHome} />],
        [
          'users',
          <VerticalMenu.NavMenuItem to="/users" label={t('menu.people')} icon={faUserFriends} />,
        ],
        [
          'groups',
          <VerticalMenu.NavMenuItem to="/groups" label={t('menu.groups')} icon={faUsers} />,
        ],
      ];

      if (networkFunctions.markers)
        menu.push([
          'bookmarks',
          <VerticalMenu.NavMenuItem
            to="/bookmarks"
            label={t('menu.bookmarks')}
            icon={faBookmark}
          />,
        ]);

      return menu;
    })()
  );

  const overlayMenu = (
    <Menu>
      <Menu.ItemGroup key="help" title={t('common.help')}>
        <Menu.Item>
          <MenuItem key="support">
            <SupportLink
              href={`mailto:${networkSettings.supportEmail ?? ''}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('common.support')}
            </SupportLink>
          </MenuItem>
        </Menu.Item>
        <Menu.Item>
          <MenuItem key="status">
            <SupportLink href="http://status.4bee.com.br" target="_blank" rel="noopener noreferrer">
              Status
            </SupportLink>
          </MenuItem>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup css={legalItemGroup} key="legal" title={t('common.legal')}>
        <Menu.Item>
          <MenuItem key="termsOfUse">
            <Link to="/legal/termsOfUse">{t('termsOfUse.menuTitle')}</Link>
          </MenuItem>
        </Menu.Item>
        <Menu.Item>
          <MenuItem key="lgpd">
            <Link to="/legal/lgpd">{t('lgpdTerms.menuTitle')}</Link>
          </MenuItem>
        </Menu.Item>
        <Menu.Item>
          <MenuItem key="privacy">
            <Link to="/legal/privacyPolicies">{t('privacyPolicies.menuTitle')}</Link>
          </MenuItem>
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup css={legalItemGroup} key="legal" title={t('currentVersion')}>
        <Menu.Item>
          <MenuItem key="currentVersion">
            <Label>{currentVersion}</Label>
          </MenuItem>
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  const { current: bottomMenuItems } = useRef(
    (() => {
      const bMenu: Array<[string, React.ReactNode]> = [
        [
          'about',
          <Dropdown overlay={overlayMenu} placement="topRight">
            <Button type="button">
              <FontAwesomeIcon className="anticon" icon={faQuestion} />{' '}
              <span>{t('menu.about')}</span>
            </Button>
          </Dropdown>,
        ],
      ];

      if (checkManagement)
        bMenu.unshift([
          'admin',
          <VerticalMenu.NavMenuItem
            key="administration"
            to="/admin"
            label={t('menu.administration')}
            icon={faUserShield}
          />,
        ]);

      return bMenu;
    })()
  );

  return (
    <>
      <VerticalMenu menuItems={menuItems} bottomMenuItems={bottomMenuItems} />
    </>
  );
};

export default NavigationMenu;
