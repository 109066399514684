import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Dropdown, Menu, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';

import { useFeatureToggle, usePermissions } from 'hooks';
import { PROFILE_TYPES } from 'lib/constants';
import { useSelector } from 'store';
import { changeVisibility, addTarget } from 'store/actions/newPostActions';
import { Visibility } from 'store/types/newPostTypes';

import { useGroupInfo } from 'components/Groups/GroupStore';

import * as S from './GroupScopeSelectorStyles';

//#region TYPES
interface GroupScopeSelectorProps {
  disabled?: boolean;
  storeKey: 'NEW_POST_GROUP';
  onScopeChanged?: (scope: Omit<'followers', Visibility>) => void;
}

interface ScopeProps {
  className?: string;
  selected: boolean;
  isNewComposer: boolean;
}
interface GroupScopeProps extends ScopeProps {
  groupName: string;
}

//#endregion

const Group: React.VFC<GroupScopeProps> = ({ className, groupName, selected, isNewComposer }) => {
  return (
    <S.Label className={className} selected={selected} isNewComposer={isNewComposer}>
      {groupName}
    </S.Label>
  );
};
const Network: React.VFC<ScopeProps> = ({ className, selected, isNewComposer }) => {
  const { t } = useTranslation();

  return (
    <S.Label className={className} selected={selected} isNewComposer={isNewComposer}>
      {t('new-post.visibility.network')}
    </S.Label>
  );
};
const Admins: React.VFC<ScopeProps> = ({ className, selected, isNewComposer }) => {
  const { t } = useTranslation();

  return (
    <S.Label className={className} selected={selected} isNewComposer={isNewComposer}>
      {t('new-post.visibility.admin')}
    </S.Label>
  );
};
const Private: React.VFC<ScopeProps> = ({ className, selected, isNewComposer }) => {
  const { t } = useTranslation();

  return (
    <S.Label className={className} selected={selected} isNewComposer={isNewComposer}>
      {t('new-post.visibility.private')}
    </S.Label>
  );
};

const GroupScopeSelector: React.VFC<GroupScopeSelectorProps> = ({
  disabled = false,
  storeKey,
  onScopeChanged,
}) => {
  const { t } = useTranslation();
  const [createPublicPostForPage] = usePermissions('createPublicPostForPage');
  const [isNewComposer] = useFeatureToggle('new_composer');

  const groupInfo = useGroupInfo();
  const dispatch = useDispatch();

  const currentProfile = useSelector(state => state.newPost.posts[storeKey]?.profile);
  const { visibility } = useSelector(state => {
    const newPostStore = state.newPost.posts[storeKey];
    return {
      visibility: newPostStore && newPostStore.visibility,
    };
  });

  const { pageId, name, public: isPublic } = groupInfo.page;

  useEffect(() => {
    if (
      !createPublicPostForPage ||
      currentProfile?.profileType === PROFILE_TYPES.user ||
      !isPublic
    ) {
      dispatch(changeVisibility(storeKey, 'group'));
      dispatch(addTarget(storeKey, [pageId]));
    } else {
      dispatch(changeVisibility(storeKey, 'public'));
      dispatch(addTarget(storeKey, []));
    }
  }, [currentProfile?.profileType, dispatch, isPublic, pageId, createPublicPostForPage, storeKey]);

  const handleVisibility = ({ key }: { key: React.Key }) => {
    if (key === visibility) return;
    dispatch(changeVisibility(storeKey, key as Visibility));

    switch (key) {
      case 'group':
      case 'admin': {
        dispatch(addTarget(storeKey, [pageId]));
        break;
      }
      case 'public':
      case 'private': {
        dispatch(addTarget(storeKey, []));
        break;
      }
      default: {
        dispatch(addTarget(storeKey, []));
        break;
      }
    }

    onScopeChanged?.(key as Omit<'followers', Visibility>);
  };

  const getToggleButton = (visibility: Omit<Visibility, 'followers'> | undefined) => {
    switch (visibility) {
      case 'group':
        return (
          <Group
            css={S.scopeSelected}
            groupName={name}
            selected={visibility === 'group'}
            isNewComposer={isNewComposer}
          />
        );
      case 'public':
        return (
          <Network
            css={S.scopeSelected}
            selected={visibility === 'public'}
            isNewComposer={isNewComposer}
          />
        );
      case 'admin':
        return (
          <Admins
            css={S.scopeSelected}
            selected={visibility === 'admin'}
            isNewComposer={isNewComposer}
          />
        );
      case 'private':
        return (
          <Private
            css={S.scopeSelected}
            selected={visibility === 'private'}
            isNewComposer={isNewComposer}
          />
        );
      default: {
        if (!createPublicPostForPage)
          return (
            <Group
              css={S.scopeSelected}
              groupName={name}
              selected={visibility === 'group'}
              isNewComposer={isNewComposer}
            />
          );
        return (
          <Network
            css={S.scopeSelected}
            selected={visibility === 'public'}
            isNewComposer={isNewComposer}
          />
        );
      }
    }
  };

  const tooltip = (tooltip: string) => (
    <div style={{ display: 'flex' }}>
      <S.Icon>
        <FontAwesomeIcon icon={faInfoCircle} style={{ marginRight: 5 }} />
      </S.Icon>
      <div>{t(`new-post.visibility.${tooltip}`)}</div>
    </div>
  );

  const menu = (
    <Menu onClick={handleVisibility}>
      {createPublicPostForPage &&
        currentProfile?.profileType === PROFILE_TYPES.group &&
        isPublic && (
          <Menu.Item key="public">
            <Network selected={visibility === 'public'} isNewComposer={isNewComposer} />
            <Tooltip arrowPointAtCenter placement="bottomLeft" title={tooltip('networkTooltip')}>
              <S.Icon as="span">
                <FontAwesomeIcon icon={faInfoCircle} />
              </S.Icon>
            </Tooltip>
          </Menu.Item>
        )}
      <Menu.Item key="group">
        <Group groupName={name} selected={visibility === 'group'} isNewComposer={isNewComposer} />
      </Menu.Item>
      {currentProfile?.profileType === PROFILE_TYPES.group && isPublic && (
        <Menu.Item key="private">
          <Private selected={visibility === 'private'} isNewComposer={isNewComposer} />
          <Tooltip arrowPointAtCenter placement="bottomLeft" title={tooltip('membersTooltip')}>
            <S.Icon as="span">
              <FontAwesomeIcon icon={faInfoCircle} />
            </S.Icon>
          </Tooltip>
        </Menu.Item>
      )}
      {currentProfile?.profileType === PROFILE_TYPES.user && (
        <Menu.Item key="admin">
          <Admins selected={visibility === 'admin'} isNewComposer={isNewComposer} />
          <Tooltip arrowPointAtCenter placement="bottomLeft" title={tooltip('adminTooltip')}>
            <S.Icon as="span">
              <FontAwesomeIcon icon={faInfoCircle} />
            </S.Icon>
          </Tooltip>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown disabled={disabled} overlay={menu}>
      <S.Button isNewComposer={isNewComposer}>
        {/* <span className="prefix">{t('groups.postTo')}</span> */}
        <div
          style={{
            maxWidth: 200,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {getToggleButton(visibility)}{' '}
        </div>
        <FontAwesomeIcon icon={faCaretDown} className="caret" />
      </S.Button>
    </Dropdown>
  );
};

export default GroupScopeSelector;
