import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const postHeader = css`
  margin-bottom: 1rem;
`;

export const ContainerPreview = styled.div`
  margin-top: 16px;
`;
export const ErrorMessage = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.danger};

  ${({ theme }) => theme.typography.fontxSmall('20px')}
`;
