import { generate } from '@ant-design/colors';
import defaultTheme from './default';

export default function generateHexTheme(colorHex: string) {
  const [primary1, primary2, primary3, primary4, primary5, primary6, primary7] = generate(colorHex);

  const primaryColor = primary6;

  const theme: import('@emotion/react').Theme = {
    ...defaultTheme,
    colors: {
      ...defaultTheme.colors,
      primary: primaryColor,
      primary1,
      primary2,
      primary3,
      primary4,
      primary5,
      primary6,
      primary7,
    },
  };

  return theme;
}
