import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { css as cssName } from '@emotion/css';
import { rgba } from 'polished';
import { Avatar } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faCheck, faTimes, faClock } from '@fortawesome/pro-solid-svg-icons';

import useNotification from 'hooks/useNotification';
import useAuth from 'hooks/useAuth';
import { setRead } from 'store/actions/notificationsPanelActions';
import { openChatRoom } from 'store/actions/chatActions';
import { channelPhoto } from 'lib/getProfilePhoto';
import { ITEM_TYPES } from 'lib/constants';

import { transparentButton } from 'styles/component-styles/buttons';

import ProfileImage from './ProfileImage/ProfileImage';

export const TYPE_VIDEO_PROCESSING = 1;
export const TYPE_VIDEO_READY = 2;
export const TYPE_POST_SCHEDULED = 3;

// #region STYLES
const linkContainer = unread => theme =>
  css`
    display: flex;
    align-items: baseline;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 8px 12px;
    padding-top: 8px !important;
    border-bottom: 1px solid ${theme.colors.gray3};

    ${unread &&
    css`
      background-color: ${rgba(theme.colors.primary, 0.2)};
      border-bottom: 0;
    `}
  `;
const box = css`
  position: relative;
  padding: 8px 12px 8px 12px;
  overflow: visible;
  border-radius: 8px;
`;
const boxClassName = cssName`
  ${box};
`;
const iconClose = theme => css`
  color: ${theme.colors.gray1};

  ${theme.typography.fontSmall()};
`;
const notificationIcon = theme => css`
  color: ${theme.colors.gray9};

  ${theme.typography.fontNormal()};
`;
const image = css`
  margin-left: 12px;
`;

const NotificationPanel = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  overflow: visible;
  border-radius: 8px 8px 8px 8px;
`;
const PanelIcon = styled.i`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.gray10};
  vertical-align: 1px;
`;
const NotificationTitle = styled.span`
  margin-left: 8px;
  font-size: 12.6px;
  font-weight: bold;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.gray10};
`;
const NotificationTime = styled.span`
  padding-right: 10px;
  font-size: 0.79rem;
  font-size: 11.2px;
  font-weight: normal;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.gray7};
`;
const TitleSeparator = styled(NotificationTime)`
  padding-right: 0;
  margin: 0 6px;
`;
const NotificationBody = styled.span`
  padding-top: 10px;
  font-size: 12.6px;
  color: ${({ theme }) => theme.colors.gray7};
  text-align: left;
`;
const NotificationImage = styled.div`
  ${image};
`;
const BirthdayItem = styled.div`
  margin-bottom: -10px;
`;
const BirthdayMessage = styled.button`
  padding: 0;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
  vertical-align: top;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  transition: all 0.2s ease-out;

  ${({ theme }) => theme.typography.fontxSmall('20px')};
`;

const ToasterButton = styled.button`
  ${transparentButton};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  text-align: left;
`;
const ToasterContainer = styled.div`
  flex: 1;
`;
const ToasterTitleContainer = styled.div`
  display: flex;
`;
const ToasterTitle = styled.h5`
  margin-left: 8px;
  font-size: 12.6px;

  color: ${({ theme }) => theme.colors.gray10};
`;
const ToasterBody = styled.span`
  padding-top: 8px;
  color: ${({ theme }) => theme.colors.gray7};
  text-align: left;

  ${({ theme }) => theme.typography.fontSmall()};
`;
const ToasterImage = styled.div`
  ${image};
`;
const CloseNotification = styled.button`
  position: absolute;
  top: -8px;
  right: -8px;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background: ${({ theme }) => theme.colors.gray7};
  border-radius: 28px;

  :hover {
    background: ${({ theme }) => theme.colors.gray9};
  }
`;
// #endregion

function getIcon(type) {
  switch (type) {
    case TYPE_VIDEO_PROCESSING:
      return faVideo;
    case TYPE_VIDEO_READY:
      return faCheck;
    case TYPE_POST_SCHEDULED:
      return faClock;
    default:
      return faCheck;
  }
}
function getOptions() {
  return {
    autoClose: 3000,
    closeOnClick: true,
    draggable: false,
    hideProgressBar: false,
    pauseOnHover: true,
    position: toast.POSITION.TOP_RIGHT,
    closeButton: <CloseNotificationButton />,
    className: boxClassName,
  };
}

// TOAST
export function renderNotification(attributes) {
  const { type, action, title, body, profile } = attributes;
  return (
    <ToasterButton type="button" onClick={action && action}>
      <ToasterContainer>
        <ToasterTitleContainer>
          <FontAwesomeIcon css={notificationIcon} icon={getIcon(type)} />
          <ToasterTitle>{title}</ToasterTitle>
        </ToasterTitleContainer>
        <ToasterBody>{body}</ToasterBody>
      </ToasterContainer>
      <ToasterImage>{profile && <ProfileImage profile={profile} size="sm" />}</ToasterImage>
    </ToasterButton>
  );
}
export function renderToast(attributes) {
  toast(renderNotification(attributes), getOptions());
}

// PAINEL DE NOTIFICAÇÕES
export function RenderPanelItem({ notification, hide }) {
  const { attributes } = useNotification(notification);
  const { t } = useTranslation();
  const { token, userId } = useAuth();
  const { itemId, itemType, type: typeToRead } = notification.notification;
  const { emitters } = notification;
  const personCount = emitters?.length ?? 0;
  const dispatch = useDispatch();
  const { icon, title, body, created, profile, type, read, path } = attributes;

  const linkAction = event => {
    event.stopPropagation();
    hide();
    if (itemType === ITEM_TYPES.chatChannel) {
      event.preventDefault();
      dispatch(openChatRoom({ roomId: profile.roomId }));
    }
    dispatch(setRead(userId, itemId, itemType, typeToRead));
  };

  const renderImage = () => {
    if (itemType === ITEM_TYPES.chatChannel) {
      return (
        <Avatar size="large" src={channelPhoto(profile.roomId, token)}>
          {profile.name.substring(0, 1)}
        </Avatar>
      );
    }
    return profile && <ProfileImage profile={profile} size="sm" />;
  };

  return (
    <Link css={linkContainer(!read)} to={path} onClick={linkAction}>
      <NotificationPanel>
        <div>
          <div>
            <PanelIcon>
              <FontAwesomeIcon icon={icon} />
            </PanelIcon>
            <NotificationTitle>{title}</NotificationTitle>
            <TitleSeparator as="span">•</TitleSeparator>
            <NotificationTime>{moment(created).fromNow()}</NotificationTime>
          </div>
          <NotificationBody>{body}</NotificationBody>
          {type === ITEM_TYPES.birthday && personCount === 1 && (
            <BirthdayItem>
              <BirthdayMessage
                type="button"
                onClick={event => {
                  event.preventDefault();
                  dispatch(openChatRoom({ userId: notification.emitters[0].id }));
                }}
              >
                {t('notification.birthdays.sendBirthdays')}
              </BirthdayMessage>
            </BirthdayItem>
          )}
        </div>
        <NotificationImage>{renderImage()}</NotificationImage>
      </NotificationPanel>
    </Link>
  );
}

const CloseNotificationButton = () => {
  return (
    <CloseNotification>
      <FontAwesomeIcon css={iconClose} icon={faTimes} />
    </CloseNotification>
  );
};
