import styled from '@emotion/styled';
import { tint } from 'polished';

export const SectionContainer = styled.div`
  max-height: 90%;
  overflow-y: auto;
`;

export const SectionTitle = styled.h2`
  display: flex;
  justify-content: space-between;
  padding-right: 8px;
  margin-bottom: 27px;
  font-weight: bold;
  color: #fff;
`;

export const CreateButton = styled.button`
  height: 20px;
  padding: 2px 8px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.gray4};
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.gray9};
  border: 0;
  border-radius: 10px;
  outline: 0;

  ${({ theme }) => theme.typography.fontxSmall(1.43)};

  &:hover {
    background-color: ${({ theme }) => tint(0.1, theme.colors.gray9)};
  }
  &:active {
    background-color: ${({ theme }) => tint(0.04, theme.colors.gray9)};
  }

  .icon {
    margin-right: 2px;
  }
`;
