import React from 'react';

import { css } from '@emotion/react';
import styled from '@emotion/styled';

import CommentTextEditor from '../../Common/CommentTextEditor';
import ProfileImage, { profileTypes } from '../../Common/ProfileImage/ProfileImage';

// #region STYLES
const avatar = css`
  margin-right: 8px;
`;

const Wrapper = styled.div`
  width: 100%;
`;
const AvatarWrapper = styled.div`
  float: left;
`;
const EditorWrapper = styled.div`
  float: left;
  width: 100%;
  padding-left: 40px;
  margin-left: -40px;
`;
// #endregion

function EditComment({ fullComment, author, token }) {
  return (
    <Wrapper>
      <AvatarWrapper>
        <ProfileImage
          css={avatar}
          profile={{
            profileId: author.userId,
            name: author.name,
            profileType: profileTypes.user,
          }}
          size="xs"
        />
      </AvatarWrapper>
      <EditorWrapper>
        <CommentTextEditor
          commentMediaEdit={fullComment.media}
          commentLink={fullComment.linkInfo}
          token={token}
          autoFocus
          commentEdit={fullComment}
          postId={fullComment.comment.itemId}
          userId={fullComment.comment.userId}
          isEditComment
        />
      </EditorWrapper>
    </Wrapper>
  );
}

export default EditComment;
