import React, { useLayoutEffect, useRef } from 'react';
import { Button, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactionOptions from './ReactionOptions';

import * as S from './ReactionButtonCommentStyles';

export type ReactionButtonCommentProps = {
  reaction: LikeReaction | undefined;
  onReactionClick: (reaction: ReactionLiteral) => void;
  onRemoveReaction: () => void;
};

export default function ReactionButtonComment({
  reaction,
  onReactionClick,
  onRemoveReaction,
}: ReactionButtonCommentProps) {
  const { t } = useTranslation();

  const btnLikeRef = useRef<React.ElementRef<typeof Button>>(null);

  const likeClickHandler = () => {
    if (!reaction) onReactionClick('like');
    else onRemoveReaction();
  };

  useLayoutEffect(() => {
    btnLikeRef.current?.blur();
  }, [reaction]);

  return (
    <Popover
      content={<ReactionOptions onChosenReaction={onReactionClick} />}
      overlayClassName={S.popover}
      overlayInnerStyle={{ borderRadius: 50 }}
    >
      <Button
        css={S.button(!!reaction)}
        size="small"
        type="link"
        onClick={likeClickHandler}
        ref={btnLikeRef}
      >
        {t(`reactions.${reaction?.name ?? 'like'}`)}
      </Button>
    </Popover>
  );
}
