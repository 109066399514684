export default {
  passwordStep: {
    description: 'Você pode acessar sua rede também via código único por e-mail.',
    emailWith: 'No e-mail com início <1>{{value}}</1>',
    title: 'Informe sua senha',
    keepMeConnected: 'Mantenha-me conectado',
    minChars: 'Digite sua senha',
  },
  usernameStep: {
    continue: 'Continuar',
    description: 'Olá! Para iniciar sua sessão, informe seu usuário ou e-mail.',
    title: 'Entrar',
    userEmail: 'Usuário/E-mail',
    errors: {
      404: 'Não encontramos uma conta associada a este usuário.',
      '404_description': 'Verifique com o gestor da sua rede se não for este o caso.',
      500: 'Houve um problema, não conseguimos completar o processo.',
      '500_description': 'Tente novamente mais tarde ou entre em contato com seu gestor da rede.',
    },
  },
  verificationStep: {
    description_email:
      'Enviamos um código para o e-mail <1>{{value}}</1>. Digite o código para entrar na sua rede.',
    description_phone:
      'Enviamos um código por sms para o número <1>{{value}}</1>. Digite o código para entrar na sua rede',
    enterCode: 'Insira o código',
    resendCode: 'Reenviar código',
    title: 'Informe o código',
    errors: {
      401: 'O código informado está inválido. Solicite um novo código e tente novamente',
      500: 'Houve um problema, não conseguimos completar o processo',
    },
  },
};
