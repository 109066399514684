// stylelint-disable selector-type-no-unknown
import React, { useMemo } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import has from 'lodash/fp/has';

import * as S from './FormsStyles';

//#region TYPES
export type FieldErrors<TError extends FieldValues = FieldValues> =
  import('react-hook-form').FieldErrors<TError>;
type FieldValues = Record<string, any>;

interface FooterProps<TError extends FieldValues = FieldValues> {
  children?: string;
  errors?: FieldErrors<TError>;
  name: string;
}

export interface FormItemProps<TError extends FieldValues = FieldValues> {
  errors?: FieldErrors<TError>;
  forInput?: string;
  footerDescription?: string;
  inputName: string;
  label?: string;
  labelHelper?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  children: React.ReactNode;
}
//#endregion

const Footer: React.VFC<FooterProps> = ({ children, errors, name }) => {
  if (has(name, errors))
    return <ErrorMessage as={<S.Small isInvalid />} errors={errors} name={name} />;
  return <>{children && <S.Small>{children}</S.Small>}</>;
};

export const FormItem: React.VFC<FormItemProps> = ({
  errors,
  forInput,
  footerDescription,
  inputName,
  label,
  labelHelper,
  className = '',
  style,
  children,
}) => {
  const composedChildren = useMemo(() => {
    const props = {} as Record<string, any>;
    props.name = inputName;
    if (forInput) props.id = forInput;

    let elements = React.Children.toArray(children);
    const clone = React.cloneElement(elements[0] as React.ReactElement, props);
    elements = [
      clone,
      <Footer key="form-footer" errors={errors} name={inputName}>
        {footerDescription}
      </Footer>,
      ...elements.slice(1),
    ];

    return elements;
  }, [children, errors, footerDescription, forInput, inputName]);

  const helperElement = () => {
    if (labelHelper) {
      if (React.isValidElement(labelHelper)) {
        return labelHelper;
      }
      return <small className="label-helper">{labelHelper}</small>;
    }
    return undefined;
  };

  return (
    <S.ItemContainer className={className} style={style}>
      <div className="label-container">
        {label && <label htmlFor={forInput}>{label}</label>}
        {helperElement()}
      </div>
      {composedChildren}
    </S.ItemContainer>
  );
};

export const FormItemWrapper: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  style,
}) => (
  <S.ItemWrapper className={className} style={style}>
    {children}
  </S.ItemWrapper>
);
