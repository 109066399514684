import { Theme, css } from '@emotion/react';
import styled from '@emotion/styled';
import { up } from 'styled-breakpoints';

import cover from 'assets/images/background-new-brand.svg';

const split = (theme: Theme) => css`
  height: 100%;

  ${up('tablet')({ theme })} {
    position: fixed;
    top: 0;
    width: 50%;
    overflow-x: hidden;
    z-index: 1;
  }
`;

export const Left = styled.div`
  left: 0;
  display: none;
  background-color: ${({ theme }) => theme.colors.primary};
  background-image: url('${cover}');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;

  ${up('tablet')} {
    ${({ theme }) => split(theme)}

    display: block;
  }
`;

export const Right = styled.div`
  ${({ theme }) => split(theme)}

  ${up('tablet')} {
    right: 0;
  }
`;

export const Centered = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: clamp(250px, 90vw, 384px);
  padding: 1rem;
`;
