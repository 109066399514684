import React from 'react';
import { Global } from '@emotion/react';
import 'styles/fonts.css';
import 'styles/index.less';

import styles from 'styles';
import antStyles from 'styles/ant-styles';

function GlobalStyles() {
  return (
    <>
      <Global styles={styles} />
      <Global styles={antStyles} />
    </>
  );
}

export default React.memo(GlobalStyles);
