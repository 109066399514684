import { useTranslation } from 'react-i18next';
import { Button, ButtonProps, Tooltip } from 'antd';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { faImage, faPaperclip, faVideo } from '@fortawesome/pro-solid-svg-icons';

import { useAuth } from 'hooks';
import ImageUploader from 'components/layout/Common/Uploader/ImageUploader/ImageUploader';
import VideoUploader from 'components/layout/Common/Uploader/VideoUploader/VideoUploader';
import FileUploader, { FileRejection } from 'components/layout/Common/Uploader/FileUploader';

export type UploadButtonProps = Pick<ButtonProps, 'type' | 'shape' | 'size'> & {
  content?: React.ReactNode;
  disabled?: boolean;
  icon?: FontAwesomeIconProps['icon'];
  onFileUploaded: (
    files: File[],
    uploadType: 'image' | 'video' | 'file',
    rejectedFiles?: FileRejection[]
  ) => void;
};

type UploadImageButtonProps = UploadButtonProps & {
  fileCount?: number;
};

type UploadFileButtonProps = UploadButtonProps & {
  onFileRejected?: (rejectedFiles: File[], fileRejections: FileRejection[]) => void;
};

export function UploadImageButton({
  content,
  disabled,
  fileCount = 0,
  icon = faImage,
  type = 'text',
  shape,
  size,
  onFileUploaded,
}: UploadImageButtonProps) {
  const { t } = useTranslation();
  const { networkSettings } = useAuth();

  const isDisabled = fileCount >= networkSettings.maxPhotosPermittedInPost || disabled;

  return (
    <ImageUploader
      disabled={isDisabled}
      onImageAdd={(acceptedFiles, rejectedFiles) =>
        onFileUploaded(acceptedFiles, 'image', rejectedFiles)
      }
    >
      <Tooltip placement="top" title={t('common.photos')}>
        <Button
          disabled={isDisabled}
          type={type}
          shape={shape}
          size={size}
          icon={<FontAwesomeIcon icon={icon} className={content ? 'anticon' : ''} />}
        >
          {content}
        </Button>
      </Tooltip>
    </ImageUploader>
  );
}

export function UploadVideoButton({
  content,
  disabled,
  icon = faVideo,
  type = 'text',
  shape,
  size,
  onFileUploaded,
}: UploadButtonProps) {
  const { t } = useTranslation();

  return (
    <VideoUploader
      disabled={disabled}
      onVideoAdd={(acceptedFiles: File[], rejectedFiles: FileRejection[]) =>
        onFileUploaded(acceptedFiles, 'video', rejectedFiles)
      }
    >
      <Tooltip placement="top" title={t('common.video')}>
        <Button
          disabled={disabled}
          type={type}
          shape={shape}
          size={size}
          icon={<FontAwesomeIcon icon={icon} className={content ? 'anticon' : ''} />}
        >
          {content}
        </Button>
      </Tooltip>
    </VideoUploader>
  );
}

export function UploadFileButton({
  content,
  disabled,
  icon = faPaperclip,
  type = 'text',
  shape,
  size,
  onFileUploaded,
  onFileRejected,
}: UploadFileButtonProps) {
  const { t } = useTranslation();

  return (
    <FileUploader
      disabled={disabled}
      onFileAdded={(acceptedFiles, rejectFiles) =>
        onFileUploaded(acceptedFiles, 'file', rejectFiles)
      }
      onFileRejected={onFileRejected}
    >
      <Tooltip placement="top" title={t('common.attachment')}>
        <Button
          disabled={disabled}
          type={type}
          shape={shape}
          size={size}
          icon={<FontAwesomeIcon icon={icon} className={content ? 'anticon' : ''} />}
        >
          {content}
        </Button>
      </Tooltip>
    </FileUploader>
  );
}
