import { useRef, useState, useEffect, useCallback } from 'react';
import PubSub from 'pubsub-js';

import styled from '@emotion/styled';
import { v4 } from 'uuid';
import { useAuth } from 'hooks';
import { fetchMedia, fetchVideoPreview } from 'lib/helper';

// #region STYLES
const Wrapper = styled.div`
  width: auto;
  text-align: center;
`;

const Video = styled.video`
  max-width: 100%;
  height: auto;
  border-radius: 5px;
`;
// #endregion

type VideoAttachmentProps = {
  mediaId: number;
};

function VideoAttachment({ mediaId }: VideoAttachmentProps) {
  const { token } = useAuth();
  const videoRef = useRef<HTMLVideoElement>(null);
  const key: string = v4();

  const [isHeightHigher, setHeightHigher] = useState(false);

  const stopVideo = useCallback(
    (_, data) => {
      if (key !== data && videoRef.current) videoRef.current.pause();
    },
    [key]
  );

  useEffect(() => {
    PubSub.subscribe('STOP_VIDEO', stopVideo);
    return () => {
      PubSub.unsubscribe('STOP_VIDEO');
    };
  }, [stopVideo]);

  const loadHandler = () => {
    if (videoRef.current) {
      const video = videoRef.current;
      setHeightHigher(video.clientHeight > video.clientWidth);
    }
  };

  return (
    <Wrapper>
      <Video
        ref={videoRef}
        controls
        controlsList="nodownload"
        preload="metadata"
        poster={fetchVideoPreview(mediaId, token)}
        style={{ width: isHeightHigher ? '50%' : '100%' }}
        onPlay={() => PubSub.publish('STOP_VIDEO', key)}
        onLoadedMetadata={loadHandler}
      >
        <source src={fetchMedia(mediaId, token)} type="video/mp4" />
        <track kind="captions" />
      </Video>
    </Wrapper>
  );
}

export default VideoAttachment;
