import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import linkifyHtml from 'linkifyjs/html';
import { Link } from 'react-router-dom';

// GLOBAL COMPONENTS
import { Menu, Dropdown, Button, Modal } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import { Animated } from 'react-animated-css';

import { useAuth, useFeatureToggle } from 'hooks';
import { ITEM_TYPES, REACTION_TYPE } from 'lib/constants';
import { like as likeApi } from 'lib/api';
import { useSelector } from 'store';
import { canUseFunction } from 'store/selectors/authSelectors';
import { deleteComment, _editComment } from 'store/actions/postsActions';
import { PostsActions } from 'store/types/types';

// COMPONENTS
import ReactionButtonComment from 'components/Reaction/ReactionButtonComment';
import Attachment from '../../Common/Attachment/Attachment';
import LikeComment from '../LikeComment/LikeComment';
import ProfileImage, { profileTypes } from '../../Common/ProfileImage/ProfileImage';
import EditComment from './EditComment';
import CommentLikeCount from './CommentLikeCount';

import {
  CommentHeader,
  CommentInteractions,
  CommentText,
  CommentTime,
  CommentWhen,
  ContentWrapper,
  Liked,
  Unliked,
  UserName,
  commentContainer,
  likeDivider,
  meatballMenu,
  userAvatar,
} from './CommentStyles';
import CommentReactionCounter from './CommentReactionCounter';

export interface CommentProps {
  fullComment: CommentInfo & { _new: boolean; _isEditing: boolean };
  commentText: string;
  userId: number;
}

const Comment = (props: CommentProps) => {
  const { fullComment, commentText, userId } = props;
  const { author, comment, media, linkInfo, likeCount, liked, reactionCount, reaction } =
    fullComment;
  const { commentId, itemId, createdDate: when } = comment;
  const attachment = media ?? linkInfo;

  // i18n
  const { t } = useTranslation();

  const { token, userInfo, userPermissions } = useAuth();
  const [reactionsFeature] = useFeatureToggle('reactions');

  // REDUX
  const dispatch: AppDispatch = useDispatch();
  const canLike = useSelector(state => canUseFunction(state, 'likes'));

  // STATE
  const [firstRender, setFirstRender] = useState(true);

  function setLike(payload: unknown) {
    dispatch({
      type: PostsActions.COMMENT_UPDATED_LIKE,
      payload,
    });
  }

  const handleDeleteComment = () => dispatch(deleteComment(fullComment));

  const reactionClickHandler = async (rl: ReactionLiteral) => {
    if (reaction?.name !== rl) {
      const payload = changeReaction(fullComment, rl, userId);

      setLike(payload);

      const status = await likeApi.changeReaction({
        itemId: commentId,
        itemType: ITEM_TYPES.comment,
        userId,
        reactionId: REACTION_TYPE[rl],
      });

      if (status !== 200) {
        setLike({
          commentId,
          itemId,
          like: liked,
          likeCount,
          reaction,
          reactionCount,
        });
      }
    }
  };

  const reactionRemoveHandler = async () => {
    const payload = removeReaction(fullComment);

    setLike(payload);

    const status = await likeApi.removeLike({
      itemId: commentId,
      itemType: ITEM_TYPES.comment,
      userId,
    });

    if (status !== 200) {
      setLike({
        commentId,
        itemId,
        like: liked,
        likeCount,
        reaction,
        reactionCount,
      });
    }
  };

  const onClickLikeComment = async () => {
    const params = {
      itemId: commentId,
      itemType: ITEM_TYPES.comment,
      userId,
    };

    if (liked) {
      setLike({ commentId, itemId, like: false, likeCount: likeCount - 1 });

      const status = await likeApi.removeLike(params);

      if (status !== 200) {
        setLike({ commentId, itemId, like: true, likeCount });
      }
    } else {
      setLike({ commentId, itemId, like: true, likeCount: likeCount + 1 });

      const status = await likeApi.sendLike(params);

      if (status !== 200) {
        setLike({ commentId, itemId, like: false, likeCount });
      }
    }
  };

  useEffect(() => setFirstRender(false), []);

  const renderLike = () => {
    if (reactionsFeature) {
      return (
        <ReactionButtonComment
          reaction={reaction}
          onReactionClick={reactionClickHandler}
          onRemoveReaction={reactionRemoveHandler}
        />
      );
    }

    return (
      <LikeComment
        likeComment={<Liked>{t('like')}</Liked>}
        unLikeComment={<Unliked>{t('like')}</Unliked>}
        likeState={liked}
        click={onClickLikeComment}
      />
    );
  };

  const renderLikeSeparator = () => {
    if (likeCount === 0) return null;
    return <FontAwesomeIcon css={likeDivider} icon={faCircle} />;
  };

  const renderCommentDropdown = () => {
    const isUserCommentAuthor = fullComment.comment.userId === userInfo.user.userId;
    const isContentAdministrator = !!userPermissions.ContentManagement;

    if (!isUserCommentAuthor && !isContentAdministrator) return null;

    const showDeleteModal = () => {
      Modal.confirm({
        title: t('comments.deleteCommentDescription'),
        okText: t('global.delete'),
        okType: 'danger',
        autoFocusButton: 'cancel',
        centered: true,
        onOk: handleDeleteComment,
      });
    };
    const menuAuthor = (
      <Menu>
        <Menu.Item onClick={() => dispatch(_editComment(fullComment))} key="edit">
          {t('global.edit')}
        </Menu.Item>
        <Menu.Item onClick={showDeleteModal} key="delete">
          {t('global.delete')}
        </Menu.Item>
      </Menu>
    );
    const menuAdministrator = (
      <Menu>
        <Menu.Item onClick={showDeleteModal} key="delete">
          {t('global.delete')}
        </Menu.Item>
      </Menu>
    );

    return (
      <Dropdown
        overlay={isUserCommentAuthor ? menuAuthor : menuAdministrator}
        trigger={['click']}
        placement="bottomRight"
      >
        <Button css={meatballMenu} type="link">
          <FontAwesomeIcon icon={faEllipsisH} />
        </Button>
      </Dropdown>
    );
  };

  const { _new } = fullComment;
  const content = () => {
    let _content = commentText;

    if (attachment && 'url' in attachment) {
      const httpRegex = /https?:\/\//;
      const previewUrl = attachment.url.replace(httpRegex, '');
      const textUrl = fullComment.comment.descriptionText.trim().replace(httpRegex, '');

      if (previewUrl === textUrl) _content = '';
    }

    return linkifyHtml(_content, {
      className: 'url-link',
      target: { email: '_blank' },
    });
  };

  return (
    <>
      {fullComment._isEditing ? (
        <EditComment fullComment={fullComment} author={author} token={token} />
      ) : (
        <Animated
          css={commentContainer}
          animationIn="fadeInDown"
          animationOut="fadeOutUp"
          animationInDuration={_new && firstRender ? 600 : 0}
          isVisible={!fullComment._isEditing}
        >
          <div>
            <ProfileImage
              css={userAvatar}
              profile={{
                profileId: author.userId,
                name: author.name,
                profileType: profileTypes.user,
              }}
              size="xs"
            />
            <ContentWrapper>
              <CommentHeader>
                <UserName>
                  <Link to={`/users/${author.userId}`}>
                    <b>{author.name}</b>
                  </Link>
                </UserName>
                {renderCommentDropdown()}
              </CommentHeader>
              <div>
                <CommentText dangerouslySetInnerHTML={{ __html: content() }} />
                {attachment && <Attachment media={attachment} attachmentMode="comment" />}
              </div>
            </ContentWrapper>
            <CommentInteractions>
              {canLike && renderLike()}
              {renderLikeSeparator()}
              {canLike &&
                reactionCount.length > 0 &&
                (reactionsFeature ? (
                  <CommentReactionCounter commentId={commentId} reactionList={reactionCount} />
                ) : (
                  <CommentLikeCount commentId={commentId} likeCount={likeCount} />
                ))}
              <CommentTime>
                <CommentWhen>{moment(when).fromNow()}</CommentWhen>
              </CommentTime>
            </CommentInteractions>
          </div>
        </Animated>
      )}
    </>
  );
};

export default Comment;

function changeReaction(commentInfo: CommentInfo, reaction: ReactionLiteral, userId: number) {
  let likeCount = commentInfo.likeCount;
  const strReaction = reaction ?? 'like';
  const reactionCount = structuredClone(commentInfo.reactionCount) as Reaction[];

  if (!commentInfo.reaction) {
    likeCount += 1;
    const reactionIndex = reactionCount.findIndex(r => r.name === strReaction);
    reactionCount.forEach(rc => (rc.totalCount = likeCount));

    if (reactionIndex > -1) {
      const currentReaction = reactionCount[reactionIndex];
      currentReaction.reactionCount += 1;
    } else {
      reactionCount.push({
        name: reaction ?? 'like',
        reactionCount: 1,
        totalCount: likeCount,
        reactionId: REACTION_TYPE[strReaction],
        deleted: false,
      });
    }
  } else {
    if (strReaction === commentInfo.reaction.name) return;

    const currentIndex = reactionCount.findIndex(r => r.name === commentInfo.reaction!.name);
    const currentReaction = reactionCount[currentIndex];
    const newIndex = commentInfo.reactionCount.findIndex(r => r.name === strReaction);

    if (currentReaction.reactionCount > 1) {
      currentReaction.reactionCount -= 1;
    } else {
      reactionCount.splice(currentIndex, 1);
    }

    if (newIndex > -1) {
      const newReaction = reactionCount[newIndex];
      newReaction.reactionCount += 1;
    } else {
      reactionCount.push({
        name: reaction ?? 'like',
        reactionCount: 1,
        totalCount: likeCount,
        reactionId: REACTION_TYPE[strReaction],
        deleted: false,
      });
    }
  }

  const { itemId: postId, commentId } = commentInfo.comment;

  return {
    itemId: postId,
    commentId,
    liked: true,
    likeCount,
    reaction: {
      itemId: commentId,
      itemType: ITEM_TYPES.comment,
      name: strReaction,
      reactionId: REACTION_TYPE[strReaction],
      userId: userId,
    },
    reactionCount,
  };
}

function removeReaction(commentInfo: CommentInfo) {
  const strReaction = commentInfo.reaction?.name || 'like';
  const reactionCount = structuredClone(commentInfo.reactionCount) as Reaction[];

  const reactionIndex = reactionCount.findIndex(r => r.name === strReaction);

  const likeReaction = reactionCount[reactionIndex];
  reactionCount.forEach(rc => (rc.totalCount -= 1));

  if (likeReaction.reactionCount === 1) {
    reactionCount.splice(reactionIndex, 1);
  } else {
    likeReaction.reactionCount -= 1;
  }

  const { itemId: postId, commentId } = commentInfo.comment;

  return {
    itemId: postId,
    commentId,
    liked: false,
    likeCount: commentInfo.likeCount - 1,
    reaction: undefined,
    reactionCount,
  };
}
